import React, { FC } from 'react'

import { Icon, Row, Text } from '../../../components'

import { useStyle } from './document-type-line.styles'
import { DocumentTypeLineProps } from './document-type-line.types'

export const DocumentTypeLine: FC<DocumentTypeLineProps> = ({
  className = '',
  name
}) => {
  const classes = useStyle()
  return (
    <Row fullWidth className={className} justifyContent="flex-start">
      <Icon name="InsertDriveFile" />
      <Text className={classes.name} preset="h3" text={name} />
    </Row>
  )
}
