import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'
import { PickerButtonStyleProps } from './picker-button.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    cursor: 'pointer'
  },
  subContainer: {
    width: 202,
    borderRadius: 32,
    padding: '6px 0',
    backgroundColor: ({ disabled }: PickerButtonStyleProps) =>
      disabled ? theme.colors.white : theme.colors.tableHeader,
    border: ({ disabled }: PickerButtonStyleProps) =>
      disabled
        ? `1px solid ${theme.colors.innerBorder}`
        : '1px solid transparent',
    '& > *': {
      cursor: 'pointer'
    }
  },
  text: {
    marginLeft: 8
  }
}))
