export interface GetCurrentPartProps {
  count: number
  current: number
  limit?: number
}

export enum ColumnKeys {
  PROGRAM = 'program',
  APP_ID = 'application',
  ACTION_LOG = 'message',
  USER = 'user_name',
  DATE_OF_ACTION = 'created_at'
}
