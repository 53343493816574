import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  fullWidth: {
    width: '100%'
  },
  input: {
    padding: '10px 0 10px 16px',
    outline: 0,
    border: 'none',
    color: theme.colors.text,
    fontSize: theme.fonts.defaultSize,
    borderBottom: 'none',
    '&::placeholder': {
      color: theme.colors.border
    },
    '&:-internal-autofill-selected, &:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus,':
      {
        backgroundColor: theme.colors.white,
        '-webkit-box-shadow': '0 0 0px 1000px white inset',
        color: `${theme.colors.text}`
      },
    '&:focus': {
      '& + span': {
        color: theme.colors.blue
      }
    },
    '&::-ms-reveal': {
      display: 'none'
    }
  },
  icon: {
    position: 'absolute',
    right: 13,
    cursor: 'pointer'
  }
}))
