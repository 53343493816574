import { WindowScrollCallback } from './useWindowScroll'
import useWindowThrottleScroll from './useWindowThrottleScroll'

export const useWindowThrottleScrollEnd = (
  cb: WindowScrollCallback,
  threshhold: number = 0.95
) => {
  const handleOnScroll = (event: Event) => {
    let currentTarget = event.target as Element | null

    if (currentTarget?.nodeName === '#document') {
      const nextTarget = event.target as Document

      currentTarget = nextTarget.scrollingElement
    }

    if (currentTarget) {
      const { scrollHeight, scrollTop, clientHeight } = currentTarget

      const currentThreshold = 1 - threshhold
      const currentScrollThreshold =
        scrollHeight - clientHeight * currentThreshold
      const currentScrollBottom = scrollTop + clientHeight

      if (currentScrollThreshold < currentScrollBottom) {
        cb(event)
      }
    }
  }

  useWindowThrottleScroll(handleOnScroll, 250)
}
