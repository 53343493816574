import React, { FC } from 'react'
import { NavText as NavTextComponent } from '@cse/ui'
import { Link, useLocation } from 'react-router-dom'

import { HeaderNavLinkProps } from './header-nav-link.types'
import { useStyle } from './header-nav-link.styles'

export const HeaderNavLink: FC<HeaderNavLinkProps> = ({ to, ...props }) => {
  const classes = useStyle()
  const { pathname } = useLocation()

  const active = pathname === to

  return (
    <Link className={classes.link} to={to}>
      <NavTextComponent active={active} {...props} />
    </Link>
  )
}
