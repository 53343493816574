import { createUseStyles } from 'react-jss'

import { LightTheme } from '../theme'

import { TextStyleProps } from './text.types'

const colorByProps =
  (theme: LightTheme) =>
  ({ color }: TextStyleProps<'' | 'test'>) =>
    color ? theme.colors[color] : theme.colors.text

export const useStyle = createUseStyles((theme: LightTheme) => ({
  h1: {
    ...theme.typography.h1,
    color: colorByProps(theme)
  },
  h2: {
    ...theme.typography.h2,
    color: colorByProps(theme)
  },
  h3: {
    ...theme.typography.h3,
    color: colorByProps(theme)
  },
  h4: {
    ...theme.typography.h4,
    color: colorByProps(theme)
  },
  h5: {
    ...theme.typography.h5,
    color: colorByProps(theme)
  },
  h6: {
    ...theme.typography.h6,
    color: colorByProps(theme)
  },
  bodyBold: {
    ...theme.typography.bodyBold,
    color: colorByProps(theme)
  },
  body: {
    ...theme.typography.body,
    color: colorByProps(theme)
  },
  bodyUnderline: {
    ...theme.typography.bodyUnderline,
    color: colorByProps(theme)
  },
  subTitle: {
    ...theme.typography.subTitle,
    color: colorByProps(theme)
  },
  subTitleSetting: {
    ...theme.typography.subTitleSetting,
    color: colorByProps(theme)
  },
  smallSubTitleSetting: {
    ...theme.typography.smallSubTitleSetting,
    color: colorByProps(theme)
  },
  subTitleUnderline: {
    ...theme.typography.subTitleUnderline,
    color: colorByProps(theme)
  },
  chart: {
    ...theme.typography.chart,
    color: colorByProps(theme)
  },
  title: {
    ...theme.typography.title,
    color: colorByProps(theme)
  },
  boldTitle: {
    ...theme.typography.boldTitle,
    color: colorByProps(theme)
  },
  subTitleMini: {
    ...theme.typography.subTitleMini,
    color: colorByProps(theme)
  },
  largeTitle: {
    ...theme.typography.largeTitle,
    color: colorByProps(theme)
  },
  smallTitle: {
    ...theme.typography.smallTitle,
    color: colorByProps(theme)
  },
  smallDescription: {
    ...theme.typography.smallDescription,
    color: colorByProps(theme)
  },
  smallInfo: {
    ...theme.typography.smallInfo,
    color: colorByProps(theme)
  },
  textSetting: {
    ...theme.typography.textSetting,
    color: colorByProps(theme)
  },
  smallSetting: {
    ...theme.typography.smallSetting,
    color: colorByProps(theme)
  },
  caption: {
    ...theme.typography.caption,
    color: colorByProps(theme)
  },
  boldHeader: {
    ...theme.typography.boldHeader,
    color: colorByProps(theme)
  },
  defaultCursor: {
    cursor: 'default',
    '-webkit-touch-callout': 'none',
    '-webkit-user-select': 'none',
    '-khtml-user-select': 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    userSelect: 'none'
  },
  textWithHint: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  hint: {
    position: 'absolute',
    top: '-10px',
    left: 0,
    bottom: 0,
    visibility: ({ hover }: TextStyleProps<'' | 'test'>) =>
      hover ? 'visible' : 'hidden',
    zIndex: 2
  }
}))
