import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../theme'

import { DocumentValidationStyleProps } from './document-validation.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: '16px 0 36px'
  },
  header: {
    backgroundColor: theme.colors.white,
    marginTop: 8,
    minHeight: 40
  },
  content: {
    marginTop: 24
  },
  fieldsView: {
    maxWidth: ({ menuWidth = 514 }: DocumentValidationStyleProps) => menuWidth,
    marginLeft: ({ menuMarginLeft = 24 }: DocumentValidationStyleProps) =>
      menuMarginLeft
  },
  fieldsMenu: {
    width: '100%',
    '&:not(:first-child)': {
      marginTop: 24
    }
  },
  fromDate: {
    marginLeft: 8
  }
}))
