import React from 'react'

import { Row } from '../../row'
import { Text } from '../../text'

import { DropdownItemProps } from './dropdown-item.types'
import { useStyle } from './dropdown-item.styles'

export const DropdownItem = <T extends string>({
  disabled = false,
  item,
  onClick
}: DropdownItemProps<T>) => {
  const classes = useStyle()
  const { name, nameTx } = item
  const disabledClass = disabled && classes.disabled
  const textColor = disabled ? 'grey' : 'inactive'
  const isStringName = name && typeof name === 'string'

  const handleOnClick = () => {
    onClick(item)
  }

  return (
    <Row
      justifyContent="flex-start"
      className={`${disabledClass} ${classes.container}`}
      onClick={handleOnClick}
    >
      {isStringName ? (
        <Text text={name} tx={nameTx} preset="h5" color={textColor} />
      ) : (
        name
      )}
    </Row>
  )
}
