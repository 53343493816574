import { ApisauceInstance } from 'apisauce'
import camelcaseKeys from 'camelcase-keys'

import { getGeneralApiProblem } from '../api-problem'

import {
  DeleteApplicationRequest,
  DeleteApplicationResponse,
  DeleteApplicationResults,
  GetApplicationRequest,
  GetApplicationResponse,
  GetApplicationResults,
  GetApplicationsRequest,
  GetApplicationsResponse,
  GetApplicationsResults,
  GetSearchPreviewRequest,
  GetSearchPreviewResponse,
  GetSearchPreviewResults
} from './applications.types'

// our "constructor"
export const applicationsService = (api: ApisauceInstance) => {
  /**
   * Get applications from api service
   */
  const getApplications = async ({
    query
  }: GetApplicationsRequest): Promise<GetApplicationsResults> => {
    if (!query) {
      return { kind: 'bad-data' }
    }

    const response = await api.get<GetApplicationsResponse>(
      '/application-preview/list',
      query
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) {
        return problem
      }
    }
    try {
      if (response.data) {
        const data = camelcaseKeys(response.data, {
          deep: true
        })

        return {
          kind: 'ok',
          applications: data,
          reset: !query.offset,
          preview: query?.preview
        }
      }

      return { kind: 'bad-data' }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  /**
   * Get application from api service
   */
  const getApplication = async ({
    params
  }: GetApplicationRequest): Promise<GetApplicationResults> => {
    if (!params) {
      return { kind: 'bad-data' }
    }

    const response = await api.get<GetApplicationResponse>(
      `/application-preview/${params.id}`
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) {
        return problem
      }
    }
    try {
      if (response.data) {
        const data = camelcaseKeys(response.data, {
          deep: true
        })

        return { kind: 'ok', application: data, reset: !params.offset }
      }

      return { kind: 'bad-data' }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  /**
   * Get search preview list from api service
   */
  const getSearchPreview = async ({
    query
  }: GetSearchPreviewRequest): Promise<GetSearchPreviewResults> => {
    if (!query) {
      return { kind: 'bad-data' }
    }

    const response = await api.get<GetSearchPreviewResponse>(
      '/application-preview/list',
      query
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) {
        return problem
      }
    }
    try {
      if (response.data) {
        const data = camelcaseKeys(response.data, {
          deep: true
        })

        return {
          kind: 'ok',
          applications: data,
          reset: !query.offset
        }
      }

      return { kind: 'bad-data' }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  /**
   * Delete application from api service
   */
  const deleteApplication = async ({
    params
  }: DeleteApplicationRequest): Promise<DeleteApplicationResults> => {
    if (!params) {
      return { kind: 'bad-data' }
    }

    const response = await api.delete<DeleteApplicationResponse>(
      `/application/${params.id}`
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) {
        return problem
      }
    }
    try {
      if (response.data) {
        const data = camelcaseKeys(response.data, {
          deep: true
        })

        return { kind: 'ok', application: data }
      }

      return { kind: 'bad-data' }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  return {
    getApplications,
    getApplication,
    getSearchPreview,
    deleteApplication
  }
}
