import { createUseStyles } from 'react-jss'

import { LightTheme } from '../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  button: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: 160,
    height: 40,
    borderRadius: 6,
    border: 'none',
    fontWeight: 500,
    outline: 'none',
    textDecoration: 'none',
    cursor: theme.cursor,
    '& > *': {
      cursor: theme.cursor,
      textDecoration: 'none'
    },
    '&:disabled, &[disabled]': {
      pointerEvents: 'none',
      cursor: 'default',
      '& > *': {
        cursor: 'default'
      }
    }
  },
  accent: {
    backgroundColor: theme.colors.darkBlue,
    outline: 'none',
    '& > *': {
      color: theme.colors.white
    },
    '&:hover': {
      background: theme.colors.blue,
      '& > *': { color: theme.colors.white }
    },
    '&:disabled, &[disabled]': {
      pointerEvents: 'none',
      backgroundColor: theme.colors.grey,
      '& > *': {
        color: theme.colors.inactive
      },
      '&:hover': {
        '& > *': {
          color: theme.colors.inactive
        }
      }
    },
    '&:active, &[active]': {
      backgroundColor: theme.colors.darkBlue,
      '& > *': {
        color: theme.colors.white
      }
    }
  },
  secondary: {
    backgroundColor: theme.colors.white,
    border: `1px solid ${theme.colors.darkBlue}`,
    '& > *': {
      color: theme.colors.darkBlue
    },
    '&:hover': {
      border: `1px solid ${theme.colors.blue}`,
      '& > *': {
        color: theme.colors.blue
      }
    },
    '&:disabled, &[disabled]': {
      pointerEvents: 'none',
      backgroundColor: theme.colors.grey,
      border: `1px solid ${theme.colors.border}`,
      '& > *': {
        color: theme.colors.border
      },
      '&:hover': {
        '& > *': {
          color: theme.colors.border
        }
      }
    },
    '&:active, &[active]': {
      backgroundColor: theme.colors.white,
      border: `1px solid ${theme.colors.darkBlue}`,
      '& > *': {
        color: theme.colors.darkBlue
      },
      '&:hover': {
        '& > *': {
          color: theme.colors.darkBlue
        }
      }
    }
  },
  disabled: {
    backgroundColor: theme.colors.grey,
    cursor: 'pointer',
    '& > *': {
      color: theme.colors.inactive
    },
    '&:hover': {
      color: theme.colors.border,
      border: `1px solid ${theme.colors.border}`
    }
  },
  small: {
    height: 30
  },
  defaultSmall: {
    backgroundColor: theme.colors.white,
    border: `1px solid ${theme.colors.border}`,
    '& > *': {
      color: theme.colors.text
    },
    '&:hover': {
      border: `1px solid ${theme.colors.blue}`,
      '& > *': {
        color: theme.colors.blue
      }
    },
    '&:disabled, &[disabled]': {
      pointerEvents: 'none',
      backgroundColor: theme.colors.grey,
      border: 'none',
      '& > *': {
        color: theme.colors.inactive
      }
    },
    '&:active, &[active]': {
      border: `1px solid ${theme.colors.border}`,
      '& > *': {
        color: theme.colors.text
      }
    }
  }
}))
