import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  link: {
    textDecoration: 'none',
    '& > *': {
      cursor: 'pointer'
    }
  }
})
