export interface MarkersProps {
  warnings: [string]
  hasRecognitionUpdate: boolean
  hasDocumentUpdate: boolean
}

export enum TabMarker {
  TRIANGLE = 'ExclamationTriangle',
  NEW_RELEASES = 'NewReleasesFilled',
  RECOGNITION = 'NotificationImportant'
}

export enum MarkerHint {
  TRIANGLE = 'marker.hint.warning',
  NEW_RELEASES = 'marker.hint.newReleases',
  RECOGNITION = 'marker.hint.recognition'
}
