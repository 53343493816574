import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

import { PolygonStyleProps } from './polygon.types'

const getSize = (relativeSize: number) => {
  const relativePadding = 0.003
  const relativeSizeWithPadding = relativeSize - relativePadding

  return `${relativeSizeWithPadding * 100}%`
}

const isRightEnd = (right: number, zoom: number) => right < 0.4 / zoom
const isTopEnd = (top: number, zoom: number) => top < 0.4 / zoom

const hintStyles = ({ top, right, zoom }: PolygonStyleProps) => {
  const rightEnd = isRightEnd(right, zoom)
  const topEnd = isTopEnd(top, zoom)

  if (rightEnd && topEnd) {
    return {
      top: '110%',
      bottom: 'auto',
      left: 'auto',
      right: 'auto'
    }
  }

  if (rightEnd) {
    return {
      top: 'auto',
      bottom: '110%',
      left: 'auto',
      right: 'auto'
    }
  }

  return {
    top: 0,
    bottom: 'auto',
    left: '110%',
    right: 'auto'
  }
}

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: ({ top, left, right, bottom, zoom }: PolygonStyleProps) => ({
    position: 'absolute',
    cursor: 'pointer',
    top: getSize(top),
    left: getSize(left),
    right: getSize(right),
    bottom: getSize(bottom),
    '&:hover > div': {
      border: `${2.5 * zoom}px solid ${theme.colors.orange}`,
      zIndex: 3
    },
    '&:hover > span': {
      visibility: 'visible'
    }
  }),
  polygon: ({ active, zoom, color }: PolygonStyleProps) => ({
    border: active
      ? `${2.5 * zoom}px solid ${theme.colors.yellow}`
      : `${2 * zoom}px solid ${theme.colors[color]}`,
    zIndex: active ? 3 : 2,
    flex: 1,
    alignSelf: 'stretch'
  }),
  hint: (props: PolygonStyleProps) => ({
    border: `1px solid ${theme.colors.yellow}`,
    background: theme.colors.yellow,
    visibility: props.active ? 'visible' : 'hidden',
    transform: `rotate(-${props.rotate}deg)`,
    transformOrigin: 'center',
    position: 'absolute',
    padding: 8,
    borderRadius: 8,
    zIndex: 10,
    ...hintStyles(props)
  })
}))
