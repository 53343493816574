import { useMemo } from 'react'

import { MainRoutes } from '../../routes'
import { Application } from '../../services'
import { IconName } from '../icon'

import {
  ChangeDataBySeachProps,
  GetDataProps,
  GetHeightProps,
  ResetHeightProps,
  SetItemClickProps,
  SetOnKeyDownProps
} from './search.types'

export const getContainerHeight = (
  itemsContainer: HTMLDivElement | null,
  minHeight: number
) => (itemsContainer ? itemsContainer.offsetHeight : minHeight)

export const isSearchOpen = (data: Application[], searchById: string) => {
  const isData = data && data.length
  const isTextTyped = isData && searchById

  return Boolean(isTextTyped)
}

export const useGetData = ({ data, searchById }: GetDataProps) => {
  const searchIcon: IconName | undefined = searchById ? 'Clear' : undefined
  const open = useMemo(() => isSearchOpen(data, searchById), [data, searchById])

  return {
    searchIcon,
    open
  }
}

export const getHeight = ({
  contentRef,
  maxHeight,
  minHeight,
  changeHeight
}: GetHeightProps) => {
  if (contentRef) {
    const content = contentRef.current
    const contentHeight = content?.offsetHeight

    if (contentHeight) {
      const isLargeContent = contentHeight > maxHeight
      const alignedHeight = isLargeContent ? maxHeight : contentHeight
      const resHeight = alignedHeight || minHeight

      changeHeight(resHeight)
      return alignedHeight
    }
  }
  return minHeight
}

export const resetHeight = ({ open, getOffsetHeight }: ResetHeightProps) => {
  if (open) {
    const sliderHeight = getOffsetHeight()

    if (sliderHeight) {
      getOffsetHeight()
    }
  }
}

export const setItemClick =
  ({ navigate, onIconClick }: SetItemClickProps) =>
  (app: Application) =>
  () => {
    if (app.id) {
      const documentId = app.documents[0]?.id || app.id
      const query = `?document_id=${documentId}`

      navigate(`${MainRoutes.DOCUMENT}/${app.id}${query}`)
      onIconClick()
    }
  }

export const setOnKeyDown =
  ({ searchList, navigate, reset }: SetOnKeyDownProps) =>
  (event: any) => {
    const isEnterKey = event.charCode === 13 || event.key === 'Enter'
    const isOneItem = searchList.length === 1

    if (isEnterKey && isOneItem) {
      const app = searchList[0]
      const documentId = app.documents[0]?.id || app.id
      const query = `?document_id=${documentId}`

      navigate(`${MainRoutes.DOCUMENT}/${app.id}${query}`)
      reset()
    }
  }

export const changeDataBySeach = ({
  searchById,
  searchList,
  changeData
}: ChangeDataBySeachProps) => {
  if (searchById) {
    changeData(searchList)
  }
}
