import React, { useMemo } from 'react'

import { Column } from '../../column'
import { Row } from '../../row'

import { ItemsListProps } from './items-list.types'
import { useStyle } from './items-list.styles'

export const ItemsList = ({
  listRef,
  contentRef,
  open,
  height,
  data,
  onItemClick
}: ItemsListProps) => {
  const classes = useStyle({ open, height })

  const Content = useMemo(
    () =>
      data.map((item) => (
        <Row
          key={`${item.id}_${item.title}`}
          className={classes.item}
          fullWidth
          justifyContent="flex-start"
          onClick={onItemClick(item)}
        >
          {item.title}
        </Row>
      )),
    [data]
  )

  if (!open) {
    return null
  }

  return (
    <Column ref={listRef} fullWidth>
      <Column className={classes.listContainer} fullWidth>
        <Column ref={contentRef} className={classes.previewList} fullWidth>
          {Content}
        </Column>
      </Column>
    </Column>
  )
}
