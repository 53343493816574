import React, { ChangeEvent, FC, useRef, useState } from 'react'
import { useDefaultValue } from '@cse/ui'
import { useIntl } from 'react-intl'

import { Column } from '../column'
import { Icon } from '../icon'
import { concatClassNames } from '../helpers'

import { InputProps } from './input.types'
import { useStyle } from './input.styles'

export const Input: FC<InputProps> = ({
  className = '',
  classNameInput,
  children,
  alignItems,
  justifyContent,
  placeholder = '',
  placeholderTx,
  defaultValue,
  value: outerValue,
  icon,
  iconFill,
  onChange,
  onIconClick,
  ...props
}) => {
  const intl = useIntl()
  const classes = useStyle()
  const ref = useRef<HTMLInputElement>(null)
  const [value, changeValue] = useState(defaultValue)

  const Value = useDefaultValue(value, outerValue)

  const formatedPlaceholder = placeholderTx
    ? intl.formatMessage({
        id: placeholderTx,
        defaultMessage: placeholder
      })
    : placeholder

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e)
    }

    changeValue(e.target.value)
  }

  return (
    <Column fullHeight className={className}>
      <input
        {...props}
        ref={ref}
        className={concatClassNames(classNameInput, classes.input)}
        placeholder={formatedPlaceholder}
        value={Value}
        autoComplete="off"
        onChange={handleOnChange}
      />
      {children}
      {icon && (
        <Icon
          className={classes.icon}
          name={icon}
          fill={iconFill}
          onClick={onIconClick}
        />
      )}
    </Column>
  )
}
