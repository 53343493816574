import { createReducer } from 'reduxsauce'

import { AppTypes, AppActionTypes } from '../../actions'

import { AppState } from './app.types'
import { defaultCatalog, getCatalogFromRawCatalog } from './utils'

/* ------------- Reducers ------------- */

const INITIAL_STATE: AppState = {
  isLoaded: false,
  catalog: defaultCatalog
}

export const initApp = (state = INITIAL_STATE, action: AppTypes.LoadAction) => {
  const { isLoaded } = action
  return {
    ...state,
    isLoaded
  }
}

export const dataRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoaded: false
  }
}

export const dataSuccessOrFailure = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoaded: true
  }
}

/***
 * Get Catalog
 */

export const getCatalogSuccess = (
  state = INITIAL_STATE,
  action: AppTypes.GetCatalogActionSuccess
) => {
  const results = getCatalogFromRawCatalog(action.payload)

  if (action.payload) {
    return {
      ...state,
      catalog: results,
      isLoaded: true
    }
  }

  return {
    ...state,
    catalog: defaultCatalog,
    isLoaded: true
  }
}

export const getCatalogFailure = (
  state = INITIAL_STATE,
  action: AppTypes.GetCatalogActionFailure
) => {
  if (action.payload) {
    return {
      ...state,
      catalog: defaultCatalog,
      isLoaded: true
    }
  }

  return state
}

/* ------------- Hookup Reducers To Types ------------- */

export const appReducer = createReducer(INITIAL_STATE, {
  [AppActionTypes.IS_LOADED]: initApp,

  [AppActionTypes.GET_CATALOG_REQUEST]: dataRequest,
  [AppActionTypes.GET_CATALOG_SUCCESS]: getCatalogSuccess,
  [AppActionTypes.GET_CATALOG_FAILURE]: getCatalogFailure
})
