import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../../theme'

import { SVGIconProps } from './icons.types'

export const PopOut: FC<SVGIconProps> = (props) => {
  const theme: LightTheme = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 5L5 19H19L19 12H21L21 19C21 20.1 20.1 21 19 21L5 21C3.89 21 3 20.1 3 19L3 5C3 3.9 3.89 3 5 3L12 3V5L5 5ZM14 5V3L21 3V10H19V6.41L9.17 16.24L7.76 14.83L17.59 5H14Z"
        fill={props.fill || theme.colors.darkBlue}
      />
    </svg>
  )
}
