import React, { FC } from 'react'

import { Text } from '../../text'
import { Column } from '../../column'
import { Row } from '../../row'

import { FieldValueTooltipProps } from './field-value-tooltip.types'
import { useStyle } from './field-value-tooltip.styles'

export const FieldValueTooltip: FC<FieldValueTooltipProps> = ({
  open,
  position,
  value
}) => {
  const classes = useStyle({ position })

  if (!open) {
    return <></>
  }

  return (
    <Row className={classes.container}>
      <Column className={classes.arrow} />
      <Column fullWidth className={classes.content} alignItems="flex-start">
        <Text preset="subTitle" text={value} />
      </Column>
    </Row>
  )
}
