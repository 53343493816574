import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  title: {
    marginTop: 8
  },
  actions: {
    marginTop: 32
  }
})
