import { useMemo } from 'react'

export const useDefaultValue = <T>(value: T, outerValue?: T) => {
  const Value = useMemo(() => {
    if (typeof outerValue !== 'undefined') {
      return outerValue
    }

    return value
  }, [outerValue, value])

  return Value
}
