import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import {
  Column,
  DocumentViewerHeader,
  Icon,
  Row,
  Text,
  DocumentViewerContent,
  DocumentViewerContentProps,
  LoadingContainer
} from '../../components'
import {
  DocumentViewerOptions,
  MenuFieldsDataType,
  getDefaultValue
} from '../../pages'
import { DocumentCreators, State } from '../../store'
import { usePopState } from '../../hooks'
import { FieldsMenuComparison } from '../fields-menu-comparison'
import { useSettings } from './hooks'

import { getImageProperties, setImageProperties, updateFields } from './helpers'

import { PopupDocumentComparisonProps } from './popup-document-comparison.types'
import { useStyle } from './popup-document-comparison.styles'

export const PopupDocumentComparison: FC<PopupDocumentComparisonProps> = ({
  open = false,
  docType,
  versions,
  firstDocData,
  secondDocData,
  onClose,
  onApprove,
  onReject,
  onFocusInput,
  onBlurInput,
  onChangeOptions,
  onChangePage,
  onClickPolygon,
  onFocusComparisonInput,
  onClickComparisonPolygon,
  onChangeComparisonPage,
  onChangeComparisonOptions
}) => {
  const dispatch = useDispatch()
  const { documentToCompareLoading } = useSelector((state: State) => ({
    ...state.document
  }))
  const { pathname, search } = useLocation()

  const viewerRef = useRef<HTMLDivElement>(null)
  const {
    activeField,
    defaultValue,
    data,
    verificationFields,
    pages,
    options: outerOptions
  } = useMemo(() => firstDocData, [firstDocData])

  const {
    activeField: activeField2,
    defaultValue: defaultValue2,
    data: data2,
    verificationFields: verificationFields2,
    pages: pages2,
    options: outerOptions2
  } = useMemo(() => secondDocData, [secondDocData])

  const DefaultValue = useMemo(
    () => getDefaultValue({ defaultValue }),
    [defaultValue]
  )
  const DefaultValue2 = useMemo(
    () => getDefaultValue({ defaultValue: defaultValue2 }),
    [defaultValue2]
  )

  const [listProps, changeListProps] = useState({
    listWidth: 534,
    listHeight: 534
  })
  const [aspectRatio, changeAspectRatio] = useState(1)
  const [openMenu, changeOpenMenu] = useState(false)
  const [fieldType, changeFieldType] = useState<
    MenuFieldsDataType | undefined
  >()
  const [options, changeOptions] = useState<DocumentViewerOptions>(DefaultValue)
  const [options2, changeOptions2] =
    useState<DocumentViewerOptions>(DefaultValue2)

  const { Options, Options2, Fields, Fields2, Values } = useSettings({
    docType,
    options,
    options2,
    outerOptions,
    outerOptions2,
    verificationFields,
    verificationFields2
  })

  const classes = useStyle({ fieldType })

  useEffect(() => {
    const isData = activeField && data
    const isActive = !openMenu && isData

    if (!isActive) {
      return
    }

    updateFields({
      openMenu,
      activeField,
      data,
      changeFieldType,
      changeOpenMenu
    })
  }, [activeField])

  const ImageProps = useMemo(
    () =>
      getImageProperties({
        options,
        listProps,
        aspectRatio
      }),
    [options.fitToPage, aspectRatio, listProps]
  )

  const handleOnImageLoad: DocumentViewerContentProps['onImageLoad'] =
    setImageProperties({ viewerRef, changeAspectRatio, changeListProps })

  const handleOnChangeOptions = (nextOptions: DocumentViewerOptions) => {
    changeOptions(nextOptions)

    if (onChangeOptions) {
      onChangeOptions(nextOptions)
    }
  }

  const handleOnChangeOptions2 = (nextOptions: DocumentViewerOptions) => {
    changeOptions2(nextOptions)

    if (onChangeComparisonOptions) {
      onChangeComparisonOptions(nextOptions)
    }
  }

  useEffect(() => {
    return () => {
      dispatch(DocumentCreators.clearDocumentsToCompare())
    }
  }, [])

  const onPopState = (event: PopStateEvent) => {
    event.preventDefault()
    event.stopImmediatePropagation()

    if (onClose) {
      onClose()
    }
  }

  usePopState(onPopState, `${pathname}/compare${search}`)

  const loadingClasses = documentToCompareLoading ? classes.spinner : ''

  if (!open) {
    return null
  }

  return (
    <Column className={classes.container} justifyContent="flex-start" fullWidth>
      <Row className={classes.top} fullWidth>
        <Text
          className={classes.title}
          text="Compare documents (${docType})"
          tx="compare.modal.title"
          values={Values}
          preset="h1"
          color="darkBlue"
        />
        <Row justifyContent="flex-end">
          <Icon className={classes.clear} name="CLEAR" onClick={onClose} />
        </Row>
      </Row>
      <Row
        className={classes.main}
        fullWidth
        fullHeight
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        {/* First document */}
        <Column
          className={classes.sideContainer}
          fullHeight
          fullWidth
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Text text={versions[0]} preset="h2" color="text" />
          <DocumentViewerHeader
            className={classes.header}
            options={Options}
            pages={pages}
            onChangeOptions={handleOnChangeOptions}
            onChangePage={onChangePage}
          />
          <Column
            className={classes.docContainer}
            fullWidth
            fullHeight
            justifyContent="flex-start"
            ref={viewerRef}
          >
            <DocumentViewerContent
              activeField={activeField}
              data={data}
              listWidth={ImageProps.listWidth}
              listHeight={ImageProps.listHeight}
              options={Options}
              onClickPolygon={onClickPolygon}
              onImageLoad={handleOnImageLoad}
            />
          </Column>
          <Row className={classes.fieldsView} fullWidth>
            <FieldsMenuComparison
              multiValue
              className={classes.fieldsMenu}
              activeField={activeField}
              verificationData={Fields.verification}
              validatedData={Fields.validated}
              onApprove={onApprove}
              onReject={onReject}
              onFocusInput={onFocusInput}
              onBlurInput={onBlurInput}
            />
          </Row>
        </Column>
        {/* Second document */}
        <LoadingContainer
          className={loadingClasses}
          loading={documentToCompareLoading}
        >
          <Column
            className={classes.sideContainer}
            fullHeight
            fullWidth
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Text text={versions[1]} preset="h2" color="text" />
            <DocumentViewerHeader
              className={classes.header}
              options={Options2}
              pages={pages2}
              onChangeOptions={handleOnChangeOptions2}
              onChangePage={onChangeComparisonPage}
            />
            <Column
              className={classes.docContainer}
              fullWidth
              fullHeight
              justifyContent="flex-start"
              ref={viewerRef}
            >
              <DocumentViewerContent
                activeField={activeField2}
                data={data2}
                listWidth={ImageProps.listWidth}
                listHeight={ImageProps.listHeight}
                options={Options2}
                onClickPolygon={onClickComparisonPolygon}
                onImageLoad={handleOnImageLoad}
              />
            </Column>
            <Row className={classes.fieldsView} fullWidth>
              <FieldsMenuComparison
                multiValue
                isEditable={false}
                activeField={activeField2}
                className={classes.fieldsMenu}
                verificationData={Fields2.verification}
                validatedData={Fields2.validated}
                onApprove={onApprove}
                onReject={onReject}
                onFocusInput={onFocusComparisonInput}
                onBlurInput={onBlurInput}
              />
            </Row>
          </Column>
        </LoadingContainer>
      </Row>
    </Column>
  )
}
