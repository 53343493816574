import React, { FC } from 'react'

import { Row } from '../row'
import { Icon } from '../icon'

import { SortButtonProps } from './sort-button.types'
import { useStyle } from './sort-button.styles'

export const SortButton: FC<SortButtonProps> = ({ sort }) => {
  const classes = useStyle({
    sort
  })

  return (
    <Row className={classes.sort}>
      <Icon name="ArrowRightAlt" />
    </Row>
  )
}
