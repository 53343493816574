import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

type WindowPopStateCallback = (event: PopStateEvent) => void

export const usePopState = (cb: WindowPopStateCallback, path: string) => {
  const navigate = useNavigate()

  useEffect(() => {
    if (!window) {
      return undefined
    }
    navigate(path)

    window.addEventListener('popstate', cb, true)

    return () => {
      window.removeEventListener('popstate', cb, true)
    }
  }, [])

  return window
}

export default usePopState
