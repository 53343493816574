import { call, put } from 'redux-saga/effects'

import {
  Api,
  GetValidationProgramsResults,
  GetValidationRuleDocumentTypesResults,
  GetValidationRuleListResults
} from '../../services'
import { ValidationRuleCreators, ValidationRuleTypes } from '../actions'

export function* GetValidationPrograms(api: Api) {
  const response: GetValidationProgramsResults = yield call(
    api.validationRule.getValidationPrograms
  )

  if (response.kind !== 'ok') {
    yield put(
      ValidationRuleCreators.getValidationProgramsFailure({
        error: 'Failure get list of validation programs'
      })
    )
  } else {
    yield put(
      ValidationRuleCreators.getValidationProgramsSuccess(response.data)
    )
  }
}

export function* GetValidationRuleList(
  api: Api,
  action: ValidationRuleTypes.GetValidationRuleListActionRequest
) {
  const response: GetValidationRuleListResults = yield call(
    api.validationRule.list,
    action.payload
  )

  if (response.kind !== 'ok') {
    yield put(
      ValidationRuleCreators.getValidationRuleListFailure({
        error: 'Failure get list of validation rules'
      })
    )
  } else {
    yield put(
      ValidationRuleCreators.getValidationRuleListSuccess(response.data)
    )
  }
}

export function* GetValidationRuleDocumentTypes(
  api: Api,
  action: ValidationRuleTypes.GetValidationRuleDocumentTypesActionRequest
) {
  const response: GetValidationRuleDocumentTypesResults = yield call(
    api.validationRule.documentTypes,
    action.payload
  )

  if (response.kind !== 'ok') {
    yield put(
      ValidationRuleCreators.getValidationRuleDocumentTypesFailure({
        error: 'Failure document types by rule'
      })
    )
  } else {
    yield put(
      ValidationRuleCreators.getValidationRuleDocumentTypesSuccess(
        response.data
      )
    )
  }
}
