import React from 'react'

import { LogsList } from '../logs-list'

import { LogsViewProps } from './logs-view.types'
import { useStyle } from './logs-view.styles'

export const LogsView = ({
  catalog,
  current,
  data,
  isLoadedMore,
  isLoaded,
  next,
  search,
  onClick,
  onLoadMore,
  onSort
}: LogsViewProps) => {
  const classes = useStyle({ isLoaded })

  return (
    <LogsList
      catalog={catalog}
      className={classes.applicationList}
      current={current}
      data={data}
      isLoadedMore={isLoadedMore}
      isLoaded={isLoaded}
      next={next}
      search={search}
      onClick={onClick}
      onLoadMore={onLoadMore}
      onSort={onSort}
    />
  )
}
