import REDUX_PERSIST from './redux-persist'

export const DOCUMENT_TITLE = 'Reviewer'

export enum Env {
  DEV = 'development',
  PROD = 'production',
  TEST = 'test'
}

const HIDDEN_VERSION = process.env.REACT_APP_HIDDEN_VERSION || 'true'

const IS_HIDDEN_VERSION = HIDDEN_VERSION === 'true'

export const config = {
  BASE_URL: process.env.REACT_APP_BASE_URL || '/api',
  ENV: process.env.NODE_ENV || Env.DEV,
  DEV_ENVIRONMENT_LINK: process.env.PUBLIC_URL,
  HIDDEN_VERSION: IS_HIDDEN_VERSION,
  REDUX_PERSIST
}
