import React, { FC } from 'react'

import { Row } from '../../row'
import { Column } from '../../column'
import { Text } from '../../text'

import { StatusItemProps } from './status-item.types'
import { useStyle } from './status-item.styles'
import { concatClassNames } from '../../helpers'

export const StatusItem: FC<StatusItemProps> = ({
  className = '',
  color,
  text,
  tx
}) => {
  const classes = useStyle({
    color
  })

  return (
    <Column className={concatClassNames(className, classes.container)}>
      <Text
        className={classes.label}
        preset="smallSetting"
        text={text}
        tx={tx}
      />
      <Row className={classes.badge} />
    </Column>
  )
}
