import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../../theme'
import { SVGIconProps } from './icons.types'

export const NewReleasesEmpty: FC<SVGIconProps> = (props) => {
  const theme: LightTheme = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.56 9.21516L23 11.9952L20.56 14.7752L20.9 18.4552L17.29 19.2752L15.4 22.4552L12 20.9952L8.6 22.4652L6.71 19.2852L3.1 18.4652L3.44 14.7752L1 11.9952L3.44 9.20516L3.1 5.52516L6.71 4.71516L8.6 1.53516L12 2.99516L15.4 1.53516L17.29 4.71516L20.9 5.53516L20.56 9.21516ZM18.75 16.8952L18.49 14.1052L20.34 11.9952L18.49 9.88516L18.75 7.09516L16.01 6.47516L14.58 4.06516L12 5.17516L9.42 4.07516L7.99 6.48516L5.25 7.09516L5.51 9.87516L3.66 11.9952L5.51 14.0952L5.25 16.8952L7.99 17.5152L9.42 19.9252L12 18.8152L14.58 19.9252L16.01 17.5152L18.75 16.8952ZM13 14.9952V16.9952H11V14.9952H13ZM13 6.99516H11V12.9952H13V6.99516Z"
        fill={props.fill || theme.colors.red}
      />
    </svg>
  )
}
