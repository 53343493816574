import React, {
  UIEventHandler,
  useCallback,
  useRef,
  useEffect,
  useState
} from 'react'

import { Column, IconButton, Spinner, Row } from '../../../../components'
import { useWindowThrottleScrollEnd } from '../../../../hooks'

import { LIMIT } from '../../application-list'
import { ListItem } from '../apps-list-item'

import { ItemsListProps } from './items-list.types'
import { useStyle } from './items-list.styles'

export const ItemsList = ({
  isLoaded,
  data,
  catalog,
  current,
  next,
  minHeight = 800,
  onLoadMore
}: ItemsListProps) => {
  const [scrollTop, changeScrollTop] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)
  const listRef = useRef<HTMLDivElement>(null)

  const [height, changeHeight] = useState(minHeight)
  const classes = useStyle({ height })

  const handleOnScrollToTop = useCallback(() => {
    if (listRef.current) {
      listRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
    }
  }, [listRef])

  const handleOnScrollEnd = useCallback(() => {
    const isAllowedToLoadMore = current && next
    const isLoadMore = isAllowedToLoadMore && isLoaded
    const isLoadAllowed = isLoadMore && onLoadMore

    if (isLoadAllowed) {
      onLoadMore({
        offset: current,
        limit: LIMIT
      })
    }
  }, [current, isLoaded, onLoadMore])

  const handleOnScroll: UIEventHandler<HTMLDivElement> = (event) => {
    const currentTarget = event.target as HTMLDivElement | null

    if (currentTarget) {
      const { scrollTop: currentScrollTop, scrollHeight } = currentTarget

      const isScrollToTop = currentScrollTop > scrollHeight * 0.1
      changeScrollTop(isScrollToTop)
    }
  }

  useWindowThrottleScrollEnd(handleOnScrollEnd)
  useEffect(() => {
    if (containerRef.current) {
      const { innerHeight } = window
      const OTHER_CONTENT_HEIGHT = 226
      const containerHeight = innerHeight - OTHER_CONTENT_HEIGHT

      changeHeight(containerHeight)
    }
  }, [containerRef.current])

  return (
    <Column fullHeight fullWidth className={classes.list}>
      <Column
        ref={containerRef}
        fullWidth
        className={classes.outerContainer}
        onScroll={handleOnScroll}
      >
        <Column fullWidth ref={listRef} className={classes.itemsList}>
          {data.map((application) => (
            <ListItem
              key={`app_${application.applicantName}_${application.id}`}
              {...application}
              catalog={catalog}
            />
          ))}
        </Column>
      </Column>
      {!isLoaded && <Spinner className={classes.loader} />}
      {scrollTop && (
        <Row fullWidth justifyContent="flex-end">
          <IconButton
            className={classes.goUp}
            name="ARROW_BACK"
            fill="darkBlue"
            preset="up"
            onClick={handleOnScrollToTop}
          />
        </Row>
      )}
    </Column>
  )
}
