import { createActions, createTypes } from 'reduxsauce'

import { LogsActions, LogsActionsTypes } from './logs.types'

export const logsStringTypes = createTypes<LogsActionsTypes>(`
  GET_ACTIVITY_LOG_LIST_REQUEST
  GET_ACTIVITY_LOG_LIST_SUCCESS
  GET_ACTIVITY_LOG_LIST_FAILURE

  GET_ACTIVITY_LOG_BY_ID_REQUEST
  GET_ACTIVITY_LOG_BY_ID_SUCCESS
  GET_ACTIVITY_LOG_BY_ID_FAILURE

  GET_ERROR_LOG_LIST_REQUEST
  GET_ERROR_LOG_LIST_SUCCESS
  GET_ERROR_LOG_LIST_FAILURE

  GET_SEARCH_ACTIVITY_PREVIEW_REQUEST
  GET_SEARCH_ACTIVITY_PREVIEW_SUCCESS
  GET_SEARCH_ACTIVITY_PREVIEW_FAILURE

  GET_ERROR_LOG_BY_ID_REQUEST
  GET_ERROR_LOG_BY_ID_SUCCESS
  GET_ERROR_LOG_BY_ID_FAILURE
`)

const { Types: LogsActionTypes, Creators: LogsCreators } = createActions<
  LogsActionsTypes,
  LogsActions
>({
  getActivityLogListRequest: ['payload'],
  getActivityLogListSuccess: ['payload'],
  getActivityLogListFailure: ['payload'],

  getActivityLogByIdRequest: ['payload'],
  getActivityLogByIdSuccess: ['payload'],
  getActivityLogByIdFailure: ['payload'],

  getErrorLogListRequest: ['payload'],
  getErrorLogListSuccess: ['payload'],
  getErrorLogListFailure: ['payload'],

  getSearchActivityPreviewRequest: ['payload'],
  getSearchActivityPreviewSuccess: ['payload'],
  getSearchActivityPreviewFailure: ['payload'],

  getErrorLogByIdRequest: ['payload'],
  getErrorLogByIdSuccess: ['payload'],
  getErrorLogByIdFailure: ['payload']
})

export { LogsCreators, LogsActionTypes }
