import { TextTranslate } from '../../text'
import { MarkerHint, TabMarker } from './markers.types'

export const getMarkerHint = (id: TabMarker): TextTranslate => {
  switch (id) {
    case TabMarker.TRIANGLE:
      return MarkerHint.TRIANGLE
    case TabMarker.NEW_RELEASES:
      return MarkerHint.NEW_RELEASES
    case TabMarker.RECOGNITION:
      return MarkerHint.RECOGNITION
    default:
      return MarkerHint.RECOGNITION
  }
}
