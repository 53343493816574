import { DropdownItemData, DropdownItemsData } from '../dropdown-item'

export enum PositionDropdown {
  LEFT = 'left',
  RIGHT = 'right',
  BOTTOM = 'bottom'
}

export type ItemsListProps<T extends string> = {
  className?: string
  open: boolean
  active?: string | number | null
  data: DropdownItemsData<T>
  isScrollable?: boolean
  width: number
  height: number
  position?: PositionDropdown
  onChange?: (value: DropdownItemData<T>) => void
  onOpen: (state?: boolean | undefined) => () => void
}
