import React, { FC, useState, useMemo } from 'react'

import { Icon } from '../../icon'
import { Row } from '../../row'
import { Marker } from './marker'
import { getMarkerHint } from './helpers'

import { MarkersProps, TabMarker } from './markers.types'
import { useStyle } from './markers.styles'
import { TextTranslate } from '../../text'

export const Markers: FC<MarkersProps> = ({
  warnings,
  hasRecognitionUpdate,
  hasDocumentUpdate
}) => {
  const classes = useStyle()

  const [currentMarker, changeCurrentMarker] = useState('')
  const [messageTx, changeMessageTx] = useState<TextTranslate>()
  const [visibleMarkers, changeVisibleMarkers] = useState({
    [TabMarker.TRIANGLE]: false,
    [TabMarker.NEW_RELEASES]: false,
    [TabMarker.RECOGNITION]: false
  })

  const isWarning = useMemo(
    () => warnings && Boolean(warnings.length),
    [warnings]
  )
  const isRecognized = useMemo(
    () => !hasDocumentUpdate && hasRecognitionUpdate,
    [hasDocumentUpdate, hasRecognitionUpdate]
  )

  const handleOnHoverState = (state: boolean, marker: TabMarker) => () => {
    changeVisibleMarkers({
      ...visibleMarkers,
      [currentMarker]: false,
      [marker]: state
    })
    changeCurrentMarker(marker)

    const hint = getMarkerHint(marker)
    changeMessageTx(hint)
  }

  return (
    <Row>
      {isWarning && (
        <Marker
          hover={visibleMarkers[TabMarker.TRIANGLE]}
          messageTx={messageTx}
        >
          <Icon
            className={classes.marker}
            name={TabMarker.TRIANGLE}
            onMouseOver={handleOnHoverState(true, TabMarker.TRIANGLE)}
            onMouseOut={handleOnHoverState(false, TabMarker.TRIANGLE)}
          />
        </Marker>
      )}

      {hasDocumentUpdate && (
        <Marker
          hover={visibleMarkers[TabMarker.NEW_RELEASES]}
          messageTx={messageTx}
        >
          <Icon
            className={classes.marker}
            name={TabMarker.NEW_RELEASES}
            onMouseOver={handleOnHoverState(true, TabMarker.NEW_RELEASES)}
            onMouseOut={handleOnHoverState(false, TabMarker.NEW_RELEASES)}
          />
        </Marker>
      )}

      {isRecognized && (
        <Marker
          hover={visibleMarkers[TabMarker.RECOGNITION]}
          messageTx={messageTx}
        >
          <Icon
            className={classes.marker}
            name={TabMarker.RECOGNITION}
            onMouseOver={handleOnHoverState(true, TabMarker.RECOGNITION)}
            onMouseOut={handleOnHoverState(false, TabMarker.RECOGNITION)}
          />
        </Marker>
      )}
    </Row>
  )
}
