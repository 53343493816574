import React, { ChangeEvent, FC, useState, useMemo } from 'react'

import {
  Column,
  concatClassNames,
  Icon,
  Input,
  MenuSlider,
  Row,
  Spinner,
  Text
} from '../../components'
import { useDebounceInput } from '../../hooks'
import { ProgramHeader } from './program-header'
import { DocumentTypeLine } from './document-type-line'

import { FullProgramCardProps } from './full-program-card.types'
import { useStyle } from './full-program-card.styles'

export const FullProgramCard: FC<FullProgramCardProps> = ({
  className = '',
  catalog,
  documentTypes,
  field,
  title,
  type,
  value,
  manualValue,
  resolution,
  updatedAt = new Date(27, 5, 2022),
  onOpen,
  onSearch
}) => {
  const [open, changeOpen] = useState(false)
  const [search, changeSearch] = useState('')

  const classes = useStyle({ open })

  const handleOnOpen = (state: boolean) => {
    changeOpen(state)

    if (onOpen) {
      onOpen(state)
    }
  }

  const handleOnChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    if (open) {
      changeSearch(event.target.value)
    }
  }

  const handleOnSearch = (nextSearch: string) => {
    if (open && onSearch) {
      onSearch(nextSearch, open)
    }
  }

  useDebounceInput({ value: search, onChange: handleOnSearch })

  const HEADER = useMemo(
    () => (
      <ProgramHeader
        open={open}
        catalog={catalog}
        field={field}
        title={title}
        type={type}
        value={value}
        manualValue={manualValue}
        resolution={resolution}
        updatedAt={updatedAt}
      />
    ),
    [open, catalog, field, title, type, value, resolution, updatedAt]
  )

  return (
    <MenuSlider
      arrow={false}
      className={concatClassNames(className, classes.container)}
      headerClassName={classes.header}
      header={HEADER}
      open={open}
      onOpen={handleOnOpen}
    >
      {documentTypes ? (
        <Column fullWidth className={classes.content}>
          <Row fullWidth justifyContent="flex-start">
            <Icon name="Document" />
            <Text
              preset="bodyBold"
              text="Documents"
              tx="program.card.document.title"
            />
            <Input
              className={classes.inputContainer}
              classNameInput={classes.input}
              placeholder="Search"
              value={search}
              onChange={handleOnChangeSearch}
            />
          </Row>
          {documentTypes?.map((documentType) => (
            <DocumentTypeLine
              key={`documentType_${documentType.id}`}
              className={classes.item}
              name={documentType.title}
            />
          ))}
        </Column>
      ) : (
        <Spinner />
      )}
    </MenuSlider>
  )
}
