import React, { FC, useCallback, useState, useMemo, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { RPSResolution } from '../../services'
import { DocumentCreators } from '../../store'
import { ResolutionModal } from '../resolution-modal'
import {
  getIsDataChanged,
  getIsProgressValue,
  submitModal,
  closeModal,
  getIsDisabled,
  getDefaultData,
  getResolutionData
} from './helpers'
import { RejectionList } from './rejection-list'

import {
  ResolutionModalTypes,
  RpsResolutionModalProps,
  RpsResolutionModalValue,
  RejectionReasonFunc
} from './rps-resolution-modal.types'

export const RpsResolutionModal: FC<RpsResolutionModalProps> = ({
  open,
  title = 'App Resolution',
  titleTx = 'document.appResolution',
  description = 'Please choose the reason code and the resolution \nfor a document.',
  descriptionTx = 'document.resolution.description',
  defaultValue: outerDefaultValue,
  disabled,
  modal = ResolutionModalTypes.APPLICATION,
  onClose,
  onSubmit
}) => {
  const dispatch = useDispatch()

  const defaultValue = getDefaultData({ outerDefaultValue })

  const [resolutionData, changeResolutionData] =
    useState<RpsResolutionModalValue>(defaultValue)

  const isProgressState = useMemo(
    () => getIsProgressValue(resolutionData.resolution),
    [resolutionData]
  )
  const isSubmitDisabled: boolean = useMemo(
    () => getIsDataChanged(resolutionData, outerDefaultValue),
    [resolutionData, outerDefaultValue]
  )

  const handleOnSubmit = useCallback(
    (resolution: RPSResolution) => {
      const nextValue = { ...resolutionData, resolution }
      changeResolutionData(nextValue)

      closeModal(onClose)

      submitModal(onSubmit)(nextValue)
    },
    [resolutionData]
  )

  const handleOnReset = () => {
    changeResolutionData(defaultValue)
  }

  const handleOnClose = () => {
    handleOnReset()

    closeModal(onClose)
  }

  const resetRejectionList = () => {
    dispatch(
      DocumentCreators.getRejectionReasonAppRequest({
        query: { rpsResolution: String(RPSResolution.IN_PROGRESS) },
        reset: true
      })
    )
  }

  const getRejectionReason = (
    resolution: RPSResolution,
    callback: RejectionReasonFunc
  ) => {
    callback(resolution)

    const isProgress = getIsProgressValue(resolution)
    if (isProgress) {
      resetRejectionList()
      return
    }

    const isDocument = modal === ResolutionModalTypes.DOCUMENT
    if (isDocument) {
      dispatch(
        DocumentCreators.getRejectionReasonDocRequest({
          query: { rpsResolution: String(resolution) }
        })
      )
      return
    }

    dispatch(
      DocumentCreators.getRejectionReasonAppRequest({
        query: { rpsResolution: String(resolution) }
      })
    )
  }

  const setBasicResolutionData = (resolution: RPSResolution) => {
    const nextValue = { ...resolutionData, resolution }
    changeResolutionData(nextValue)
  }

  const updateResolutionData = (resolution: RPSResolution) => {
    const nextValue = getResolutionData({
      resolutionData,
      resolution,
      outerDefaultValue
    })
    changeResolutionData(nextValue)
  }

  const handleOnResolutionChange = (resolution: RPSResolution) => {
    getRejectionReason(resolution, updateResolutionData)
  }

  useEffect(() => {
    if (!open) {
      return
    }

    if (isProgressState) {
      return
    }

    getRejectionReason(defaultValue?.resolution, setBasicResolutionData)
  }, [open])

  useEffect(() => {
    return () => {
      resetRejectionList()
    }
  }, [open])

  useEffect(() => {
    const defValue = getDefaultData({ outerDefaultValue })
    changeResolutionData(defValue)
  }, [outerDefaultValue?.documentId])

  if (!open) {
    return null
  }

  const isDisabledButton = getIsDisabled({ disabled, isSubmitDisabled })
  return (
    <ResolutionModal
      open={open}
      docId={resolutionData.documentId}
      defaultValue={resolutionData.resolution}
      description={description}
      descriptionTx={descriptionTx}
      disabled={isDisabledButton}
      title={title}
      titleTx={titleTx}
      onClose={handleOnClose}
      onSubmit={handleOnSubmit}
      onResolutionChange={handleOnResolutionChange}
    >
      <RejectionList data={resolutionData} onChange={changeResolutionData} />
    </ResolutionModal>
  )
}
