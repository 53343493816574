import React, { FC, useMemo } from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../../../theme'
import {
  Column,
  concatClassNames,
  Icon,
  Modal,
  Row,
  Spinner,
  Text
} from '../../../../components'

import { LogDetailsProps } from './log-details.types'
import { useStyle } from './log-details.styles'

export const LogDetails: FC<LogDetailsProps> = ({
  open,
  className,
  data,
  title,
  titleTx,
  onClose
}) => {
  const classes = useStyle()
  const theme: LightTheme = useTheme()

  const CONTENT = useMemo(
    () =>
      data && (
        <Column
          className={classes.emptyContent}
          fullWidth
          alignItems="flex-start"
        >
          <Row className={classes.row}>
            <Text text="User:" preset="body" color="border" />
            <Text text={data.userName} preset="h3" />
          </Row>
          <Row className={classes.row}>
            <Text text="User’s Email:" preset="body" color="border" />
            <Text text="-" preset="h3" />
          </Row>
          <Row className={classes.row}>
            <Text text="Program:" preset="body" color="border" />
            <Text text={data.program} preset="h3" />
          </Row>
          <Row className={classes.row}>
            <Text text="APP ID:" preset="body" color="border" />
            <Text text={data.id} preset="h3" />
          </Row>
          <Row className={classes.row}>
            <Text text="App Resolution:" preset="body" color="border" />
            <Text text="-" preset="h3" />
          </Row>
          <Row className={classes.row}>
            <Text text="Document:" preset="body" color="border" />
            <Text text="-" preset="h3" />
          </Row>
        </Column>
      ),
    [data]
  )

  if (!open) {
    return <></>
  }

  return (
    <Modal
      className={concatClassNames(className, classes.modal)}
      onClose={onClose}
    >
      <Row
        fullWidth
        className={classes.titleContainer}
        justifyContent="flex-start"
      >
        <Icon name="Document" fill={theme.colors.blue} />
        <Text className={classes.title} preset="h1" text={title} tx={titleTx} />
      </Row>
      {data ? (
        CONTENT
      ) : (
        <Column className={classes.basic}>
          <Spinner preset="small" />
        </Column>
      )}
    </Modal>
  )
}
