import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  container: {
    position: 'absolute',
    right: 28,
    bottom: 28,
    opacity: 0.7,
    zIndex: 100
  }
})
