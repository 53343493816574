import React, { FC } from 'react'

import { concatClassNames, Counter, Row, Text } from '../../../components'

import { useStyle } from './full-fields-menu-header.styles'
import { FullFieldsMenuHeaderProps } from './full-fields-menu-header.types'

export const FullFieldsMenuHeader: FC<FullFieldsMenuHeaderProps> = ({
  className,
  count,
  title,
  titleTx
}) => {
  const classes = useStyle()
  return (
    <Row
      fullWidth
      className={concatClassNames(className)}
      justifyContent="flex-start"
    >
      <Counter count={count} />
      <Text className={classes.title} preset="h2" text={title} tx={titleTx} />
    </Row>
  )
}
