import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'

import configureStore from './store'
import rootSaga from './sagas'
import { config } from '../config'
import * as reducers from './reducers'

/* ------------- Assemble The Reducers ------------- */

export const storeReducers = combineReducers({
  app: reducers.appReducer,
  applications: reducers.applicationsReducer,
  auth: reducers.authReducer,
  document: reducers.documentReducer,
  settings: reducers.settingsReducer,
  validationRule: reducers.validationRuleReducer,
  logs: reducers.logsReducer
})

export type Store = typeof storeReducers

export default () => {
  let finalReducers: Store = storeReducers
  // If rehydration is on use persistReducer otherwise default combineReducers
  if (config.REDUX_PERSIST.active) {
    const persistConfig = config.REDUX_PERSIST.storeConfig
    // @ts-ignore
    finalReducers = persistReducer(persistConfig, storeReducers)
  }

  let { store, sagasManager, sagaMiddleware } = configureStore(
    finalReducers,
    rootSaga
  )

  if (module.hot) {
    module.hot.accept(() => {
      const nextRootReducer = require('./').reducers
      store.replaceReducer(nextRootReducer)

      const newYieldedSagas = require('./sagas').default

      sagasManager.cancel()
      sagasManager.toPromise().then(() => {
        // @ts-ignore
        sagasManager = sagaMiddleware(newYieldedSagas)
      })
    })
  }

  return store
}

export * from './reducers'
export * from './actions'
export * from './store.types'
export * from './sagas'
