import { GetValidationRuleFilteredList } from '../../../services'
import { rawCoreItemToCoreItem } from '../app'

import {
  ValidationRuleFilterList,
  ValidationRuleFilterListKeys
} from './validation-rule.types'

export const defaultFilterCatalog = {
  fields: [],
  documentTypes: [],
  document: [],
  program: []
}

export const getFilterCatalogFromRawCatalog = (
  data: GetValidationRuleFilteredList
) => {
  return Object.entries(data).reduce<ValidationRuleFilterList>(
    (acc, catalogType) => {
      const key = catalogType[0] as ValidationRuleFilterListKeys

      return {
        ...acc,
        [key]: catalogType[1].map(rawCoreItemToCoreItem)
      }
    },
    defaultFilterCatalog
  )
}
