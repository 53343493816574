import React, { FC, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { State } from '../../../store'
import { concatClassNames } from '../../helpers'
import { Icon } from '../../icon'
import { Modal } from '../../modal'
import { Row } from '../../row'
import { Column } from '../../column'
import { Text } from '../../text'
import { Spinner } from '../../spinner'
import { FieldCommentsList } from '../../field-comments-list'

import { ValidationFieldHintProps } from './validation-field-hint.types'
import { useStyle } from './validation-field-hint.styles'

export const ValidationFieldHint: FC<ValidationFieldHintProps> = ({
  open,
  className,
  title,
  titleTx,
  onClose
}) => {
  const { fieldInfo, fieldCommentLoaded } = useSelector((state: State) => ({
    ...state.document
  }))
  const isScrollable: boolean = fieldInfo ? fieldInfo.length > 3 : false
  const classes = useStyle({ isScrollable })

  const isInfoExist = useMemo(() => fieldInfo && fieldInfo.length, [fieldInfo])
  const CONTENT = useMemo(
    () =>
      isInfoExist ? (
        <FieldCommentsList fieldInfo={fieldInfo} />
      ) : (
        <Row className={classes.emptyContent}>
          <Text
            text="This field has no description, comments, or relevant rules."
            tx="field.noDescription"
            preset="body"
            color="inactive"
          />
        </Row>
      ),
    [fieldInfo, isInfoExist]
  )

  if (!open) {
    return <></>
  }

  return (
    <Modal
      className={concatClassNames(className, classes.modal)}
      onClose={onClose}
    >
      <Row
        fullWidth
        className={classes.titleContainer}
        justifyContent="flex-start"
      >
        <Icon name="Info" />
        <Text className={classes.title} preset="h1" text={title} tx={titleTx} />
      </Row>
      {fieldCommentLoaded ? (
        CONTENT
      ) : (
        <Column className={classes.basic}>
          <Spinner preset="small" />
        </Column>
      )}
    </Modal>
  )
}
