import { createReducer } from 'reduxsauce'

import { DocumentActionTypes, DocumentTypes } from '../../actions'

import { DocumentState } from './document.types'
import { getRejectionReasonList, setItemById } from './utils'

/* ------------- Reducers ------------- */

const INITIAL_STATE: DocumentState = {
  documentHeader: null,
  totalPages: 0,
  documents: {},
  documentHeaderLoading: false,
  documentLoading: false,

  documentHeader2Loading: false,
  documentHeader2: null,

  documentToCompareLoading: false,
  documentsToCompare: {},
  totalPagesToCompare: 0,

  fieldCommentLoaded: true,
  fieldInfo: null,
  rejectionReasonList: null,

  versionsToCompare: null,
  versionsLoading: false
}

export const getBasicState = (state = INITIAL_STATE) => state
export const getStateByFailure = (state = INITIAL_STATE) => ({
  ...state,
  rejectionReasonList: null
})

/***
 * Get Document Header
 */

export const getDocumentHeaderRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    documentHeaderLoading: true,
    documentHeader: null
  }
}

export const getDocumentHeaderSuccess = (
  state = INITIAL_STATE,
  action: DocumentTypes.GetDocumentHeaderActionSuccess
) => {
  const documentHeader = action.payload

  return {
    ...state,
    documentHeaderLoading: false,
    documentHeader
  }
}

export const getDocumentHeaderFailure = (state = INITIAL_STATE) => {
  return {
    ...state,
    documentHeaderLoading: false,
    documentHeader: null
  }
}

/***
 * Get document
 */

export const getDocumentRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    documentLoading: true
  }
}

export const getDocumentSuccess = (
  state = INITIAL_STATE,
  action: DocumentTypes.GetDocumentActionSuccess
) => {
  const { id, pages, count, fields } = action.payload

  return {
    ...state,
    documents: {
      ...state.documents,
      [id]: { pages, count, fields }
    },
    totalPages: count,
    documentLoading: false
  }
}

export const getDocumentFailure = (state = INITIAL_STATE) => {
  return {
    ...state,
    document: [],
    documentLoading: false
  }
}

/***
 * Get document versions
 */

export const getDocumentVersionsRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    versionsLoading: true
  }
}

export const getDocumentVersionsSuccess = (
  state = INITIAL_STATE,
  action: DocumentTypes.GetDocumentVersionsActionSuccess
) => {
  const { results } = action.payload

  return {
    ...state,
    versionsToCompare: results,
    versionsLoading: false
  }
}

export const getDocumentVersionsFailure = (state = INITIAL_STATE) => {
  return {
    ...state,
    versionsToCompare: null,
    versionsLoading: false
  }
}

/***
 * Get Document Header TO COMPARE
 */

export const getDocumentHeaderToCompareRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    documentHeaderLoading2: true,
    documentHeader2: null
  }
}

export const getDocumentHeaderToCompareSuccess = (
  state = INITIAL_STATE,
  action: DocumentTypes.GetDocumentHeaderToCompareActionSuccess
) => {
  const documentHeader2 = action.payload

  return {
    ...state,
    documentHeader2Loading: false,
    documentHeader2
  }
}

export const getDocumentHeaderToCompareFailure = (state = INITIAL_STATE) => {
  return {
    ...state,
    documentHeader2Loading: false,
    documentHeader2: null
  }
}

/***
 * Get document to COMPARE
 */

export const getDocumentToCompareRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    documentToCompareLoading: true
  }
}

export const getDocumentToCompareSuccess = (
  state = INITIAL_STATE,
  action: DocumentTypes.GetDocumentToCompareActionSuccess
) => {
  const { id, pages, count, fields } = action.payload

  return {
    ...state,
    documentsToCompare: {
      ...state.documentsToCompare,
      [id]: { pages, count, fields }
    },
    totalPagesToCompare: count,
    documentToCompareLoading: false
  }
}

export const getDocumentToCompareFailure = (state = INITIAL_STATE) => {
  return {
    ...state,
    documentsToCompare: {},
    documentToCompareLoading: false
  }
}

// Update Rps resolution localy

export const updateResolutionAppSuccess = (
  state = INITIAL_STATE,
  action: DocumentTypes.UpdateResolutionAppActionSuccess
) => {
  if (state.documentHeader) {
    const { rpsResolution, rejectionReason } = action.payload

    return {
      ...state,
      documentHeader: {
        ...state.documentHeader,
        rpsResolution,
        rejectionReason,
        rpsResolutionDt: new Date()
      }
    }
  }

  return state
}

export const updateResolutionDocFieldSuccess = (
  state = INITIAL_STATE,
  action: DocumentTypes.UpdateResolutionDocFieldActionSuccess
) => {
  const { rpsResolution, rejectionReason, id } = action.payload

  if (!state.documentHeader) {
    return state
  }

  return {
    ...state,
    documentHeader: {
      ...state.documentHeader,
      documents: state.documentHeader.documents.map((document) =>
        setItemById(document, id, {
          ...document,
          rpsResolution,
          rejectionReason,
          rpsResolutionDt: new Date()
        })
      )
    }
  }
}

export const updateResolutionFieldSuccess = (
  state = INITIAL_STATE,
  action: DocumentTypes.UpdateResolutionFieldActionSuccess
) => {
  const { id, rpsResolution, document } = action.payload
  const doc = state.documents[document]

  if (doc) {
    const updatedDoc = {
      ...doc,
      fields: doc.fields.map((field) =>
        setItemById(field, id, {
          ...field,
          rpsResolution
        })
      )
    }

    return {
      ...state,
      documents: {
        ...state.documents,
        [document]: updatedDoc
      }
    }
  }

  return state
}

export const clearDocuments = (state = INITIAL_STATE) => ({
  ...state,
  documents: {},
  documentsToCompare: {}
})

export const clearDocumentsToCompare = (state = INITIAL_STATE) => ({
  ...state,
  documentsToCompare: {}
})
/***
 * Get Field comments
 */

export const getFieldInfoRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    fieldCommentLoaded: false,
    fieldInfo: null
  }
}

export const getFieldInfoSuccess = (
  state = INITIAL_STATE,
  action: DocumentTypes.GetFieldInfoActionSuccess
) => {
  const fieldInfo = action.payload

  return {
    ...state,
    fieldCommentLoaded: true,
    fieldInfo
  }
}

export const getFieldInfoFailure = (state = INITIAL_STATE) => {
  return {
    ...state,
    fieldCommentLoaded: true,
    fieldInfo: null
  }
}

/***
 * Get rejection reason application list
 */

export const getRejectionReasonAppRequest = getBasicState

export const getRejectionReasonAppSuccess = (
  state = INITIAL_STATE,
  action: DocumentTypes.GetRejectionReasonAppActionSuccess
) => {
  const results = getRejectionReasonList(action.payload.results)

  return {
    ...state,
    rejectionReasonList: results
  }
}

export const getRejectionReasonAppFailure = getStateByFailure

/***
 * Get rejection reason document list
 */

export const getRejectionReasonDocRequest = getBasicState

export const getRejectionReasonDocSuccess = getRejectionReasonAppSuccess

export const getRejectionReasonDocFailure = getStateByFailure

/* ------------- Hookup Reducers To Types ------------- */

export const documentReducer = createReducer(INITIAL_STATE, {
  [DocumentActionTypes.GET_DOCUMENT_HEADER_REQUEST]: getDocumentHeaderRequest,
  [DocumentActionTypes.GET_DOCUMENT_HEADER_SUCCESS]: getDocumentHeaderSuccess,
  [DocumentActionTypes.GET_DOCUMENT_HEADER_FAILURE]: getDocumentHeaderFailure,

  [DocumentActionTypes.GET_DOCUMENT_REQUEST]: getDocumentRequest,
  [DocumentActionTypes.GET_DOCUMENT_SUCCESS]: getDocumentSuccess,
  [DocumentActionTypes.GET_DOCUMENT_FAILURE]: getDocumentFailure,

  [DocumentActionTypes.GET_DOCUMENT_VERSIONS_REQUEST]:
    getDocumentVersionsRequest,
  [DocumentActionTypes.GET_DOCUMENT_VERSIONS_SUCCESS]:
    getDocumentVersionsSuccess,
  [DocumentActionTypes.GET_DOCUMENT_VERSIONS_REQUEST]:
    getDocumentVersionsFailure,

  [DocumentActionTypes.GET_DOCUMENT_HEADER_TO_COMPARE_REQUEST]:
    getDocumentHeaderToCompareRequest,
  [DocumentActionTypes.GET_DOCUMENT_HEADER_TO_COMPARE_SUCCESS]:
    getDocumentHeaderToCompareSuccess,
  [DocumentActionTypes.GET_DOCUMENT_HEADER_TO_COMPARE_FAILURE]:
    getDocumentHeaderToCompareFailure,

  [DocumentActionTypes.GET_DOCUMENT_TO_COMPARE_REQUEST]:
    getDocumentToCompareRequest,
  [DocumentActionTypes.GET_DOCUMENT_TO_COMPARE_SUCCESS]:
    getDocumentToCompareSuccess,
  [DocumentActionTypes.GET_DOCUMENT_TO_COMPARE_FAILURE]:
    getDocumentToCompareFailure,

  [DocumentActionTypes.UPDATE_RESOLUTION_APP_SUCCESS]:
    updateResolutionAppSuccess,
  [DocumentActionTypes.UPDATE_RESOLUTION_APP_FAILURE]: getDocumentFailure,

  [DocumentActionTypes.UPDATE_RESOLUTION_DOC_FIELD_SUCCESS]:
    updateResolutionDocFieldSuccess,
  [DocumentActionTypes.UPDATE_RESOLUTION_DOC_FIELD_FAILURE]: getDocumentFailure,

  [DocumentActionTypes.UPDATE_RESOLUTION_FIELD_SUCCESS]:
    updateResolutionFieldSuccess,
  [DocumentActionTypes.UPDATE_RESOLUTION_FIELD_FAILURE]: getDocumentFailure,

  [DocumentActionTypes.GET_FIELD_INFO_REQUEST]: getFieldInfoRequest,
  [DocumentActionTypes.GET_FIELD_INFO_SUCCESS]: getFieldInfoSuccess,
  [DocumentActionTypes.GET_FIELD_INFO_FAILURE]: getFieldInfoFailure,

  [DocumentActionTypes.GET_REJECTION_REASON_APP_SUCCESS]:
    getRejectionReasonAppSuccess,
  [DocumentActionTypes.GET_REJECTION_REASON_APP_FAILURE]:
    getRejectionReasonAppFailure,

  [DocumentActionTypes.GET_REJECTION_REASON_DOC_SUCCESS]:
    getRejectionReasonDocSuccess,
  [DocumentActionTypes.GET_REJECTION_REASON_DOC_FAILURE]:
    getRejectionReasonDocFailure,

  [DocumentActionTypes.CLEAR_DOCUMENTS]: clearDocuments,
  [DocumentActionTypes.CLEAR_DOCUMENTS_TO_COMPARE]: clearDocumentsToCompare
})
