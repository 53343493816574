export interface GetCurrentPartProps {
  count: number
  current: number
  limit?: number
}

export enum FilterValue {
  PROGRAM = 'program',
  APP_ID = 'id',
  APP_DATE = 'date',
  STATUS = 'status',
  RESULTS = 'results',
  RUN_DATE = 'run_date',
  RPS = 'rps',
  RPS_REVIEW_DATE = 'review_date',
  RESOLUTION = 'resolution'
}

export type ParamsProps = {
  id: string
}
