import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  item: {},
  title: {
    '&:not(:first-child)': {
      marginLeft: 8
    }
  },
  valueTitle: {
    width: '100%'
  },
  iconTitles: {
    minWidth: 95
  },
  header: {},
  listContainer: { marginTop: 8 },
  list: {}
})
