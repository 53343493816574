import React from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../theme'
import { Column } from '../column'
import { Icon } from '../icon'
import { Row } from '../row'
import { Text } from '../text'

import { EmptySearchResultsProps } from './empty-search-results.types'
import { useStyle } from './empty-search-results.styles'

export const EmptySearchResults = ({
  text = 'search'
}: EmptySearchResultsProps) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })

  return (
    <Column fullWidth className={classes.container} justifyContent="flex-start">
      <Icon name="EmptySearch" className={classes.icon} />
      <Row className={classes.title}>
        <Text
          text="Oops, no results!"
          tx="application.search.noresults"
          preset="h2"
        />
      </Row>
      <Row className={classes.description}>
        <Text
          text="We can’t find any item matching your {search}."
          tx="application.search.resultsDescription"
          preset="body"
          values={{ search: text }}
        />
      </Row>
    </Column>
  )
}
