import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  column: {
    width: 114
  },
  title: {
    width: 32
  }
})
