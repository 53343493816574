import React, { FC } from 'react'

import { IconProps } from './types'

export const Plot: FC<IconProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={580}
      height={550}
      viewBox="0 0 580 550"
      fill="none"
      {...props}
    >
      <image href={props.value} width={580} height={550} />
    </svg>
  )
}
