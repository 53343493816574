import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../../theme'
import { SVGIconProps } from './icons.types'

export const Info: FC<SVGIconProps> = (props) => {
  const theme: LightTheme = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={40}
      height={40}
      viewBox="0 0 40 40"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 3.333C10.8 3.333 3.333 10.8 3.333 20c0 9.2 7.467 16.667 16.667 16.667 9.2 0 16.667-7.467 16.667-16.667C36.667 10.8 29.2 3.334 20 3.334Zm-1.667 8.334V15h3.334v-3.333h-3.334Zm0 6.667v10h3.334v-10h-3.334ZM6.667 20c0 7.35 5.983 13.334 13.333 13.334 7.35 0 13.333-5.984 13.333-13.334S27.35 6.667 20 6.667C12.65 6.667 6.667 12.65 6.667 20Z"
        fill={props.fill || theme.colors.orange}
      />
    </svg>
  )
}
