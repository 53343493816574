import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import { useDefaultValue } from '@cse/ui'

import {
  Column,
  concatClassNames,
  DocumentViewerHeader,
  Icon,
  Row,
  DocumentViewerContent,
  DocumentViewerContentProps
} from '../../components'
import { DocumentViewerOptions, getDefaultValue } from '../../pages'
import { getImageProperties, setImageProperties, updateFields } from './helpers'
import { MenuContent } from './menu-content'

import {
  MenuFieldsDataType,
  PopupDocumentValidationProps
} from './popup-document-validation.types'
import { useStyle } from './popup-document-validation.styles'

export const PopupDocumentValidation: FC<PopupDocumentValidationProps> = ({
  open,
  activeField,
  data,
  isEditable,
  defaultValue,
  pages,
  fields,
  options: outerOptions,
  onClose,
  onApprove,
  onReject,
  onFocusInput,
  onBlurInput,
  onChangeOptions,
  onChangePage,
  onClickPolygon,
  onChangeFitToPage,
  onChangeFitToWidth
}) => {
  const viewerRef = useRef<HTMLDivElement>(null)

  const DefaultValue = useMemo(
    () => getDefaultValue({ defaultValue }),
    [defaultValue]
  )

  const [listProps, changeListProps] = useState({
    listWidth: 534,
    listHeight: 534
  })
  const [aspectRatio, changeAspectRatio] = useState(1)
  const [openMenu, changeOpenMenu] = useState(false)
  const [fieldType, changeFieldType] = useState<
    MenuFieldsDataType | undefined
  >()
  const [options, changeOptions] = useState<DocumentViewerOptions>(DefaultValue)

  const Options = useDefaultValue(options, outerOptions)
  const classes = useStyle({ fieldType })
  const isValidatedFieldsExist = Boolean(fields.validated.length)
  const isVerficationFieldsExist = Boolean(fields.verification.length)

  useEffect(() => {
    const isData = activeField && data
    const isActive = !openMenu && isData

    if (!isActive) {
      return
    }

    updateFields({
      openMenu,
      activeField,
      data,
      changeFieldType,
      changeOpenMenu
    })
  }, [activeField])

  const ImageProps = useMemo(
    () =>
      getImageProperties({
        options,
        listProps,
        aspectRatio
      }),
    [options.fitToPage, aspectRatio, listProps]
  )

  const handleOnImageLoad: DocumentViewerContentProps['onImageLoad'] =
    setImageProperties({ viewerRef, changeAspectRatio, changeListProps })

  const handleOnChangeOptions = (nextOptions: DocumentViewerOptions) => {
    changeOptions(nextOptions)

    if (onChangeOptions) {
      onChangeOptions(nextOptions)
    }
  }

  const handleOnChangeType = (type: MenuFieldsDataType) => () => {
    changeFieldType(type)
    changeOpenMenu(true)
  }

  if (!open) {
    return null
  }

  return (
    <Column className={classes.container}>
      <Column fullWidth fullHeight>
        <DocumentViewerHeader
          className={classes.header}
          options={Options}
          pages={pages}
          onChangeOptions={handleOnChangeOptions}
          onChangePage={onChangePage}
          onChangeFitToPage={onChangeFitToPage}
          onChangeFitToWidth={onChangeFitToWidth}
        >
          <Icon name="CLEAR" onClick={onClose} />
        </DocumentViewerHeader>
        <Row
          fullWidth
          fullHeight
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Column
            fullWidth
            fullHeight
            justifyContent="flex-start"
            ref={viewerRef}
          >
            <DocumentViewerContent
              activeField={activeField}
              data={data}
              listWidth={ImageProps.listWidth}
              listHeight={ImageProps.listHeight}
              options={Options}
              onClickPolygon={onClickPolygon}
              onImageLoad={handleOnImageLoad}
            />
          </Column>
          <Column
            fullHeight
            className={classes.menuContainer}
            justifyContent="flex-start"
          >
            {isVerficationFieldsExist && (
              <Icon
                className={concatClassNames(
                  classes.menuItem,
                  classes.verificationItem
                )}
                name="NewReleasesEmpty"
                onClick={handleOnChangeType('verification')}
              />
            )}
            {isValidatedFieldsExist && (
              <Icon
                className={concatClassNames(
                  classes.menuItem,
                  classes.validatedItem
                )}
                name="VerifiedUser"
                onClick={handleOnChangeType('validated')}
              />
            )}
            <MenuContent
              activeField={activeField}
              openMenu={openMenu}
              isEditable={isEditable}
              fields={fields}
              fieldType={fieldType}
              changeOpenMenu={changeOpenMenu}
              changeFieldType={changeFieldType}
              onApprove={onApprove}
              onReject={onReject}
              onFocusInput={onFocusInput}
              onBlurInput={onBlurInput}
            />
          </Column>
        </Row>
      </Column>
    </Column>
  )
}
