import React, { FC } from 'react'

import { Row } from '../../../row'
import { Text } from '../../../text'
import { PopoverHint, PopoverHintPosition } from '../../../popover-hint'

import { MarkerProps } from './marker.types'
import { useStyle } from './marker.styles'

export const Marker: FC<MarkerProps> = ({ hover, messageTx, children }) => {
  const classes = useStyle({ hover })

  return (
    <Row>
      {children}
      <Row className={classes.messageHint}>
        <PopoverHint
          open={hover}
          className={classes.messageContainer}
          hintPosition={PopoverHintPosition.TOP_LEFT}
        >
          <Text tx={messageTx} text="" preset="subTitle" color="inactive" />
        </PopoverHint>
      </Row>
    </Row>
  )
}
