import React, { useEffect, useRef, useState } from 'react'
import { Button as ButtonComponent } from '@cse/ui'

import { PopoverHint, PopoverHintPosition } from '../popover-hint'
import { Row } from '../row'
import { Text } from '../text'

import { ButtonProps } from './button.types'
import { useStyle } from './button.styles'

export const Button = ({
  fixed,
  className = '',
  isHint = false,
  message = '',
  messageTx,
  ...props
}: ButtonProps) => {
  const [hover, changeHover] = useState(false)
  const classes = useStyle({ fixed, hover })
  const buttonRef = useRef<HTMLDivElement>(null)
  const [buttonWidth, changeButtonWidth] = useState<number>(0)

  const handleOnHoverState = (state: boolean) => () => {
    changeHover(state)
  }

  useEffect(() => {
    if (buttonRef.current) {
      const { width } = buttonRef.current.getBoundingClientRect()
      changeButtonWidth(width)
    }
  }, [buttonRef])

  return (
    <>
      <Row
        ref={buttonRef}
        className={`${className} ${classes.container}`}
        onMouseOver={handleOnHoverState(true)}
        onMouseOut={handleOnHoverState(false)}
      >
        <ButtonComponent {...props} />
      </Row>
      {isHint && (
        <Row className={classes.messageHint}>
          <PopoverHint
            open={hover}
            className={classes.messageContainer}
            hintPosition={PopoverHintPosition.TOP_CENTER}
            buttonWidth={buttonWidth}
          >
            <Text
              className={classes.message}
              text={message}
              tx={messageTx}
              preset="subTitle"
              color="inactive"
            />
          </PopoverHint>
        </Row>
      )}
    </>
  )
}
