import { useContext, useMemo } from 'react'

import { UserContext } from '../../../providers'
import { ProgramSettings } from '../../../services'

import {
  AutoDeletionStatusProps,
  GetAutoDeletionDataProps
} from './apps-auto-deletion.types'

export const getIsDataChanged = (
  oldData: ProgramSettings[] | null,
  newData?: AutoDeletionStatusProps
) => {
  if (oldData && newData) {
    const isChanged = oldData?.find(
      (item) => item.autoDeletionTerm !== newData[item.id]
    )
    return Boolean(isChanged)
  }
  return false
}

export const useAutoDeletionData = ({
  daysByProgram,
  settings
}: GetAutoDeletionDataProps) => {
  const { isSuperUser, isSysAdmin } = useContext(UserContext)

  const IsGrantedUser = useMemo(
    () => isSysAdmin || isSuperUser,
    [isSysAdmin, isSuperUser]
  )
  const isDataChanged = getIsDataChanged(settings, daysByProgram)
  const IsSaveAvailable = isDataChanged ? IsGrantedUser : false

  return {
    isSaveAvailable: IsSaveAvailable,
    isGrantedUser: IsGrantedUser
  }
}
