import { createUseStyles } from 'react-jss'

import { LightTheme } from '../theme'

// TODO: replace repeats with 'mixins' in jss

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    minWidth: 80,
    padding: '20px 0',
    cursor: theme.cursor,
    '& span': {
      cursor: theme.cursor
    }
  },
  bottomDivider: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: 2,
    borderRadius: 4,
    backgroundColor: theme.colors.blue
  }
}))
