import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  item: {
    '&:first-child': {
      marginLeft: 8
    },
    '&:not(:first-child)': {
      marginLeft: 4
    }
  }
})
