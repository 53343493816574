import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { Color, LightTheme } from '../../theme'

import { Row } from '../row'
import { Text } from '../text'
import { Icon } from '../icon'
import { Markers } from './markers'

import { TabsNavItemProps } from './tabs-nav-item.types'
import { useStyle } from './tabs-nav-item.styles'

export const CategoryNavItem: FC<TabsNavItemProps> = ({
  className = '',
  active,
  text,
  tx,
  icon,
  warnings,
  hasRecognitionUpdate,
  hasDocumentUpdate,
  onClick
}) => {
  const theme = useTheme<LightTheme>()
  const activeColor: Color = active ? 'blue' : 'text'
  const activeTextColor: Color = active ? 'blue' : 'text'
  const activeFill = theme.colors[activeColor]
  const classes = useStyle({ active })

  return (
    <>
      {icon ? (
        <Row
          className={`${className} ${classes.container} ${classes.iconContainer}`}
          justifyContent="flex-start"
          onClick={onClick}
        >
          <Icon fill={activeFill} height={24} name={icon} width={24} />
          <Text
            className={classes.text}
            text={text}
            tx={tx}
            color={activeTextColor}
          />
          <Markers
            warnings={warnings}
            hasDocumentUpdate={hasDocumentUpdate}
            hasRecognitionUpdate={hasRecognitionUpdate}
          />
        </Row>
      ) : (
        <Row
          className={`${className} ${classes.container}`}
          justifyContent="center"
          onClick={onClick}
        >
          <Text text={text} tx={tx} color={activeTextColor} />
          <Markers
            warnings={warnings}
            hasDocumentUpdate={hasDocumentUpdate}
            hasRecognitionUpdate={hasRecognitionUpdate}
          />
        </Row>
      )}
    </>
  )
}
