import { createUseStyles } from 'react-jss'

import { MarkersStyleProps } from './marker.types'

export const useStyle = createUseStyles({
  messageHint: {
    visibility: ({ hover }: MarkersStyleProps) => (hover ? 'visible' : 'hidden')
  },
  messageContainer: {
    padding: '10px !important',
    maxWidth: 80
  }
})
