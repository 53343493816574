import React from 'react'
import { DropdownFloatLabel as DropdownComponent } from '@cse/ui'

import { Row } from '../row'

import { DropdownProps } from './dropdown-float-label.types'
import { useStyle } from './dropdown-float-label.styles'

export const DropdownFloatLabel = ({
  fixed,
  className = '',
  ...props
}: DropdownProps) => {
  const classes = useStyle({ fixed })
  return (
    <Row className={`${className} ${classes.container}`}>
      <DropdownComponent {...props} />
    </Row>
  )
}
