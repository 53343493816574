import {
  getColorByStatusResolution,
  getColorByStatusRpsResolution,
  getResolutionIcon,
  getStatusIconByColor
} from '../../../components'
import { getCatalogData } from '../../../pages'
import { DocumentSmall, Document } from '../../../services'

import { Catalog } from '../../reducers'

import { AdvancedDocument } from '../types'

export type GetStatusTableDataParams<T extends DocumentSmall | Document> = {
  data: T[]
  catalog: Catalog
}

export const getStatusTableData = <T extends Document>({
  data,
  catalog
}: GetStatusTableDataParams<T>): AdvancedDocument[] =>
  data.map<AdvancedDocument>((item) => {
    const document = getCatalogData({
      catalog,
      id: 'document',
      value: item.document
    })

    const resolution = getCatalogData({
      catalog,
      id: 'resolution',
      value: item.resolution
    })

    const rpsResolution = getCatalogData({
      catalog,
      id: 'rpsResolution',
      value: item.rpsResolution
    })

    return {
      ...item,
      title: document,
      iconColor: getColorByStatusResolution(item.resolution),
      icon: getStatusIconByColor(item.resolution),
      color: getColorByStatusResolution(item.resolution),

      iconColorRps: getColorByStatusRpsResolution(item.rpsResolution),
      iconRps: getResolutionIcon(item.rpsResolution),
      colorRps: getColorByStatusRpsResolution(item.rpsResolution),

      statusProps: {
        text: resolution || ''
      },
      statusRpsProps: {
        text: rpsResolution || ''
      }
    }
  })
