import React from 'react'

import { Text } from '..'

import { VersionLineProps } from './version-line.types'
import { useStyle } from './version-line.styles'

export const VersionLine = ({ submissionVersion, date }: VersionLineProps) => {
  const classes = useStyle()

  return (
    <>
      <Text
        text={`Version #${submissionVersion}`}
        preset="body"
        color="black"
      />
      <Text
        className={classes.fromDate}
        text={`from ${date}`}
        preset="body"
        color="border"
      />
    </>
  )
}
