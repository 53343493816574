import React, { FC } from 'react'

import { Breadcrumbs } from '../breadcrumbs'
import { Column } from '../column'
import { Content } from '../content'
import { Row } from '../row'
import { Text } from '../text'
import { concatClassNames } from '../helpers'

import { useStyle } from './page-header.styles'
import { PageHeaderProps } from './page-header.types'

export const PageHeader: FC<PageHeaderProps> = ({
  back,
  backLink,
  className = '',
  children,
  title,
  titleTx,
  rightTitleContent,
  programFilter
}) => {
  const classes = useStyle()

  return (
    <Column
      fullWidth
      justifyContent="flex-start"
      className={concatClassNames(className, classes.container)}
    >
      <Content className={classes.noPadding}>
        <Row fullWidth justifyContent="space-between">
          <Row>
            {back && <Breadcrumbs to={backLink} />}
            <Text
              className={classes.title}
              preset="h1"
              text={title}
              tx={titleTx}
            />
            {programFilter}
          </Row>
          {rightTitleContent}
        </Row>
        <Row fullWidth justifyContent="flex-start" className={classes.row}>
          {children}
        </Row>
      </Content>
    </Column>
  )
}
