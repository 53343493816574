import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  container: {
    marginTop: 24,
    justifyItems: 'flex-start',
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
    listStyleType: 'none',
    columnGap: 24,
    rowGap: 24
  }
})
