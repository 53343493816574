import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { DropdownItemData } from '@cse/ui'

import {
  Button,
  concatClassNames,
  Dropdown,
  Row,
  TextTranslate
} from '../../../components'
import { State } from '../../../store'
import { useUserData } from '../../../hooks'

import { AppHeaderFilterValue } from '../apps-header'
import { useAppsData } from './helpers'

import { AppsHeaderFilterProps, Filters } from './apps-header-filter.types'
import { useStyle } from './apps-header-filter.styles'

export const AppsHeaderFilter: FC<AppsHeaderFilterProps> = ({
  className = '',
  value,
  defaultValues,
  onChange,
  onClear
}) => {
  const classes = useStyle()

  const { catalog, allReviewers, rpsResolution, user } = useSelector(
    (state: State) => ({
      ...state.app,
      ...state.app.catalog,
      ...state.auth
    })
  )
  const [values, changeValues] = useState<Filters>(defaultValues)

  const { RpsMenuData, RpsResolutionData, IsFilter } = useAppsData({
    allReviewers,
    rpsResolution,
    values
  })
  const { GeneralProgramsData } = useUserData({
    validationRule: null,
    catalog,
    user
  })

  const handleOnFilter =
    (prop: keyof AppHeaderFilterValue) =>
    (inputValue: DropdownItemData<TextTranslate>) => {
      if (inputValue.id) {
        const nextValue: AppHeaderFilterValue = {
          ...values,
          [prop]: String(inputValue.id)
        }

        if (onChange) {
          onChange(nextValue)
        }
      } else {
        if (onChange) {
          onChange({ ...value, [prop]: undefined })
        }
      }
    }

  useEffect(() => {
    changeValues(defaultValues)
  }, [defaultValues])

  return (
    <Row fullWidth justifyContent="flex-start" className={className}>
      <Dropdown
        fixed
        className={concatClassNames(classes.program, classes.input)}
        isScrollable
        data={GeneralProgramsData}
        label="Program"
        preset="body"
        active={values?.program}
        defaultValue={values?.program}
        value={values?.program}
        onChange={handleOnFilter('program')}
      />
      <Dropdown
        fixed
        className={concatClassNames(classes.program, classes.input)}
        isScrollable
        data={RpsResolutionData}
        label="Resolution"
        preset="body"
        active={values?.rps_resolution}
        defaultValue={values?.rps_resolution}
        value={values?.rps_resolution}
        onChange={handleOnFilter('rps_resolution')}
      />
      <Dropdown
        fixed
        className={concatClassNames(classes.reviewed, classes.input)}
        isScrollable
        data={RpsMenuData}
        label="Reviewed By"
        preset="body"
        active={values?.rps_reviewer}
        defaultValue={values?.rps_reviewer}
        value={values?.rps_reviewer}
        onChange={handleOnFilter('rps_reviewer')}
      />
      {IsFilter && (
        <Row className={classes.actions}>
          <Button
            className={classes.button}
            preset="secondary"
            text="Clear"
            tx="program.clear"
            onClick={onClear}
          />
        </Row>
      )}
    </Row>
  )
}
