import React, { FC, MouseEvent, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import {
  arrayHasElements,
  Column,
  Content,
  DocumentViewer,
  Row
} from '../../../components'
import { FieldVerification, PolygonField } from '../../../services'
import { DocumentCreators } from '../../../store'
import {
  FieldsMenu,
  PopupDocumentComparison,
  PopupDocumentValidation
} from '../../../views'
import { DocumentViewerOptions } from '../../types'
import { DocumentValidationHeader } from '../document-validation-header'
import { useSettings } from './hooks'

import { DocumentValidationProps } from './document-validation.types'
import { useStyle } from './document-validation.styles'

const MENU_WIDTH = 514
const MENU_MARGIN_LEFT = 24
const VALIDATION_MENU_HEIGHT = 58
const MENU_HEIGHT = 543
const COMPARISON_POPUP_URL_DIFFERENCE = 'compare'

export const DocumentValidation: FC<DocumentValidationProps> = ({
  documentHeader,
  versionsToCompare,
  docId,
  date,
  defaultValue,
  pages,
  fields,
  data,
  comparisonFields,
  comparisonPages,
  comparisonData,
  currentPage,
  currentComparisonPage,
  pagesData,
  comparisonPagesData,
  onChangePage,
  onChangeComparisonPage,
  onApprove,
  onReject,
  onSubmitResolution
}) => {
  const location = useLocation()
  const dispatch = useDispatch()
  const contentRef = useRef<HTMLDivElement>(null)
  const fieldViewRef = useRef<HTMLDivElement>(null)
  const navigate = useNavigate()

  const classes = useStyle({
    menuWidth: MENU_WIDTH,
    menuMarginLeft: MENU_MARGIN_LEFT
  })

  const [openPopup, changeOpenPopup] = useState(false)
  const [openComparisonPopup, changeOpenComparisonPopup] = useState(false)

  const [listWidth, changeListWidth] = useState<number | undefined>(undefined)
  const [listHeight, changeListHeight] = useState<number>(543)
  const [activeField, changeActiveField] = useState<FieldVerification['id']>()
  const [activeComparisonField, changeActiveComparisonField] =
    useState<FieldVerification['id']>()
  const [options, changeOptions] = useState<DocumentViewerOptions>({
    page: 1,
    zoom: 1,
    rotate: 0,
    fitToPage: false
  })
  const [options2, changeOptions2] = useState<DocumentViewerOptions>({
    page: 1,
    zoom: 1,
    rotate: 0,
    fitToPage: false
  })
  const [versionNames, changeVersionNames] = useState<[string, string]>([
    '',
    ''
  ])

  useEffect(() => {
    const isAnotherPage = currentPage !== options.page
    const isPage = currentPage && isAnotherPage

    if (isPage) {
      changeOptions({
        ...options,
        page: currentPage
      })
    }
  }, [currentPage])

  useEffect(() => {
    const isAnotherPage = currentComparisonPage !== options2.page
    const isPage = currentComparisonPage && isAnotherPage

    if (isPage) {
      changeOptions2({
        ...options2,
        page: currentComparisonPage
      })
    }
  }, [currentComparisonPage])

  const {
    Versions,
    DocNumber,
    DocName,
    Document,
    Fields,
    FirstDocData,
    SecondDocData
  } = useSettings({
    documentHeader,
    docId,
    fields,
    comparisonFields,
    activeField,
    activeComparisonField,
    options,
    options2,
    data,
    pages,
    comparisonData,
    comparisonPages,
    versionsToCompare
  })
  const isAllowed = documentHeader ? documentHeader.allowRpsResolutions : false

  useEffect(() => {
    dispatch(
      DocumentCreators.getDocumentVersionsRequest({
        params: { docId: Number(docId) }
      })
    )
  }, [docId])

  useEffect(() => {
    const contentWidth = contentRef.current?.offsetWidth
    const nextListWidth = contentWidth
      ? contentWidth - MENU_MARGIN_LEFT - MENU_WIDTH
      : undefined

    changeListWidth(nextListWidth)
  }, [
    openPopup,
    contentRef.current?.offsetWidth,
    Fields,
    fieldViewRef.current?.offsetWidth
  ])

  useEffect(() => {
    const contentHeight = fieldViewRef.current?.offsetHeight || 0
    const isContentLarge = contentHeight > MENU_HEIGHT

    if (isContentLarge) {
      changeListHeight(contentHeight - VALIDATION_MENU_HEIGHT)
    } else {
      changeListHeight(MENU_HEIGHT)
    }
  }, [
    Fields,
    contentRef.current?.offsetHeight,
    fieldViewRef.current?.offsetHeight
  ])

  const handleOnOpenPopup = (state: boolean) => () => {
    changeOpenPopup(state)
    changeOptions({
      ...options,
      fitToPage: false,
      zoom: 1
    })
    changeActiveField(undefined)
  }

  const handleOnOpenComparisonPopup = (state: boolean) => () => {
    changeOpenComparisonPopup(state)
    changeOptions2({
      ...options2,
      fitToPage: false,
      zoom: 1
    })
    changeActiveComparisonField(undefined)

    const isCompareState = location.pathname.includes(
      COMPARISON_POPUP_URL_DIFFERENCE
    )

    if (!isCompareState) {
      return
    }

    if (!state) {
      navigate(-1)
    }
  }

  const handleOnChangePage = (page: number) => {
    changeOptions({ ...options, page })

    if (onChangePage) {
      onChangePage(page)
    }
  }

  const handleOnComparisonChangePage = (page: number) => {
    changeOptions2({ ...options2, page })

    if (onChangeComparisonPage) {
      onChangeComparisonPage(page)
    }
  }

  const handleOnChangeOptions = (nextOptions: DocumentViewerOptions) => {
    changeOptions(nextOptions)
  }
  const onChangeComparisonOptions = (nextOptions: DocumentViewerOptions) => {
    changeOptions2(nextOptions)
  }

  const handleOnFocusInput = (field: FieldVerification) => {
    const page = pagesData.find((pageData) => pageData.id === field.page)
    if (page) {
      handleOnChangePage(page.number)
    }

    changeActiveField(field.id)
  }
  const handleOnComparisonFocusInput = (field: FieldVerification) => {
    const page = comparisonPagesData?.find(
      (pageData) => pageData.id === field.page
    )
    if (page) {
      handleOnComparisonChangePage(page.number)
    }

    changeActiveComparisonField(field.id)
  }

  const handleOnClickPolygon = (
    field: PolygonField,
    event: MouseEvent<Element, globalThis.MouseEvent>
  ) => {
    event.stopPropagation()
    event.preventDefault()

    changeActiveField(field.id)
  }
  const handleOnComparisonClickPolygon = (
    field: PolygonField,
    event: MouseEvent<Element, globalThis.MouseEvent>
  ) => {
    event.stopPropagation()
    event.preventDefault()

    changeActiveComparisonField(field.id)
  }

  const handleOnChangeFitToPage = (state: boolean) => () => {
    changeOptions({
      ...options,
      fitToPage: state
    })
  }

  const handleOnBlurInput = () => {
    changeActiveField(undefined)
    changeActiveComparisonField(undefined)
  }

  const setVersionNames = (name: string, prevVersionName: string) => {
    changeVersionNames([name, prevVersionName])
  }

  return (
    <Content className={classes.container}>
      {!openPopup && (
        <>
          <DocumentValidationHeader
            className={classes.header}
            isAllowed={isAllowed}
            docNumber={DocNumber}
            docType={DocName}
            versions={Versions}
            hasOtherVersions={Document?.hasOtherVersions}
            defaultValue={defaultValue}
            fields={Fields.preview}
            date={date}
            setVersionNames={setVersionNames}
            onSubmit={onSubmitResolution}
            onChangeComparisonPopup={handleOnOpenComparisonPopup(true)}
          />
          <Row
            fullWidth
            alignItems="flex-start"
            className={classes.content}
            ref={contentRef}
          >
            <DocumentViewer
              activeField={activeField}
              defaultValue={options}
              data={data}
              pages={pages}
              options={options}
              listWidth={listWidth}
              listHeight={listHeight}
              onChangeOptions={handleOnChangeOptions}
              onChangePage={handleOnChangePage}
              onClickPolygon={handleOnClickPolygon}
              onChangePopOut={handleOnOpenPopup(true)}
            />
            <Column fullWidth className={classes.fieldsView} ref={fieldViewRef}>
              {arrayHasElements(Fields.verification) && (
                <FieldsMenu
                  multiValue
                  defaultOpen
                  isEditable={isAllowed}
                  activeField={activeField}
                  className={classes.fieldsMenu}
                  data={Fields.verification}
                  icon="NewReleasesEmpty"
                  title="Fields for Verification"
                  titleTx="fields.verification"
                  onApprove={onApprove}
                  onReject={onReject}
                  onFocusInput={handleOnFocusInput}
                  onBlurInput={handleOnBlurInput}
                />
              )}
              {arrayHasElements(Fields.validated) && (
                <FieldsMenu
                  multiValue
                  defaultOpen
                  isEditable={isAllowed}
                  activeField={activeField}
                  className={classes.fieldsMenu}
                  data={Fields.validated}
                  icon="VerifiedUser"
                  title="Validated Fields"
                  titleTx="fields.validated"
                  onApprove={onApprove}
                  onReject={onReject}
                  onFocusInput={handleOnFocusInput}
                  onBlurInput={handleOnBlurInput}
                />
              )}
            </Column>
          </Row>
        </>
      )}
      {openPopup && (
        <PopupDocumentValidation
          activeField={activeField}
          defaultValue={options}
          data={data}
          isEditable={isAllowed}
          fields={Fields}
          pages={pages}
          open={openPopup}
          options={options}
          onApprove={onApprove}
          onReject={onReject}
          onFocusInput={handleOnFocusInput}
          onClose={handleOnOpenPopup(false)}
          onChangeOptions={handleOnChangeOptions}
          onChangePage={handleOnChangePage}
          onClickPolygon={handleOnClickPolygon}
          onChangeFitToPage={handleOnChangeFitToPage(true)}
          onChangeFitToWidth={handleOnChangeFitToPage(false)}
        />
      )}
      {openComparisonPopup && (
        <PopupDocumentComparison
          open={openComparisonPopup}
          docType={DocName}
          versions={versionNames}
          firstDocData={FirstDocData}
          secondDocData={SecondDocData}
          onClose={handleOnOpenComparisonPopup(false)}
          onApprove={onApprove}
          onReject={onReject}
          onBlurInput={handleOnBlurInput}
          onFocusInput={handleOnFocusInput}
          onChangeOptions={handleOnChangeOptions}
          onChangePage={handleOnChangePage}
          onClickPolygon={handleOnClickPolygon}
          onFocusComparisonInput={handleOnComparisonFocusInput}
          onClickComparisonPolygon={handleOnComparisonClickPolygon}
          onChangeComparisonPage={handleOnComparisonChangePage}
          onChangeComparisonOptions={onChangeComparisonOptions}
        />
      )}
    </Content>
  )
}
