import React, { useMemo } from 'react'
import { DropdownItemData } from '@cse/ui'

import {
  BreadcrumbsProps,
  TextTranslate,
  VersionLine
} from '../../../../components'
import { MainRoutes } from '../../../../routes'
import { RPSResolution, SubmissionVersion } from '../../../../services'
import { formatDateBySlash } from '../../../helpers'

import { SettingsProps } from './types'

export const useSettings = ({ id, data, catalog }: SettingsProps) => {
  const backProps: Partial<BreadcrumbsProps> = {
    to: `${MainRoutes.APPLICATION}/${id}`
  }

  const modalDefaultValue = {
    documentId: data?.id || 0, // appId
    resolution: data?.rpsResolution || RPSResolution.IN_PROGRESS,
    rejectionReason: data?.rejectionReason || ''
  }

  const Data = useMemo(
    () => ({
      resolution: 0,
      rejectionReason:
        catalog.rejectionReason as DropdownItemData<TextTranslate>[]
    }),
    []
  )

  const Versions = useMemo(() => {
    if (data !== null) {
      return (
        data?.submissionVersions?.map((item: SubmissionVersion) => {
          const date = formatDateBySlash(item.submissionVersionDt)
          const { id: versionId, submissionVersion } = item

          return {
            id: versionId,
            name: (
              <VersionLine submissionVersion={submissionVersion} date={date} />
            ),
            value: Number(versionId)
          }
        }) || []
      )
    }
    return []
  }, [data])

  return {
    backProps,
    modalDefaultValue,
    Data,
    Versions
  }
}
