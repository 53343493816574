import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  content: {
    padding: 0
  },
  box: {
    padding: '48px 0'
  },
  emailApplicant: {
    marginTop: 16
  },
  description: {
    marginTop: 24,
    whiteSpace: 'pre-line'
  },
  saveSettings: {
    marginTop: 48
  },
  inputs: {
    flexWrap: 'wrap'
  },
  cell: {
    maxWidth: 180,
    minWidth: 180,
    padding: '16px 0',
    '&:not(:first-child)': {
      marginLeft: 70
    },
    '&:nth-child(3n + 3) + &': {
      marginLeft: 0
    }
  },
  spinner: {
    height: 40
  }
})
