import {
  getColorByStatusResolution,
  getColorByStatusRpsResolution,
  getResolutionIcon,
  getStatusIconByColor,
  StatusExtendedItemProps
} from '../../../../components'

import { getCatalogData } from '../../../helpers'

import { ResultTableItemProps } from './results-row.types'

export const getResultsTableData = ({
  data,
  catalog
}: ResultTableItemProps): StatusExtendedItemProps[] =>
  data.map((item) => ({
    title: getCatalogData({
      catalog,
      id: 'document',
      value: item.document
    }),
    document: getCatalogData({
      catalog,
      id: 'document',
      value: item.document
    }),
    iconColor: getColorByStatusResolution(item.resolution),
    icon: getStatusIconByColor(item.resolution),
    color: getColorByStatusResolution(item.resolution),

    iconColorRps: getColorByStatusRpsResolution(item.rpsResolution),
    iconRps: getResolutionIcon(item.rpsResolution),
    colorRps: getColorByStatusRpsResolution(item.rpsResolution),

    statusProps: {
      text:
        getCatalogData({
          catalog,
          id: 'resolution',
          value: item.resolution
        }) || ''
    },
    statusRpsProps: {
      text:
        getCatalogData({
          catalog,
          id: 'rpsResolution',
          value: item.rpsResolution
        }) || ''
    }
  }))
