import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

import { BadgeStyleProps } from './badge.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    backgroundColor: ({ color = 'blue' }: BadgeStyleProps) =>
      `${theme.colors[color]}66`,
    borderRadius: 4,
    padding: '6px 10px'
  },
  text: {
    textTransform: 'capitalize'
  }
}))
