import React, { FC, useMemo } from 'react'
import { Resolution } from '../../services'

import { Badge } from '../badge'
import { getColorByStatusResolution } from '../helpers'

import { StatusBadgeProps, RESOLUTION } from './status-badge.types'

export const StatusBadge: FC<StatusBadgeProps> = ({
  className,
  value = Resolution.IGNORE
}) => {
  const nextValue = value || Resolution.IGNORE

  const { text, tx } = RESOLUTION[nextValue]
  const color = useMemo(() => getColorByStatusResolution(value), [value])

  return <Badge className={className} color={color} text={text} tx={tx} />
}
