import React, { useEffect, useState } from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../theme'

import { Icon } from '../icon'
import { Row } from '../row'
import { Text } from '../text'

import { getDisabledStatus } from './helpers'
import { PickerButtonProps, PickerButtonVariants } from './picker-button.types'
import { useStyle } from './picker-button.styles'

export const PickerButton = <T extends PickerButtonVariants>({
  className = '',
  name,
  variants,
  iconName,
  iconColor,
  titleProps,
  value,
  onClick
}: PickerButtonProps<T>) => {
  const theme: LightTheme = useTheme()
  const [disabled, changeDisabled] = useState(getDisabledStatus(value, name))
  const classes = useStyle({ theme, disabled })
  const fill = disabled ? theme.colors.innerBorder : iconColor

  useEffect(() => {
    const isUpdDisabled = getDisabledStatus(value, variants[name])
    changeDisabled(isUpdDisabled)
  }, [value])

  const handleOnClick = () => {
    if (onClick) {
      const isUpdDisabled = getDisabledStatus(value, variants[name])
      onClick(variants[name], isUpdDisabled)
    }
  }

  return (
    <Row
      className={`${className} ${classes.container}`}
      onClick={handleOnClick}
    >
      <Row fullWidth className={classes.subContainer}>
        <Icon width={16} height={16} name={iconName} fill={fill} />
        <Text className={classes.text} {...titleProps} preset="body" />
      </Row>
    </Row>
  )
}
