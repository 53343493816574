import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    backgroundColor: theme.colors.white,
    minHeight: 660
  },
  icon: {
    marginTop: 80
  },
  title: {
    marginTop: 24
  },
  description: {
    marginTop: 8
  }
}))
