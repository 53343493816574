import decamelize from './decamelize'

export const decamelizeObject = <T>(object: T, separator = '_'): any => {
  if (!object || typeof object !== 'object') return object

  if (Array.isArray(object)) {
    return object.map((item) => {
      return decamelizeObject(item)
    })
  }

  return Object.entries(object).reduce(
    (acc, item) => ({
      ...acc,
      [decamelize(item[0], separator)]: decamelizeObject(item[1])
    }),
    {}
  )
}

export default decamelizeObject
