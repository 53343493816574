import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'
import { CheckboxProps } from './checkbox.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  value: {
    marginLeft: 16
  },
  text: {
    marginLeft: 16,
    '&:hover': {
      color: ({ description }: CheckboxProps) =>
        !description ? theme.colors.text : theme.colors.orange,
      cursor: ({ description }: CheckboxProps) =>
        !description ? 'default' : 'help'
    }
  }
}))
