import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../theme'
import { IconProps } from './types'

export const CheckCircle: FC<IconProps> = (props) => {
  const theme: LightTheme = useTheme()
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path 
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00004 1.33325C4.33337 1.33325 1.33337 4.33325 1.33337 7.99992C1.33337 11.6666 4.33337 14.6666 8.00004 14.6666C11.6667 14.6666 14.6667 11.6666 14.6667 7.99992C14.6667 4.33325 11.6667 1.33325 8.00004 1.33325ZM10.8 6.86659L7.60004 10.0666C7.33337 10.3333 6.93337 10.3333 6.66671 10.0666L5.20004 8.59992C4.93337 8.33325 4.93337 7.93325 5.20004 7.66659C5.46671 7.39992 5.86671 7.39992 6.13337 7.66659L7.13337 8.66659L9.86671 5.93325C10.1334 5.66659 10.5334 5.66659 10.8 5.93325C11.0667 6.19992 11.0667 6.59992 10.8 6.86659Z" 
        fill={props.fill || theme.colors.button}
      />
    </svg>
  )
}
