import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    backgroundColor: theme.colors.tableRow,
    borderRadius: '12px 12px 0 0 ',
    width: 'calc(100% - 32px)',
    height: 46,
    padding: '0 16px',
    borderBottom: `1px solid ${theme.colors.grey}`,
    boxShadow: '2px 4px 6px 0px #0000000f',
    '&:hover': {
      boxShadow: '2px 4px 8px #00000029'
    }
  },
  menu: {
    marginLeft: 16,
    '& > *': {
      cursor: 'pointer'
    }
  },
  row: {
    cursor: 'pointer',
    '& > *': {
      cursor: 'pointer'
    }
  },
  program: {
    width: 90
  },
  message: {
    width: 260,
    marginLeft: 8
  },
  date: {
    width: 160,
    marginLeft: 8
  },
  column: {
    width: 95,
    marginLeft: 8
  },
  text: {
    cursor: 'pointer',
    marginTop: 16,
    maxWidth: 123,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  '@media screen and (max-width: 800px)': {
    name: {
      width: 100
    },
    text: {
      maxWidth: 90
    },
    column: {
      width: 'auto'
    }
  }
}))
