import { call, put } from 'redux-saga/effects'

import { Api, GetSettingsResults, PostSettingsResults } from '../../services'
import { SettingsCreators, SettingsTypes } from '../actions'

export function* GetSettings(
  api: Api,
  action: SettingsTypes.GetSettingsActionRequest
) {
  const response: GetSettingsResults = yield call(
    api.settings.getSettings,
    action.payload
  )

  if (response.kind !== 'ok') {
    yield put(
      SettingsCreators.getSettingsFailure({
        error: 'Failure get list of settings'
      })
    )
  } else {
    yield put(
      SettingsCreators.getSettingsSuccess({
        ...response.data
      })
    )
  }
}

export function* PostSettings(
  api: Api,
  action: SettingsTypes.PostSettingsActionRequest
) {
  const response: PostSettingsResults = yield call(
    api.settings.postSettings,
    action.payload
  )

  if (response.kind !== 'ok') {
    yield put(
      SettingsCreators.postSettingsFailure({
        error: 'Failure post of settings'
      })
    )
  } else {
    yield put(
      SettingsCreators.postSettingsSuccess(Object.values(response.data))
    )
  }
}
