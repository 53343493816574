import { SyntheticEvent } from 'react'

import { getFieldTypeByActiveField } from '../../pages'

import {
  GetImageProps,
  SetImageProps,
  UpdateFieldsProps
} from './popup-document-comparison.types'

export const getImageProperties = ({
  options,
  listProps,
  aspectRatio
}: GetImageProps) => {
  if (options.fitToPage) {
    return {
      listWidth: listProps.listWidth * aspectRatio,
      listHeight: listProps.listHeight
    }
  }

  return listProps
}

export const updateFields = ({
  openMenu,
  activeField,
  data,
  changeFieldType,
  changeOpenMenu
}: UpdateFieldsProps) => {
  const isActive = !openMenu && activeField && data
  if (!isActive) {
    return
  }
  const fieldTypeMenu = getFieldTypeByActiveField(activeField, data.fields)

  if (fieldTypeMenu) {
    changeFieldType(fieldTypeMenu)
    changeOpenMenu(true)
  }
}

export const setImageProperties =
  ({ viewerRef, changeAspectRatio, changeListProps }: SetImageProps) =>
  (event: SyntheticEvent<HTMLImageElement, Event>) => {
    const { naturalHeight: height, naturalWidth: width } = event.currentTarget
    const nextImgAspectRatio = width / height

    changeAspectRatio(nextImgAspectRatio)

    if (viewerRef.current) {
      const { offsetWidth: listWidth, offsetHeight: listHeight } =
        viewerRef.current
      changeListProps({ listHeight, listWidth })
    }
  }
