import { createReducer } from 'reduxsauce'

import { ValidationRuleActionTypes, ValidationRuleTypes } from '../../actions'

import { setItemById } from '../document'

import { defaultFilterCatalog, getFilterCatalogFromRawCatalog } from './utils'
import { ValidationRuleState } from './validation-rule.types'

/* ------------- Reducers ------------- */

const INITIAL_STATE: ValidationRuleState = {
  count: 0,
  current: 0,
  next: null,
  previous: null,
  results: [],
  programs: [],
  isLoaded: true,
  filteredCatalog: defaultFilterCatalog
}

export const getResetedState = (state = INITIAL_STATE) => ({
  ...state,
  ...INITIAL_STATE
})

/***
 * Get validation programs
 */

export const getValidationProgramsRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    programs: [],
    isLoaded: false
  }
}

export const getValidationProgramsSuccess = (
  state = INITIAL_STATE,
  action: ValidationRuleTypes.GetValidationProgramsActionSuccess
) => {
  const { results } = action.payload

  return {
    ...state,
    programs: results,
    current: results.length,
    isLoaded: true
  }
}

export const getValidationProgramsFailure = getResetedState

/***
 * Get settings
 */

export const getValidationRuleListRequest = (
  state = INITIAL_STATE,
  action: ValidationRuleTypes.GetValidationRuleListActionRequest
) => {
  const { query } = action.payload

  if (query?.offset) {
    return {
      ...state,
      isLoaded: false
    }
  }

  return {
    ...state,
    results: [],
    isLoaded: false
  }
}

export const getValidationRuleListSuccess = (
  state = INITIAL_STATE,
  action: ValidationRuleTypes.GetValidationRuleListActionSuccess
) => {
  const { results } = action.payload

  if (state.results.length) {
    const updatedResults = [...state.results, ...results]

    return {
      ...state,
      ...action.payload,
      results: updatedResults,
      current: updatedResults.length,
      filteredCatalog: getFilterCatalogFromRawCatalog(
        action.payload.filteredCatalog
      ),
      isLoaded: true
    }
  }

  return {
    ...state,
    ...action.payload,
    current: results.length,
    filteredCatalog: getFilterCatalogFromRawCatalog(
      action.payload.filteredCatalog
    ),
    isLoaded: true
  }
}

export const getValidationRuleListFailure = getResetedState

export const getValidationRuleDocumentTypesRequest = (state = INITIAL_STATE) =>
  state

export const getValidationRuleDocumentTypesSuccess = (
  state = INITIAL_STATE,
  action: ValidationRuleTypes.GetValidationRuleDocumentTypesActionSuccess
) => {
  const { results, rulePk } = action.payload

  const validationRules = state.results.map((validationRule) =>
    setItemById(validationRule, rulePk, {
      ...validationRule,
      documentTypes: results
    })
  )

  return {
    ...state,
    results: validationRules
  }
}

export const getValidationRuleDocumentTypesFailure = (state = INITIAL_STATE) =>
  state

/* ------------- Hookup Reducers To Types ------------- */

export const validationRuleReducer = createReducer(INITIAL_STATE, {
  [ValidationRuleActionTypes.GET_VALIDATION_PROGRAMS_REQUEST]:
    getValidationProgramsRequest,
  [ValidationRuleActionTypes.GET_VALIDATION_PROGRAMS_SUCCESS]:
    getValidationProgramsSuccess,
  [ValidationRuleActionTypes.GET_VALIDATION_PROGRAMS_FAILURE]:
    getValidationProgramsFailure,

  [ValidationRuleActionTypes.GET_VALIDATION_RULE_LIST_REQUEST]:
    getValidationRuleListRequest,
  [ValidationRuleActionTypes.GET_VALIDATION_RULE_LIST_SUCCESS]:
    getValidationRuleListSuccess,
  [ValidationRuleActionTypes.GET_VALIDATION_RULE_LIST_FAILURE]:
    getValidationRuleListFailure,

  [ValidationRuleActionTypes.GET_VALIDATION_RULE_DOCUMENT_TYPES_REQUEST]:
    getValidationRuleDocumentTypesRequest,
  [ValidationRuleActionTypes.GET_VALIDATION_RULE_DOCUMENT_TYPES_SUCCESS]:
    getValidationRuleDocumentTypesSuccess,
  [ValidationRuleActionTypes.GET_VALIDATION_RULE_DOCUMENT_TYPES_FAILURE]:
    getValidationRuleDocumentTypesFailure
})
