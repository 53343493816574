import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  container: {
    padding: '0 16px',
    minHeight: 660
  },
  content: {
    padding: '24px 0px'
  },
  list: {
    marginTop: 24
  }
})
