import { call, put, select } from 'redux-saga/effects'

import { getCatalogData } from '../../pages'
import {
  Api,
  GetApplicationResults,
  GetApplicationsResults,
  DeleteApplicationResults,
  GetSearchPreviewResults
} from '../../services'

import { ApplicationsCreators, ApplicationsTypes } from '../actions'
import { Catalog } from '../reducers'

import { getStatusTableData } from './helpers'
import { selectCatalog } from './selectors'

export function* GetApplications(
  api: Api,
  action: ApplicationsTypes.GetApplicationsActionRequest
) {
  const catalog: Catalog = yield select(selectCatalog)

  const response: GetApplicationsResults = yield call(
    api.applications.getApplications,
    action.payload
  )

  if (response.kind !== 'ok') {
    yield put(
      ApplicationsCreators.getApplicationsFailure({
        error: 'Failure get list of application'
      })
    )
  } else {
    const { reset, preview } = response

    const applicationsData = response.applications.results.map(
      (application) => {
        const resolutionValue = getCatalogData({
          catalog,
          id: 'resolution',
          value: application.resolution
        })

        const rpsResolutionValue = getCatalogData({
          catalog,
          id: 'rpsResolution',
          value: application.rpsResolution
        })

        const programValue = getCatalogData({
          catalog,
          id: 'program',
          value: application.program
        })

        const documents = getStatusTableData({
          data: application.documents,
          catalog
        })

        return {
          ...application,
          rpsResolutionValue,
          resolutionValue,
          programValue,
          documents
        }
      }
    )

    yield put(
      ApplicationsCreators.getApplicationsSuccess({
        ...response.applications,
        results: applicationsData,
        reset,
        preview
      })
    )
  }
}

export function* GetApplication(
  api: Api,
  action: ApplicationsTypes.GetApplicationActionRequest
) {
  const response: GetApplicationResults = yield call(
    api.applications.getApplication,
    action.payload
  )

  if (response.kind !== 'ok') {
    yield put(
      ApplicationsCreators.getApplicationFailure({
        error: 'Failure get of application'
      })
    )
  } else {
    yield put(
      ApplicationsCreators.getApplicationSuccess({
        ...response.application
      })
    )
  }
}

export function* GetSearchPreview(
  api: Api,
  action: ApplicationsTypes.GetSearchPreviewActionRequest
) {
  const response: GetSearchPreviewResults = yield call(
    api.applications.getSearchPreview,
    action.payload
  )

  if (response.kind !== 'ok') {
    yield put(
      ApplicationsCreators.getSearchPreviewFailure({
        error: 'Failure get search preview list'
      })
    )
  } else {
    const { reset } = response

    yield put(
      ApplicationsCreators.getSearchPreviewSuccess({
        ...response.applications,
        reset
      })
    )
  }
}

export function* DeleteApplication(
  api: Api,
  action: ApplicationsTypes.DeleteApplicationActionRequest
) {
  const response: DeleteApplicationResults = yield call(
    api.applications.deleteApplication,
    action.payload
  )

  if (response.kind !== 'ok') {
    yield put(
      ApplicationsCreators.deleteApplicationFailure({
        error: 'Failure: delete of application'
      })
    )
  } else {
    yield put(
      ApplicationsCreators.deleteApplicationSuccess({
        ...response.application
      })
    )
  }
}
