export const filterUniqueLogs = (objects: any[]) => {
  const uniqueItems = objects.filter((obj, index) => {
    return (
      objects.findIndex(
        (item) => item.application.name === obj.application.name
      ) === index
    )
  })

  return uniqueItems
}
