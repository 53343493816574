import React, { FC, useEffect, useRef, useState } from 'react'

import { MenuSlider, MenuSliderProps } from '../../components'
import { findById } from '../../utils'
import { FieldsTable } from '../fields-table'

import { FieldsMenuHeader } from './fields-menu-header'
import { FieldsMenuProps } from './fields-menu.types'

export const FieldsMenu: FC<FieldsMenuProps> = ({
  multiValue,
  defaultOpen,
  isEditable,
  activeField,
  className,
  data,
  icon,
  title,
  titleTx,
  onApprove,
  onReject,
  onFocusInput,
  onBlurInput
}) => {
  const contentMenuRef = useRef<HTMLDivElement>(null)
  const [open, changeOpen] = useState(defaultOpen)

  useEffect(() => {
    const hasActive = findById(data, activeField)

    if (hasActive) {
      changeOpen(true)
    }
  }, [data, activeField])

  const handleOnOpen = (state: MenuSliderProps['defaultOpen']) => {
    changeOpen(state)
  }

  const handleOnActivate = (offsetTop: number) => {
    if (contentMenuRef.current) {
      contentMenuRef.current.scrollTo({ top: offsetTop, behavior: 'smooth' })
    }
  }

  const header = (
    <FieldsMenuHeader
      count={data.length}
      icon={icon}
      title={title}
      titleTx={titleTx}
    />
  )

  return (
    <MenuSlider
      className={className}
      open={open}
      header={header}
      contentRef={contentMenuRef}
      onOpen={handleOnOpen}
    >
      <FieldsTable
        activeField={activeField}
        isEditable={isEditable}
        multiValue={multiValue}
        data={data}
        onApprove={onApprove}
        onReject={onReject}
        onFocusInput={onFocusInput}
        onBlurInput={onBlurInput}
        onActivate={handleOnActivate}
      />
    </MenuSlider>
  )
}
