import React, { Suspense, useContext, useEffect } from 'react'
import { Routes, Route, useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Column, DropdownItemsData } from '@cse/ui'

import packageJSON from '../../package.json'

import { TextTranslate, Header, Spinner, lazyComponent } from '../components'
import { AuthCreators, State } from '../store'
import { UserContext } from '../providers'
import { VersionBlock } from '../views'

import { MainRoutes } from './main.types'
import { useStyle } from './main.styles'

const ApplicationListPage = lazyComponent(
  import('../pages'),
  'ApplicationListPage'
)
const LoginPage = lazyComponent(import('../pages'), 'LoginPage')
const DocumentsListPage = lazyComponent(import('../pages'), 'DocumentsListPage')
const DocumentInfoPage = lazyComponent(import('../pages'), 'DocumentInfoPage')
const SettingsPage = lazyComponent(import('../pages'), 'SettingsPage')
const ProgramRulesPage = lazyComponent(import('../pages'), 'ProgramRulesPage')
const ProgramPage = lazyComponent(import('../pages'), 'ProgramPage')
const ActivityLogPage = lazyComponent(import('../pages'), 'ActivityLogPage')

export const MainRouter = () => {
  const { isRPS } = useContext(UserContext)
  const [searchParams] = useSearchParams()
  const dispatch = useDispatch()
  const { isAppLoaded, isAuthorizated, authToken, user } = useSelector(
    (state: State) => ({
      isAppLoaded: state.app.isLoaded,
      isAuthorizated: state.auth.isAuthorizated,
      authToken: state.auth.token,
      user: state.auth.user
    })
  )
  const classes = useStyle()
  const navigate = useNavigate()

  const token = searchParams.get('token')

  const handleOnRefreshToken = (nextToken: string) => {
    dispatch(AuthCreators.refreshUserRequest({ body: { token: nextToken } }))
  }

  useEffect(() => {
    if (token) {
      handleOnRefreshToken(token)
      navigate(MainRoutes.APPLICATION)
    } else {
      if (authToken) {
        handleOnRefreshToken(authToken)
      } else {
        dispatch(AuthCreators.refreshUserFailure({ error: 'No Token' }))
      }
    }
  }, [token])

  const handleOnLogout = () => {
    dispatch(AuthCreators.logout())
  }

  const handleOnNavigate = (to: string) => () => {
    navigate(to)
  }

  const data: DropdownItemsData<TextTranslate> = [
    {
      id: '2',
      value: 'Logout',
      name: 'Logout',
      onClick: handleOnLogout
    }
  ]

  const fallback = (
    <Column fullFilledHeight fullWidth>
      <Spinner />
    </Column>
  )

  if (!isAppLoaded) {
    return (
      <Column fullFilledHeight>
        <Spinner />
      </Column>
    )
  }

  if (!isAuthorizated) {
    return (
      <Column className={classes.container} justifyContent="space-between">
        <Column fullWidth className={classes.contentContainer}>
          <Suspense fallback={fallback}>
            <Routes>
              <Route path="*" element={<LoginPage />} />
            </Routes>
          </Suspense>
        </Column>
      </Column>
    )
  }

  return (
    <Column className={classes.container} justifyContent="space-between">
      <Header
        isLoggedIn
        backgroundColor="tableHeader"
        displayName={user?.username || ''}
        menuData={data}
        onLogoClick={handleOnNavigate(MainRoutes.HOME)}
      />
      <Column fullWidth className={classes.contentContainer}>
        <Suspense fallback={fallback}>
          <Routes>
            <Route path={MainRoutes.HOME} element={<ApplicationListPage />} />
            <Route path={MainRoutes.APPLICATION}>
              <Route index element={<ApplicationListPage />} />
            </Route>
            <Route path={MainRoutes.DOCUMENT}>
              <Route index element={<DocumentsListPage />} />
              <Route path=":id/*" element={<DocumentInfoPage />} />
            </Route>
            {!isRPS && (
              <Route path={MainRoutes.SETTINGS} element={<SettingsPage />} />
            )}
            <Route
              path={MainRoutes.ACTIVITY_LOG}
              element={<ActivityLogPage />}
            />
            <Route
              path={MainRoutes.PROGRAM_RULES}
              element={<ProgramRulesPage />}
            />
            <Route
              path={`${MainRoutes.PROGRAM_RULES}/:id`}
              element={<ProgramPage />}
            />
            <Route path="*" element={<ApplicationListPage />} />
          </Routes>
        </Suspense>
      </Column>
      <VersionBlock
        count={packageJSON.version}
        versionTitle="Version @"
        versionTitleTx="version"
      />
    </Column>
  )
}
