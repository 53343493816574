import React, { ForwardedRef, forwardRef } from 'react'
import { Text as TextComponent } from '@cse/ui'

import { TextProps } from './text.types'

export const Text = forwardRef(
  (props: TextProps, ref: ForwardedRef<HTMLSpanElement>) => {
    return <TextComponent ref={ref} tx={props.tx} {...props} />
  }
)
