import { createReducer } from 'reduxsauce'

import { SettingsActionTypes, SettingsTypes } from '../../actions'

import { SettingsState } from './settings.types'

/* ------------- Reducers ------------- */

const INITIAL_STATE: SettingsState = {
  count: 0,
  current: 0,
  next: '',
  previous: '',
  settings: null,
  isLoaded: true
}

export const getLoadingState = (state = INITIAL_STATE) => ({
  ...state,
  isLoaded: false
})

/***
 * Get settings
 */

export const getSettingsRequest = getLoadingState

export const getSettingsSuccess = (
  state = INITIAL_STATE,
  action: SettingsTypes.GetSettingsActionSuccess
) => {
  const { results, count } = action.payload

  return {
    ...state,
    settings: results,
    count,
    isLoaded: true
  }
}

export const getSettingsFailure = (state = INITIAL_STATE) => {
  return {
    ...state,
    settings: null,
    count: 0,
    current: 0,
    next: '',
    previous: '',
    isLoaded: true
  }
}

/***
 * POST settings
 */

export const postSettingsRequest = getLoadingState

export const postSettingsSuccess = (
  state = INITIAL_STATE,
  action: SettingsTypes.PostSettingsActionSuccess
) => {
  const results = action.payload

  return {
    ...state,
    settings: results,
    isLoaded: true
  }
}

export const postSettingsFailure = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoaded: true
  }
}

/* ------------- Hookup Reducers To Types ------------- */

export const settingsReducer = createReducer(INITIAL_STATE, {
  [SettingsActionTypes.GET_SETTINGS_REQUEST]: getSettingsRequest,
  [SettingsActionTypes.GET_SETTINGS_SUCCESS]: getSettingsSuccess,
  [SettingsActionTypes.GET_SETTINGS_FAILURE]: getSettingsFailure,

  [SettingsActionTypes.POST_SETTINGS_REQUEST]: postSettingsRequest,
  [SettingsActionTypes.POST_SETTINGS_SUCCESS]: postSettingsSuccess,
  [SettingsActionTypes.POST_SETTINGS_FAILURE]: postSettingsFailure
})
