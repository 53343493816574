import { createTheme, LightTheme as LightComponentsTheme } from '@cse/ui'

const overrideTheme = {
  colors: {
    blackInactive: 'rbga(0, 0, 0, 0.54)',
    blue: '#169BD5',
    arctic: '#F2FBFF',
    tableRow: '#EDF3F6'
  }
} as LightComponentsTheme

export const lightTheme = createTheme(overrideTheme)

export type Color = keyof typeof lightTheme.colors

type LightType = typeof lightTheme

export interface LightTheme extends LightType {}
