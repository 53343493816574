import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    backgroundColor: theme.colors.white,
    width: 'calc(100% - 32px)',
    padding: '0 16px',
    cursor: 'pointer',
    zIndex: 1,
    '&:last-child': {
      borderRadius: '0 0 12px 12px',
      '& > *': {
        borderBottom: '1px solid transparent'
      }
    },
    '&:hover': {
      backgroundColor: `${theme.colors.arctic}`,
      boxShadow: '2px 4px 16px rgb(3 82 127 / 12%)',
      zIndex: 3,
      '&:last-child': {
        borderRadius: '0 0 12px 12px'
      }
    }
  },
  subContainer: {
    zIndex: 4,
    padding: '16px 0px',
    width: '100%',
    borderBottom: `1px solid ${theme.colors.grey}`
  },
  menu: {
    marginLeft: 16,
    '& > *': {
      cursor: 'pointer'
    }
  },
  program: {
    width: 90
  },
  message: {
    width: 260,
    marginLeft: 8
  },
  date: {
    width: 160,
    marginLeft: 8
  },
  column: {
    width: 95,
    marginLeft: 8
  },
  text: {
    cursor: 'pointer',
    maxWidth: 100
  },
  dotsOverflow: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'break-spaces',
    '& > *': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'break-spaces'
    }
  },
  '@media screen and (max-width: 800px)': {
    text: {
      maxWidth: 90
    }
  }
}))
