import { ApisauceInstance } from 'apisauce'
import camelcaseKeys from 'camelcase-keys'
import { decamelizeObject } from '../../utils'

import { getGeneralApiProblem } from '../api-problem'

import {
  GetSettingsRequest,
  GetSettingsResults,
  GetSettingsResponse,
  PostSettingsRequest,
  PostSettingsResults,
  PostSettingsResponse
} from './settings.types'

// our "constructor"
export const settingsService = (api: ApisauceInstance) => {
  /**
   * Get settings from api service
   * @param params - parameters to identified settings
   */
  const getSettings = async ({
    params
  }: GetSettingsRequest): Promise<GetSettingsResults> => {
    const response = await api.get<GetSettingsResponse>(
      '/settings/program/list',
      params
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) {
        return problem
      }
    }
    try {
      if (response.data) {
        const data = camelcaseKeys(response.data, {
          deep: true
        })

        return { kind: 'ok', data }
      }

      return { kind: 'bad-data' }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  /**
   * Post settings to api service
   */
  const postSettings = async ({
    params
  }: PostSettingsRequest): Promise<PostSettingsResults> => {
    const body = decamelizeObject(params)

    const response = await api.post<PostSettingsResponse>(
      '/settings/program/list',
      body
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) {
        return problem
      }
    }
    try {
      if (response.data) {
        const data = camelcaseKeys(response.data, {
          deep: true
        })

        return { kind: 'ok', data }
      }

      return { kind: 'bad-data' }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  return {
    getSettings,
    postSettings
  }
}
