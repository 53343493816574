import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

import { PopupDocumentComparisonStyleProps } from './popup-document-comparison.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  top: {
    padding: '12px 0',
    '& > *:not(:first-child)': {
      position: 'fixed',
      right: 16
    }
  },
  main: {
    width: 'calc(100% - 20px)',
    height: 'calc(100% - 84px)',
    padding: '16px 24px',
    background: theme.colors.background
  },
  sideContainer: {
    padding: '0 8px 16px',
    height: '100%',
    width: 'calc(50% - 16px)'
  },
  container: {
    position: 'fixed',
    background: theme.colors.white,
    inset: '0px 0px 0px 0px',
    zIndex: 10
  },
  header: {
    marginTop: 16,
    borderRadius: '12px 12px 0 0',
    borderBottom: `1px solid ${theme.colors.secondButton}`
  },
  rightPanelMenu: {
    overflowY: 'scroll',
    position: 'absolute',
    right: '100%',
    top: 0,
    bottom: 0,
    padding: '24px 16px',
    width: 484,
    borderLeft: `1px solid ${theme.colors.secondButton}`,
    backgroundColor: theme.colors.white
  },
  viewer: {
    width: '100%',
    height: '100%'
  },
  menuContainer: {
    borderLeft: `1px solid ${theme.colors.secondButton}`,
    padding: '16px 0'
  },
  menuItem: {
    padding: 16,
    '& > path': {
      fill: theme.colors.text
    },
    '&:hover': {
      backgroundColor: theme.colors.footerBackground
    }
  },
  title: {
    textTransform: 'uppercase'
  },
  clear: {
    cursor: 'pointer'
  },
  fieldsMenu: {
    justifyContent: 'flex-start',
    height: '100%',
    width: '100%',
    '&:not(:first-child)': {
      marginTop: 24
    }
  },
  fieldsView: {
    marginTop: 24,
    height: '40%',
    alignItems: 'flex-start'
  },
  docContainer: {
    overflowX: 'hidden',
    height: '40%'
  },
  spinner: {
    position: 'absolute',
    right: 0,
    height: 504,
    width: '50%'
  },
  validatedItem: ({ fieldType }: PopupDocumentComparisonStyleProps) => ({
    backgroundColor: fieldType === 'validated' && theme.colors.footerBackground,
    '& > path': {
      fill: theme.colors[fieldType === 'validated' ? 'green' : 'text']
    },
    '&:hover > path': {
      fill: theme.colors.green
    }
  }),
  verificationItem: ({ fieldType }: PopupDocumentComparisonStyleProps) => ({
    backgroundColor:
      fieldType === 'verification' && theme.colors.footerBackground,
    '& > path': {
      fill: theme.colors[fieldType === 'verification' ? 'red' : 'text']
    },
    '&:hover > path': {
      fill: theme.colors.red
    }
  })
}))
