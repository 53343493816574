import React, { FC } from 'react'

import {
  Column,
  Row,
  Text,
  StatusBadgeTypeName,
  OptionLine,
  StatusBadge,
  getResolutionTypeName
} from '../../../../components'

import { formatDateBySlash } from '../../../helpers'

import { DocsListItemProps } from './docs-list-item.types'
import { useStyle } from './docs-list-item.styles'

export const DocsListItem: FC<DocsListItemProps> = ({
  className = '',
  fieldsVerified,
  createdAt,
  dateModified,
  validationRate,
  resolution,
  version,
  documentType,
  onClick
}) => {
  const classes = useStyle()
  const statusMod: StatusBadgeTypeName = getResolutionTypeName(resolution)
  const { [statusMod]: modifier } = classes
  const appDate = formatDateBySlash(createdAt)
  const runDate = formatDateBySlash(dateModified)

  return (
    <Row
      className={`${className} ${classes.container} ${modifier}`}
      justifyContent="space-between"
    >
      <Row
        className={classes.subContainer}
        fullWidth
        justifyContent="space-between"
        onClick={onClick}
      >
        <Column
          className={classes.docType}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Text
            className={classes.text}
            text={String(documentType)}
            preset="caption"
          />
        </Column>
        <Column
          className={classes.column}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <OptionLine
            className={classes.text}
            preset="caption"
            value={validationRate}
          />
        </Column>
        <Column
          className={classes.column}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <OptionLine
            className={classes.text}
            preset="caption"
            value={fieldsVerified}
          />
        </Column>
        <Column
          className={classes.resolution}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <StatusBadge value={resolution} />
        </Column>
        <Column
          className={classes.column}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <OptionLine
            className={classes.text}
            preset="caption"
            value={appDate}
          />
        </Column>
        <Column
          className={classes.column}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <OptionLine
            className={classes.text}
            preset="caption"
            value={runDate}
          />
        </Column>
        <Column
          className={classes.version}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Text
            className={classes.text}
            text={String(version)}
            preset="caption"
          />
        </Column>
      </Row>
    </Row>
  )
}
