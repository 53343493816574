import {
  PopoverHintPosition,
  UpdatePopoverPositionProps
} from './popover-hint.types'

export const updatePopoverPosition = ({
  hintRef,
  containerRef,
  hintPosition,
  buttonWidth,
  changePosition
}: UpdatePopoverPositionProps) => {
  if (containerRef.current && hintRef.current) {
    const isTopLeft = hintPosition === PopoverHintPosition.TOP_LEFT
    const isTopCenter = hintPosition === PopoverHintPosition.TOP_CENTER
    const isBottom = hintPosition === PopoverHintPosition.BOTTOM
    const isTop = hintPosition === PopoverHintPosition.TOP

    const { height: hintHeight, width: hintWidth } =
      hintRef.current.getBoundingClientRect()
    const domRect = containerRef.current.getBoundingClientRect()

    const diffWidthElement = (domRect.width - hintWidth) / 2
    const width = domRect.x + diffWidthElement

    if (isTop) {
      const pos = domRect.y - hintHeight - 20
      const arrowY = hintHeight + 20
      const top = -28

      changePosition({
        x: width,
        y: pos,
        arrowY,
        arrowX: 0,
        rotate: 315,
        top
      })
    }

    if (isBottom) {
      const iqrHeight = domRect.height
      const pos = domRect.y + iqrHeight + 20
      const arrowY = iqrHeight
      const top = -8

      changePosition({
        x: width,
        y: pos,
        arrowY,
        arrowX: 0,
        rotate: 135,
        top
      })
    }

    if (isTopCenter) {
      const pos = domRect.y - hintHeight - 30
      const arrowY = hintHeight + 20
      const top = -28

      changePosition({
        x: width - buttonWidth / 2,
        y: pos,
        arrowY,
        rotate: 315,
        arrowX: 0,
        top
      })
    }

    if (isTopLeft) {
      const pos = domRect.y - hintHeight - 20
      const arrowY = hintHeight + 20
      const top = -28

      changePosition({
        x: width - buttonWidth + 10,
        y: pos,
        arrowY,
        arrowX: -18,
        rotate: 315,
        top
      })
    }
  }
}
