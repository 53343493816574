import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../../theme'

import { SVGIconProps } from './icons.types'

export const ZoomIn: FC<SVGIconProps> = (props) => {
  const theme: LightTheme = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.755 14.255h-.79l-.28-.27a6.471 6.471 0 0 0 1.57-4.23 6.5 6.5 0 1 0-6.5 6.5c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99 1.49-1.49-4.99-5Zm-6 0c-2.49 0-4.5-2.01-4.5-4.5s2.01-4.5 4.5-4.5 4.5 2.01 4.5 4.5-2.01 4.5-4.5 4.5Zm-.5-5v-2h1v2h2v1h-2v2h-1v-2h-2v-1h2Z"
        fill={props.fill || theme.colors.darkBlue}
      />
    </svg>
  )
}
