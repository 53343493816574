import { ReactNode, RefObject, SetStateAction, Dispatch } from 'react'

export type PositionElement = {
  x: number
  y: number
  arrowX?: number
  arrowY?: number
  rotate?: number
  top?: number
}

export enum PopoverHintPosition {
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
  TOP_CENTER = 'TOP_CENTER',
  TOP_LEFT = 'TOP_LEFT'
}

export type PopoverHintStyleProps = {
  open?: boolean
  position: PositionElement
  hintPosition?: keyof typeof PopoverHintPosition
  popoverArrow?: PositionElement
  isTopCenter?: boolean
}

export interface PopoverHintProps
  extends Omit<PopoverHintStyleProps, 'position'> {
  className?: string
  children?: ReactNode
  buttonWidth?: number
}

export interface UpdatePopoverPositionProps {
  hintRef: RefObject<HTMLDivElement>
  containerRef: RefObject<HTMLDivElement>
  hintPosition: keyof typeof PopoverHintPosition
  buttonWidth: number
  changePosition: Dispatch<SetStateAction<PositionElement>>
}
