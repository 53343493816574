import { TextProps } from '../../components'

import { ErrorType } from './settings.types'

export const getError = (
  id: string,
  error?: ErrorType
): TextProps | undefined => {
  if (error && error.id === id) {
    return error.textProps
  }

  return undefined
}
