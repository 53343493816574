import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../../theme'
import { AppListItemStyleProps } from './apps-list-item.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    backgroundColor: theme.colors.white,
    opacity: ({ disabled }: AppListItemStyleProps) => (disabled ? 0.6 : 1),
    width: 'calc(100% - 32px)',
    padding: '0 16px',
    cursor: 'pointer',
    zIndex: 1,
    '&:hover': {
      backgroundColor: `${theme.colors.arctic}`,
      boxShadow: '2px 4px 16px rgb(3 82 127 / 12%)'
    }
  },
  link: {
    width: '100%',
    '&:last-child': {
      borderRadius: '0 0 12px 12px',
      borderBottom: '1px solid transparent'
    },
    '&:hover': {
      zIndex: 2,
      '&:last-child': {
        borderRadius: '0 0 12px 12px'
      }
    }
  },
  subContainer: {
    zIndex: 4,
    padding: '16px 0px',
    width: '100%',
    borderBottom: `1px solid ${theme.colors.grey}`
  },
  menu: {
    marginLeft: 16,
    '& > *': {
      cursor: 'pointer'
    }
  },
  name: {
    minWidth: 80
  },
  appId: {
    minWidth: 100
  },
  iqrRes: {
    minWidth: 96
  },
  iqr: {
    minWidth: 150
  },
  applDate: {
    minWidth: 80
  },
  runDate: {
    minWidth: 100
  },
  reviewedBy: {
    minWidth: 120
  },
  reviewedDate: {
    minWidth: 100
  },
  resolution: {
    minWidth: 116
  },
  indent: {
    marginLeft: 8
  },
  text: {
    cursor: 'pointer',
    maxWidth: 100
  },
  dotsOverflow: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    '& > *': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap'
    }
  },
  '@media screen and (max-width: 800px)': {
    name: {
      width: 100
    },
    text: {
      maxWidth: 90
    }
  },
  '@media screen and (max-width: 1400px)': {
    name: {
      minWidth: 75,
      maxWidth: 75
    },
    appId: {
      minWidth: 84,
      maxWidth: 84
    },
    applDate: {
      minWidth: 70,
      maxWidth: 70
    },
    iqrRes: {
      minWidth: 85,
      maxWidth: 85
    },
    iqr: {
      minWidth: 134,
      maxWidth: 134
    },
    runDate: {
      minWidth: 81,
      maxWidth: 81
    },
    reviewedBy: {
      minWidth: 100,
      maxWidth: 100
    },
    reviewedDate: {
      minWidth: 89,
      maxWidth: 89
    },
    resolution: {
      minWidth: 104,
      maxWidth: 104
    }
  }
}))
