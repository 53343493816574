import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useState
} from 'react'
import { useSelector } from 'react-redux'

import { State } from '../../store'

import {
  UserContextValue,
  UserProviderProps,
  UserRoleStateNames
} from './user.types'

const userRolesState: UserContextValue = {
  isRPS: false,
  isCoordinator: false,
  isManager: false,
  isSysAdmin: false,
  isSuperUser: false
}

export const UserContext = createContext<UserContextValue>(userRolesState)

export const UserProvider: FC<UserProviderProps> = ({ children }) => {
  const [contextState, changeContextState] = useState(userRolesState)
  const { user } = useSelector((state: State) => ({
    ...state.auth
  }))

  useEffect(() => {
    if (user) {
      const { customRole } = user
      const roleState = UserRoleStateNames[customRole]
      changeContextState({ ...userRolesState, [roleState]: true })
    }
  }, [user])

  return (
    <UserContext.Provider value={contextState}>{children}</UserContext.Provider>
  )
}

export const useUser = () => useContext(UserContext)
