import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../theme'

import { StatusItemStyleProps } from './status-item.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    cursor: 'pointer'
  },
  badge: {
    backgroundColor: ({ color }: StatusItemStyleProps) => theme.colors[color],
    height: 4,
    width: 25,
    marginTop: 8
  },
  label: {
    cursor: 'pointer'
  }
}))
