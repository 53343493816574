import { Resolution } from '../../services'

import { BadgeProps } from '../badge'
import { TextProps } from '../text'

export enum StatusBadgeTypeName {
  VERIFICATION = 'verification',
  PASS = 'pass',
  DECLINE = 'decline',
  IGNORE = 'ignore',
  INFORMATION = 'information'
}

export interface StatusBadgePresetName {
  verification: string
  pass: string
  decline: string
  ignore: string
  information: string
}

export interface StatusBadgeProps extends Pick<BadgeProps, 'className'> {
  value: Resolution
}

export type ResolutionKeys = Record<Resolution, Pick<TextProps, 'text' | 'tx'>>

export const RESOLUTION: ResolutionKeys = {
  [Resolution.PASS]: {
    text: 'pass',
    tx: 'resolution.pass'
  },
  [Resolution.VERIFICATION]: {
    text: 'verification',
    tx: 'resolution.verification'
  },
  [Resolution.IGNORE]: {
    text: 'ignore',
    tx: 'resolution.ignore'
  },
  [Resolution.DECLINE]: {
    text: 'decline',
    tx: 'resolution.decline'
  },
  [Resolution.FYI]: {
    text: 'FYI',
    tx: 'resolution.fyi'
  }
}
