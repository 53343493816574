import { DropdownItemData } from '@cse/ui'

import { TextTranslate } from '../../components'
import { RPSResolution } from '../../services'

import { ResolutionModalProps } from '../resolution-modal'

export enum ResolutionModalTypes {
  DOCUMENT = 'document',
  APPLICATION = 'application'
}

export type RpsResolutionModalValue = {
  documentId: number
  resolution: RPSResolution
  rejectionReason?: string | number | null
}

export type RpsResolutionData = {
  resolution: RPSResolution
  rejectionReason: DropdownItemData<TextTranslate>[]
}

export interface RpsResolutionModalProps
  extends Pick<ResolutionModalProps, 'titleTx' | 'disabled' | 'onClose'> {
  defaultValue?: RpsResolutionModalValue
  data?: RpsResolutionData
  open?: boolean
  title?: string
  description?: string
  descriptionTx?: TextTranslate
  modal?: ResolutionModalTypes
  onSubmit?: (value: RpsResolutionModalValue) => void
}

export type RejectionReasonFunc = (resolution: RPSResolution) => void

export interface GetIsDisabledProps {
  disabled?: boolean
  isSubmitDisabled: boolean
}

export interface GetDefaultDataProps {
  outerDefaultValue?: RpsResolutionModalValue
}

export interface GetResolutionDataProps {
  resolutionData: RpsResolutionModalValue
  resolution: RPSResolution
  outerDefaultValue?: RpsResolutionModalValue
}
