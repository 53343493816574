import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../../theme'
import { SVGIconProps } from './icons.types'

export const History: FC<SVGIconProps> = (props) => {
  const theme: LightTheme = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.62666 1.33398C5.91759 1.33886 4.27566 1.99993 3.04 3.18065V2.00065C3.04 1.82384 2.96976 1.65427 2.84474 1.52925C2.71971 1.40422 2.55014 1.33398 2.37333 1.33398C2.19652 1.33398 2.02695 1.40422 1.90193 1.52925C1.7769 1.65427 1.70667 1.82384 1.70667 2.00065V5.00065C1.70667 5.17746 1.7769 5.34703 1.90193 5.47206C2.02695 5.59708 2.19652 5.66732 2.37333 5.66732H5.37333C5.55014 5.66732 5.71971 5.59708 5.84474 5.47206C5.96976 5.34703 6.04 5.17746 6.04 5.00065C6.04 4.82384 5.96976 4.65427 5.84474 4.52925C5.71971 4.40422 5.55014 4.33398 5.37333 4.33398H3.77333C4.38114 3.69199 5.139 3.21116 5.97875 2.93474C6.8185 2.65833 7.71379 2.595 8.58409 2.75045C9.45439 2.90591 10.2724 3.27527 10.9645 3.82531C11.6566 4.37535 12.2012 5.08882 12.5491 5.90154C12.8971 6.71425 13.0376 7.60072 12.9579 8.4812C12.8783 9.36167 12.581 10.2085 12.0928 10.9456C11.6047 11.6827 10.9409 12.2869 10.1613 12.7038C9.38174 13.1207 8.51073 13.3372 7.62666 13.334C7.44985 13.334 7.28029 13.4042 7.15526 13.5292C7.03024 13.6543 6.96 13.8238 6.96 14.0007C6.96 14.1775 7.03024 14.347 7.15526 14.4721C7.28029 14.5971 7.44985 14.6673 7.62666 14.6673C9.39477 14.6673 11.0905 13.9649 12.3407 12.7147C13.591 11.4645 14.2933 9.76876 14.2933 8.00065C14.2933 6.23254 13.591 4.53685 12.3407 3.28661C11.0905 2.03636 9.39477 1.33398 7.62666 1.33398ZM7.62666 5.33398C7.44985 5.33398 7.28029 5.40422 7.15526 5.52925C7.03024 5.65427 6.96 5.82384 6.96 6.00065V8.00065C6.96 8.17746 7.03024 8.34703 7.15526 8.47206C7.28029 8.59708 7.44985 8.66732 7.62666 8.66732H8.96C9.13681 8.66732 9.30638 8.59708 9.4314 8.47206C9.55643 8.34703 9.62666 8.17746 9.62666 8.00065C9.62666 7.82384 9.55643 7.65427 9.4314 7.52925C9.30638 7.40422 9.13681 7.33398 8.96 7.33398H8.29333V6.00065C8.29333 5.82384 8.22309 5.65427 8.09807 5.52925C7.97305 5.40422 7.80348 5.33398 7.62666 5.33398Z"
        fill={props.fill || theme.colors.border}
      />
    </svg>
  )
}
