import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../theme'
import { IconProps } from './types'

export const FilterDramaSmall: FC<IconProps> = (props) => {
  const theme: LightTheme = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 2.6665C10.4267 2.6665 12.4467 4.39317 12.9 6.69317C14.6333 6.81317 16 8.23984 16 9.99984C16 11.8398 14.5067 13.3332 12.6667 13.3332H4C1.79333 13.3332 0 11.5398 0 9.33317C0 7.2665 1.56667 5.57317 3.57333 5.35984C4.40667 3.75984 6.07333 2.6665 8 2.6665ZM4 11.9998H12.6667C13.7667 11.9998 14.6667 11.0998 14.6667 9.99984C14.6667 8.89984 13.7667 7.99984 12.6667 7.99984H11.6667V7.6665C11.6667 5.6465 10.02 3.99984 8 3.99984C6.8 3.99984 5.74 4.5865 5.06667 5.47984C6.76 5.9465 8 7.49317 8 9.33317H6.66667C6.66667 7.85984 5.47333 6.6665 4 6.6665C2.52667 6.6665 1.33333 7.85984 1.33333 9.33317C1.33333 10.8065 2.52667 11.9998 4 11.9998Z"
        fill={props.fill || theme.colors.button}
      />
    </svg>
  )
}
