import React, { FC, useEffect, useMemo, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import {
  Column,
  HeaderContainer,
  Spinner,
  HeaderContainerContentProps
} from '../../components'
import { DocumentCreators, State } from '../../store'
import { RpsResolutionModalValue } from '../../views'
import { DOCUMENT_TITLE } from '../../config'

import { DocHeader } from './doc-header'
import { getDocId, getIsDocHeaderLoads, getRejectionReason } from './helpers'
import { TabsContent } from './tabs-content'
import { HeaderContent } from './header-content'

import { DocumentInfoProps } from './document-info.types'
import { useStyle } from './document-info.styles'

export const SEARCH_PARAM_KEY = 'document_id'

export const DocumentInfoPage: FC<DocumentInfoProps> = () => {
  const [open, changeOpen] = useState(false)
  const classes = useStyle({ open })

  const { id = '' } = useParams()

  const [isVersionChanged, changeIsVersionChanged] = useState(false)
  const customVersion = isVersionChanged ? 0 : Number(id)
  const [version, changeVersion] = useState(customVersion)
  const dispatch = useDispatch()

  const { catalog, documentHeader, documents, documentHeaderLoading } =
    useSelector((state: State) => ({
      ...state.applications,
      ...state.app,
      ...state.document
    }))
  const [searchParams] = useSearchParams()

  const DocId = useMemo(
    () => getDocId({ searchParams }),
    [searchParams.get(SEARCH_PARAM_KEY)]
  )
  const CurrentDocument = useMemo(() => documents[DocId], [documents, DocId])

  const handleOnOpen = () => {
    changeOpen(!open)
  }

  useEffect(() => {
    if (documentHeader) {
      document.title = documentHeader.title
    }

    return () => {
      document.title = DOCUMENT_TITLE
    }
  }, [documentHeader])

  useEffect(() => {
    dispatch(
      DocumentCreators.getDocumentHeaderRequest({ params: { appId: id } })
    )

    return () => {
      dispatch(DocumentCreators.clearDocuments())
      dispatch(DocumentCreators.clearDocumentsToCompare())
    }
  }, [id])

  useEffect(() => {
    changeVersion(Number(id))
  }, [id])

  const handleOnSubmitAppResolution = (data: RpsResolutionModalValue) => {
    const rejectionReason = getRejectionReason(data)

    dispatch(
      DocumentCreators.updateResolutionAppRequest({
        params: { appId: id },
        body: {
          rpsResolution: data.resolution,
          rejectionReason
        }
      })
    )
  }

  const handleOnVersionChanged = (versionId?: number) => {
    if (versionId) {
      changeVersion(versionId)
      changeIsVersionChanged(true)
    } else {
      changeIsVersionChanged(false)
    }
  }

  useEffect(() => {
    return () => {
      changeVersion(0)
    }
  }, [])

  const CONTENT_DATA: HeaderContainerContentProps[] = useMemo(
    () => [
      {
        id: 1,
        open: true,
        className: classes.noPadding,
        data: (
          <DocHeader
            data={documentHeader}
            catalog={catalog}
            isVersionChanged={isVersionChanged}
            defaultOpen={open}
            onOpen={handleOnOpen}
            onSubmit={handleOnSubmitAppResolution}
            onVersionChange={handleOnVersionChanged}
          />
        )
      },
      {
        id: 2,
        open,
        className: classes.detailedHeader,
        data: (
          <HeaderContent documentHeader={documentHeader} catalog={catalog} />
        )
      }
    ],
    [documentHeader, catalog, open]
  )

  const isHeaderLoads = getIsDocHeaderLoads({
    documentHeader,
    documentHeaderLoading
  })

  if (isHeaderLoads)
    return (
      <Column fullFilledHeight key={`document_info_${id}`}>
        <Spinner />
      </Column>
    )

  return (
    <Column
      fullWidth
      key={`document_info_${id}`}
      className={classes.container}
      justifyContent="flex-start"
    >
      <HeaderContainer data={CONTENT_DATA} />
      <Column
        fullWidth
        className={classes.loadingContainer}
        justifyContent="flex-start"
      >
        <TabsContent
          docId={DocId}
          isVersionChanged={isVersionChanged}
          versionId={version}
          document={CurrentDocument}
        />
      </Column>
    </Column>
  )
}
