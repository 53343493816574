import React from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../theme'
import { Column } from '../column'
import { Row } from '../row'
import { Content } from '../content'
import { Breadcrumbs } from '../breadcrumbs'
import { concatClassNames } from '../helpers'

import {
  HeaderContainerContentProps,
  HeaderContainerProps
} from './header-container.types'
import { useStyle } from './header-container.styles'

export const HeaderContainer = ({
  data,
  backProps,
  children
}: HeaderContainerProps) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })

  return (
    <Column className={classes.container} fullWidth>
      {backProps && (
        <Content className={classes.noPadding}>
          <Row
            className={classes.breadcrumbs}
            fullWidth
            justifyContent="space-between"
          >
            <Breadcrumbs {...backProps} />
          </Row>
        </Content>
      )}
      {data.map(
        (content: HeaderContainerContentProps) =>
          content.open && (
            <Column
              key={`header-${content.id}`}
              className={concatClassNames(content.className, classes.box)}
              fullWidth
            >
              {Boolean(content.id) && (
                <Column className={classes.titleSeparator} fullHeight />
              )}
              <Content>{content.data}</Content>
            </Column>
          )
      )}
      {children}
    </Column>
  )
}
