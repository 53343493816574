import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../theme'

import { StatusHintItemStyleProps } from './status-hint-item.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  title: {
    width: 44
  },
  icon: {
    marginLeft: ({ title }: StatusHintItemStyleProps) => (title ? 12 : 0),
    height: 16,
    width: 16
  },
  status: {
    marginLeft: 8,
    width: 80
  },
  itemWarn: {
    background: theme.colors.lightOrange
  },
  warning: {
    marginLeft: 12
  }
}))
