import React, { FC } from 'react'

import { FieldInfo } from '../../services'
import { Column, Row, Text } from '../../components'
import { StatusBadge } from '../status-badge'

import { FieldCommentInfoProps } from './field-comments-info.types'
import { useStyle } from './field-comments-info.styles'

export const FieldCommentsList: FC<FieldCommentInfoProps> = ({ fieldInfo }) => {
  const classes = useStyle()

  if (!fieldInfo) {
    return <></>
  }

  return (
    <Row className={classes.content} justifyContent="flex-start" fullWidth>
      <Column
        className={classes.info}
        justifyContent="flex-start"
        fullWidth
        fullHeight
      >
        {fieldInfo.map((item: FieldInfo) => (
          <Column
            key={`info-field-${item.textractPk}`}
            className={classes.item}
            alignItems="flex-start"
          >
            <Text text={item.title} preset="bodyBold" />
            <Text text={item.description} preset="caption" />
            <Row fullWidth justifyContent="flex-start">
              <StatusBadge value={item.resolution} />
              <Text
                className={classes.comment}
                text={item.comment}
                preset="title"
                color="inactive"
              />
            </Row>
          </Column>
        ))}
      </Column>
    </Row>
  )
}
