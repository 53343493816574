import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useState,
  useRef
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useOutsideAlerter } from '@cse/ui'

import { Input } from '../input'
import { Column } from '../column'

import { ColumnKeys } from '../../pages'
import { ApplicationsCreators, State } from '../../store'
import { Application } from '../../services'
import { useDebounceInput } from '../../hooks'
import {
  changeDataBySeach,
  getContainerHeight,
  useGetData,
  getHeight,
  resetHeight,
  setItemClick,
  setOnKeyDown
} from './helpers'
import { ItemsList } from './items-list'

import { SearchProps } from './search.types'
import { useStyle } from './search.styles'

export const Search = ({
  className,
  minHeight = 40,
  maxHeight = 200
}: SearchProps) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { searchList } = useSelector((state: State) => ({
    ...state.applications
  }))
  const searchRef = useRef<HTMLDivElement>(null)
  const [data, changeData] = useState<Application[]>([])
  const [searchById, changeSearchById] = useState<string>('')

  const listRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)
  const itemsContainer = listRef.current
  const containerHeight = getContainerHeight(itemsContainer, minHeight)
  const [height, changeHeight] = useState(containerHeight)

  const { open, searchIcon } = useGetData({ data, searchById })
  const classes = useStyle({ open, height })

  const changeValue = useCallback((value: string) => {
    changeSearchById(value)
  }, [])

  const handleOnLoadApps = useCallback((search: string) => {
    const ordering = `-${ColumnKeys.IQR_RUN_DATE},-${ColumnKeys.RPS_REWIEW_DATE}`

    dispatch(
      ApplicationsCreators.getSearchPreviewRequest({
        query: {
          search,
          preview: true,
          ordering
        }
      })
    )
  }, [])

  const handleOnSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value
      changeValue(value)
    },
    [changeValue]
  )

  const handleOnSearchClick = useCallback(() => {
    changeSearchById('')
    changeData([])
  }, [])

  const handleOnItemClick = useCallback(
    setItemClick({ navigate, onIconClick: handleOnSearchClick }),
    []
  )

  const handleOnKeyDown = setOnKeyDown({
    searchList,
    navigate,
    reset: handleOnSearchClick
  })

  const getOffsetHeight = useCallback(
    () =>
      getHeight({
        contentRef,
        maxHeight,
        minHeight,
        changeHeight
      }),
    [contentRef]
  )

  useEffect(() => {
    resetHeight({ open, getOffsetHeight })
  }, [listRef, data, open])

  useEffect(() => {
    changeDataBySeach({
      searchById,
      searchList,
      changeData
    })
  }, [searchById, searchList])

  useDebounceInput({ value: searchById, onChange: handleOnLoadApps })
  useOutsideAlerter(searchRef, Boolean(searchById), handleOnSearchClick)

  return (
    <Column ref={searchRef} className={className}>
      <Input
        classNameInput={classes.searchInput}
        placeholder="Search by App ID"
        placeholderTx="header.searchByAppId"
        defaultValue={searchById}
        value={searchById}
        icon={searchIcon}
        iconFill="black"
        onKeyDown={handleOnKeyDown}
        onChange={handleOnSearch}
        onIconClick={handleOnSearchClick}
      />
      <ItemsList
        listRef={listRef}
        contentRef={contentRef}
        open={open}
        height={height}
        data={data}
        onItemClick={handleOnItemClick}
      />
    </Column>
  )
}
