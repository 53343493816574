import { createUseStyles } from 'react-jss'

import { LightTheme } from '../theme'

import { DropdownFloatLabelStyleProps } from './dropdown-float-label.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    cursor: ({ disabled }: DropdownFloatLabelStyleProps<any>) =>
      disabled ? 'default' : theme.cursor,
    height: ({ custom }: DropdownFloatLabelStyleProps<any>) =>
      !custom ? 48 : custom.container,
    borderBottom: ({ open, isUndo }: DropdownFloatLabelStyleProps<any>) =>
      open || isUndo
        ? `1px solid ${theme.colors.blue}`
        : `1px solid ${theme.colors.grey}`,
    padding: '8px 0px'
  },
  itemsContainer: {
    maxHeight: 230,
    position: 'absolute',
    overflowX: 'hidden',
    boxShadow: '2px 4px 8px #00000014',
    borderTop: 'none',
    border: `2px solid ${theme.colors.background}`,
    borderRadius: 12,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0
  },
  innerItemsContainer: {
    position: 'relative',
    border: 'none',
    boxShadow: 'none',
    borderRadius: 'none',
    width: '100%',
    overflowY: 'auto',
    top: 0
  },
  label: {
    transformOrigin: '0 0',
    transform: ({ isPreSetted }: DropdownFloatLabelStyleProps<any>) =>
      isPreSetted ? 'scale(0.9) translateY(-4px)' : 'scale(1) translateY(10px)',
    transition: 'transform 0.25s ease-in-out',
    color: ({ open, isUndo }: DropdownFloatLabelStyleProps<any>) =>
      open || isUndo ? theme.colors.blue : theme.colors.darkBlue,
    '&:hover': {
      color: ({ description }: DropdownFloatLabelStyleProps<any>) =>
        !description ? theme.colors.darkBlue : theme.colors.orange,
      cursor: ({ description }: DropdownFloatLabelStyleProps<any>) =>
        !description ? 'default' : 'help'
    }
  },
  disabled: {
    color: theme.colors.inactive
  },
  disabledLabel: {
    color: `${theme.colors.border}!important`
  },
  undoIcon: {
    position: 'absolute',
    right: -40,
    bottom: 10,
    cursor: theme.cursor,
    '& > *:not(:first-child)': {
      marginTop: 6
    }
  },
  error: {
    borderBottom: `1px solid ${theme.colors.red}!important`
  },
  textIcon: {
    position: 'absolute',
    color: theme.colors.border,
    padding: 0,
    right: 40,
    bottom: 10,
    cursor: theme.cursor,
    '&:hover > *': {
      visibility: 'visible'
    }
  },
  value: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflowX: 'hidden'
  },
  caret: {
    marginTop: ({ isPreSetted }: DropdownFloatLabelStyleProps<any>) =>
      isPreSetted ? '18px' : '20px'
  }
}))
