import { createUseStyles } from 'react-jss'

import { LightTheme } from '@cse/ui'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  item: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 24,
    '& > *': {
      color: theme.colors.text
    }
  },
  text: {
    marginLeft: 8,
    textTransform: 'capitalize'
  }
}))
