import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  description: {
    textAlign: 'center'
  },
  text: {
    whiteSpace: 'pre-line'
  },
  extra: {
    marginTop: 16
  }
})
