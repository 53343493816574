import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    padding: '14px 16px',
    width: 'calc(100% - 32px)',
    backgroundColor: theme.colors.white,
    color: theme.colors.text,
    cursor: theme.cursor,
    '& > *': {
      cursor: theme.cursor
    },
    '&:hover': {
      backgroundColor: theme.colors.tableRow
    }
  },
  disabled: {
    cursor: 'default',
    backgroundColor: theme.colors.white,
    '& > *': {
      cursor: 'default',
      color: theme.colors.grey
    },
    '&:hover': {
      backgroundColor: theme.colors.white,
      '& > *': {
        color: `${theme.colors.grey}!important`
      }
    }
  }
}))
