import React, { FC } from 'react'
import { useTheme } from 'react-jss'
import { LightTheme } from '../theme'
import { IconProps } from './types'

export const Undo: FC<IconProps> = (props) => {
  const theme: LightTheme = useTheme()
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M8.177 5.667c-1.767 0-3.367.66-4.6 1.733L1.177 5v6h6L4.763 8.587a5.302 5.302 0 013.414-1.254c2.36 0 4.366 1.54 5.066 3.667l1.58-.52c-.926-2.793-3.546-4.813-6.646-4.813z"
        fill={props.fill || theme.colors.darkBlue}
      />
    </svg>
  )
}
