import React, {
  ChangeEvent,
  useEffect,
  useRef,
  useState,
  FocusEvent
} from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../theme'
import { Column } from '../column'
import { Icon } from '../icon'
import { Text } from '../text'
import { Row } from '../row'

import { FieldValueType, InputFloatLabelProps } from './input-float-label.types'
import { useStyle } from './input-float-label.styles'

export const InputFloatLabel = ({
  alignItems,
  justifyContent,
  className = '',
  placeholder = '',
  isSuccess,
  labelProps,
  error,
  isFocused = false,
  children,
  iconName,
  iconProps,
  errorProps,
  textIcon,
  defaultValue,
  description = '',
  isRegular = false,
  onIconClick,
  onChange,
  onBlur,
  ...props
}: InputFloatLabelProps) => {
  const isRegularInput = !description ? true : isRegular
  const theme: LightTheme = useTheme()
  const classes = useStyle({
    alignItems,
    justifyContent,
    isSuccess,
    isFocused,
    isRegular: isRegularInput,
    theme
  })
  const ref = useRef<HTMLInputElement>(null)
  const [isFocusSetted, changeFocusSetted] = useState(isFocused)
  const labelPreset = isFocusSetted ? 'caption' : 'body'
  const [value, changeValue] = useState(defaultValue)
  const updatedFocusStyle = isFocusSetted ? classes.labelUp : classes.labelDown
  const errorStyle = errorProps ? classes.error : ''
  const [focusStyle, changeFocusStyle] = useState('')

  const checkIsEmpty = (fieldValue: FieldValueType) =>
    fieldValue === undefined || !String(fieldValue).length

  const onFocus = (event?: FocusEvent<HTMLInputElement>) => {
    if (event) {
      changeFocusStyle(classes.focused)
    }
    changeFocusSetted(true)
  }

  const handleOnBlur = (event?: FocusEvent<HTMLInputElement>) => {
    const isValueEmpty = checkIsEmpty(value)
    const isDefValueEmpty = checkIsEmpty(defaultValue)
    changeFocusStyle('')

    if (event && onBlur) {
      onBlur(event)
    }

    if (isValueEmpty || isDefValueEmpty) {
      changeFocusSetted(false)
    }
  }

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e)
    }

    const alignedValue: string | number = e.target.value
    changeValue(alignedValue)
  }

  useEffect(() => {
    const isExist = !checkIsEmpty(defaultValue)
    if (isExist) {
      onFocus()
    } else {
      handleOnBlur()
    }

    changeValue(defaultValue)
  }, [defaultValue])

  useEffect(() => {
    if (isFocused) {
      onFocus()
    } else {
      handleOnBlur()
    }
  }, [isFocused])

  useEffect(() => {
    if (errorProps && ref.current) {
      ref.current.scrollIntoView({
        block: 'center',
        behavior: 'smooth'
      })
    }
  }, [errorProps])

  const isFieldIconVisible = iconName && value

  return (
    <Column className={`${className}`}>
      <Row
        className={`${errorStyle} ${focusStyle} ${classes.container}`}
        justifyContent="flex-start"
        fullWidth
      >
        <input
          {...props}
          ref={ref}
          className={`${classes.input} ${classes.fullWidth}`}
          placeholder={placeholder}
          value={value}
          autoComplete="off"
          onBlur={handleOnBlur}
          onFocus={onFocus}
          onChange={handleOnChange}
        />

        {labelProps && (
          <Text
            className={`${classes.floatingLabel} ${updatedFocusStyle}`}
            preset={labelPreset}
            {...labelProps}
          />
        )}

        {errorProps && (
          <Text
            preset="subTitleMini"
            className={classes.errorContainer}
            {...errorProps}
          />
        )}

        {isFieldIconVisible && (
          <Row className={classes.iconName} onClick={onIconClick}>
            {iconName && <Icon name={iconName} fill="darkBlue" />}
          </Row>
        )}

        {iconProps && (
          <Row className={classes.textIcon}>
            <Text preset="bodyBold" color="border" {...iconProps} />
          </Row>
        )}

        {children}
      </Row>
    </Column>
  )
}
