import { GeneralApiProblem } from '../api-problem'
import { List, ListQuery } from '../api.types'

export enum LogsPathes {
  ACTIVITIES = '/logs/activities',
  ERRORS = '/logs/errors'
}

export enum SortTypes {
  ASC = 'asc',
  DESC = 'desc'
}

export type ApplicationLog = {
  exists: boolean
  id: string | null
  name: string
}

export type ActivityLogDetail = {
  id: string
  program: string
  application: ApplicationLog
  message: string
  userName: string
  createdAt: Date
  logType: string
}

export type ActivityLogList = ActivityLogDetail

export interface ErrorLogDetail {
  id: string
  errorType: string
}

export interface ErrorLog extends Omit<ErrorLogDetail, 'user' | 'errorType'> {}

export type ActivityLog = ActivityLogList[]
export type ErrorLogList = ErrorLog[]

export interface GetActivityLogListRequestQuery extends ListQuery {
  ordering?: string
  program?: string
  search?: string
  userName?: string
  preview?: boolean
}

export type GetActivityLogListRequest = {
  query?: GetActivityLogListRequestQuery
}

export type GetSearchActivityPreviewRequest = {
  query?: GetActivityLogListRequestQuery
}

export interface GetActivityLogByIdRequestParams {
  id: string
}

export type GetActivityLogByIdRequest = {
  params: GetActivityLogByIdRequestParams
}

export interface GetErrorLogListRequestQuery
  extends Omit<GetActivityLogListRequestQuery, 'user'> {}

export type GetErrorLogListRequest = {
  query?: GetErrorLogListRequestQuery
}

export interface GetErrorLogByIdRequestParams {
  id: ErrorLog['id']
}

export type GetErrorLogByIdRequest = {
  params: GetErrorLogByIdRequestParams
}

export type GetActivityLogListResponse = List<ActivityLogList>
export type GetSearchActivityPreviewResponse = List<ActivityLogList>

export interface GetActivityLogByIdResponse extends ActivityLogDetail {}

export interface GetErrorLogListResponse extends List<ErrorLog> {}

export interface GetErrorLogByIdResponse extends ErrorLogDetail {}

export type GetActivityLogListResults =
  | {
      kind?: string
      data: GetActivityLogListResponse
      reset?: boolean
    }
  | GeneralApiProblem

export type GetActivityLogByIdResults =
  | {
      kind?: string
      data: GetActivityLogByIdResponse
    }
  | GeneralApiProblem

export type GetErrorLogListResults =
  | {
      kind?: string
      data: GetErrorLogListResponse
    }
  | GeneralApiProblem

export type GetErrorLogByIdResults =
  | {
      kind?: string
      data: GetErrorLogByIdResponse
    }
  | GeneralApiProblem

export type GetSearchActivityPreviewResults =
  | {
      kind?: string
      data: GetActivityLogListResponse
      reset?: boolean
      preview?: boolean
    }
  | GeneralApiProblem

export type LogsApi = {
  activityList: (
    options: GetActivityLogListRequest
  ) => Promise<GetActivityLogListResults>
  activityById: (
    options: GetActivityLogByIdRequest
  ) => Promise<GetActivityLogByIdResults>
  errorList: (
    options: GetErrorLogListRequest
  ) => Promise<GetErrorLogListResults>
  errorById: (
    options: GetErrorLogByIdRequest
  ) => Promise<GetErrorLogByIdResults>
  getSearchActivityPreview: (
    options: GetSearchActivityPreviewRequest
  ) => Promise<GetSearchActivityPreviewResults>
}
