import React from 'react'
import { Button } from '@cse/ui'

import { Row, Spinner } from '../../../components'

import { SubmitButtonProps } from './submit-button.types'
import { useStyle } from './submit-button.styles'

export const SubmitButton = ({
  isLoaded,
  disabled = false,
  onClick
}: SubmitButtonProps) => {
  const classes = useStyle()

  return (
    <Row fullWidth className={classes.saveSettings}>
      {isLoaded ? (
        <Button
          text="Save Settings"
          tx="settings.saveSettings"
          disabled={disabled}
          onClick={onClick}
        />
      ) : (
        <Spinner className={classes.spinner} />
      )}
    </Row>
  )
}
