import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Row, Content, Column, Spinner } from '../../components'
import { ApplicationsCreators, State } from '../../store'
import { Application, Document } from '../../services'
import { ListView } from './list-view'
import { DocsHeader } from './docs-header'
import { getCurrentPart } from './helpers'

import { FilterValue, ParamsProps } from './documents-list.types'
import { useStyle } from './documents-list.styles'
import { MainRoutes } from '../../routes'

const LIMIT = 10

export const DocumentsListPage = () => {
  const navigate = useNavigate()
  const classes = useStyle()
  const dispatch = useDispatch()
  const count = LIMIT
  const current = LIMIT
  const { id = '' } = useParams<ParamsProps>()
  const { application, catalog } = useSelector((state: State) => ({
    ...state.applications,
    ...state.app
  }))
  const [data, changeData] = useState<Application | null>(application)
  const [orderBy] = useState<string>(FilterValue.PROGRAM)
  const [part, changePart] = useState(0)

  useEffect(() => {
    const currentPart = getCurrentPart({
      count,
      current,
      limit: LIMIT
    })
    changePart(currentPart)
  }, [current])

  useEffect(() => {
    dispatch(ApplicationsCreators.getApplicationRequest({ params: { id } }))
  }, [current, orderBy])

  useEffect(() => {
    changeData(application)
  }, [application])

  const handleOnClickItem = (doc: Document) => {
    if (data) {
      const query = data.documents[0] ? `?document_id=${doc.id}` : ''

      navigate(`${MainRoutes.DOCUMENT}/${data.id}${query}`)
    }
  }

  if (!data) {
    return (
      <Column
        fullWidth
        className={classes.container}
        justifyContent="flex-start"
      >
        <Spinner />
      </Column>
    )
  }

  return (
    <Column fullWidth className={classes.container} justifyContent="flex-start">
      <DocsHeader data={data} catalog={catalog} />
      <Content className={classes.content}>
        <Row className={classes.innerContent} fullWidth alignItems="flex-start">
          {data ? (
            <ListView
              data={data}
              count={count}
              current={current}
              part={part}
              orderBy={orderBy}
              onClickItem={handleOnClickItem}
            />
          ) : (
            <Spinner />
          )}
        </Row>
      </Content>
    </Column>
  )
}
