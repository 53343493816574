export enum MainRoutes {
  HOME = '/',
  APPLICATION = '/application',
  ACTIVITY_LOG = '/activity-log',
  SETTINGS = '/settings',
  PROGRAM_RULES = '/program-rules',
  ABOUT = '/about',
  PRIVACY = '/privacy',
  DOCUMENT = '/document'
}
