import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../../theme'
import { SVGIconProps } from './icons.types'

export const Remove: FC<SVGIconProps> = (props) => {
  const theme: LightTheme = useTheme()
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6667 7.3335H3.33335C3.15654 7.3335 2.98697 7.40373 2.86195 7.52876C2.73693 7.65378 2.66669 7.82335 2.66669 8.00016C2.66669 8.17697 2.73693 8.34654 2.86195 8.47157C2.98697 8.59659 3.15654 8.66683 3.33335 8.66683H12.6667C12.8435 8.66683 13.0131 8.59659 13.1381 8.47157C13.2631 8.34654 13.3334 8.17697 13.3334 8.00016C13.3334 7.82335 13.2631 7.65378 13.1381 7.52876C13.0131 7.40373 12.8435 7.3335 12.6667 7.3335Z"
        fill={props.fill || theme.colors.disabledRadioText}
      />
    </svg>
  )
}
