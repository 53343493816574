import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

import { RowStyles } from './row.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  row: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'row',
    alignItems: (props: RowStyles) => props.alignItems || 'center',
    justifyContent: (props: RowStyles) => props.justifyContent || 'center',
    height: (props: RowStyles) => (props.fullHeight ? '100%' : 'auto'),
    width: (props: RowStyles) => (props.fullWidth ? '100%' : 'auto'),
    scrollbarFaceColor: theme.colors.grey /* Firefox 63 compatibility */,
    scrollbarTrackColor: 'transparent',
    scrollbarColor: `${theme.colors.grey} transparent`,
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      width: 4,
      height: 4
    },
    /* Track */
    '&::-webkit-scrollbar-track': {
      background: 'transparent'
    },
    /* Handle */
    '&::-webkit-scrollbar-thumb': {
      background: theme.colors.grey,
      borderRadius: 14
    },
    /* Handle on hover */
    '&::-webkit-scrollbar-thumb:hover': {
      background: theme.colors.hoverGrey
    }
  }
}))
