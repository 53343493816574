import React from 'react'

import { Row } from '../../../components'
import { ValidationProgram } from '../../../services'
import { RuleItem } from './rule-item'

import { RulesListProps } from './rules-list.types'
import { useStyle } from './rules-list.styles'

export const RulesList = ({ programs, onClick }: RulesListProps) => {
  const classes = useStyle()

  const handleOnClick = (id: number) => () => {
    if (onClick) {
      onClick(id)
    }
  }

  if (!programs) {
    return null
  }

  return (
    <Row fullWidth className={classes.container} justifyContent="flex-start">
      {programs.map((item: ValidationProgram) => (
        <RuleItem
          key={`program-rule-${item.title}`}
          {...item}
          onClick={handleOnClick(item.id)}
        />
      ))}
    </Row>
  )
}
