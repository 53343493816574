import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../theme'
import { formatDateBySlash } from '../../pages'

import { Icon } from '../icon'
import { Row } from '../row'
import { Text } from '../text'
import {
  concatClassNames,
  getColorByStatusRpsResolution,
  getResolutionIconOutline,
  getRpsResolutionTypeName
} from '../helpers'

import { ResolutionStatusBadgeProps } from './resolution-status-badge.types'
import { useStyle } from './resolution-status-badge.styles'

export const ResolutionStatusBadge: FC<ResolutionStatusBadgeProps> = ({
  className,
  date,
  rpsResolution
}) => {
  const formatDate = formatDateBySlash(date)
  const title = getRpsResolutionTypeName(rpsResolution)
  const color = getColorByStatusRpsResolution(rpsResolution)
  const icon = getResolutionIconOutline(rpsResolution)

  const theme: LightTheme = useTheme()
  const classes = useStyle({ color })

  const iconFill = theme.colors[color]
  const dotFill = theme.colors.darkBlue

  return (
    <Row className={concatClassNames(className, classes.container)}>
      {icon && (
        <>
          <Icon height={16} width={16} name={icon} fill={iconFill} />
          {title && (
            <Text
              className={classes.iconTitle}
              preset="subTitle"
              text={title}
            />
          )}
        </>
      )}
      <Row className={classes.field}>
        {icon && <Icon width={8} height={8} name="DOT" fill={dotFill} />}
        <Text
          className={classes.field}
          color="disabledRadioText"
          preset="caption"
          text={formatDate}
        />
      </Row>
    </Row>
  )
}
