import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../theme'
import { Text } from '../text'

import { ButtonProps } from './button.types'
import { useStyle } from './button.styles'

export const Button: FC<ButtonProps> = ({
  preset = 'accent',
  children,
  disabled,
  isSmall = false,
  className = '',
  color,
  text,
  tx,
  ...props
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })
  const { [preset]: modifier } = classes

  const smallBtnClass = isSmall ? classes.small : ''
  const content = text ? <Text color={color} text={text} tx={tx} /> : children

  return (
    <button
      className={`${className} ${classes.button} ${modifier} ${smallBtnClass}`}
      disabled={disabled}
      {...props}
    >
      {content}
    </button>
  )
}
