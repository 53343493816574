import { createUseStyles } from 'react-jss'

import { RowProps } from './row.types'

export const useStyle = createUseStyles({
  row: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'row',
    alignItems: (props: RowProps) => props.alignItems || 'center',
    justifyContent: (props: RowProps) => props.justifyContent || 'center',
    alignContent: (props: RowProps) => props.alignContent || 'center',
    height: (props: RowProps) => (props.fullHeight ? '100%' : 'auto'),
    width: (props: RowProps) => (props.fullWidth ? '100%' : 'auto')
  }
})
