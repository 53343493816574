import React, { FC } from 'react'

import { concatClassNames } from '../helpers'
import { Row } from '../row'
import { Text } from '../text'

import { useStyle } from './counter.styles'
import { CounterProps } from './counter.types'

export const Counter: FC<CounterProps> = ({ className, count }) => {
  const classes = useStyle()
  const countString = String(count)

  return (
    <Row className={concatClassNames(className, classes.container)}>
      <Text color="inactive" preset="subTitle" text={countString} />
    </Row>
  )
}
