import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../../theme'

import { SVGIconProps } from './icons.types'

export const Replay: FC<SVGIconProps> = (props) => {
  const theme: LightTheme = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      {...props}
    >
      <path
        d="M18 12h4l-5 5-5-5h4c0-3.31-2.69-6-6-6s-6 2.69-6 6 2.69 6 6 6v2c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8Z"
        fill={props.fill || theme.colors.darkBlue}
      />
    </svg>
  )
}
