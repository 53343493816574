import React, { createContext, FC, useContext, useState } from 'react'
import { IntlProvider } from 'react-intl'

import {
  Locale,
  LocaleContextValue,
  LocaleData,
  LocaleProviderProps
} from './locale.types'

const localeData: LocaleData = {
  // Default language
  [Locale.EN]: {}
}

export const localeNames: Record<Locale, string> = {
  [Locale.EN]: 'English'
}

const defaultLocale = Locale.EN

export const LocaleContext = createContext<LocaleContextValue>({
  localeData,
  currentLocale: Locale.EN
})

export const LocaleProvider: FC<LocaleProviderProps> = ({
  localeData: localeDataOverride,
  locale: localeOverride,
  children
}) => {
  const locale = localeOverride || Locale.EN
  const defaultLocales = localeData || localeDataOverride
  const [currentLocale] = useState(locale)
  const [locales] = useState(defaultLocales)

  const contextState = { localeData: locales, currentLocale }
  return (
    <LocaleContext.Provider value={contextState}>
      <IntlProvider
        defaultLocale={defaultLocale}
        locale={currentLocale}
        messages={defaultLocales[currentLocale]}
        key={locale}
      >
        {children}
      </IntlProvider>
    </LocaleContext.Provider>
  )
}

export const useLocale = () => useContext(LocaleContext)
