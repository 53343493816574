import React, { FC, useContext, useState, useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import {
  Row,
  arrayHasElements,
  Text,
  IconButton,
  RadioButton,
  Column
} from '../../../components'
import { UserContext } from '../../../providers'
import { CoreItemElement, DocumentCreators, State } from '../../../store'
import { CompareVersionModal, DocResolutionPicker } from '../../../views'
import { DocumentOrdering } from '../../../services'
import { useSettings } from './hooks'
import { Fields } from './fields'

import { DocumentValidationHeaderProps } from './document-validation-header.types'
import { useStyle } from './document-validation-header.styles'

export const DocumentValidationHeader: FC<DocumentValidationHeaderProps> = ({
  className = '',
  isAllowed = false,
  hasOtherVersions = true,
  docNumber,
  docType,
  date,
  fields,
  versions,
  defaultValue,
  setVersionNames,
  onSubmit,
  onChangeComparisonPopup
}) => {
  const dispatch = useDispatch()
  const { isSysAdmin } = useContext(UserContext)
  const hasElements = arrayHasElements(fields)
  const [openModal, changeOpenModal] = useState(false)

  const classes = useStyle({ hasElements })
  const { id = '' } = useParams()
  const [versionToCompare, changeVersionToCompare] = useState(0)
  const [docToCompare, changeDocToCompare] = useState(0)
  const {
    currentVersion,
    compareVersion,
    filteredVersions,
    textValues,
    isFewVersions
  } = useSettings({ id, versionToCompare, versions, docType, hasOtherVersions })
  const { documentHeader2 } = useSelector((state: State) => ({
    ...state.document
  }))

  const handleOnOpenModal = useCallback(
    (state: boolean) => () => {
      changeOpenModal(state)
    },
    [openModal]
  )

  const handleOnSubmit = () => {
    handleOnOpenModal(false)()

    const params = { docId: docToCompare }
    const query = { ordering: DocumentOrdering.ASC_NUMBER }
    dispatch(DocumentCreators.getDocumentToCompareRequest({ params, query }))

    if (onChangeComparisonPopup) {
      onChangeComparisonPopup()
    }
    if (setVersionNames) {
      setVersionNames(currentVersion, compareVersion)
    }
  }

  const handleOnRadioChange = (value: string) => () => {
    changeVersionToCompare(Number(value))

    dispatch(
      DocumentCreators.getDocumentHeaderToCompareRequest({
        params: { appId: String(value) }
      })
    )
  }

  useEffect(() => {
    if (versionToCompare) {
      dispatch(
        DocumentCreators.getDocumentHeaderToCompareRequest({
          params: { appId: String(versionToCompare) }
        })
      )
    }
  }, [versionToCompare])

  useEffect(() => {
    const isVersions = filteredVersions && filteredVersions?.length
    if (isVersions) {
      changeVersionToCompare(Number(filteredVersions[0].id))
    }
  }, [])

  useEffect(() => {
    if (documentHeader2) {
      const doc = documentHeader2.documents.find(
        (item: any) => item.document === docNumber
      )
      const docId = doc ? doc.id : 0

      changeDocToCompare(docId)
    }
  }, [documentHeader2, docNumber])

  return (
    <Row
      className={`${className} ${classes.container}`}
      justifyContent="space-between"
    >
      {!hasElements ? (
        <Text
          text="No additional information"
          tx="document.noInfo"
          preset="caption"
          color="border"
        />
      ) : (
        <Fields list={fields} />
      )}
      <Row>
        {!isSysAdmin && (
          <DocResolutionPicker
            isChangeAllowed={isAllowed}
            date={date}
            defaultValue={defaultValue}
            onSubmit={onSubmit}
          />
        )}
        {isFewVersions && (
          <IconButton
            disabled={!isAllowed}
            className={classes.iconButton}
            name="COMPARE"
            preset="warning"
            isMessage={!isAllowed}
            message="This button is disabled because the current version is view-only."
            messageTx="rpsResolution.hint"
            onClick={handleOnOpenModal(true)}
          />
        )}
      </Row>
      <CompareVersionModal
        open={openModal}
        title="Compare documents (${docType})"
        titleTx="compare.modal.title"
        values={textValues}
        description="Select the version of the document with which you want to compare the current one"
        descriptionTx="compare.modal.description"
        submitText="Compare"
        submitTx="btn.compare"
        onSubmit={handleOnSubmit}
        onClose={handleOnOpenModal(false)}
      >
        <Row
          className={classes.versionName}
          fullWidth
          justifyContent="flex-start"
        >
          <Text text={currentVersion} preset="h2" color="blue" />
        </Row>
        <Column className={classes.list} fullWidth>
          <Column className={classes.listContainer} fullWidth>
            {filteredVersions?.map((item: CoreItemElement) => (
              <Row
                key={`version_to_compare_${item.id}`}
                fullWidth
                justifyContent="space-around"
              >
                <Row className={classes.name}>{item.name}</Row>
                <RadioButton
                  iconColor="darkBlue"
                  defaultChecked={Number(item.id) === versionToCompare}
                  onChange={handleOnRadioChange(item.id)}
                />
              </Row>
            ))}
          </Column>
        </Column>
      </CompareVersionModal>
    </Row>
  )
}
