import React, { FC } from 'react'

import { concatClassNames } from '../helpers'
import { Row } from '../row'
import { Text } from '../text'

import { useStyle } from './badge.styles'
import { BadgeProps } from './badge.types'

export const Badge: FC<BadgeProps> = ({
  children,
  className,
  color,
  text,
  textColor = 'inactive',
  textPreset = 'subTitleSetting',
  tx
}) => {
  const classes = useStyle({ color })

  return (
    <Row className={concatClassNames(className, classes.container)}>
      {children ||
        (text && (
          <Text
            className={classes.text}
            color={textColor}
            preset={textPreset}
            text={text}
            tx={tx}
          />
        ))}
    </Row>
  )
}
