import { useMemo } from 'react'

import { getUserPrograms } from '../pages'
import { User } from '../services'
import { CoreItem } from '../store'

export type UseUserProgramsProps = {
  program: CoreItem[]
  user: User | null
}

export const useUserPrograms = ({ user, program }: UseUserProgramsProps) => {
  const UserPrograms = useMemo(
    () => getUserPrograms(user, program),
    [user, program]
  )

  return UserPrograms
}
