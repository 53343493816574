import React from 'react'

import { Column } from '../column'
import { Text } from '../text'

import { useStyle } from './nav-text.styles'
import { NavTextProps } from './nav-text.types'

export const NavText = <TranslationKeysT extends string>({
  className = '',
  active,
  preset,
  ...props
}: NavTextProps<TranslationKeysT>) => {
  const classes = useStyle()
  const textColor = active ? 'blue' : 'darkBlue'
  const textPreset = active ? 'bodyBold' : 'body'

  return (
    <Column className={`${className} ${classes.container}`}>
      <Text color={textColor} preset={preset || textPreset} {...props} />
      {active && <div className={classes.bottomDivider} />}
    </Column>
  )
}
