import { TextProps } from '../../components'

export const approveLabelProps: TextProps = {
  text: 'Approve',
  tx: 'document.approve'
}
export const rejectLabelProps: TextProps = {
  text: 'Ineligible',
  tx: 'resolution.ineligible'
}
export const inprogressLabelProps: TextProps = {
  text: 'In Progress',
  tx: 'resolution.inProgress'
}
export const incompleteLabelProps: TextProps = {
  text: 'Incomplete',
  tx: 'resolution.incomplete'
}
