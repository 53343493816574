import { createActions, createTypes } from 'reduxsauce'

import { AuthActions, AuthActionsTypes } from './auth.types'

export const loginStringTypes = createTypes<AuthActionsTypes>(`
  OKTA_LOGIN_REQUEST
  OKTA_LOGIN_SUCCESS
  OKTA_LOGIN_FAILURE

  REFRESH_USER_REQUEST
  REFRESH_USER_SUCCESS
  REFRESH_USER_FAILURE

  GET_USER_ME_REQUEST
  GET_USER_ME_SUCCESS
  GET_USER_ME_FAILURE

  DROP_REFRESH_TOKEN

  LOGOUT
`)

const { Types: AuthActionTypes, Creators: AuthCreators } = createActions<
  AuthActionsTypes,
  AuthActions
>({
  oktaLoginRequest: ['payload'],
  oktaLoginSuccess: ['payload'],
  oktaLoginFailure: ['payload'],

  refreshUserRequest: ['payload'],
  refreshUserSuccess: ['payload'],
  refreshUserFailure: ['payload'],

  getUserMeRequest: ['payload'],
  getUserMeSuccess: ['payload'],
  getUserMeFailure: ['payload'],

  dropRefreshToken: ['payload'],

  logout: ['payload']
})

export { AuthCreators, AuthActionTypes }
