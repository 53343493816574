import { createUseStyles } from 'react-jss'

import { ButtonStyleProps } from './button.types'

export const useStyle = createUseStyles({
  container: {
    width: ({ fixed }: ButtonStyleProps) => (fixed ? 200 : 'auto')
  },
  message: {
    whiteSpace: 'break-spaces'
  },
  messageHint: {
    visibility: ({ hover }: ButtonStyleProps) => (hover ? 'visible' : 'hidden')
  },
  messageContainer: {
    padding: '16px !important',
    maxWidth: 164
  }
})
