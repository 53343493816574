import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

import { PopoverHintStyleProps } from './popover-hint.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  hintPosition: {
    position: 'absolute',
    inset: '0px 0px 0px 0px',
    height: '100%'
  },
  container: {
    visibility: ({ open, position: { x, y } }: PopoverHintStyleProps) =>
      open && x && y ? 'visible' : 'hidden',
    transition: 'transform(0, -50%) translateX(-10%)',
    zIndex: 100,
    position: 'fixed',
    top: ({ position }: PopoverHintStyleProps) => position.y,
    left: ({ position }: PopoverHintStyleProps) => position.x
  },
  arrow: {
    position: 'absolute',
    top: ({ position }: PopoverHintStyleProps) => position.top,
    background: theme.colors.white,
    borderBottom: `1px solid ${theme.colors.footerBackground}`,
    borderLeft: `1px solid ${theme.colors.footerBackground}`,
    padding: 7,
    transform: ({
      position: { arrowY, arrowX, rotate }
    }: PopoverHintStyleProps) =>
      `translateX(${arrowX}px) translateY(${arrowY}px) rotate(${
        rotate || 315
      }deg)`,
    transformOrigin: 'center',
    zIndex: 1
  },
  content: {
    background: theme.colors.white,
    boxShadow: theme.boxShadow,
    border: `1px solid ${theme.colors.footerBackground}`,
    borderRadius: 12,
    padding: '16px 0'
  }
}))
