import React from 'react'
import { DropdownItemData } from '@cse/ui'

import { Column, Content, Row, Text } from '../../../components'
import { LogsHeaderFilter, LogsHeaderFilters } from '../logs-header-filter'

import { LogsHeaderProps } from './logs-header.types'
import { useStyle } from './logs-header.styles'

export const LogsHeader = ({
  values,
  onChangeFilter,
  onClear,
  onSearchClose
}: LogsHeaderProps) => {
  const classes = useStyle()

  const handleOnChange = (
    prop: LogsHeaderFilters,
    item: DropdownItemData<any>
  ) => {
    if (onChangeFilter) {
      onChangeFilter(prop, item)
    }
  }

  return (
    <Column fullWidth className={classes.container}>
      <Content>
        <Row fullWidth justifyContent="space-between">
          <Text
            className={classes.text}
            text="Activity Log"
            tx="header.activityLog"
            preset="h1"
          />
          <LogsHeaderFilter
            className={classes.filter}
            defaultValues={values}
            onChange={handleOnChange}
            onClear={onClear}
            onSearchClose={onSearchClose}
          />
        </Row>
      </Content>
    </Column>
  )
}
