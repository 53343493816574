import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  container: {
    width: 'calc(100% - 32px)',
    height: 46
  },
  menu: {
    marginLeft: 16,
    '& > *': {
      cursor: 'pointer'
    }
  },
  name: {
    width: 300
  },
  resolution: {
    width: 160,
    marginLeft: 8
  },
  version: {
    width: 50,
    marginLeft: 8
  },
  column: {
    width: 100,
    marginLeft: 8
  },
  text: {
    cursor: 'pointer',
    marginTop: 16,
    maxWidth: 123,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  '@media screen and (max-width: 800px)': {
    name: {
      width: 100
    },
    text: {
      maxWidth: 90
    },
    column: {
      width: 'auto'
    }
  }
})
