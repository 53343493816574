import React from 'react'
import { useSelector } from 'react-redux'

import { TabsNavItem } from '../../../components'
import { DocumentOrdering, PageVerification } from '../../../services'
import { DocumentCreators, State } from '../../../store'
import { RpsResolutionModalValue } from '../../../views'
import { getUpdatedHeaderDocuments } from '../helpers'
import { SEARCH_PARAM_KEY } from '../document-info'
import { Content } from './content'

import {
  GetCurrentDataProps,
  GetDataProps,
  GetDefValueProps,
  GetDocIdProps,
  GetFullFieldsProps,
  GetResolutionDataProps
} from '../document-info.types'
import {
  TabClickProps,
  TabsNavDataProps,
  UpdateResolutionProps
} from './tabs-content.types'

export const getDefValue = ({ doc }: GetDefValueProps) => {
  if (doc) {
    return {
      documentId: doc.id,
      resolution: doc.rpsResolution,
      rejectionReason: doc.rejectionReason
    }
  }
  return {
    documentId: 0,
    resolution: 0,
    rejectionReason: null
  }
}

export const getCurrentData = ({
  document,
  page
}: GetCurrentDataProps): PageVerification | undefined => {
  if (!document) {
    return
  }
  return document.pages.find((docPage) => docPage.number === page)
}

export const getFullFields = ({ document }: GetFullFieldsProps) => {
  if (!document) {
    return []
  }
  return document.fields
}

export const getResolutionData = ({ doc }: GetResolutionDataProps) => {
  if (!doc) {
    return null
  }

  return doc.rpsResolutionDt
}

export const getData = ({
  documentHeader,
  docId,
  document,
  documentLoading,
  page,
  comparisonDocument,
  comparisonPage
}: GetDataProps) => {
  const currentAppDoc = documentHeader?.documents.find(
    (doc) => doc.id === docId
  )
  const currentData = getCurrentData({
    document,
    page
  })
  const currentComparisonData = getCurrentData({
    document: comparisonDocument,
    page: comparisonPage
  })

  const defaultValue: RpsResolutionModalValue = getDefValue({
    doc: currentAppDoc
  })
  const resolutionData = getResolutionData({ doc: currentAppDoc })

  const isContentAvailable = !documentLoading && currentData

  return {
    isContentAvailable,
    resolutionData,
    defaultValue,
    currentData,
    currentComparisonData
  }
}

export const getRejectionReason = (data: RpsResolutionModalValue) =>
  data.rejectionReason || null

export const getDocId = ({ searchParams }: GetDocIdProps) => {
  const docId = searchParams.get(SEARCH_PARAM_KEY)
  if (!docId) {
    return 0
  }

  return Number(docId)
}

export const useGetTabsNavData = ({
  docId,
  page,
  document,
  comparisonPage,
  onChangePage,
  onChangeComparisonPage
}: TabsNavDataProps): TabsNavItem[] => {
  const { catalog, documentHeader } = useSelector((state: State) => ({
    ...state.applications,
    ...state.app,
    ...state.document
  }))

  if (!documentHeader) {
    return []
  }

  return getUpdatedHeaderDocuments({
    documentHeader,
    catalog,
    content: (
      <Content
        docId={docId}
        page={page}
        document={document}
        comparisonPage={comparisonPage}
        onChangePage={onChangePage}
        onChangeComparisonPage={onChangeComparisonPage}
      />
    )
  })
}

export const updateResolution = ({
  document,
  page,
  docId,
  fieldId,
  rpsResolution,
  dispatch
}: UpdateResolutionProps) => {
  const currentData = document.pages.find(
    (docPage: PageVerification) => docPage.number === page
  )

  const isDoc = currentData && docId
  if (isDoc) {
    dispatch(
      DocumentCreators.updateResolutionFieldRequest({
        params: { id: String(fieldId) },
        body: {
          rpsResolution
        }
      })
    )
  }
}

export const updateResolutionField = ({
  document,
  page,
  docId,
  fieldId,
  rpsResolution,
  dispatch
}: UpdateResolutionProps) => {
  if (!document) {
    return
  }

  updateResolution({
    document,
    page,
    docId,
    fieldId,
    rpsResolution,
    dispatch
  })
}

export const setTabClick = ({
  docId,
  documentHeader,
  documents,
  changePage,
  dispatch
}: TabClickProps) => {
  const isDoc = !docId || !documentHeader
  if (isDoc) {
    return
  }

  const currentDocId: PageVerification['id'] = Number(docId)
  const params = { docId: currentDocId }
  const query = { ordering: DocumentOrdering.ASC_NUMBER }

  changePage(1)

  if (!documents[Number(docId)]) {
    dispatch(DocumentCreators.getDocumentRequest({ params, query }))
  }
}
