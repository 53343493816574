import React, { FC } from 'react'

import {
  Badge,
  Column,
  concatClassNames,
  Icon,
  Row,
  Text
} from '../../../components'

import { useData } from '../helpers'
import { ColumnIconValue } from '../column-icon-value'

import { ProgramHeaderProps } from './program-header.types'
import { useStyle } from './program-header.styles'

export const ProgramHeader: FC<ProgramHeaderProps> = ({
  open,
  catalog,
  field,
  title,
  type,
  value,
  manualValue,
  resolution,
  updatedAt = new Date(27, 5, 2022)
}) => {
  const classes = useStyle({ open })

  const { DateValue, Name, Value, Type, Resolution } = useData({
    catalog,
    field,
    type,
    value,
    manualValue,
    resolution,
    updatedAt
  })

  return (
    <Column fullWidth alignItems="flex-start" justifyContent="flex-start">
      <Row
        fullWidth
        className={classes.headerTop}
        justifyContent="space-between"
      >
        <Row>
          <Icon className={classes.arrow} name="ARROW_DROP_DOWN" />
          <Badge
            className={concatClassNames(classes.badge, classes.field)}
            color="white"
            text={Name}
          />
          <Text
            className={classes.field}
            color="darkBlue"
            preset="h3"
            text={title}
          />
        </Row>
        <Row>
          <Text color="border" preset="caption" text={DateValue} />
          <Icon className={classes.icon} name="History" />
        </Row>
      </Row>
      <Row className={classes.headerBottom} justifyContent="space-between">
        <ColumnIconValue
          className={classes.type}
          colorIcon="button"
          iconName="LooksOne"
          title="Validation Logic:"
          titleTx="program.card.validationLogic"
          value={Type}
        />
        <ColumnIconValue
          className={classes.value}
          colorIcon="blue"
          iconName="LooksTwo"
          title="Validation Value:"
          titleTx="program.card.validationValue"
          value={Value}
        />
        <ColumnIconValue
          className={classes.resolution}
          colorIcon="purple"
          iconName="LooksThree"
          title="Action on fail:"
          titleTx="program.card.actionFail"
          value={Resolution}
        />
      </Row>
    </Column>
  )
}
