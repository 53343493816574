import React, { useState } from 'react'

import { Column, Content, Row, Text } from '../../../components'
import { AppsHeaderFilter } from '../apps-header-filter'

import { AppHeaderFilterValue, AppsHeaderProps } from './apps-header.types'
import { useStyle } from './apps-header.styles'

export const AppsHeader = ({
  defaultValues,
  onChangeFilter,
  onClear
}: AppsHeaderProps) => {
  const [filter, changeFilter] = useState<AppHeaderFilterValue>({})
  const classes = useStyle()

  const handleOnFilter = (value: AppHeaderFilterValue) => {
    const nextValue: AppHeaderFilterValue = {
      ...filter,
      ...value
    }

    changeFilter(nextValue)

    if (onChangeFilter) {
      onChangeFilter(nextValue)
    }
  }

  return (
    <Column fullWidth className={classes.container}>
      <Content>
        <Row fullWidth justifyContent="space-between">
          <Text
            className={classes.text}
            text="Application List"
            tx="application.title"
            preset="h1"
          />
          <AppsHeaderFilter
            className={classes.filter}
            value={filter}
            defaultValues={defaultValues}
            onChange={handleOnFilter}
            onClear={onClear}
          />
          <Row />
        </Row>
      </Content>
    </Column>
  )
}
