import React, { FC, useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'

import { usePortal } from '../../hooks'
import { Column } from '../column'
import { Row } from '../row'
import { concatClassNames } from '../helpers'
import { updatePopoverPosition } from './helpers'

import {
  PopoverHintPosition,
  PopoverHintProps,
  PositionElement
} from './popover-hint.types'
import { useStyle } from './popover-hint.styles'

export const PopoverHint: FC<PopoverHintProps> = ({
  open,
  className = '',
  children,
  hintPosition = PopoverHintPosition.TOP,
  buttonWidth = 0
}) => {
  const [position, changePosition] = useState<PositionElement>({
    x: 0,
    y: 0
  })
  const containerRef = useRef<HTMLDivElement>(null)
  const hintRef = useRef<HTMLDivElement>(null)
  const target = usePortal('popover')

  const classes = useStyle({ position, open })

  useEffect(() => {
    if (open) {
      updatePopoverPosition({
        hintRef,
        containerRef,
        hintPosition,
        buttonWidth,
        changePosition
      })
    }
  }, [open])

  const popover = ReactDOM.createPortal(
    <Row className={classes.container} ref={hintRef}>
      <Column className={classes.arrow} />
      <Column
        fullWidth
        className={concatClassNames(classes.content, className)}
        alignItems="flex-start"
      >
        {children}
      </Column>
    </Row>,
    target
  )

  return (
    <Row className={classes.hintPosition} ref={containerRef}>
      {popover}
    </Row>
  )
}
