import React, { FC } from 'react'
import { useTheme } from 'react-jss'
import { LightTheme } from '../theme'
import { IconProps } from './types'

export const Print: FC<IconProps> = (props) => {
  const theme: LightTheme = useTheme()
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 8h-1V3H6v5H5c-1.66 0-3 1.34-3 3v6h4v4h12v-4h4v-6c0-1.66-1.34-3-3-3zM8 5h8v3H8V5zm8 14v-4H8v4h8zm2-4v-2H6v2H4v-4c0-.55.45-1 1-1h14c.55 0 1 .45 1 1v4h-2zm-1-3.5a1 1 0 112 0 1 1 0 01-2 0z"
        fill={props.fill || theme.colors.blackInactive}
      />
    </svg>
  )
}
