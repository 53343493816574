import { call, put } from 'redux-saga/effects'

import {
  Api,
  GetActivityLogByIdResults,
  GetActivityLogListResults,
  GetErrorLogByIdResults,
  GetErrorLogListResults,
  GetSearchActivityPreviewResults
} from '../../services'

import { LogsCreators, LogsTypes } from '../actions'

export function* GetActivityLogList(
  api: Api,
  action: LogsTypes.GetActivityLogListActionRequest
) {
  const response: GetActivityLogListResults = yield call(
    api.logs.activityList,
    action.payload
  )

  if (response.kind !== 'ok') {
    yield put(
      LogsCreators.getActivityLogListFailure({
        error: 'Failure get list of activity logs'
      })
    )
  } else {
    const { reset } = response

    yield put(
      LogsCreators.getActivityLogListSuccess({
        reset,
        ...response.data
      })
    )
  }
}

export function* GetSearchActivityPreview(
  api: Api,
  action: LogsTypes.GetSearchActivityPreviewActionRequest
) {
  const response: GetSearchActivityPreviewResults = yield call(
    api.logs.getSearchActivityPreview,
    action.payload
  )

  if (response.kind !== 'ok') {
    yield put(
      LogsCreators.getSearchActivityPreviewFailure({
        error: 'Failure get search activity preview list'
      })
    )
  } else {
    const { reset } = response

    yield put(
      LogsCreators.getSearchActivityPreviewSuccess({
        ...response.data,
        reset
      })
    )
  }
}

export function* GetActivityLogById(
  api: Api,
  action: LogsTypes.GetActivityLogByIdActionRequest
) {
  const response: GetActivityLogByIdResults = yield call(
    api.logs.activityById,
    action.payload
  )

  if (response.kind !== 'ok') {
    yield put(
      LogsCreators.getActivityLogByIdFailure({
        error: 'Failure get of activity by id log'
      })
    )
  } else {
    yield put(LogsCreators.getActivityLogByIdSuccess(response.data))
  }
}

export function* GetErrorLogList(
  api: Api,
  action: LogsTypes.GetErrorLogListActionRequest
) {
  const response: GetErrorLogListResults = yield call(
    api.logs.errorList,
    action.payload
  )

  if (response.kind !== 'ok') {
    yield put(
      LogsCreators.getErrorLogListFailure({
        error: 'Failure get list of error logs'
      })
    )
  } else {
    yield put(LogsCreators.getErrorLogListSuccess(response.data))
  }
}

export function* GetErrorLogById(
  api: Api,
  action: LogsTypes.GetErrorLogByIdActionRequest
) {
  const response: GetErrorLogByIdResults = yield call(
    api.logs.errorById,
    action.payload
  )

  if (response.kind !== 'ok') {
    yield put(
      LogsCreators.getErrorLogByIdFailure({
        error: 'Failure get of error by id log'
      })
    )
  } else {
    yield put(LogsCreators.getErrorLogByIdSuccess(response.data))
  }
}
