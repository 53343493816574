import { lightTheme, LightTheme } from './theme'

export const createTheme = (theme: LightTheme) => ({
  ...lightTheme,
  ...theme,
  colors: {
    ...lightTheme.colors,
    ...theme.colors
  },
  fonts: {
    ...lightTheme.fonts,
    ...theme.fonts
  },
  typography: {
    ...lightTheme.typography,
    ...theme.typography
  },
  breakpoints: {
    ...lightTheme.breakpoints,
    ...theme.breakpoints
  },
  row: {
    ...lightTheme.row,
    ...theme.row
  },
  column: {
    ...lightTheme.column,
    ...theme.column
  },
  spacing: {
    ...lightTheme.spacing,
    ...theme.spacing
  },
  content: {
    ...lightTheme.content,
    ...theme.content
  },
  link: {
    ...lightTheme.link,
    ...theme.link
  }
})
