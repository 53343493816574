import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  item: {
    marginTop: 16
  },
  title: {
    '&:not(:first-child)': {
      marginLeft: 8
    }
  },
  valueTitle: {
    width: '100%'
  },
  iconTitles: {
    minWidth: 95
  },
  header: {
    padding: '16px 32px'
  },
  container: {
    borderRadius: 12,
    background: theme.colors.white
  },
  content: {
    borderTop: `1px solid ${theme.colors.secondButton}`,
    padding: 16,
    transition: 'all 0.25s ease-in-out',
    width: 'calc(100% - 32px)',
    overflowY: 'auto'
  },
  active: {
    borderBottom: `2px ${theme.colors.blue} solid`
  }
}))
