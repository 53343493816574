import { RPSResolution } from '../../services'

import {
  GetDefaultDataProps,
  GetIsDisabledProps,
  GetResolutionDataProps,
  RpsResolutionModalValue
} from './rps-resolution-modal.types'

export const getIsDataChanged = (
  newData: RpsResolutionModalValue,
  oldData?: RpsResolutionModalValue
): boolean => {
  if (oldData) {
    const isRejected =
      newData.resolution !== RPSResolution.APPROVED &&
      newData.resolution !== RPSResolution.IN_PROGRESS
    const isResolutionChanged =
      Number(oldData.resolution) !== Number(newData.resolution)
    const isRejectionChanged =
      Number(oldData.rejectionReason) !== Number(newData.rejectionReason)
    const isRejection = isRejected ? isRejectionChanged : isResolutionChanged

    const result = isResolutionChanged || isRejection

    return !result
  }
  return false
}

export const getIsProgressValue = (value: RPSResolution) => {
  const isProgress = value === RPSResolution.IN_PROGRESS
  const isApproved = value === RPSResolution.APPROVED

  return isProgress || isApproved
}

export const closeModal = (onClose?: () => void) => {
  if (onClose) {
    onClose()
  }
}

export const submitModal =
  (onSubmit?: (value: RpsResolutionModalValue) => void) =>
  (value: RpsResolutionModalValue) => {
    if (onSubmit) {
      onSubmit(value)
    }
  }

export const getIsDisabled = ({
  disabled,
  isSubmitDisabled
}: GetIsDisabledProps) => disabled || isSubmitDisabled

export const getDefaultData = ({ outerDefaultValue }: GetDefaultDataProps) => {
  const BasicValue = {
    documentId: 0,
    resolution: RPSResolution.IN_PROGRESS,
    rejectionReason: undefined
  }
  return outerDefaultValue || BasicValue
}

export const getResolutionData = ({
  resolutionData,
  resolution,
  outerDefaultValue
}: GetResolutionDataProps) => {
  const prevValue = resolutionData?.resolution
  const isPrevResolution = Number(prevValue) === Number(resolution)
  const reason = outerDefaultValue?.rejectionReason
  const rejectionReason = isPrevResolution ? reason : 0
  const nextValue = {
    documentId: resolutionData.documentId,
    resolution,
    rejectionReason
  }

  return nextValue
}
