import React, {
  useState,
  useCallback,
  MouseEvent,
  useMemo,
  useEffect
} from 'react'
import { useTheme } from 'react-jss'

import { useDefaultValue } from '../../hooks'
import { Icon, IconName } from '../icon'
import { LightTheme } from '../theme'
import { Column } from '../column'
import { Row } from '../row'
import { Text } from '../text'
import {
  DropdownItemData,
  DropdownItems,
  PositionDropdown
} from '../dropdown-items'
import { RightCaret } from './right-caret'

import { DropdownFloatLabelProps } from './dropdown-float-label.types'
import { useStyle } from './dropdown-float-label.styles'

const DEFAULT_ITEM = {
  id: 0,
  name: '',
  value: 0
}

export const DropdownFloatLabel = <T extends string>({
  active,
  className = '',
  containerClassName = '',
  custom,
  data,
  defaultValue,
  disabled = false,
  description = '',
  errorProps,
  iconProps,
  isUndo = false,
  label,
  labelTx,
  preset,
  rightIconName,
  value: outerValue,
  isScrollable,
  isPreSetted = false,
  onChange
}: DropdownFloatLabelProps<T>) => {
  const [value, changeValue] = useState<string | number | undefined>(
    defaultValue || ''
  )
  const [controlled, changeControlled] = useState(true)
  const [open, changeOpen] = useState<boolean>(false)
  const [openModal, changeOpenModal] = useState(false)

  useEffect(() => {
    if (typeof outerValue !== 'undefined') {
      changeControlled(false)
      changeValue('')
    }
  }, [outerValue])

  const Value = useDefaultValue<string | number | null | undefined>(
    value,
    outerValue
  )

  const ValueItem = useMemo(
    () => data.find((item) => item.id === Value),
    [data, Value]
  )
  const isStringName = ValueItem && typeof ValueItem.name === 'string'

  const theme: LightTheme = useTheme()
  const classes = useStyle({
    open,
    custom,
    isUndo,
    disabled,
    isPreSetted,
    description
  })

  const disabledInputClass = disabled ? classes.disabled : ''
  const disabledLabelClass = disabled ? classes.disabledLabel : ''
  const iconFill = disabled ? theme.colors.border : theme.colors.darkBlue
  const combinedClass = errorProps
    ? `${classes.container} ${classes.error}`
    : classes.container

  const handleOnChange = (item: DropdownItemData<T>) => {
    if (controlled) {
      changeValue(item.id)
    }
    if (onChange) {
      onChange(item)
    }
  }

  const handleOnOpenModal = useCallback(
    (state?: boolean) => () => {
      if (typeof state === 'boolean') {
        changeOpenModal(state)
      } else {
        changeOpenModal(!openModal)
      }
    },
    [openModal]
  )

  const handleOnGetDescription = (
    event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => {
    event.preventDefault()
    event.stopPropagation()
    const openModalFunction = handleOnOpenModal(true)

    if (description) {
      openModalFunction()
    }
  }

  const handleOnOpen = useCallback(
    (state: boolean) => {
      if (!disabled) {
        changeOpen(state)
      }
    },
    [disabled]
  )

  const handleOnCaretClick = () => {
    handleOnOpen(false)
  }

  const handleOnClear = (
    event: MouseEvent<SVGSVGElement, globalThis.MouseEvent>
  ) => {
    event.preventDefault()
    event.stopPropagation()

    handleOnChange(DEFAULT_ITEM)
  }

  return (
    <Column fullWidth>
      <DropdownItems
        isScrollable={isScrollable}
        className={`${disabledInputClass} ${classes.innerItemsContainer} ${className}`}
        containerClassName={`${containerClassName} ${classes.itemsContainer}`}
        disabled={!disabled}
        active={active}
        data={data}
        position={PositionDropdown.BOTTOM}
        onOpen={handleOnOpen}
        onChange={handleOnChange}
      >
        <Row className={combinedClass} fullWidth justifyContent="space-between">
          <Column alignItems="flex-start">
            {label && (
              <Text
                className={`${disabledLabelClass} ${classes.label}`}
                text={label}
                tx={labelTx}
                color="darkBlue"
                preset={preset}
                onClick={handleOnGetDescription}
              />
            )}
            {isStringName ? (
              <Text
                className={classes.value}
                text={String(ValueItem.name)}
                tx={ValueItem.nameTx}
                preset={preset}
              />
            ) : ValueItem?.name}
          </Column>
          {iconProps && (
            <Row className={classes.textIcon}>
              <Text preset="bodyBold" color="border" {...iconProps} />
            </Row>
          )}
          {!isPreSetted ? (
            <RightCaret
              className={classes.caret}
              open={open}
              custom={custom}
              iconName={rightIconName}
              fill={iconFill}
              onClick={handleOnCaretClick}
            />
          ) : (
            <Icon
              className={classes.caret}
              name={IconName.CLEAR}
              fill={theme.colors.black}
              onClick={handleOnClear}
            />
          )}
        </Row>
      </DropdownItems>
    </Column>
  )
}
