import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../../theme'
import { SVGIconProps } from './icons.types'

export const CheckboxActive: FC<SVGIconProps> = (props) => {
  const theme: LightTheme = useTheme()
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.733 0A4.267 4.267 0 0116 4.267v7.466A4.267 4.267 0 0111.733 16H4.267A4.267 4.267 0 010 11.733V4.267A4.267 4.267 0 014.267 0h7.466zm-.13 5.471a.432.432 0 00-.588-.042l-.046.042-3.865 4.06-2.073-2.177a.433.433 0 00-.633 0 .488.488 0 00-.04.617l.04.048 2.39 2.51c.16.168.411.182.587.042l.046-.042 4.181-4.392a.487.487 0 000-.666z"
        fill={props.fill || theme.colors.button}
      />
    </svg>
  )
}
