import { DropdownItemsData } from '@cse/ui'
import { useMemo } from 'react'

import { TextTranslate } from '../../../components'
import { DEFAULT_APPS_FILTERS } from '../application-list'

import { Filters, GetAppsDataProps } from './apps-header-filter.types'

export const getIsFilterSetted = (values: Filters) => {
  const filterKeys = Object.keys(DEFAULT_APPS_FILTERS) as Array<keyof Filters>
  return filterKeys.reduce(
    (prev, key): any => Boolean(values[key]) || prev,
    false
  )
}

export const useAppsData = ({
  allReviewers,
  rpsResolution,
  values
}: GetAppsDataProps) => {
  const RpsMenuData: DropdownItemsData<TextTranslate> = useMemo(
    () => allReviewers.map((item) => ({ ...item, value: item.name })),
    [allReviewers]
  )

  const RpsResolutionData: DropdownItemsData<TextTranslate> = useMemo(
    () => rpsResolution.map((item) => ({ ...item, value: item.name })),
    [rpsResolution]
  )

  const IsFilter = useMemo(() => getIsFilterSetted(values), [values])

  return {
    RpsMenuData,
    RpsResolutionData,
    IsFilter
  }
}
