import React, { useMemo } from 'react'

import { Column, Row } from '../../../../components'
import { ActivityLogDetail } from '../../../../services'

import { ItemsListProps } from './items-list.types'
import { useStyle } from './items-list.styles'

export const ItemsList = ({
  listRef,
  contentRef,
  open,
  height,
  data,
  onItemClick
}: ItemsListProps) => {
  const classes = useStyle({ open, height })
  const isData = data.length

  const Content = useMemo(
    () =>
      isData &&
      data.map((item: ActivityLogDetail) => {
        const isApp = item.application

        if (isApp) {
          return (
            <Row
              key={`${item.id}_${item.message}`}
              className={classes.item}
              fullWidth
              justifyContent="flex-start"
              onClick={onItemClick(item.application.name)}
            >
              {item.application.name}
            </Row>
          )
        }

        return null
      }),
    [data]
  )

  if (!open) {
    return null
  }

  return (
    <Column ref={listRef} fullWidth>
      <Column className={classes.listContainer} fullWidth>
        <Column ref={contentRef} className={classes.previewList} fullWidth>
          {Content}
        </Column>
      </Column>
    </Column>
  )
}
