import React, {
  FC,
  MouseEventHandler,
  useCallback,
  useRef,
  useState
} from 'react'

import { StatusType } from '../../services'
import { AdvancedDocument } from '../../store'

import { Row } from '../row'
import { StatusHint } from '../status-hint'

import { StatusItem } from './status-item'
import { StatusTableItemProps } from './status-table-item.types'
import { useStyle } from './status-table-item.styles'

export const StatusTableItem: FC<StatusTableItemProps> = ({
  className = '',
  data,
  type = StatusType.RES
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const classes = useStyle()
  const [open, changeOpen] = useState(false)

  const getColor = useCallback(
    (item: AdvancedDocument) =>
      type === StatusType.RES ? item.color : item.colorRps,
    [type]
  )

  const handleOnHover =
    (status: boolean): MouseEventHandler<HTMLDivElement> =>
    (event) => {
      event.preventDefault()
      event.stopPropagation()

      if (status !== open) {
        changeOpen(status)
      }
    }

  return (
    <Row
      className={className}
      ref={ref}
      justifyContent="flex-start"
      onMouseEnter={handleOnHover(true)}
      onMouseLeave={handleOnHover(false)}
    >
      {data.map((item) => (
        <StatusItem
          key={`status_table_item_${item.title}`}
          className={classes.item}
          color={getColor(item)}
          text={item.title}
        />
      ))}
      <StatusHint open={open} title="IQR Doc Results" data={data} type={type} />
    </Row>
  )
}
