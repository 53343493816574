import { createActions, createTypes } from 'reduxsauce'

import { AppActions, AppActionsTypes } from './app.types'

export const appStringTypes = createTypes(`
  IS_LOADED

  GET_CATALOG_REQUEST
  GET_CATALOG_SUCCESS
  GET_CATALOG_FAILURE
`)

const { Types: AppActionTypes, Creators: AppCreators } = createActions<
  AppActionsTypes,
  AppActions
>({
  isLoaded: ['isLoaded'],

  getCatalogRequest: ['payload'],
  getCatalogSuccess: ['payload'],
  getCatalogFailure: ['payload']
})

export { AppCreators, AppActionTypes }
