import React from 'react'
import { useTheme } from 'react-jss'

import {
  Column,
  Row,
  Text,
  Icon,
  OptionLine,
  StatusExtendedItemProps
} from '../../../components'
import { LightTheme } from '../../../theme'
import { StatusType } from '../../../services'
import { useSettings } from './hooks'
import { ResultsRow } from './results-row'

import { DetailedHeaderProps } from './detailed-header.types'
import { useStyle } from './detailed-header.styles'

export const DetailedHeader = ({
  data,
  catalog,
  vertical = false
}: DetailedHeaderProps) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ vertical, theme })
  const application = data

  const {
    programC,
    appDate,
    runDate,
    profileDate,
    resolutionValues,
    rpsResolutionValues
  } = useSettings({
    application,
    catalog
  })

  return (
    <Row fullWidth justifyContent="flex-start">
      <Column className={classes.content} alignItems="flex-start">
        <Row>
          <Icon name="EXTENSION" />
          <Text
            className={classes.title}
            text="Summary"
            tx="document.summary"
            preset="bodyBold"
          />
        </Row>
        <OptionLine
          title="Program:"
          titleTx="document.program"
          value={programC}
        />
        <OptionLine
          title="Applicant:"
          titleTx="document.applicant"
          value={application.applicantName}
        />
        <OptionLine
          title="RPS:"
          titleTx="document.rps"
          value={application?.rpsReviewer}
        />
      </Column>
      <Row className={classes.separator} fullHeight />
      <Column className={classes.content} alignItems="flex-start">
        <Row>
          <Icon name="Date" />
          <Text
            className={classes.title}
            text="Dates"
            tx="document.dates"
            preset="bodyBold"
          />
        </Row>

        <OptionLine
          title="SF Date:"
          titleTx="document.sfDate"
          value={appDate}
        />
        <OptionLine
          title="IQR Run Date:"
          titleTx="document.iqrRunDate"
          value={runDate}
        />
        <OptionLine
          title="IQR Profile Date:"
          titleTx="document.iqrProfileDate"
          value={profileDate}
        />
      </Column>
      <Column className={classes.separator} fullHeight />
      <Column className={classes.content} alignItems="flex-start">
        <Row>
          <Icon name="STAR_RATE" />
          <Text
            className={classes.title}
            text="Results"
            tx="document.results"
            preset="bodyBold"
          />
        </Row>
        <Row className={classes.resultsHeader}>
          {resolutionValues.map((item: StatusExtendedItemProps) => (
            <Text
              key={`results-program-${item.document}`}
              text={item.document}
              color="darkBlue"
            />
          ))}
        </Row>

        <ResultsRow
          data={resolutionValues}
          name="iqr"
          title="IQR:"
          titleTx="document.iqr"
        />
        <ResultsRow
          data={rpsResolutionValues}
          name="rps"
          title="RPS:"
          titleTx="document.rps"
          type={StatusType.RPS}
        />
      </Column>
    </Row>
  )
}
