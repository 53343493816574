import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    marginTop: 24
  },
  warnings: {
    '& > *:not(:first-child)': {
      marginTop: 12
    }
  },
  warning: {
    borderRadius: '16px',
    padding: '4px 8px',
    '& > *:not(:first-child)': {
      marginLeft: 8
    }
  },
  warn: {
    background: theme.colors.lightOrange
  },
  updated: {
    background: theme.colors.strongGreenHover
  },
  recognized: {
    background: theme.colors.strongPinkHover
  }
}))
