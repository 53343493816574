import React, { FC } from 'react'
import { useTheme } from 'react-jss'
import { LightTheme } from '../theme'
import { IconProps } from './types'

export const Category: FC<IconProps> = (props) => {
  const theme: LightTheme = useTheme()
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 11L11.5 2L17 11H6ZM13.43 9L11.5 5.84L9.56 9H13.43ZM17 13C14.51 13 12.5 15.01 12.5 17.5C12.5 19.99 14.51 22 17 22C19.49 22 21.5 19.99 21.5 17.5C21.5 15.01 19.49 13 17 13ZM14.5 17.5C14.5 18.88 15.62 20 17 20C18.38 20 19.5 18.88 19.5 17.5C19.5 16.12 18.38 15 17 15C15.62 15 14.5 16.12 14.5 17.5ZM2.5 21.5H10.5V13.5H2.5V21.5ZM8.5 15.5H4.5V19.5H8.5V15.5Z"
        fill={props.fill || theme.colors.button}
      />
    </svg>
  )
}
