import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import {
  getResolutionIcon,
  getColorByStatusRpsResolution,
  Icon,
  IconName,
  Row,
  Text
} from '../../../../components'
import { Color, LightTheme } from '../../../../theme'
import { RPSResolutionNames } from '../../../../services'

import { ResolutionItemProps } from './resolution-item.types'
import { useStyle } from './resolution-item.styles'

export const ResolutionItem: FC<ResolutionItemProps> = ({
  className,
  text,
  value,
  children
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })
  const icon: IconName | null = getResolutionIcon(value)
  const fill: Color | null = getColorByStatusRpsResolution(value)
  const iconFill = fill && theme.colors[fill]
  const alignedText = text === RPSResolutionNames.NONE ? '' : text

  return (
    <Row className={`${className} ${classes.item}`}>
      {icon && iconFill && <Icon name={icon} fill={iconFill} />}
      <Text className={classes.text} text={alignedText} preset="caption" />
      {children}
    </Row>
  )
}
