import React from 'react'

import { getFieldTypes } from '../../../pages'
import { FullFieldsMenu } from '../../full-fields-menu'

import { FieldsTypesProps } from '../popup-document-validation.types'

export const ValidatedFields = ({
  className = '',
  isEditable,
  activeField,
  fields,
  fieldType,
  onApprove,
  onReject,
  onFocusInput,
  onBlurInput
}: FieldsTypesProps) => {
  const { isVerification } = getFieldTypes({
    fieldType
  })

  if (isVerification) {
    return null
  }

  const isFieldsExist = Boolean(fields.validated.length)
  if (!isFieldsExist) {
    return null
  }

  return (
    <FullFieldsMenu
      multiValue
      className={className}
      isEditable={isEditable}
      activeField={activeField}
      data={fields.validated}
      title="Validated Fields"
      titleTx="fields.validated"
      onApprove={onApprove}
      onReject={onReject}
      onFocusInput={onFocusInput}
      onBlurInput={onBlurInput}
    />
  )
}
