import React, { forwardRef } from 'react'
import { Content as ContentComponent } from '@cse/ui'

import { ContentProps } from './content.types'

export const Content = forwardRef<HTMLDivElement, ContentProps>(
  (props, ref) => {
    return <ContentComponent {...props} ref={ref} />
  }
)
