import { GetListHeightProps } from './dropdown-items.types'

export const ITEM_HEIGHT = 52

export const getListHeight = ({ data }: GetListHeightProps<any>): number => {
  if (data && data.length) {
    return data.length < 4 ? data.length * ITEM_HEIGHT : 200
  }
  return 0
}
