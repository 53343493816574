import React, { FC, useRef } from 'react'
import { useSelector } from 'react-redux'

import { Column, Row, Spinner } from '../../../components'
import { State } from '../../../store'
import { ItemsList } from './items-list'
import { ListHeader } from './docs-list-header'

import { DocListProps } from './docs-list.types'
import { useStyle } from './docs-list.styles'

export const DocList: FC<DocListProps> = ({ className, data, onClickItem }) => {
  const classes = useStyle()
  const ref = useRef<HTMLDivElement>(null)
  const isLoaded = true
  const { catalog } = useSelector((state: State) => ({
    ...state.app
  }))

  if (!data) {
    return null
  }

  return (
    <Column fullWidth justifyContent="flex-start">
      <Column
        ref={ref}
        className={`${className} ${classes.container}`}
        justifyContent="flex-start"
      >
        <Column fullWidth justifyContent="flex-start">
          <ListHeader />
          <ItemsList data={data} catalog={catalog} onClickItem={onClickItem} />
        </Column>
        <Row className={classes.spinner}>
          <Spinner isLoading={!isLoaded} />
        </Row>
      </Column>
    </Column>
  )
}
