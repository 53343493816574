import { call, put, select } from 'redux-saga/effects'
import { getCatalogData } from '../../pages'

import {
  Api,
  DocumentSmall,
  GetDocumentHeaderResults,
  GetDocumentResults,
  GetDocumentVersionsResults,
  GetFieldInfoResults,
  GetRejectionReasonAppResults,
  GetRejectionReasonDocResults,
  UpdateResolutionAppResults,
  UpdateResolutionDocResults,
  UpdateResolutionFieldResults
} from '../../services'

import { DocumentCreators, DocumentTypes } from '../actions'
import { Catalog } from '../reducers'
import { selectCatalog } from './selectors'

export function* GetDocumentHeader(
  api: Api,
  action: DocumentTypes.GetDocumentHeaderActionRequest
) {
  const catalog: Catalog = yield select(selectCatalog)

  const response: GetDocumentHeaderResults = yield call(
    api.document.getDocumentHeader,
    action.payload
  )

  if (response.kind !== 'ok') {
    yield put(
      DocumentCreators.getDocumentHeaderFailure({
        error: 'Failure get list of application'
      })
    )
  } else {
    const updatedDocuments = response.data.documents.map(
      (doc: DocumentSmall) => {
        const documentName = getCatalogData({
          catalog,
          id: 'document',
          value: doc.document
        })

        return {
          ...doc,
          documentName
        }
      }
    )

    const docHeader = {
      ...response.data,
      documents: updatedDocuments
    }

    yield put(DocumentCreators.getDocumentHeaderSuccess(docHeader))
  }
}

export function* GetDocument(
  api: Api,
  action: DocumentTypes.GetDocumentActionRequest
) {
  const response: GetDocumentResults = yield call(
    api.document.getDocument,
    action.payload
  )

  if (response.kind !== 'ok') {
    yield put(
      DocumentCreators.getDocumentFailure({
        error: 'Failure get of document'
      })
    )
  } else {
    yield put(DocumentCreators.getDocumentSuccess(response.data))
  }
}

export function* GetDocumentVersions(
  api: Api,
  action: DocumentTypes.GetDocumentVersionsActionRequest
) {
  const response: GetDocumentVersionsResults = yield call(
    api.document.getDocumentVersions,
    action.payload
  )

  if (response.kind !== 'ok') {
    yield put(
      DocumentCreators.getDocumentVersionsFailure({
        error: 'Failure get of document versions'
      })
    )
  } else {
    yield put(DocumentCreators.getDocumentVersionsSuccess(response.data))
  }
}

export function* GetDocumentHeaderToCompare(
  api: Api,
  action: DocumentTypes.GetDocumentHeaderToCompareActionRequest
) {
  const catalog: Catalog = yield select(selectCatalog)

  const response: GetDocumentHeaderResults = yield call(
    api.document.getDocumentHeader,
    action.payload
  )

  if (response.kind !== 'ok') {
    yield put(
      DocumentCreators.getDocumentHeaderToCompareFailure({
        error: 'Failure get list of application'
      })
    )
  } else {
    const updatedDocuments = response.data.documents.map(
      (doc: DocumentSmall) => {
        const documentName = getCatalogData({
          catalog,
          id: 'document',
          value: doc.document
        })

        return {
          ...doc,
          documentName
        }
      }
    )

    const docHeader = {
      ...response.data,
      documents: updatedDocuments
    }

    yield put(DocumentCreators.getDocumentHeaderToCompareSuccess(docHeader))
  }
}

export function* GetDocumentToCompare(
  api: Api,
  action: DocumentTypes.GetDocumentToCompareActionRequest
) {
  const response: GetDocumentResults = yield call(
    api.document.getDocumentToCompare,
    action.payload
  )

  if (response.kind !== 'ok') {
    yield put(
      DocumentCreators.getDocumentToCompareFailure({
        error: 'Failure get of document to compare'
      })
    )
  } else {
    yield put(DocumentCreators.getDocumentToCompareSuccess(response.data))
  }
}

export function* GetFieldInfo(
  api: Api,
  action: DocumentTypes.GetFieldInfoActionRequest
) {
  const response: GetFieldInfoResults = yield call(
    api.document.getFieldInfo,
    action.payload
  )

  if (response.kind !== 'ok') {
    yield put(
      DocumentCreators.getFieldInfoFailure({
        error: 'Failure getting of field info'
      })
    )
  } else {
    yield put(DocumentCreators.getFieldInfoSuccess(response.data.results))
  }
}

export function* UpdateResolutionApp(
  api: Api,
  action: DocumentTypes.UpdateResolutionAppActionRequest
) {
  const response: UpdateResolutionAppResults = yield call(
    api.document.updateResolutionApp,
    action.payload
  )

  if (response.kind !== 'ok') {
    yield put(
      DocumentCreators.getDocumentFailure({
        error: 'Failure update the resolution of application'
      })
    )
  } else {
    yield put(DocumentCreators.updateResolutionAppSuccess(response.data))
  }
}

export function* UpdateResolutionDoc(
  api: Api,
  action: DocumentTypes.UpdateResolutionDocFieldActionRequest
) {
  const response: UpdateResolutionDocResults = yield call(
    api.document.updateResolutionDocField,
    action.payload
  )

  if (response.kind !== 'ok') {
    yield put(
      DocumentCreators.getDocumentFailure({
        error: 'Failure update the resolution of document'
      })
    )
  } else {
    yield put(DocumentCreators.updateResolutionDocFieldSuccess(response.data))
  }
}

export function* UpdateResolutionField(
  api: Api,
  action: DocumentTypes.UpdateResolutionFieldActionRequest
) {
  const response: UpdateResolutionFieldResults = yield call(
    api.document.updateResolutionField,
    action.payload
  )

  if (response.kind !== 'ok') {
    yield put(
      DocumentCreators.getDocumentFailure({
        error: 'Failure update the resolution of field'
      })
    )
  } else {
    yield put(DocumentCreators.updateResolutionFieldSuccess(response.data))
  }
}

export function* GetRejectionReasonApp(
  api: Api,
  action: DocumentTypes.GetRejectionReasonAppActionRequest
) {
  const response: GetRejectionReasonAppResults = yield call(
    api.document.getRejectionReasonApp,
    action.payload
  )

  if (response.kind !== 'ok') {
    yield put(
      DocumentCreators.getRejectionReasonAppFailure({
        error: 'Failure getting of rejection reason app list'
      })
    )
  } else {
    yield put(DocumentCreators.getRejectionReasonAppSuccess(response.data))
  }
}

export function* GetRejectionReasonDoc(
  api: Api,
  action: DocumentTypes.GetRejectionReasonDocActionRequest
) {
  const response: GetRejectionReasonDocResults = yield call(
    api.document.getRejectionReasonDoc,
    action.payload
  )

  if (response.kind !== 'ok') {
    yield put(
      DocumentCreators.getRejectionReasonDocFailure({
        error: 'Failure get of application'
      })
    )
  } else {
    yield put(DocumentCreators.getRejectionReasonDocSuccess(response.data))
  }
}
