import React, {
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useTheme } from 'react-jss'

import { useDefaultValue } from '../../hooks'
import { Column } from '../column'
import { RightCaret } from '../dropdown-float-label'

import {
  DropdownItemData,
  DropdownItems,
  PositionDropdown
} from '../dropdown-items'
import { Row } from '../row'
import { Text } from '../text'
import { Icon, IconName } from '../icon'
import { LightTheme } from '../theme'

import { DropdownProps } from './dropdown.types'
import { useStyle } from './dropdown.styles'

const DEFAULT_ITEM = {
  id: 0,
  name: '',
  value: 0
}

export const Dropdown = <T extends string>({
  active,
  className,
  containerClassName,
  data,
  defaultValue,
  disabled,
  errorProps,
  label,
  labelTx,
  preset,
  rightIconName,
  value: outerValue,
  isScrollable,
  isPreSetted = false,
  isAlwaysSelected = false,
  onChange
}: DropdownProps<T>) => {
  const [value, changeValue] = useState<string | number | undefined>(
    defaultValue || ''
  )
  const [controlled, changeControlled] = useState(true)
  const [open, changeOpen] = useState<boolean>(false)
  const isAllowedToReset = isAlwaysSelected || !outerValue

  useEffect(() => {
    if (typeof outerValue !== 'undefined') {
      changeControlled(false)
      changeValue('')
    }
  }, [outerValue])

  const Value = useDefaultValue<string | number | null | undefined>(
    value,
    outerValue
  )

  const ValueItem = useMemo(
    () => data?.find((item) => item.id === Value),
    [data, Value]
  )
  const isStringName = ValueItem && typeof ValueItem.name === 'string'

  const theme: LightTheme = useTheme()
  const classes = useStyle({
    open,
    disabled,
    value: Value,
    isPreSetted
  })

  const disabledInputClass = disabled ? classes.disabled : ''
  const disabledLabelClass = disabled ? classes.disabledLabel : ''
  const iconFill = disabled ? theme.colors.border : theme.colors.darkBlue
  const combinedClass = errorProps
    ? `${classes.container} ${classes.error}`
    : classes.container

  const handleOnChange = (item: DropdownItemData<T>) => {
    if (controlled) {
      changeValue(item.id)
    }

    if (onChange) {
      onChange(item)
    }
  }

  const handleOnOpen = useCallback(
    (state: boolean) => {
      if (!disabled) {
        changeOpen(state)
      }
    },
    [disabled]
  )

  const handleOnCaretClick = () => {
    handleOnOpen(false)
  }

  const handleOnClear = (
    event: MouseEvent<SVGSVGElement, globalThis.MouseEvent>
  ) => {
    event.preventDefault()
    event.stopPropagation()

    handleOnChange(DEFAULT_ITEM)
  }

  return (
    <Column fullWidth>
      <DropdownItems
        isScrollable={isScrollable}
        className={`${disabledInputClass} ${classes.innerItemsContainer} ${className}`}
        containerClassName={`${containerClassName} ${classes.itemsContainer}`}
        disabled={!disabled}
        active={active}
        data={data}
        position={PositionDropdown.BOTTOM}
        onOpen={handleOnOpen}
        onChange={handleOnChange}
      >
        <Row className={combinedClass} fullWidth justifyContent="space-between">
          <Column alignItems="flex-start" className={classes.valueContainer}>
            {label && !ValueItem && (
              <Text
                className={disabledLabelClass}
                text={label}
                tx={labelTx}
                preset={preset}
              />
            )}
            {isStringName ? (
              <Text
                className={classes.value}
                text={String(ValueItem.name)}
                tx={ValueItem.nameTx}
                preset={preset}
              />
            ) : (
              <Row>{ValueItem?.name}</Row>
            )}
          </Column>
          {isAllowedToReset ? (
            <RightCaret
              open={open}
              iconName={rightIconName}
              fill={iconFill}
              onClick={handleOnCaretClick}
            />
          ) : (
            <Icon
              name={IconName.CLEAR}
              fill={theme.colors.black}
              onClick={handleOnClear}
            />
          )}
        </Row>
      </DropdownItems>
    </Column>
  )
}
