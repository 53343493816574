import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  container: {
    padding: '16px 0',
  },
  basic: {},
  large: {},
  extraLarge: {
    width: 'calc(100% - 330px)'
  },
  settings: {
    width: 688
  },
  '@media screen and (min-width: 1365px) and (max-width: 1604px)': {
    container: {
      padding: '8px 0'
    },
    extraLarge: {
      width: 'calc(100% - 200px)'
    }
  },
  '@media screen and (max-width: 1365px)': {
    container: {
      padding: '8px 0',
    },
    extraLarge: {
      width: 'calc(100% - 16px)'
    }
  },
  '@media screen and (max-width: 800px)': {
    container: {
      padding: '8px 0',
    },
    extraLarge: {
      width: 'calc(720px - 16px)'
    }
  }
})
