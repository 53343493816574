import React, { FC } from 'react'
import { useTheme } from 'react-jss'
import { LightTheme } from '../theme'
import { IconProps } from './types'

export const Widgets: FC<IconProps> = (props) => {
  const theme: LightTheme = useTheme()
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 3H21V11H13V3ZM11 3.00488H3V11.0049H11V3.00488ZM9 9.00488V5.00488H5V9.00488H9ZM19 9.00977V5.00977H15V9.00977H19ZM19 15.0049V19.0049H15V15.0049H19ZM9 19.0049V15.0049H5V19.0049H9ZM21 13.0049H13V21.0049H21V13.0049ZM3 13.0049H11V21.0049H3V13.0049Z"
        fill={props.fill || theme.colors.black}
      />
    </svg>
  )
}
