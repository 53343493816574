import React, { ForwardedRef, forwardRef, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { Hint } from '../hint'
import { Column } from '../column'
import { Row } from '../row'

import { TextComponentProps, TextProps } from './text.types'
import { useStyle } from './text.styles'

const spanComponent = ({ children, ...props }: TextComponentProps) => (
  <span {...props}>{children}</span>
)
const divComponent = ({ children, ...props }: TextComponentProps) => (
  <div {...props}>{children}</div>
)

export const presetDivComponents = {
  h1: ({ children, ...props }: TextComponentProps) => (
    <h1 {...props}>{children}</h1>
  ),
  h2: ({ children, ...props }: TextComponentProps) => (
    <h2 {...props}>{children}</h2>
  ),
  h3: ({ children, ...props }: TextComponentProps) => (
    <h3 {...props}>{children}</h3>
  ),
  h4: ({ children, ...props }: TextComponentProps) => (
    <h4 {...props}>{children}</h4>
  ),
  h5: ({ children, ...props }: TextComponentProps) => (
    <h5 {...props}>{children}</h5>
  ),
  h6: ({ children, ...props }: TextComponentProps) => (
    <h6 {...props}>{children}</h6>
  ),
  bodyBold: divComponent,
  body: divComponent,
  bodyUnderline: divComponent,
  subTitle: divComponent,
  smallTitle: divComponent,
  smallDescription: divComponent,
  smallInfo: divComponent,
  smallSetting: divComponent,
  subTitleSetting: divComponent,
  smallSubTitleSetting: divComponent,
  subTitleUnderline: divComponent,
  title: divComponent,
  chart: divComponent,
  subTitleMini: divComponent,
  largeTitle: divComponent,
  caption: divComponent,
  boldHeader: divComponent,
  textSetting: divComponent,
  boldTitle: divComponent
}

export const presetTextComponents = {
  h1: ({ children, ...props }: TextComponentProps) => (
    <h1 {...props}>{children}</h1>
  ),
  h2: ({ children, ...props }: TextComponentProps) => (
    <h2 {...props}>{children}</h2>
  ),
  h3: ({ children, ...props }: TextComponentProps) => (
    <h3 {...props}>{children}</h3>
  ),
  h4: ({ children, ...props }: TextComponentProps) => (
    <h4 {...props}>{children}</h4>
  ),
  h5: ({ children, ...props }: TextComponentProps) => (
    <h5 {...props}>{children}</h5>
  ),
  h6: ({ children, ...props }: TextComponentProps) => (
    <h6 {...props}>{children}</h6>
  ),
  bodyBold: spanComponent,
  body: spanComponent,
  bodyUnderline: spanComponent,
  subTitle: spanComponent,
  smallTitle: spanComponent,
  smallDescription: spanComponent,
  smallInfo: spanComponent,
  smallSetting: spanComponent,
  subTitleSetting: spanComponent,
  smallSubTitleSetting: spanComponent,
  subTitleUnderline: spanComponent,
  title: spanComponent,
  chart: spanComponent,
  subTitleMini: spanComponent,
  largeTitle: spanComponent,
  caption: spanComponent,
  boldHeader: divComponent,
  textSetting: spanComponent,
  boldTitle: spanComponent
}

export const Text = forwardRef(
  <T extends string>(
    {
      className = '',
      contentClassName = '',
      description,
      text = '',
      tx,
      values,
      preset = 'body',
      innerHtml,
      maxLength = 15,
      hint,
      ...props
    }: TextProps<T>,
    ref: ForwardedRef<HTMLSpanElement>
  ) => {
    const [hover, changeHover] = useState(false)
    const classes = useStyle({ ...props, hover })

    const Component = presetTextComponents[preset]
    const hideHint = useMemo(
      () => text && text?.length < maxLength,
      [text?.length, maxLength]
    )
    const hintTextOverflow = useMemo(
      () => (hint && !hideHint ? classes.textWithHint : ''),
      [hint, hideHint]
    )

    const handleOnHoverState = (state: boolean) => () => {
      changeHover(state)
    }

    if (!tx) {
      return (
        <>
          {hint && !hideHint && (
            <Row fullWidth>
              <Column className={classes.hint}>
                <Hint text={text} />
              </Column>
            </Row>
          )}
          <Component
            className={`${className} ${hintTextOverflow} ${classes.defaultCursor} ${classes[preset]}`}
            ref={ref}
            onMouseOver={handleOnHoverState(true)}
            onMouseOut={handleOnHoverState(false)}
            {...props}
          >
            {text}
          </Component>
        </>
      )
    }

    return (
      <>
        {hint && !hideHint && (
          <Row fullWidth>
            <Column className={classes.hint}>
              <Hint text={text} />
            </Column>
          </Row>
        )}
        <Component
          className={`${className} ${hintTextOverflow} ${classes.defaultCursor} ${classes[preset]}`}
          ref={ref}
          onMouseOver={handleOnHoverState(true)}
          onMouseOut={handleOnHoverState(false)}
          {...props}
        >
          <FormattedMessage
            id={tx || text}
            description={description}
            defaultMessage={text}
            values={values}
          />
        </Component>
      </>
    )
  }
)
