import React, { FC, useRef, useState } from 'react'

import {
  Column,
  Row,
  concatClassNames,
  arrayHasElements
} from '../../components'
import { FieldsTable } from '../fields-table'

import { FieldsMenuHeader } from './fields-menu-header'
import { MenuFieldsDataType } from '../popup-document-validation'

import { FieldsMenuComparisonProps } from './fields-menu-comparison.types'
import { useStyle } from './fields-menu-comparison.styles'

export const FieldsMenuComparison: FC<FieldsMenuComparisonProps> = ({
  isEditable = true,
  activeField,
  className,
  verificationData,
  validatedData,
  multiValue,
  onApprove,
  onReject,
  onFocusInput,
  onBlurInput
}) => {
  const classes = useStyle()
  const contentMenuRef = useRef<HTMLDivElement>(null)
  const isVerification = arrayHasElements(verificationData)
  const isValidated = arrayHasElements(validatedData)

  const [fieldType, changeFieldType] = useState<MenuFieldsDataType | undefined>(
    isVerification ? 'verification' : 'validated'
  )
  const getClassList = (type: string) =>
    fieldType === type
      ? concatClassNames(classes.active, classes.header)
      : classes.header

  const handleOnActivate = (offsetTop: number) => {
    if (contentMenuRef.current) {
      contentMenuRef.current.scrollTo({ top: offsetTop, behavior: 'smooth' })
    }
  }

  const handleOnChangeType = (type: MenuFieldsDataType) => () => {
    changeFieldType(type)
  }

  const verificationHeader = (
    <FieldsMenuHeader
      isSelected={fieldType === 'verification'}
      count={verificationData.length}
      icon="NewReleasesEmpty"
      title="Fields for Verification"
      titleTx="fields.verification"
    />
  )

  const validatedHeader = (
    <FieldsMenuHeader
      isSelected={fieldType === 'validated'}
      count={validatedData.length}
      icon="VerifiedUser"
      title="Validated Fields"
      titleTx="fields.validated"
    />
  )

  const isValidatedList = fieldType === 'validated' && isValidated
  const isVerificationList = fieldType === 'verification' && isVerification

  return (
    <Column className={concatClassNames(className, classes.container)}>
      <Row fullWidth justifyContent="flex-start">
        {isVerification && (
          <Row
            className={getClassList('verification')}
            justifyContent="space-between"
            onClick={handleOnChangeType('verification')}
          >
            {verificationHeader}
          </Row>
        )}
        {isValidated && (
          <Row
            className={getClassList('validated')}
            justifyContent="space-between"
            onClick={handleOnChangeType('validated')}
          >
            {validatedHeader}
          </Row>
        )}
      </Row>
      <Column
        className={classes.content}
        justifyContent="flex-start"
        ref={contentMenuRef}
      >
        {isVerificationList && (
          <FieldsTable
            isEditable={isEditable}
            activeField={activeField}
            multiValue={multiValue}
            data={verificationData}
            onApprove={onApprove}
            onReject={onReject}
            onFocusInput={onFocusInput}
            onBlurInput={onBlurInput}
            onActivate={handleOnActivate}
          />
        )}
        {isValidatedList && (
          <FieldsTable
            isEditable={isEditable}
            activeField={activeField}
            multiValue={multiValue}
            data={validatedData}
            onApprove={onApprove}
            onReject={onReject}
            onFocusInput={onFocusInput}
            onBlurInput={onBlurInput}
            onActivate={handleOnActivate}
          />
        )}
      </Column>
    </Column>
  )
}
