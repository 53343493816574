import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  button: {
    whiteSpace: 'nowrap'
  },
  row: {
    cursor: 'pointer',
    marginLeft: 16
  },
  text: {
    cursor: 'pointer',
    marginLeft: 8
  }
})
