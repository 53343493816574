import { createUseStyles } from 'react-jss'
import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    background: theme.colors.tableRow,
    borderTop: `1px solid ${theme.colors.grey}`,
    borderBottom: `2px solid ${theme.colors.grey}`
  },
  nav: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    padding: 0
  },
  item: {
    '&:not(:first-child)': {
      marginLeft: 40
    }
  }
}))
