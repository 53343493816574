import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

import { ResolutionStatusBadgeStyleProps } from './resolution-status-badge.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    background: ({ color }: ResolutionStatusBadgeStyleProps) =>
      `${theme.colors[color]}33`,
    borderRadius: 16,
    padding: '4px 8px',
    whiteSpace: 'nowrap'
  },
  iconTitle: {
    marginLeft: 4,
    textTransform: 'capitalize'
  },
  field: {
    marginLeft: 8
  }
}))
