import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { DropdownItemData } from '@cse/ui'

import {
  Button,
  concatClassNames,
  Dropdown,
  Row,
  TextTranslate
} from '../../../components'
import { State } from '../../../store'
import { useUserData } from '../../../hooks'
import { Search } from '../logs-search'
import { useData } from './helpers'

import {
  Filters,
  LogsHeaderFilterProps,
  LogsHeaderFilters
} from './logs-header-filter.types'
import { useStyle } from './logs-header-filter.styles'

export const LogsHeaderFilter: FC<LogsHeaderFilterProps> = ({
  className = '',
  value,
  defaultValues,
  onChange,
  onClear,
  onSearchClose
}) => {
  const classes = useStyle()

  const { catalog, allReviewers, user } = useSelector((state: State) => ({
    ...state.app.catalog,
    ...state.app,
    ...state.auth
  }))
  const [values, changeValues] = useState<Filters>(defaultValues)

  const { RpsMenuData, isFilter } = useData({
    allReviewers,
    values
  })
  const { GeneralProgramsData } = useUserData({
    validationRule: null,
    catalog,
    user
  })

  useEffect(() => {
    changeValues(defaultValues)
  }, [defaultValues])

  const handleOnChange =
    (prop: LogsHeaderFilters) => (item: DropdownItemData<TextTranslate>) => {
      if (onChange) {
        onChange(prop, item)
      }
    }

  return (
    <Row fullWidth justifyContent="space-between" className={className}>
      <Row>
        <Dropdown
          fixed
          className={concatClassNames(classes.program, classes.input)}
          isScrollable
          data={GeneralProgramsData}
          label="Program"
          preset="body"
          active={values?.program}
          defaultValue={values?.program}
          value={values?.program}
          onChange={handleOnChange('program')}
        />
        <Dropdown
          fixed
          className={concatClassNames(classes.user, classes.input)}
          isScrollable
          data={RpsMenuData}
          label="User"
          preset="body"
          active={value?.userName}
          defaultValue={values?.userName}
          value={values?.userName}
          onChange={handleOnChange('userName')}
        />
        {isFilter && (
          <Row className={classes.actions}>
            <Button
              className={classes.button}
              preset="secondary"
              text="Clear"
              tx="program.clear"
              onClick={onClear}
            />
          </Row>
        )}
      </Row>
      <Row>
        <Search onClose={onSearchClose} />
      </Row>
    </Row>
  )
}
