import { ApisauceInstance } from 'apisauce'
import camelcaseKeys from 'camelcase-keys'

import { decamelizeObject } from '../../utils'

import { getGeneralApiProblem } from '../api-problem'
import {
  DocumentPathes,
  GetDocumentHeaderRequest,
  GetDocumentHeaderResponse,
  GetDocumentHeaderResults,
  GetDocumentRequest,
  GetDocumentResponse,
  GetDocumentResults,
  GetDocumentVersionsRequest,
  GetDocumentVersionsResponse,
  GetDocumentVersionsResults,
  GetFieldInfoRequest,
  GetFieldInfoResponse,
  GetFieldInfoResults,
  GetRejectionReasonAppRequest,
  GetRejectionReasonAppResponse,
  GetRejectionReasonAppResults,
  GetRejectionReasonDocRequest,
  GetRejectionReasonDocResponse,
  GetRejectionReasonDocResults,
  UpdateResolutionAppRequest,
  UpdateResolutionAppResponse,
  UpdateResolutionAppResults,
  UpdateResolutionDocRequest,
  UpdateResolutionDocResponse,
  UpdateResolutionDocResults,
  UpdateResolutionFieldRequest,
  UpdateResolutionFieldResponse,
  UpdateResolutionFieldResults
} from './document.types'

// our "constructor"
export const documentService = (api: ApisauceInstance) => {
  /**
   * Get document header
   * @param params - parameters to identified application
   * @returns Promise of app data for document validation page
   */
  const getDocumentHeader = async ({
    params
  }: GetDocumentHeaderRequest): Promise<GetDocumentHeaderResults> => {
    const response = await api.get<GetDocumentHeaderResponse>(
      `${DocumentPathes.HEADER}/${params.appId}`
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) {
        return problem
      }
    }
    try {
      if (response.data) {
        const data = camelcaseKeys(response.data, {
          deep: true
        })

        return { kind: 'ok', data }
      }

      return { kind: 'bad-data' }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  /**
   * Get document
   * @param params - parameters to identified application
   * @param query - query params to sorting by ordering
   * @returns Promise of app data for document validation page
   */
  const getDocument = async ({
    params,
    query
  }: GetDocumentRequest): Promise<GetDocumentResults> => {
    const decamelizeQuery = decamelizeObject(query)

    const response = await api.get<GetDocumentResponse>(
      `${DocumentPathes.DOCUMENT}/${params.docId}`,
      decamelizeQuery
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) {
        return problem
      }
    }
    try {
      if (response.data) {
        const data = camelcaseKeys(response.data, {
          deep: true
        })

        return { kind: 'ok', data: { ...data, id: params.docId } }
      }

      return { kind: 'bad-data' }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  /**
   * Get document to compare
   * @param params - parameters to identified application
   * @param query - query params to sorting by ordering
   * @returns Promise of app data for document validation page
   */
  const getDocumentToCompare = getDocument

  /**
   * Get field comment
   * @param params - parameters to identified application
   * @param query - query params to sorting by ordering
   * @returns Promise of app data for document validation page
   */
  const getFieldInfo = async ({
    params
  }: GetFieldInfoRequest): Promise<GetFieldInfoResults> => {
    const response = await api.get<GetFieldInfoResponse>(
      `${DocumentPathes.FIELD_INFO}/${params.fieldPk}/validation`
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) {
        return problem
      }
    }
    try {
      if (response.data) {
        const data = camelcaseKeys(response.data, {
          deep: true
        })

        return { kind: 'ok', data }
      }

      return { kind: 'bad-data' }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  const updateResolutionApp = async (
    options: UpdateResolutionAppRequest
  ): Promise<UpdateResolutionAppResults> => {
    const decamelizeBody = decamelizeObject(options.body, '_')

    const response = await api.patch<UpdateResolutionAppResponse>(
      `${DocumentPathes.RESOLUTION_APP_UPDATE}/${options.params.appId}`,
      decamelizeBody
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) {
        return problem
      }
    }
    try {
      if (response.data) {
        const data = camelcaseKeys(response.data, {
          deep: true
        })

        return { kind: 'ok', data }
      }

      return { kind: 'bad-data' }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  const updateResolutionDocField = async (
    options: UpdateResolutionDocRequest
  ): Promise<UpdateResolutionDocResults> => {
    const decamelizeBody = decamelizeObject(options.body, '_')

    const response = await api.patch<UpdateResolutionDocResponse>(
      `${DocumentPathes.RESOLUTION_DOC_FIELD}/${options.params.docId}`,
      decamelizeBody
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) {
        return problem
      }
    }
    try {
      if (response.data) {
        const data = camelcaseKeys(response.data, {
          deep: true
        })

        return { kind: 'ok', data }
      }

      return { kind: 'bad-data' }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  const updateResolutionField = async (
    options: UpdateResolutionFieldRequest
  ): Promise<UpdateResolutionFieldResults> => {
    const decamelizeBody = decamelizeObject(options.body, '_')

    const response = await api.patch<UpdateResolutionFieldResponse>(
      `${DocumentPathes.RESOLUTION_FIELD_UPDATE}/${options.params.id}`,
      decamelizeBody
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) {
        return problem
      }
    }
    try {
      if (response.data) {
        const data = camelcaseKeys(response.data, {
          deep: true
        })

        return { kind: 'ok', data }
      }

      return { kind: 'bad-data' }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  /**
   * Get rejection reason application list
   * @param params - parameters to identified application
   * @param query - query params to sorting by ordering
   * @returns Promise of app data for document validation page
   */
  const getRejectionReasonApp = async ({
    query,
    reset
  }: GetRejectionReasonAppRequest): Promise<GetRejectionReasonAppResults> => {
    if (reset) {
      return { kind: 'bad-data' }
    }

    const decamelizeQuery = decamelizeObject(query)
    const response = await api.get<GetRejectionReasonAppResponse>(
      `${DocumentPathes.REJECTION_REASON_APP}`,
      decamelizeQuery
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) {
        return problem
      }
    }
    try {
      if (response.data) {
        const data = camelcaseKeys(response.data, {
          deep: true
        })

        return { kind: 'ok', data }
      }

      return { kind: 'bad-data' }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  /**
   * Get rejection reason document list
   * @param params - parameters to identified application
   * @param query - query params to sorting by ordering
   * @returns Promise of app data for document validation page
   */
  const getRejectionReasonDoc = async ({
    query,
    reset
  }: GetRejectionReasonDocRequest): Promise<GetRejectionReasonDocResults> => {
    if (reset) {
      return { kind: 'bad-data' }
    }

    const decamelizeQuery = decamelizeObject(query)
    const response = await api.get<GetRejectionReasonDocResponse>(
      `${DocumentPathes.REJECTION_REASON_DOC}`,
      decamelizeQuery
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) {
        return problem
      }
    }
    try {
      if (response.data) {
        const data = camelcaseKeys(response.data, {
          deep: true
        })

        return { kind: 'ok', data }
      }

      return { kind: 'bad-data' }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  /**
   * Get document versions
   * @param params - parameters to identified application
   * @param query - query params to sorting by ordering
   * @returns Promise of app data for document validation page
   */
  const getDocumentVersions = async ({
    params
  }: GetDocumentVersionsRequest): Promise<GetDocumentVersionsResults> => {
    const response = await api.get<GetDocumentVersionsResponse>(
      `${DocumentPathes.DOCUMENT_VERSIONS}/${params.docId}`
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) {
        return problem
      }
    }
    try {
      if (response.data) {
        const data = camelcaseKeys(response.data, {
          deep: true
        })

        return { kind: 'ok', data: { ...data, id: params.docId } }
      }

      return { kind: 'bad-data' }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  return {
    getDocumentHeader,
    getDocument,
    getDocumentHeaderToCompare: getDocumentHeader,
    getDocumentToCompare,
    updateResolutionApp,
    updateResolutionDocField,
    updateResolutionField,
    getFieldInfo,
    getRejectionReasonApp,
    getRejectionReasonDoc,
    getDocumentVersions
  }
}
