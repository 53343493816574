import { useEffect } from 'react'

export type UseDebounceInputProps = {
  value: string
  onChange?: (value: string) => void
  timeout?: number
}

export const useDebounceInput = ({
  value,
  timeout = 500,
  onChange
}: UseDebounceInputProps) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      if (onChange) {
        onChange(value)
      }
    }, timeout)

    return () => clearTimeout(timer)
  }, [value])
}

export default useDebounceInput
