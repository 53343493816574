import { createUseStyles } from 'react-jss'

import { DocumentViewerContentStyleProps } from './document-viewer-content.types'

export const useStyle = createUseStyles({
  imgContent: ({
    rotate,
    transformOrigin,
    ImagePropeties
  }: DocumentViewerContentStyleProps) => ({
    left: 0,
    ...ImagePropeties,
    position: 'absolute',
    transform: `rotate(${rotate}deg)`,
    transformOrigin
  }),
  imgContainer: {
    cursor: ({ grab }: DocumentViewerContentStyleProps) =>
      grab ? 'grabbing' : 'grab',
    height: ({ listHeight }: DocumentViewerContentStyleProps) => listHeight,
    overflow: 'auto',
    userSelect: 'none',
    width: ({ listWidth }: DocumentViewerContentStyleProps) => listWidth
  }
})
