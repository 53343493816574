import React, { FC } from 'react'

import { QuestionModal, Column, Text } from '../../components'

import { CompareVersionModalProps } from './compare-version-modal.types'
import { useStyle } from './compare-version-modal.styles'

export const CompareVersionModal: FC<CompareVersionModalProps> = ({
  open,
  className,
  children,
  description,
  descriptionTx,
  submitText,
  submitTx,
  disabled,
  title,
  titleTx,
  values,
  onClose,
  onSubmit
}) => {
  const classes = useStyle()

  const handleOnSubmit = () => {
    if (onSubmit) {
      onSubmit()
    }
  }

  if (!open) {
    return <></>
  }

  return (
    <QuestionModal
      className={className}
      title={title}
      titleTx={titleTx}
      submitText={submitText}
      submitTx={submitTx}
      values={values}
      disabled={disabled}
      onSubmit={handleOnSubmit}
      onClose={onClose}
    >
      <Column
        fullWidth
        fullHeight
        className={classes.description}
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Text
          className={classes.text}
          text={description}
          tx={descriptionTx}
          preset="body"
          color="inactive"
        />
        <Text
          className={classes.extra}
          text="current document version:"
          tx="compare.modal.extra"
          preset="caption"
          color="border"
        />
      </Column>
      {children}
    </QuestionModal>
  )
}
