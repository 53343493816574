import { useRef } from 'react'

import useWindowScroll, { WindowScrollCallback } from './useWindowScroll'

export const useWindowThrottleScroll = (
  cb: WindowScrollCallback,
  timeoutTime: number = 250
) => {
  const timeout = useRef<NodeJS.Timeout | null>(null)

  const handleOnScroll = (event: Event) => {
    if (timeout.current) {
      clearTimeout(timeout.current)
    }

    timeout.current = setTimeout(() => {
      cb(event)
    }, timeoutTime || 250)
  }

  useWindowScroll(handleOnScroll)
}

export default useWindowThrottleScroll
