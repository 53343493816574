import { useDefaultValue } from '@cse/ui'
import React, { useEffect, useRef, useState } from 'react'

import { Row } from '../../row'
import { Text } from '../../text'
import { PositionElement } from '../../popover-hint'
import { FieldValueTooltip } from '../field-value-tooltip'
import { useGetData, onActive, setPositionTimeout } from '../helpers'
import { onFieldValueHover } from './helpers'

import { MultiLineProps } from './multi-line.types'
import { useStyle } from '../validation-field.styles'

export const MultiLine = ({
  active,
  multiValue,
  value,
  rpsResolution,
  focused,
  onActivate
}: MultiLineProps) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const valueRef = useRef<HTMLDivElement>(null)

  const [openTooltip, changeOpenTooltip] = useState(false)
  const [position, changePosition] = useState<PositionElement>({
    x: 0,
    y: 0
  })

  const Value = useDefaultValue(focused, active)
  const classes = useStyle({ multiValue, focused: Value })

  const { FocusedFieldPreset, ValueStringify } = useGetData({
    rpsResolution,
    focused,
    value
  })

  const handleOnActivate = () =>
    onActive({
      active,
      containerRef,
      onActivate
    })

  useEffect(handleOnActivate, [active])

  useEffect(() => {
    const timeout = setTimeout(() =>
      setPositionTimeout({
        valueRef,
        changePosition
      })
    )

    return () => {
      clearTimeout(timeout)
    }
  }, [valueRef.current])

  const handleFieldValueHover = onFieldValueHover({
    value: ValueStringify,
    changeOpenTooltip
  })

  if (!multiValue) {
    return null
  }

  return (
    <Row ref={valueRef} className={classes.valueContainer}>
      <Row
        fullWidth
        justifyContent="flex-start"
        onMouseOver={handleFieldValueHover(true)}
        onMouseLeave={handleFieldValueHover(false)}
      >
        <Text
          className={classes.text}
          preset={FocusedFieldPreset}
          text={ValueStringify}
        />
      </Row>
      <FieldValueTooltip
        open={openTooltip}
        position={position}
        value={ValueStringify}
      />
    </Row>
  )
}
