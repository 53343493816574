import React, { FC, useEffect, useMemo, useState } from 'react'

import {
  Column,
  Row,
  StatusTableItem,
  Text,
  OptionLine,
  StatusBadge,
  concatClassNames,
  LinkItem
} from '../../../../components'
import { MainRoutes } from '../../../../routes'
import { StatusType } from '../../../../services'

import { formatDateBySlash } from '../../../helpers'
import { ResolutionItem } from '../resolution-item'

import { ListItemProps } from './apps-list-item.types'
import { useStyle } from './apps-list-item.styles'

export const ListItem: FC<ListItemProps> = ({
  className = '',
  id,
  title,
  resolution,
  documents,
  rpsReviewer,
  programValue,
  rpsResolution,
  rpsResolutionValue,
  applicationDate,
  lastRecognitionTime,
  rpsReviewDate,
  catalog,
  disabled
}) => {
  const classes = useStyle({ disabled })
  const [query, changeQuery] = useState('')

  const appDate = useMemo(
    () => formatDateBySlash(applicationDate),
    [applicationDate]
  )
  const runDate = useMemo(
    () => formatDateBySlash(lastRecognitionTime),
    [lastRecognitionTime]
  )
  const reviewDate = useMemo(
    () => formatDateBySlash(rpsReviewDate),
    [rpsReviewDate]
  )

  useEffect(() => {
    if (id) {
      const doc = documents[0]
      const link = doc ? `?document_id=${doc.id}` : ''

      changeQuery(`${MainRoutes.DOCUMENT}/${id}${link}`)
    }
  }, [id, documents])

  return (
    <LinkItem className={classes.link} href={query}>
      <Row
        className={concatClassNames(className, classes.container)}
        justifyContent="space-between"
      >
        <Row
          className={classes.subContainer}
          fullWidth
          justifyContent="space-between"
        >
          <Column
            className={classes.name}
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <Text
              className={classes.text}
              text={programValue}
              preset="caption"
            />
          </Column>
          <Column
            className={classes.appId}
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <Text
              className={concatClassNames(
                classes.text,
                classes.dotsOverflow,
                classes.appId
              )}
              text={title}
              preset="caption"
              color="blue"
            />
          </Column>
          <Column
            className={classes.applDate}
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <Text
              className={concatClassNames(classes.text, classes.indent)}
              text={appDate}
              preset="caption"
            />
          </Column>
          <Column
            className={classes.iqrRes}
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <StatusBadge className={classes.indent} value={resolution} />
          </Column>
          <StatusTableItem
            className={classes.iqr}
            data={documents}
            catalog={catalog}
          />
          <Column
            className={classes.runDate}
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <OptionLine
              className={classes.text}
              preset="caption"
              value={runDate}
            />
          </Column>
          <Column
            className={classes.reviewedBy}
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <OptionLine
              className={concatClassNames(classes.text, classes.dotsOverflow)}
              preset="caption"
              value={rpsReviewer}
            />
          </Column>
          <Column
            className={classes.reviewedDate}
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <OptionLine
              className={classes.text}
              preset="caption"
              value={reviewDate}
            />
          </Column>
          <Column
            className={classes.resolution}
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <ResolutionItem
              className={classes.indent}
              text={rpsResolutionValue}
              value={rpsResolution}
            />
          </Column>
          <StatusTableItem
            className={classes.iqr}
            data={documents}
            type={StatusType.RPS}
            catalog={catalog}
          />
        </Row>
      </Row>
    </LinkItem>
  )
}
