import { useMemo } from 'react'
import { DropdownItemsData } from '@cse/ui'

import { TextTranslate } from '../../../components'
import { DEFAULT_LOG_FILTERS } from '../logs'

import { Filters, DataProps } from './logs-header-filter.types'

export const getIsFilterSetted = (values: Filters) => {
  const filterKeys = Object.keys(DEFAULT_LOG_FILTERS) as Array<keyof Filters>
  return filterKeys.reduce(
    (prev, key): any => Boolean(values[key]) || prev,
    false
  )
}

export const useData = ({ allReviewers, values }: DataProps) => {
  const RpsMenuData: DropdownItemsData<TextTranslate> = useMemo(
    () => allReviewers.map((item: any) => ({ ...item, value: item.name })),
    [allReviewers]
  )
  const IsFilterSetted = useMemo(() => getIsFilterSetted(values), [values])

  return {
    RpsMenuData,
    isFilter: IsFilterSetted
  }
}
