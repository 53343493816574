import { CatalogKeys, GetCatalogResponse, RawCoreItem } from '../../../services'

import { Catalog, CoreItem } from './app.types'

export const defaultCatalog = {
  program: [],
  resolution: [],
  document: [],
  rpsResolution: [],
  rpsReviewer: [],
  field: [],
  fieldRuleType: [],
  fieldRuleValue: [],
  rejectionReason: [],
  allReviewers: []
}

export const rawCoreItemToCoreItem = (item: RawCoreItem): CoreItem => ({
  id: item[0],
  value: item[0],
  name: item[1]
})

export const getCatalogFromRawCatalog = (data: GetCatalogResponse) => {
  return Object.entries(data).reduce<Catalog>((acc, catalogType) => {
    const key = catalogType[0] as CatalogKeys

    return {
      ...acc,
      [key]: catalogType[1].map(rawCoreItemToCoreItem)
    }
  }, defaultCatalog)
}
