import React from 'react'

import { Content } from '../content'
import { Row } from '../row'
import { Icon } from '../icon'
import { Column } from '../column'
import { IconName } from '../icon'
import { DropdownItems } from '../dropdown-items'
import { NavText } from '../nav-text'

import { HeaderProps } from './header.types'
import { useStyle } from './header.styles'

export const Header = <T extends string>({
  backgroundColor,
  className = '',
  children,
  leftIcon,
  menuData,
  displayName,
  profileLink = '/profile',
  isLoggedIn,
  onLogoClick,
  onLogin
}: HeaderProps<T>) => {
  const { pathname } = window.location
  const classes = useStyle({ backgroundColor })
  const isBackgroundClass = isLoggedIn ? classes.filledBackground : ''

  const getIsActive = (navPath: string) => pathname === navPath

  return (
    <Column className={`${isBackgroundClass}`} fullWidth>
      <Content className={`${className} ${classes.header}`}>
        <Row>
          <Row className={classes.logo} onClick={onLogoClick}>
            {leftIcon || <Icon name={IconName.LOGO} width={130} height={40} />}
          </Row>
          <Row>{children}</Row>
        </Row>
        {isLoggedIn ? (
          <Row className={classes.complexContainer}>
            <DropdownItems disabled className={classes.menu} data={menuData}>
              <Icon
                className={classes.icon}
                name={IconName.USER}
                width={22}
                height={22}
              />
              <NavText
                active={getIsActive(profileLink)}
                className={classes.navText}
                text={displayName}
                color="inactive"
              />
            </DropdownItems>
          </Row>
        ) : (
          <Row>
            <NavText
              active
              className={classes.navText}
              text="Login"
              tx="login.title"
              color="darkBlue"
              onClick={onLogin}
            />
          </Row>
        )}
      </Content>
    </Column>
  )
}
