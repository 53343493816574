import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Column, Spinner } from '../../../../components'
import { RPSResolution } from '../../../../services'
import { DocumentCreators, State } from '../../../../store'
import { RpsResolutionModalValue } from '../../../../views'
import { DocumentValidation } from '../../document-validation'
import {
  getData,
  getFullFields,
  getRejectionReason,
  updateResolutionField
} from '../helpers'

import { ContentProps } from './content.types'

export const Content = ({
  docId,
  page,
  document,
  comparisonPage,
  onChangePage,
  onChangeComparisonPage
}: ContentProps) => {
  const dispatch = useDispatch()

  const {
    documentHeader,
    versionsToCompare,
    documentLoading,
    documentsToCompare
  } = useSelector((state: State) => ({
    ...state.applications,
    ...state.app,
    ...state.document
  }))

  const handleOnUpdateResolutionField =
    (rpsResolution: RPSResolution) => (fieldId: number) =>
      updateResolutionField({
        document,
        page,
        docId,
        fieldId,
        rpsResolution,
        dispatch
      })

  const handleOnSubmitDocumentResolution = (data: RpsResolutionModalValue) => {
    const rejectionReason = getRejectionReason(data)

    dispatch(
      DocumentCreators.updateResolutionDocFieldRequest({
        params: { docId: String(docId) },
        body: {
          rpsResolution: data.resolution,
          rejectionReason
        }
      })
    )
  }

  const comparisonDocument = useMemo(() => {
    const documentKeys = Object.keys(documentsToCompare)
    const isDocumentToCompare = documentKeys.length
    const docsValues = Object.values(documentsToCompare)

    if (isDocumentToCompare) {
      return docsValues[0]
    }
    return undefined
  }, [documentsToCompare])

  const FullFields = useMemo(() => getFullFields({ document }), [document])
  const FullComparisonFields = useMemo(
    () => getFullFields({ document: comparisonDocument }),
    [documentsToCompare]
  )

  const {
    isContentAvailable,
    resolutionData,
    defaultValue,
    currentData,
    currentComparisonData
  } = getData({
    documentHeader,
    docId,
    document,
    documentLoading,
    page,
    comparisonDocument,
    comparisonPage
  })
  const comparisonPages = useMemo(
    () => comparisonDocument?.count || 0,
    [comparisonDocument]
  )
  const comparisonPagesData = useMemo(
    () => comparisonDocument?.pages,
    [comparisonDocument]
  )

  if (isContentAvailable) {
    return (
      <DocumentValidation
        documentHeader={documentHeader}
        versionsToCompare={versionsToCompare}
        defaultValue={defaultValue}
        docId={docId}
        date={resolutionData}
        document={document}
        pages={document.count}
        fields={FullFields}
        data={currentData}
        comparisonFields={FullComparisonFields}
        comparisonPages={comparisonPages}
        comparisonData={currentComparisonData}
        currentPage={page}
        currentComparisonPage={comparisonPage}
        pagesData={document.pages}
        comparisonPagesData={comparisonPagesData}
        onChangePage={onChangePage}
        onChangeComparisonPage={onChangeComparisonPage}
        onApprove={handleOnUpdateResolutionField(RPSResolution.APPROVED)}
        onReject={handleOnUpdateResolutionField(RPSResolution.INELIGIBLE)}
        onSubmitResolution={handleOnSubmitDocumentResolution}
      />
    )
  }

  return (
    <Column fullFilledHeight>
      <Spinner />
    </Column>
  )
}
