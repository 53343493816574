import { ApisauceInstance } from 'apisauce'
import camelcaseKeys from 'camelcase-keys'

import { decamelizeObject } from '../../utils'

import { getGeneralApiProblem } from '../api-problem'

import {
  AuthPathes,
  GetOktaURLRequest,
  GetOktaUrlResponse,
  GetOktaUrlResults,
  GetUserMeResponse,
  GetUserMeResults,
  RefreshTokenRequest,
  RefreshTokenResponse,
  RefreshTokenResults
} from './auth.types'

// our "constructor"
export const authService = (api: ApisauceInstance) => {
  /**
   * Request to get full url to login with okat service
   * @param options - Data about at redirect options
   */
  const getUrlOkta = async ({
    query
  }: GetOktaURLRequest): Promise<GetOktaUrlResults> => {
    const queryDecamelize = decamelizeObject(query)
    const response = await api.get<GetOktaUrlResponse>(
      AuthPathes.GET_OKTA_URL,
      queryDecamelize
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) {
        return problem
      }
    }
    try {
      if (response.data) {
        const data = camelcaseKeys(response.data, {
          deep: true
        })
        return { kind: 'ok', data }
      }

      return { kind: 'bad-data' }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  /**
   * Request to get refreshed token from service
   * @param options - Information to refresh token, how prev token
   */

  const refreshToken = async ({
    body
  }: RefreshTokenRequest): Promise<RefreshTokenResults> => {
    const bodyDecamelize = decamelizeObject(body)
    const response = await api.post<RefreshTokenResponse>(
      AuthPathes.REFRESH_TOKEN,
      bodyDecamelize
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) {
        return problem
      }
    }
    try {
      if (response.data) {
        const data = camelcaseKeys(response.data, {
          deep: true
        })
        return { kind: 'ok', data }
      }

      return { kind: 'bad-data' }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  const getUserMe = async (): Promise<GetUserMeResults> => {
    const response = await api.get<GetUserMeResponse>(AuthPathes.GET_USER_ME)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) {
        return problem
      }
    }
    try {
      if (response.data) {
        const data = camelcaseKeys(response.data, {
          deep: true
        })

        return { kind: 'ok', user: data }
      }

      return { kind: 'bad-data' }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  return {
    getUrlOkta,
    refreshToken,
    getUserMe
  }
}
