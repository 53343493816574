import { createUseStyles } from 'react-jss'
import { DocHeaderStyleProps } from './doc-header.types'

export const useStyle = createUseStyles({
  iqr: {
    marginLeft: 24
  },
  badge: {
    marginLeft: 8
  },
  save: {
    marginLeft: 24
  },
  btnResolution: {
    whiteSpace: 'nowrap'
  },
  title: {
    marginLeft: 24,
    userSelect: 'text'
  },
  subHeader: {
    cursor: 'pointer'
  },
  arrow: {
    marginLeft: 16,
    transform: ({ open }: DocHeaderStyleProps) =>
      open ? 'rotate(180deg)' : 'rotate(0deg)',
    transformOrigin: 'center',
    transition: 'transform 0.25s ease-in-out'
  },
  versions: {
    marginLeft: 16,
    maxWidth: 318,
    minWidth: 316
  }
})
