import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  rightPanelMenu: {
    overflowY: 'scroll',
    position: 'absolute',
    right: '100%',
    top: 0,
    bottom: 0,
    padding: '24px 16px',
    width: 484,
    borderLeft: `1px solid ${theme.colors.secondButton}`,
    backgroundColor: theme.colors.white
  },
  container: {
    paddingBottom: 16
  }
}))
