import React, { FC } from 'react'

import { Row, StatusHintItem, Text } from '../../../../components'
import { StatusType } from '../../../../services'

import { ResultsRowProps } from './results-row.types'
import { useStyle } from './results-row.styles'

export const ResultsRow: FC<ResultsRowProps> = ({
  className = '',
  data,
  name,
  title,
  titleTx,
  type = StatusType.RES
}) => {
  const classes = useStyle()

  return (
    <Row className={className}>
      <Text
        className={classes.title}
        text={title}
        tx={titleTx}
        preset="caption"
        color="inactive"
      />
      <Row>
        {data.map((item) => (
          <StatusHintItem
            key={`${item.title}_${name}`}
            className={classes.column}
            type={type}
            {...item}
          />
        ))}
      </Row>
    </Row>
  )
}
