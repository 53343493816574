import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  description: {
    marginTop: 16,
    textAlign: 'start',
    height: 'auto'
  },
  text: {
    whiteSpace: 'pre-line'
  },
  extra: {
    marginTop: 16
  }
})
