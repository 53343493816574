import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  value: {
    position: 'absolute',
    textAlign: 'center',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    verticalAlign: 'center'
  },
  radio: {
    overflow: 'visible'
  }
})
