import { createUseStyles } from 'react-jss'

import { SortStyles, SortTypes } from './sort-button.types'

export const useStyle = createUseStyles({
  sort: {
    cursor: 'pointer',
    transform: ({ sort }: SortStyles) =>
      sort === SortTypes.ASC ? 'rotate(0deg)' : 'rotate(180deg)'
  }
})
