import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  outerContainer: {
    height: '100%',
    width: 'calc(100% - 32px)',
    borderRadius: '0 0 12px 12px',
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  programCardsList: {
    borderRadius: '0 0 12px 12px',
    '& >:last-child': {
      borderBottom: 'none'
    }
  },
  item: {
    '&:not(:first-child)': {
      marginTop: 16
    }
  },
  loader: {
    height: 120,
    marginTop: -28,
    width: '100%',
    zIndex: 3
  },
  scrollBtn: {
    position: 'fixed',
    width: 40,
    height: 40,
    right: 150,
    bottom: 16
  },
  goUp: {
    position: 'absolute',
    borderRadius: '50%',
    transform: 'rotate(-90deg)',
    boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
    zIndex: 3
  }
})
