export enum SortTypes {
  ASC = 'asc',
  DESC = 'desc'
}

export interface SortButtonProps {
  sort: SortTypes
  onSort?: () => void
}

export type SortStyles = {
  sort: SortTypes
}
