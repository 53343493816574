import React, { useCallback, useRef, useState } from 'react'

import { Column, Content, IconButton, Row, Spinner } from '../../../components'
import { FullProgramCard } from '../../../views'
import { useWindowScroll, useWindowThrottleScrollEnd } from '../../../hooks'

import { PROGRAM_CARDS_LIMIT } from '../program'

import { ProgramCardsListProps } from './program-cards-list.types'
import { useStyle } from './program-cards-list.styles'

export const ProgramCardsList = ({
  isLoaded,
  data,
  catalog,
  current,
  next,
  onLoadMore,
  onOpenValidationRule,
  onSearchDocumentType
}: ProgramCardsListProps) => {
  const [scrollTop, changeScrollTop] = useState(false)
  const classes = useStyle()
  const listRef = useRef<HTMLDivElement>(null)

  const handleOnScrollToTop = useCallback(() => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0
    })
  }, [listRef])

  const handleOnLoadMore = useCallback(() => {
    const isAllowedToLoadMore = current && next
    const isLoadMore = isAllowedToLoadMore && isLoaded
    const isLoadAllowed = isLoadMore && onLoadMore

    if (isLoadAllowed) {
      onLoadMore({
        offset: current,
        limit: PROGRAM_CARDS_LIMIT
      })
    }
  }, [current, isLoaded, onLoadMore])

  const handleOnScroll = () => {
    const isContent = listRef && listRef.current

    if (isContent) {
      const { scrollHeight } = listRef.current
      const minScrollHeight = scrollHeight * 0.1 * 2
      const isScrollToTop = window.scrollY > minScrollHeight

      changeScrollTop(isScrollToTop)
    }
  }

  useWindowScroll(handleOnScroll)
  useWindowThrottleScrollEnd(handleOnLoadMore, 0.5)

  if (!data) {
    return (
      <Column fullFilledHeight>
        <Spinner />
      </Column>
    )
  }

  return (
    <Column fullWidth justifyContent="flex-start">
      <Content>
        <Column
          fullWidth
          className={classes.outerContainer}
          justifyContent="flex-start"
        >
          <Column fullWidth ref={listRef} className={classes.programCardsList}>
            {data.map(
              ({
                id,
                documentTypes,
                field,
                title,
                manualValue,
                updatedAt,
                value,
                type,
                resolution
              }) => (
                <FullProgramCard
                  key={`full_program_card_${id}_${title}`}
                  className={classes.item}
                  catalog={catalog}
                  documentTypes={documentTypes}
                  manualValue={manualValue}
                  field={field}
                  title={title}
                  updatedAt={updatedAt}
                  value={value}
                  type={type}
                  resolution={resolution}
                  onOpen={onOpenValidationRule(id)}
                  onSearch={onSearchDocumentType(id)}
                />
              )
            )}
          </Column>
        </Column>
        {!isLoaded && <Spinner className={classes.loader} />}
        {scrollTop && (
          <Row
            className={classes.scrollBtn}
            fullWidth
            justifyContent="flex-end"
          >
            <IconButton
              className={classes.goUp}
              name="ARROW_BACK"
              fill="darkBlue"
              preset="up"
              onClick={handleOnScrollToTop}
            />
          </Row>
        )}
      </Content>
    </Column>
  )
}
