import React from 'react'

import { DetailedHeader } from '../detailed-header'

import { HeaderProps } from './header-content.types'

export const HeaderContent = ({ documentHeader, catalog }: HeaderProps) => {
  if (!documentHeader) {
    return null
  }

  return <DetailedHeader data={documentHeader} catalog={catalog} />
}
