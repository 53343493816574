import { call, put } from 'redux-saga/effects'

import { Api, GetCatalogResults } from '../../services'

import { AppCreators, AppTypes } from '../actions'

export function* GetCatalog(
  api: Api,
  action: AppTypes.GetCatalogActionRequest
) {
  const response: GetCatalogResults = yield call(
    api.app.getCatalog,
    action.payload
  )

  if (response.kind !== 'ok') {
    yield put(
      AppCreators.getCatalogFailure({
        error: 'Failure get catalog list'
      })
    )
  } else {
    yield put(
      AppCreators.getCatalogSuccess({
        ...response.catalog
      })
    )
  }
}
