import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../../theme'
import { SVGIconProps } from './icons.types'

export const LooksTwo: FC<SVGIconProps> = (props) => {
  const theme: LightTheme = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={18}
      viewBox="0 0 18 18"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.25 2.25H3.75c-.825 0-1.5.675-1.5 1.5v10.5c0 .825.675 1.5 1.5 1.5h10.5c.825 0 1.5-.675 1.5-1.5V3.75c0-.825-.675-1.5-1.5-1.5Zm0 12H3.75V3.75h10.5v10.5Zm-6-3h3v1.5h-4.5v-3a1.5 1.5 0 0 1 1.5-1.5h1.5v-1.5h-3v-1.5h3a1.5 1.5 0 0 1 1.5 1.5v1.5a1.5 1.5 0 0 1-1.5 1.5h-1.5v1.5Z"
        fill={props.fill || theme.colors.blue}
      />
    </svg>
  )
}
