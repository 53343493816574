import { call, put } from 'redux-saga/effects'

import {
  Api,
  GetOktaUrlResults,
  GetUserMeResults,
  RefreshTokenResults
} from '../../services'
import { AuthCreators, AuthTypes } from '../actions'

export function* LoginOkta(api: Api, action: AuthTypes.OktaLoginActionRequest) {
  const response: GetOktaUrlResults = yield call(
    api.auth.getUrlOkta,
    action.payload
  )

  if (response.kind !== 'ok') {
    yield put(
      AuthCreators.oktaLoginFailure({
        error: 'Failure auth to Okta. Try again.'
      })
    )
  } else {
    window.location.replace(response.data.oktaUrl)
  }
}

export function* RefreshToken(
  api: Api,
  action: AuthTypes.RefreshActionRequest
) {
  const response: RefreshTokenResults = yield call(
    api.auth.refreshToken,
    action.payload
  )

  if (response.kind === 'ok') {
    yield put(AuthCreators.refreshUserSuccess(response.data))

    api.setHeader('authorization', `JWT ${response.data.token}`)

    yield put(AuthCreators.getUserMeRequest())
  } else {
    switch (response.kind) {
      case 'bad-data':
      case 'unauthorized':
      case 'rejected':
        yield put(AuthCreators.dropRefreshToken())
        break
      default:
        yield put(
          AuthCreators.refreshUserFailure({
            error: 'Failure auth to Okta. Try again.'
          })
        )
        break
    }
  }
}

export function* Logout(api: Api) {
  yield api.setHeader('authorization', '')
}

export function* GetUserMe(api: Api, action: AuthTypes.GetUserMeActionRequest) {
  const response: GetUserMeResults = yield call(
    api.auth.getUserMe,
    action.payload
  )

  if (response.kind !== 'ok') {
    yield put(
      AuthCreators.getUserMeFailure({
        error: 'Failure getting user'
      })
    )
  } else {
    yield put(AuthCreators.getUserMeSuccess(response.user))
  }
}
