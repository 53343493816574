import { createReducer } from 'reduxsauce'

import { AuthTypes, AuthActionTypes } from '../../actions'
import { AuthState } from './auth.types'

/* ------------- Reducers ------------- */

const INITIAL_STATE: AuthState = {
  isLoaded: false,
  isAuthorizated: false,
  user: null
}

const logout = (state = INITIAL_STATE) => {
  return {
    ...state,
    ...INITIAL_STATE,
    token: undefined,
    isLoaded: true,
    isAuthorizated: false
  }
}

const dataRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoaded: false,
    isAuthorizated: false
  }
}

/***
 * Get Refresh Token
 */

const refreshTokenSuccess = (
  state = INITIAL_STATE,
  action: AuthTypes.RefreshActionSuccess
) => {
  if (action.payload) {
    return {
      ...state,
      token: action.payload.token,
      isAuthorizated: true,
      isLoaded: true
    }
  }
}

const refreshTokenFailure = (
  state = INITIAL_STATE,
  action: AuthTypes.RefreshActionFailure
) => {
  if (action.payload) {
    return {
      ...state,
      isAuthorizated: false,
      isLoaded: true
    }
  }

  return state
}

const dropRefreshToken = (state = INITIAL_STATE) => {
  return {
    ...state,
    isAuthorizated: false,
    isLoaded: true,
    token: undefined
  }
}

export const getUserMeSuccess = (
  state = INITIAL_STATE,
  action: AuthTypes.GetUserMeActionSuccess
) => {
  if (action.payload) {
    const user = action.payload

    return {
      ...state,
      user
    }
  }

  return state
}

/* ------------- Hookup Reducers To Types ------------- */

export const authReducer = createReducer(INITIAL_STATE, {
  [AuthActionTypes.OKTA_LOGIN_REQUEST]: dataRequest,
  [AuthActionTypes.REFRESH_USER_REQUEST]: dataRequest,
  [AuthActionTypes.REFRESH_USER_SUCCESS]: refreshTokenSuccess,
  [AuthActionTypes.REFRESH_USER_FAILURE]: refreshTokenFailure,
  [AuthActionTypes.GET_USER_ME_SUCCESS]: getUserMeSuccess,

  [AuthActionTypes.DROP_REFRESH_TOKEN]: dropRefreshToken,

  [AuthActionTypes.OKTA_LOGIN_FAILURE]: logout,
  [AuthActionTypes.LOGOUT]: logout
})
