import React, { FC, MouseEvent, useContext, useMemo } from 'react'

import {
  Column,
  concatClassNames,
  Row,
  Text,
  ValidationField
} from '../../components'
import { UserContext } from '../../providers'
import { FieldVerification, RPSResolution } from '../../services'

import { FieldsTableProps } from './fields-table.types'
import { useStyle } from './fields-table.styles'

export const FieldsTable: FC<FieldsTableProps> = ({
  activeField,
  isEditable = true,
  className,
  data,
  multiValue,
  onApprove,
  onReject,
  onFocusInput,
  onBlurInput,
  onActivate
}) => {
  const classes = useStyle()
  const { isSysAdmin } = useContext(UserContext)

  const fields = useMemo(() => data, [data])

  const getActive = useMemo(
    () => (id: FieldVerification['id']) => id === activeField,
    [activeField]
  )

  const handleOnChangeStatus = (status: RPSResolution, id: number) => () => {
    if (RPSResolution.APPROVED === status) {
      if (onApprove) {
        onApprove(id)
      }
    }

    if (RPSResolution.INELIGIBLE === status) {
      if (onReject) {
        onReject(id)
      }
    }
  }

  const handleOnFocusInput =
    (input: FieldVerification) =>
    (event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
      if (onFocusInput) {
        onFocusInput(input, event)
      }
    }

  const handleOnBlurInput = (input: FieldVerification) => () => {
    if (onBlurInput) {
      onBlurInput(input)
    }
  }

  return (
    <Column fullWidth className={concatClassNames(className)}>
      <Row className={classes.header} fullWidth justifyContent="space-between">
        <Row fullWidth justifyContent="flex-start">
          {multiValue && (
            <>
              <Text
                className={concatClassNames(classes.title, classes.valueTitle)}
                color="inactive"
                preset="subTitle"
                text="Reference"
              />
              <Text
                className={concatClassNames(classes.title, classes.valueTitle)}
                color="inactive"
                preset="subTitle"
                text="Recognize"
              />
            </>
          )}
        </Row>
        {isEditable && !isSysAdmin && (
          <Row className={classes.iconTitles} justifyContent="flex-start">
            <Text
              className={classes.title}
              color="inactive"
              preset="subTitle"
              text="Approve"
            />
            <Text
              className={classes.title}
              color="inactive"
              preset="subTitle"
              text="Rejected"
            />
          </Row>
        )}
        {!isEditable && (
          <Row className={classes.iconTitles}>
            <Text
              className={classes.title}
              color="inactive"
              preset="subTitle"
              text="Resolution"
            />
          </Row>
        )}
      </Row>
      <Column fullWidth className={classes.listContainer}>
        <Column fullWidth className={classes.list}>
          {fields.map((field) => {
            const active = getActive(field.id)

            return (
              <ValidationField
                key={`field_${field.id}`}
                isEditable={isEditable}
                id={field.id}
                active={active}
                className={classes.item}
                multiValue={multiValue}
                defaultRpsResolution={field.rpsResolution}
                description={field.description}
                label={field.title}
                prevValue={field.referenceValue}
                value={field.recognizedValue}
                onApprove={handleOnChangeStatus(
                  RPSResolution.APPROVED,
                  field.id
                )}
                onReject={handleOnChangeStatus(
                  RPSResolution.INELIGIBLE,
                  field.id
                )}
                onFocus={handleOnFocusInput(field)}
                onBlur={handleOnBlurInput(field)}
                onActivate={onActivate}
              />
            )
          })}
        </Column>
      </Column>
    </Column>
  )
}
