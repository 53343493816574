import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../theme'
import { SearchStyleProps } from './logs-search.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  searchInput: {
    border: `1px solid ${theme.colors.footerBackground}`,
    borderRadius: ({ open }: SearchStyleProps) => (open ? '12px 12px 0 0' : 12),
    '& > *': {
      backgroundColor: `${theme.colors.tableRow}!important`,
      '-webkit-box-shadow': `0 0 0px 1000px ${theme.colors.tableRow} inset`
    }
  },
  iconButton: {
    marginLeft: 8
  }
}))
