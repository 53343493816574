import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    padding: '0 16px',
    minHeight: 660
  },
  content: {
    padding: 0
  },
  box: {
    padding: '48px 0'
  },
  title: {
    paddingBottom: 4,
    borderBottom: `2px solid ${theme.colors.darkBlue}`
  }
}))
