import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { images } from '../../assets'
import { Button, Column, Spinner } from '../../components'
import { MainRoutes } from '../../routes'
import { AuthCreators, State } from '../../store'

import { getOriginUrl } from '../helpers'

import { useStyle } from './login.styles'

export const LoginPage = () => {
  const { isLoaded } = useSelector((state: State) => state.auth)
  const dispatch = useDispatch()
  const classes = useStyle()

  const handleOnSignIn = () => {
    const successUrl = getOriginUrl(MainRoutes.HOME)
    const cancelUrl = getOriginUrl(MainRoutes.HOME)

    const query = {
      successUrl,
      cancelUrl
    }
    dispatch(AuthCreators.oktaLoginRequest({ query }))
  }

  if (!isLoaded) {
    return (
      <Column fullWidth className={classes.container}>
        <Spinner />
      </Column>
    )
  }

  return (
    <Column fullWidth className={classes.container}>
      <Column className={classes.form}>
        <img className={classes.img} src={images.OktaLogo} />
        <Button
          className={classes.button}
          preset="accent"
          text="Login with okta"
          onClick={handleOnSignIn}
        />
      </Column>
    </Column>
  )
}
