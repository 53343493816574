import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../../theme'
import { SVGIconProps } from './icons.types'

export const EmptySearch: FC<SVGIconProps> = (props) => {
  const theme: LightTheme = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={80}
      height={80}
      viewBox="0 0 80 80"
      fill="none"
      {...props}
    >
      <circle cx="36" cy="36" r="36" fill={theme.colors.yellow} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.9068 54.0133H58.0135L71.3202 67.3466L67.3468 71.3199L54.0135 58.0133V55.9066L53.2935 55.1599C50.2535 57.7733 46.3068 59.3466 42.0135 59.3466C32.4402 59.3466 24.6802 51.5866 24.6802 42.0133C24.6802 32.4399 32.4402 24.6799 42.0135 24.6799C51.5868 24.6799 59.3468 32.4399 59.3468 42.0133C59.3468 46.3066 57.7735 50.2533 55.1602 53.2933L55.9068 54.0133ZM30.0135 42.0133C30.0135 48.6533 35.3735 54.0133 42.0135 54.0133C48.6535 54.0133 54.0135 48.6533 54.0135 42.0133C54.0135 35.3733 48.6535 30.0133 42.0135 30.0133C35.3735 30.0133 30.0135 35.3733 30.0135 42.0133Z"
        fill={props.fill || theme.colors.text}
      />
    </svg>
  )
}
