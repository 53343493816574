import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../../theme'
import { SVGIconProps } from './icons.types'

export const CheckCircle: FC<SVGIconProps> = (props) => {
  const theme: LightTheme = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M8 1.333c-3.667 0-6.667 3-6.667 6.667s3 6.667 6.667 6.667 6.667-3 6.667-6.667-3-6.667-6.667-6.667Zm2.8 5.534-3.2 3.2a.644.644 0 0 1-.933 0L5.2 8.6a.644.644 0 0 1 0-.933.644.644 0 0 1 .933 0l1 1 2.734-2.734a.644.644 0 0 1 .933 0 .644.644 0 0 1 0 .934Z"
        fill={props.fill || theme.colors.button}
      />
    </svg>
  )
}
