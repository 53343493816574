import React, {
  FC,
  MouseEventHandler,
  ReactEventHandler,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { useOutsideAlerter } from '@cse/ui'

import {
  getPoligonPositionByScroll,
  getRotateIndex,
  getScrollDot
} from '../../../pages'
import { PolygonField } from '../../../services'

import { Column } from '../../column'
import { Polygon } from '../../polygon'
import { concatClassNames } from '../../helpers'
import { getActiveField } from './helpers'

import {
  DocumentViewerContentProps,
  DocumentViewerContentStyleProps
} from './document-viewer-content.types'
import { useStyle } from './document-viewer-content.styles'

const DEFAULT_POSITION = { right: 0, top: 0, left: 0, x: 0, y: 0 }

export const DocumentViewerContent: FC<DocumentViewerContentProps> = ({
  activeField,
  className,
  data,
  listWidth = 543,
  listHeight = 543,
  options,
  onClickPolygon,
  onImageLoad
}) => {
  const imgRef = useRef<HTMLDivElement>(null)

  const [grab, changeGrab] =
    useState<DocumentViewerContentStyleProps['grab']>(false)
  const [position, changePosition] = useState(DEFAULT_POSITION)
  const [imgAspectRatio, changeImgAspectRatio] = useState(1)

  useEffect(() => {
    const { zoom, rotate } = options
    const currentActiveField = getActiveField({
      data,
      activeField
    })

    const element = imgRef.current
    const isActive = currentActiveField && element

    if (isActive) {
      const firstPoint = getScrollDot(currentActiveField.location, rotate)

      const polygonPosition = getPoligonPositionByScroll(
        element,
        firstPoint,
        zoom
      )

      if (firstPoint) {
        element.scrollTo({
          ...polygonPosition,
          behavior: 'smooth'
        })
      }
    }
  }, [activeField, data?.fields])

  const ImagePropeties = useMemo(() => {
    const { zoom, rotate, fitToPage } = options
    const rotateIndex = getRotateIndex(rotate)

    if (fitToPage) {
      if (rotateIndex === 3 || rotateIndex === 1) {
        if (imgAspectRatio > 1) {
          const height = listHeight * zoom
          const width = height * imgAspectRatio

          return {
            width,
            height
          }
        }
      }

      const height = listHeight * zoom
      const width = height * imgAspectRatio

      return {
        width,
        height
      }
    }

    if (rotateIndex === 3 || rotateIndex === 1) {
      if (imgAspectRatio > 1) {
        const height = listWidth * zoom
        const width = height * imgAspectRatio

        return {
          width,
          height
        }
      }
    }

    const width = listWidth * zoom
    const height = width / imgAspectRatio

    return {
      width,
      height
    }
  }, [options, imgAspectRatio, listHeight, listWidth])

  const TransformOrigin = useMemo(() => {
    const rotateIndex = getRotateIndex(options.rotate)

    switch (rotateIndex) {
      case 1:
        return `${50 / imgAspectRatio}% ${50}%`
      case 3:
        return `${50}% ${50 * imgAspectRatio}%`
      default:
        return 'center'
    }
  }, [options.rotate, imgAspectRatio])

  const classes = useStyle({
    grab,
    listWidth,
    listHeight,
    ImagePropeties,
    rotate: options.rotate,
    transformOrigin: TransformOrigin
  })

  const getActivePolygon = (id: PolygonField['id']) => activeField === id

  const handleOnClickPolygon =
    (field: PolygonField): MouseEventHandler =>
    (event) => {
      if (onClickPolygon) {
        onClickPolygon(field, event)
      }
    }

  const handleOnImageLoad: ReactEventHandler<HTMLImageElement> = (event) => {
    const { naturalHeight: height, naturalWidth: width } = event.currentTarget
    const nextImgAspectRatio = width / height

    changeImgAspectRatio(nextImgAspectRatio)

    if (onImageLoad) {
      onImageLoad(event)
    }
  }

  const handleOnMouseDown: MouseEventHandler<HTMLElement> = (event) => {
    event.preventDefault()

    changeGrab(true)

    if (imgRef.current) {
      const { scrollLeft: left, scrollTop: top } = imgRef.current
      const { clientX: x, clientY: y } = event

      const nextPosition = {
        right: 0,
        left,
        top,
        x,
        y
      }

      changePosition(nextPosition)
    }
  }

  const handleOnMouseMove: MouseEventHandler<HTMLElement> = (event) => {
    if (!grab) {
      return
    }

    const { clientX, clientY, currentTarget } = event
    const { x, y, top, left } = position
    const dx = clientX - x
    const dy = clientY - y

    if (Math.abs(dx) < 20 && Math.abs(dy) < 20) {
      return
    }

    event.preventDefault()
    event.stopPropagation()

    const nextTop = top - dy
    const nextLeft = left - dx

    currentTarget.scroll({
      top: nextTop,
      left: nextLeft,
      behavior: 'auto'
    })
  }

  const handleOnMouseUp = () => {
    changeGrab(false)
    changePosition(DEFAULT_POSITION)
  }

  useOutsideAlerter(imgRef, grab, handleOnMouseUp)

  return (
    <Column
      className={concatClassNames(className, classes.imgContainer)}
      justifyContent="flex-start"
      ref={imgRef}
      onMouseDown={handleOnMouseDown}
      onMouseMove={handleOnMouseMove}
      onMouseUp={handleOnMouseUp}
    >
      <Column className={classes.imgContent}>
        {data ? (
          <Column>
            {data.pageImage && (
              <img
                src={data.pageImage}
                height={ImagePropeties.height}
                width={ImagePropeties.width}
                onLoad={handleOnImageLoad}
              />
            )}
            {data.fields.map((field) =>
              field.location.map((blockItem) => (
                <Polygon
                  key={`${field.recognizedValue}_${field.id}`}
                  hint
                  active={getActivePolygon(field.id)}
                  rotate={options.rotate}
                  resolution={field.resolution}
                  zoom={options.zoom}
                  data={blockItem.polygon}
                  hintText={field.recognizedValue}
                  onClick={handleOnClickPolygon(field)}
                />
              ))
            )}
          </Column>
        ) : null}
      </Column>
    </Column>
  )
}
