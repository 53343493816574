import React, { useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Content, Column, Text } from '../../components'
import { SettingsCreators, State } from '../../store'
import { ProgramSettings } from '../../services'
import { EmailOnSubmissionProps } from './triggered-emails'
import { AppsAutoDeletion, AutoDeletionProps } from './apps-auto-deletion'

import { useStyle } from './settings.styles'

export type SettingItem = EmailOnSubmissionProps | AutoDeletionProps

export const SettingsPage = () => {
  const classes = useStyle()
  const dispatch = useDispatch()
  const { catalog, user, settings, isSettingsLoaded } = useSelector(
    (state: State) => ({
      ...state.auth,
      ...state.app,
      ...state.settings,
      catalog: state.app.catalog,
      isSettingsLoaded: state.settings.isLoaded
    })
  )
  const [programSettings, changeProgramSettings] = useState<
    ProgramSettings[] | null
  >(settings)

  const getChangesById = useCallback((data: ProgramSettings[], id: number) => {
    return data.find((item) => item.id === id)
  }, [])

  const handleOnSave = useCallback(
    (data: ProgramSettings[]) => {
      if (programSettings) {
        const updates: ProgramSettings[] = programSettings?.map((item) => ({
          ...item,
          ...getChangesById(data, item.id)
        }))

        changeProgramSettings(updates)
        dispatch(
          SettingsCreators.postSettingsRequest({
            params: updates
          })
        )
      }
    },
    [programSettings, settings]
  )

  useEffect(() => {
    dispatch(SettingsCreators.getSettingsRequest({}))
  }, [])

  return (
    <Column fullWidth className={classes.container} justifyContent="flex-start">
      <Content className={classes.content} preset="settings">
        <Column className={classes.box}>
          <Text
            className={classes.title}
            text="Settins"
            tx="header.settings"
            preset="h1"
            color="darkBlue"
          />
        </Column>
      </Content>
      <AppsAutoDeletion
        user={user}
        catalog={catalog}
        settings={settings}
        isLoaded={isSettingsLoaded}
        onSave={handleOnSave}
      />
    </Column>
  )
}
