import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    background: theme.colors.white,
    padding: '24px 0px'
  },
  row: {
    marginTop: 22
  },
  badge: {
    marginLeft: 10
  },
  text: {
    marginLeft: 8
  },
  info: {
    marginLeft: 56
  },
  noPadding: {
    padding: 0
  },
  description: {
    textAlign: 'center'
  },
  approve: {
    marginLeft: 16
  },
  reject: {
    marginLeft: 12
  },
  deleteApp: {
    margin: 'auto 0'
  }
}))
