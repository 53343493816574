import React, { FC, MouseEvent, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

import { Content } from '../content'
import { Row } from '../row'
import { CategoryNavItem } from '../tab-nav-item'
import { Warnings } from './warnings'

import { TabsNavProps } from './tabs-nav.types'
import { useStyle } from './tabs-nav.styles'

export const TabsNav: FC<TabsNavProps> = ({
  className = '',
  data,
  param = 'tab',
  onClick
}) => {
  const defaultSearchParams = {
    [param]: data[0]?.link || ''
  }

  const [tabsParams, changeTabsParams] = useSearchParams(defaultSearchParams)

  const classes = useStyle()

  const TabContent = useMemo(() => {
    const active = data.find(
      (navElement) => navElement.link === tabsParams.get(param)
    )

    if (active) {
      return active.content
    }

    return <></>
  }, [Array.from(tabsParams.values())])

  const getActive = (tab: string) => tabsParams.get(param) === tab

  const handleOnClick =
    (tab: string) =>
    (event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
      changeTabsParams({ [param]: tab })

      if (onClick) {
        onClick(Number(tab), event)
      }
    }

  return (
    <>
      <Row fullWidth className={`${className} ${classes.container}`}>
        <Content className={classes.nav}>
          {data.map((navItem) => (
            <CategoryNavItem
              key={`navigation_path_${navItem.link}`}
              active={getActive(navItem.link)}
              className={classes.item}
              icon={navItem.icon}
              text={navItem.text}
              tx={navItem.tx}
              warnings={navItem.warnings}
              hasRecognitionUpdate={navItem.hasRecognitionUpdate}
              hasDocumentUpdate={navItem.hasDocumentUpdate}
              hasOtherVersions={navItem.hasOtherVersions}
              onClick={handleOnClick(navItem.link)}
            />
          ))}
        </Content>
      </Row>
      <Warnings data={data} getActive={getActive} />
      {TabContent}
    </>
  )
}
