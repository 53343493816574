import React, { FC, useMemo, useRef } from 'react'

import {
  arrayHasElements,
  Column,
  LoadingContainer,
  EmptyList
} from '../../../components'
import { SortTypes } from '../../../services'

import { ItemsList } from './items-list'
import { LogsListHeader } from './logs-list-header'

import { ColumnKeys } from '../logs.types'
import { LogsListProps } from './logs-list.types'
import { useStyle } from './logs-list.styles'

export const LogsList: FC<LogsListProps> = ({
  catalog,
  className,
  current,
  data,
  isLoadedMore,
  isLoaded,
  next,
  search,
  onClick,
  onLoadMore,
  onSort
}) => {
  const classes = useStyle()
  const ref = useRef<HTMLDivElement>(null)

  const handleOnSort = (id: ColumnKeys, sortBy: SortTypes) => {
    if (onSort) {
      if (sortBy === SortTypes.DESC) {
        onSort(`-${id}`)
      } else {
        onSort(id)
      }
    }
  }

  const HasElement = useMemo(() => arrayHasElements(data), [data])

  return (
    <Column fullWidth justifyContent="flex-start">
      <Column
        className={`${className} ${classes.container}`}
        justifyContent="flex-start"
      >
        <Column ref={ref} fullWidth justifyContent="flex-start">
          <LogsListHeader onSort={handleOnSort} />
          <LoadingContainer className={classes.spinner} loading={!isLoaded}>
            {HasElement ? (
              <ItemsList
                isLoaded={isLoadedMore}
                data={data}
                catalog={catalog}
                current={current}
                next={next}
                onClick={onClick}
                onLoadMore={onLoadMore}
              />
            ) : (
              <EmptyList search={search} />
            )}
          </LoadingContainer>
        </Column>
      </Column>
    </Column>
  )
}
