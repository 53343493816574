export interface GetCurrentPartProps {
  count: number
  current: number
  limit?: number
}

export enum ColumnKeys {
  PROGRAM = 'program',
  APP_ID = 'title',
  APPLICATION_DATE = 'application_date',
  STATUS = 'resolution',
  IQR_RUN_DATE = 'last_recognition_time',
  RPS = 'rps_reviewer',
  RPS_REWIEW_DATE = 'rps_review_date',
  APP_RESOLUTION = 'rps_resolution'
}

export enum SortTypes {
  ASC = 'asc',
  DESC = 'desc'
}
