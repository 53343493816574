import { createActions, createTypes } from 'reduxsauce'

import { SettingsActions, SettingsActionsTypes } from './settings.types'

export const settingsStringTypes = createTypes<SettingsActionsTypes>(`
    GET_SETTINGS_REQUEST
    GET_SETTINGS_SUCCESS
    GET_SETTINGS_FAILURE

    POST_SETTINGS_REQUEST
    POST_SETTINGS_SUCCESS
    POST_SETTINGS_FAILURE
`)

const { Types: SettingsActionTypes, Creators: SettingsCreators } =
  createActions<SettingsActionsTypes, SettingsActions>({
    getSettingsRequest: ['payload'],
    getSettingsSuccess: ['payload'],
    getSettingsFailure: ['payload'],

    postSettingsRequest: ['payload'],
    postSettingsSuccess: ['payload'],
    postSettingsFailure: ['payload']
  })

export { SettingsCreators, SettingsActionTypes }
