import React, { FC, useEffect, useRef, useState } from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../theme'
import { Icon } from '../icon'
import { Row } from '../row'
import { Column } from '../column'
import { Text } from '../text'
import { PopoverHint, PopoverHintPosition } from '../popover-hint'

import { IconButtonProps } from './icon-button.types'
import { useStyle } from './icon-button.styles'

export const IconButton: FC<IconButtonProps> = ({
  className = '',
  preset = 'success',
  name,
  fill: fillColor,
  disabled = false,
  text = '',
  hint,
  isMessage = false,
  message = '',
  messageTx,
  onClick
}) => {
  const theme: LightTheme = useTheme()
  const [hover, changeHover] = useState(false)
  const classes = useStyle({ hover })
  const { [preset]: modifier } = classes
  const containerClass = disabled ? classes.disabled : classes.hover
  const fill = fillColor ? theme.colors[fillColor] : undefined
  const buttonRef = useRef<HTMLDivElement>(null)
  const [buttonWidth, changeButtonWidth] = useState<number>(0)

  const handleOnHoverState = (state: boolean) => () => {
    changeHover(state)
  }

  const handleOnClick = (event: any) => {
    if (disabled) {
      return
    }

    if (onClick) {
      onClick(event)
    }
  }

  useEffect(() => {
    if (buttonRef.current) {
      const { width } = buttonRef.current.getBoundingClientRect()
      changeButtonWidth(width)
    }
  }, [buttonRef])

  return (
    <>
      <Row>
        <Row
          ref={buttonRef}
          className={`${modifier} ${containerClass} ${classes.container} ${className}`}
          onMouseOver={handleOnHoverState(true)}
          onMouseOut={handleOnHoverState(false)}
          onClick={handleOnClick}
        >
          <Icon name={name} width={24} height={24} fill={fill} />
        </Row>
        {hint && (
          <Row fullWidth className={classes.hint}>
            <Column className={classes.hintContainer}>
              <Text text={text} preset="subTitle" color="inactive" />
            </Column>
          </Row>
        )}
      </Row>
      {isMessage && (
        <Row className={classes.messageHint}>
          <PopoverHint
            open={hover}
            className={classes.messageContainer}
            hintPosition={PopoverHintPosition.TOP_CENTER}
            buttonWidth={buttonWidth}
          >
            <Text
              className={classes.message}
              text={message}
              tx={messageTx}
              preset="subTitle"
              color="inactive"
            />
          </PopoverHint>
        </Row>
      )}
    </>
  )
}
