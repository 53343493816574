import React, { FC } from 'react'
import { useTheme } from 'react-jss'
import { LightTheme } from '../theme'
import { IconProps } from './types'

export const ExpandAll: FC<IconProps> = (props) => {
  const theme: LightTheme = useTheme()
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M7 11L12 6L17 11H7Z"
        fill={props.fill || theme.colors.darkBlue}
      />
      <path
        d="M17 13L12 18L7 13H17Z"
        fill={props.fill || theme.colors.darkBlue}
      />
      <rect
        x="4"
        y="20"
        width="16"
        height="2"
        fill={props.fill || theme.colors.darkBlue}
      />
      <rect
        x="4"
        y="2"
        width="16"
        height="2"
        fill={props.fill || theme.colors.darkBlue}
      />
    </svg>
  )
}
