import { createUseStyles } from 'react-jss'

import { IconProps } from './types'

export const useStyle = createUseStyles({
  icon: {
    '&:hover > path': {
      fill: ({ fill, onClick }: IconProps) => onClick && `${fill}a2`
    }
  }
})
