import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  breadcrumbs: {
    cursor: 'pointer',
    '& > *': {
      cursor: 'pointer'
    }
  }
})
