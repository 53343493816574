import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  content: {
    marginTop: 8
  },
  title: {
    marginLeft: 16
  },
  item: {
    padding: '4px 16px',
    width: 'calc(100% - 32px)'
  }
})
