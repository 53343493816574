import React, { FC } from 'react'

import { Row } from '../../row'
import { Column } from '../../column'
import { Text } from '../../text'
import { Icon } from '../../icon'
import { concatClassNames } from '../../helpers'

import { WarningsProps } from './warnings.types'
import { useStyle } from './warnings.styles'

export const Warnings: FC<WarningsProps> = ({ data, getActive }) => {
  const classes = useStyle()

  return (
    <Row className={classes.container}>
      {data.map(
        ({ link, warnings, hasDocumentUpdate, hasRecognitionUpdate }) => {
          const isWarn = getActive(link) && Boolean(warnings.length)
          const isUpdated = getActive(link) && hasDocumentUpdate
          const isRecognized =
            getActive(link) && !hasDocumentUpdate && hasRecognitionUpdate

          return (
            <Column key={`warnigs_${link}`} className={classes.warnings}>
              {isWarn && (
                <Row
                  key={`document_${link}_warning`}
                  className={concatClassNames(classes.warn, classes.warning)}
                >
                  <Icon name="ExclamationTriangle" />
                  <Text text={warnings[0]} preset="subTitle" />
                </Row>
              )}
              {isUpdated && (
                <Row
                  key={`document_${link}_updated`}
                  className={concatClassNames(classes.updated, classes.warning)}
                >
                  <Icon name="NewReleasesFilled" />
                  <Text
                    tx="warnings.updated"
                    text="A new document uploaded"
                    preset="subTitle"
                  />
                </Row>
              )}
              {isRecognized && (
                <Row
                  key={`document_${link}_recognized`}
                  className={concatClassNames(
                    classes.recognized,
                    classes.warning
                  )}
                >
                  <Icon name="NotificationImportant" />
                  <Text
                    tx="warnings.recognized"
                    text="The recognized and validated field statuses have been updated"
                    preset="subTitle"
                  />
                </Row>
              )}
            </Column>
          )
        }
      )}
    </Row>
  )
}
