import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'
import { TabsNavItemStyle } from './tabs-nav-item.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    padding: '14px 8px',
    borderBottom: ({ active }: TabsNavItemStyle) =>
      active ? `2px solid ${theme.colors.blue}` : '2px solid transparent',
    transform: 'translateY(2px)',
    cursor: 'pointer',
    '&:hover > span': {
      color: theme.colors.blue
    }
  },
  iconContainer: {
    paddingLeft: 0
  },
  text: {
    marginLeft: 12
  }
}))
