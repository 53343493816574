import { ApisauceInstance } from 'apisauce'
import camelcaseKeys from 'camelcase-keys'

import { decamelizeObject } from '../../utils'

import { getGeneralApiProblem } from '../api-problem'

import {
  GetValidationProgramsResponse,
  GetValidationProgramsResults,
  GetValidationRuleDocumentTypesRequest,
  GetValidationRuleDocumentTypesResponse,
  GetValidationRuleDocumentTypesResults,
  GetValidationRuleListRequest,
  GetValidationRuleListResponse,
  GetValidationRuleListResults,
  ValidationRulePath
} from './validation-rules.types'

// our "constructor"
export const validationRuleService = (api: ApisauceInstance) => {
  const getValidationPrograms =
    async (): Promise<GetValidationProgramsResults> => {
      const response = await api.get<GetValidationProgramsResponse>(
        ValidationRulePath.PROGRAM_RULES
      )

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) {
          return problem
        }
      }
      try {
        if (response.data) {
          const data = camelcaseKeys(response.data, {
            deep: true
          })

          return { kind: 'ok', data }
        }

        return { kind: 'bad-data' }
      } catch {
        return { kind: 'bad-data' }
      }
    }

  /**
   * Get list of validation rules from api service
   * @param query - query parameters to filter and work with pagination of validation rules
   */
  const list = async ({
    query
  }: GetValidationRuleListRequest): Promise<GetValidationRuleListResults> => {
    const queryDecamelize = decamelizeObject(query)

    const response = await api.get<GetValidationRuleListResponse>(
      ValidationRulePath.LIST,
      queryDecamelize
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) {
        return problem
      }
    }
    try {
      if (response.data) {
        const data = camelcaseKeys(response.data, {
          deep: true
        })

        return { kind: 'ok', data }
      }

      return { kind: 'bad-data' }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  /**
   * Get document types list of validation rules from api service
   * @param query - query parameters to filter and indified document types by
   */

  const documentTypes = async ({
    query
  }: GetValidationRuleDocumentTypesRequest): Promise<GetValidationRuleDocumentTypesResults> => {
    const queryDecamelize = decamelizeObject(query)

    const response = await api.get<GetValidationRuleDocumentTypesResponse>(
      ValidationRulePath.DOCUMENT_TYPES,
      queryDecamelize
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) {
        return problem
      }
    }
    try {
      if (response.data) {
        const data = camelcaseKeys(response.data, {
          deep: true
        })

        return { kind: 'ok', data: { ...data, rulePk: query.rulePk } }
      }

      return { kind: 'bad-data' }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  return {
    getValidationPrograms,
    list,
    documentTypes
  }
}
