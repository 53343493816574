import { DropdownItemData } from '@cse/ui'
import { TextTranslate } from '../../../components'
import { RejectionReason } from '../../../services'

export const setItemById = <T extends { id: number }>(
  item: T,
  id: number,
  update: T
) => {
  if (item.id === id) {
    return update
  }

  return item
}

export const getRejectionReasonList = (list: RejectionReason[]) =>
  list.map((item: RejectionReason) => {
    return {
      id: String(item.id),
      value: String(item.reasonCode),
      name: item.title
    } as DropdownItemData<TextTranslate>
  })
