import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  field: {
    '&:not(:first-child)': {
      marginLeft: 16,
      width: 172
    }
  },
  dropdown: {
    width: 178
  },
  rightPannel: {
    marginLeft: 24,
    borderLeft: `1px solid ${theme.colors.secondButton}`,
    paddingLeft: 24
  },
  actions: {
    marginLeft: 16,
    '& > *:not(:first-child)': {
      marginLeft: 16
    }
  },
  button: {
    width: '64px!important'
  },
  program: {
    maxWidth: 134,
    minWidth: 130
  },
  docs: {
    maxWidth: 218,
    minWidth: 216
  },
  docTypes: {
    maxWidth: 342,
    minWidth: 340
  }
}))
