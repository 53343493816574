import React from 'react'

import { concatClassNames } from '../helpers'

import { LinkItemProps } from './link-item.types'
import { useStyle } from './link-item.styles'

export const LinkItem = ({
  className = '',
  rel = '',
  href,
  target = '',
  children
}: LinkItemProps) => {
  const classes = useStyle()

  return (
    <a
      className={concatClassNames(className, classes.link)}
      rel={rel}
      href={href}
      target={target}
    >
      {children}
    </a>
  )
}
