import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  docsList: {
    marginTop: 16,
    padding: '24px 8px 108px 8px',
    maxHeight: 600,
    '&::-webkit-scrollbar': {
      width: 0
    }
  }
})
