import React, { FC } from 'react'
import { useTheme } from 'react-jss'
import { LightTheme } from '../theme'
import { IconProps } from './types'

export const Phase2: FC<IconProps> = (props) => {
  const theme: LightTheme = useTheme()
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <circle
        opacity="0.2"
        cx="16"
        cy="16"
        r="16"
        fill={props.fill || theme.colors.purple}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 7H9C7.9 7 7 7.9 7 9V23C7 24.1 7.9 25 9 25H23C24.1 25 25 24.1 25 23V9C25 7.9 24.1 7 23 7ZM23 23H9V9H23V23ZM15 19H19V21H13V17C13 15.89 13.9 15 15 15H17V13H13V11H17C18.1 11 19 11.89 19 13V15C19 16.11 18.1 17 17 17H15V19Z"
        fill={props.fill || theme.colors.purple}
      />
    </svg>
  )
}
