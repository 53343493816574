import React from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../../theme'
import { Application } from '../../../services'
import { Column, Spinner } from '../../../components'
import { DocList } from '../docs-list'

import { ListViewProps } from './list-view.types'
import { useStyle } from './list-view.styles'

export const ListView = ({
  data,
  count,
  orderBy,
  current,
  part,
  onClickItem
}: ListViewProps) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })
  const isData = data ? Object.keys(data).length : false

  if (!isData) {
    return (
      <Column fullFilledHeight>
        <Spinner />
      </Column>
    )
  }

  const list = data as Application

  return (
    <DocList
      key={count}
      className={classes.docsList}
      data={list}
      orderBy={orderBy}
      current={current}
      valuesAmount={{
        count,
        part
      }}
      onClickItem={onClickItem}
    />
  )
}
