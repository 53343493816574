import { createUseStyles } from 'react-jss'

import { images } from '../../assets'
import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    backgroundImage: `url(${images.Background})`,
    backgroundSize: 'cover',
    flex: 1
  },
  form: {
    background: theme.colors.white,
    padding: '40px 0px',
    width: 480,
    borderRadius: 12
  },
  img: {
    width: 230,
    minHeight: 90
  },
  button: {
    marginTop: 42
  }
}))
