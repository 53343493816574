import { createUseStyles } from 'react-jss'

import { LightTheme } from '../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    minHeight: '100vh',
    background: theme.colors.lightBackground,
    overflowX: 'hidden',
    overflowY: 'hidden',
    scrollbarWidth: 'none',
    '-ms-overflow-style': 'none'
  },
  contentContainer: {
    flex: 1,
    justifyContent: 'stretch'
  }
}))
