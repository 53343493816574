import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../../theme'
import { ItemsListStyleProps } from './items-list.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  list: {
    background: theme.colors.white,
    borderRadius: '0 0 12px 12px',
    boxShadow: '4px 8px 12px rgba(0, 0, 0, 0.06)'
  },
  outerContainer: {
    height: ({ height }: ItemsListStyleProps) => height,
    borderRadius: '0 0 12px 12px',
    overflowY: 'scroll'
  },
  itemsList: {
    position: 'absolute',
    top: 0,
    borderRadius: '0 0 12px 12px',
    '& >:last-child': {
      borderBottom: 'none'
    }
  },
  loader: {
    height: 120,
    alignItems: 'baseline',
    marginTop: -28,
    width: '100%',
    background: theme.colors.white,
    zIndex: 3,
    '& > *': {
      marginTop: -18
    }
  },
  goUp: {
    position: 'absolute',
    bottom: 16,
    right: 16,
    borderRadius: '50%',
    transform: 'rotate(-90deg)',
    boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
    zIndex: 3
  }
}))
