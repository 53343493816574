import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../../theme'
import { SVGIconProps } from './icons.types'

export const ExclamationCircle: FC<SVGIconProps> = (props) => {
  const theme: LightTheme = useTheme()
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00016 1.33325C4.3335 1.33325 1.3335 4.33325 1.3335 7.99992C1.3335 11.6666 4.3335 14.6666 8.00016 14.6666C11.6668 14.6666 14.6668 11.6666 14.6668 7.99992C14.6668 4.33325 11.6668 1.33325 8.00016 1.33325ZM8.00016 11.3333C7.60016 11.3333 7.3335 11.0666 7.3335 10.6666C7.3335 10.2666 7.60016 9.99992 8.00016 9.99992C8.40016 9.99992 8.66683 10.2666 8.66683 10.6666C8.66683 11.0666 8.40016 11.3333 8.00016 11.3333ZM8.66683 7.99992C8.66683 8.39992 8.40016 8.66659 8.00016 8.66659C7.60016 8.66659 7.3335 8.39992 7.3335 7.99992V5.33325C7.3335 4.93325 7.60016 4.66659 8.00016 4.66659C8.40016 4.66659 8.66683 4.93325 8.66683 5.33325V7.99992Z"
        fill={props.fill || theme.colors.orange}
      />
    </svg>
  )
}
