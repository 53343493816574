import React, { useMemo } from 'react'

import { CoreItemElement } from '../../../../store'
import { SettingsProps } from './types'

export const useSettings = ({
  id,
  versionToCompare,
  versions,
  docType,
  hasOtherVersions
}: SettingsProps) => {
  const currentVersion = versions?.find(
    (el: CoreItemElement) => String(el.id) === id
  )
  const compareVersion = versions?.find(
    (el: CoreItemElement) => Number(el.id) === versionToCompare
  )
  const filteredVersions = versions?.filter(
    (el: CoreItemElement) => String(el.id) !== id
  )
  const textValues = {
    docType: <>{docType}</>
  }
  const isFewVersions = useMemo(
    () => hasOtherVersions,
    [versions, hasOtherVersions]
  )

  return {
    currentVersion: currentVersion?.value || '',
    compareVersion: compareVersion?.value || '',
    filteredVersions,
    textValues,
    isFewVersions
  }
}
