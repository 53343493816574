import { ValidationProgram } from '../../services'
import { ValidationRuleState } from '../../store'

export const getProgramState = (
  id: number,
  validationRule: ValidationRuleState
): string => {
  const { programs } = validationRule
  if (programs.length && id) {
    const program = programs.find(
      (item: ValidationProgram) => item.id === Number(id)
    )

    if (program) {
      return program.state
    }
  }
  return ''
}

export const getProgramUpdateDt = (
  id: number,
  validationRule: ValidationRuleState
): Date => {
  const { programs } = validationRule
  if (programs.length && id) {
    const program = programs.find(
      (item: ValidationProgram) => item.id === Number(id)
    )

    if (program) {
      return program.validationUpdateDt
    }
  }
  return new Date()
}
