import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../../theme'
import { SearchStyleProps } from '../logs-search.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  item: {
    padding: '8px 15px',
    width: 'calc(100% - 30px)',
    '&:hover': {
      backgroundColor: theme.colors.tableRow,
      cursor: 'pointer'
    }
  },
  listContainer: {
    position: 'absolute',
    top: 20,
    right: 48,
    width: ({ open }: SearchStyleProps) => (open ? 210 : 0),
    height: ({ open, height }: SearchStyleProps) => (open ? height : 0),
    overflowY: 'auto',
    overflowX: 'hidden',
    backgroundColor: theme.colors.white,
    borderRadius: '0 0 12px 12px',
    zIndex: 4,
    boxShadow: '2px 4px 83px rgba(0, 0, 0, 0.08)',
    '&::-webkit-scrollbar': {
      width: 4,
      borderRadius: 4
    },
    borderTop: 'transparent',
    border: `1px solid ${theme.colors.footerBackground}`
  },
  previewList: {
    position: 'absolute',
    top: 0,
    zIndex: 1,
    overflow: 'hidden'
  }
}))
