import React, { FC, useMemo, useState } from 'react'
import { useTheme } from 'react-jss'

import {
  Button,
  Icon,
  ResolutionStatusBadge,
  Row,
  Text
} from '../../components'
import { RPSResolution } from '../../services'
import { LightTheme } from '../../theme'
import {
  ResolutionModalTypes,
  RpsResolutionModal,
  RpsResolutionModalValue
} from '../rps-resolution-modal'

import { DocResolutionPickerProps } from './doc-resolution-picker.types'
import { useStyle } from './doc-resolution-picker.styles'

export const DocResolutionPicker: FC<DocResolutionPickerProps> = ({
  isChangeAllowed,
  date,
  defaultValue: outerDefaultValue,
  onClose,
  onSubmit
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle()

  const [openModal, changeOpenModal] = useState(false)

  const defaultValue = useMemo(
    () =>
      outerDefaultValue || {
        documentId: 0,
        resolution: RPSResolution.IN_PROGRESS,
        rejectionReason: null
      },
    [outerDefaultValue]
  )
  const ResolutionDate = useMemo(() => date || new Date(), [date])

  const handleOnOpenModal = (status: boolean) => () => {
    changeOpenModal(status)

    if (!status && onClose) {
      onClose()
    }
  }

  const handleOnSubmit = (value: RpsResolutionModalValue) => {
    changeOpenModal(false)

    if (onSubmit) {
      onSubmit(value)
    }
  }

  const badge = (
    <Row>
      <ResolutionStatusBadge
        date={ResolutionDate}
        rpsResolution={defaultValue.resolution}
      />
      {isChangeAllowed && (
        <Row className={classes.row} onClick={handleOnOpenModal(true)}>
          <Icon fill={theme.colors.blue} name="Edit" />
          <Text className={classes.text} color="blue" text="Change" />
        </Row>
      )}
    </Row>
  )

  const button = (
    <Button
      className={classes.button}
      disabled={!isChangeAllowed}
      isHint={!isChangeAllowed}
      message="This button is disabled because the current version is view-only."
      messageTx="rpsResolution.hint"
      onClick={handleOnOpenModal(true)}
    >
      <Text
        text="Doc Resolution"
        tx="document.docResolution.button"
        preset="body"
        color="white"
      />
    </Button>
  )

  return (
    <>
      {date ? badge : button}

      <RpsResolutionModal
        open={openModal}
        defaultValue={defaultValue}
        modal={ResolutionModalTypes.DOCUMENT}
        title="Document Resolution"
        titleTx="document.docResolution.modal.title"
        description="Please choose the resolution for a document."
        descriptionTx="document.docResolution.modal.description"
        onSubmit={handleOnSubmit}
        onClose={handleOnOpenModal(false)}
      />
    </>
  )
}
