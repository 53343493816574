import React, { FC, useMemo } from 'react'
import { getColorByStatusVerification, getPolygonPosition } from '../helpers'

import { Row } from '../row'
import { Text } from '../text'

import { useStyle } from './polygon.styles'
import { PolygonProps } from './polygon.types'

export const Polygon: FC<PolygonProps> = ({
  active,
  data,
  resolution,
  hint,
  hintText,
  hintTx,
  children,
  rotate,
  zoom = 1,
  onClick
}) => {
  const PositionCalculate = useMemo(() => getPolygonPosition(data), [data])

  const Position = useMemo(() => {
    const { top, left, topEnd, leftEnd } = PositionCalculate

    const right = 1 - leftEnd
    const bottom = 1 - topEnd

    return {
      top,
      left,
      right,
      bottom
    }
  }, [PositionCalculate])

  const Color = useMemo(
    () => getColorByStatusVerification(resolution),
    [resolution]
  )

  const classes = useStyle({ ...Position, zoom, rotate, active, color: Color })

  return (
    <Row className={classes.container}>
      {children}
      <Row className={classes.polygon} onClick={onClick}></Row>
      {hint && hintText && (
        <Text
          className={classes.hint}
          color="black"
          text={hintText}
          tx={hintTx}
        />
      )}
    </Row>
  )
}
