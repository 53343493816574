import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../../theme'

import { SVGIconProps } from './icons.types'

export const FitToPage: FC<SVGIconProps> = (props) => {
  const theme: LightTheme = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 3L3 3C1.9 3 1 3.9 1 5L1 19C1 20.1 1.9 21 3 21L21 21C22.1 21 23 20.1 23 19L23 5C23 3.9 22.1 3 21 3ZM10 9H7V12H5V7L10 7V9ZM17 12H19V17H14V15H17V12ZM3 19.01L21 19.01L21 4.99L3 4.99L3 19.01Z"
        fill={props.fill || theme.colors.darkBlue}
      />
    </svg>
  )
}
