import { useMemo } from 'react'

import { CoreItem } from '../../../store'
import { DEFAULT_FILTERS } from '../program'

import { Filters } from '../program.types'
import { DataProps } from './program-header.types'

export const getIsFilterSetted = (values: Filters) => {
  const filterKeys = Object.keys(DEFAULT_FILTERS) as Array<keyof Filters>
  return filterKeys.reduce(
    (prev, key): any => Boolean(values[key]) || prev,
    false
  )
}

export const useData = ({ filteredCatalog, values }: DataProps) => {
  const Documents = useMemo(
    () =>
      filteredCatalog.document.map((coreItem: CoreItem) => ({
        ...coreItem,
        value: coreItem.name
      })),
    [filteredCatalog.document]
  )
  const DocumentsTypes = useMemo(
    () =>
      filteredCatalog.documentTypes.map((coreItem: CoreItem) => ({
        ...coreItem,
        value: coreItem.name
      })),
    [filteredCatalog.documentTypes]
  )
  const Fields = useMemo(
    () =>
      filteredCatalog.fields.map((coreItem: CoreItem) => ({
        ...coreItem,
        value: coreItem.name
      })),
    [filteredCatalog.fields]
  )
  const IsFilterSetted = useMemo(() => getIsFilterSetted(values), [values])

  return {
    Documents,
    DocumentsTypes,
    Fields,
    isFilter: IsFilterSetted
  }
}
