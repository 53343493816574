import { createReducer } from 'reduxsauce'
import { List } from '../../../services'

import { LogsActionTypes, LogsTypes } from '../../actions'

import { LogsState } from './logs.types'

const defaultList: List<never> = {
  count: 0,
  previous: null,
  next: null,
  results: []
}

/* ------------- Reducers ------------- */

const INITIAL_STATE: LogsState = {
  current: 0,
  activityLogList: defaultList,
  errorLogList: defaultList,
  activityLog: null,
  errorLog: null,
  activityLogListLoaded: false,
  activityLogListLoadedMore: true,
  errorLogListLoaded: false,
  errorLogListLoadedMore: true,
  isSearchLoaded: true,
  searchList: []
}

export const getBasicActivityLogState = (state = INITIAL_STATE) => ({
  ...state,
  activityLog: null
})

export const getBasicErrorLogState = (state = INITIAL_STATE) => ({
  ...state,
  errorLog: null
})

/***
 * Get Activity log list
 */

const getActivityLogListRequest = (
  state = INITIAL_STATE,
  action: LogsTypes.GetActivityLogListActionRequest
) => {
  if (action.payload.query?.offset) {
    return {
      ...state,
      activityLogListLoadedMore: false
    }
  }

  return {
    ...state,
    activityLogList: defaultList,
    activityLogListLoaded: false,
    searchList: []
  }
}

const getActivityLogListSuccess = (
  state = INITIAL_STATE,
  action: LogsTypes.GetActivityLogListActionSuccess
) => {
  const { results, count } = action.payload

  if (action.payload.reset) {
    return {
      ...state,
      current: count,
      activityLogList: action.payload,
      activityLogListLoadedMore: true,
      activityLogListLoaded: true
    }
  }

  const result = [...state.activityLogList.results, ...results]
  const updatedList = {
    ...action.payload,
    results: result,
    count: result.length
  }
  const current = result.length

  if (action.payload) {
    return {
      ...state,
      current,
      activityLogList: updatedList,
      activityLogListLoadedMore: true,
      activityLogListLoaded: true
    }
  }

  return {
    ...state,
    current: 0,
    activityLogList: defaultList,
    activityLogListLoadedMore: true,
    activityLogListLoaded: true
  }
}

const getActivityLogListFailure = (state = INITIAL_STATE) => {
  return {
    ...state,
    activityLogListLoadedMore: true,
    activityLogListLoaded: true
  }
}

/***
 * Get Activity log By Id
 */

const getActivityLogByIdRequest = getBasicActivityLogState

const getActivityLogByIdSuccess = (
  state = INITIAL_STATE,
  action: LogsTypes.GetActivityLogByIdActionSuccess
) => {
  return {
    ...state,
    activityLog: action.payload
  }
}

const getActivityLogByIdFailure = getBasicActivityLogState

/***
 * Get errors log list
 */

const getErrorLogListRequest = (
  state = INITIAL_STATE,
  action: LogsTypes.GetErrorLogListActionRequest
) => {
  if (action.payload.query?.offset) {
    return {
      ...state,
      errorLogListLoadedMore: false
    }
  }

  return {
    ...state,
    errorLogList: defaultList,
    errorLogListLoaded: false
  }
}

const getErrorLogListSuccess = (
  state = INITIAL_STATE,
  action: LogsTypes.GetErrorLogListActionSuccess
) => {
  const current = [...state.errorLogList.results, ...action.payload.results]
    .length

  return {
    ...state,
    current,
    errorLogList: action.payload,
    errorLogListLoadedMore: true,
    errorLogListLoaded: true
  }
}

const getErrorLogListFailure = (state = INITIAL_STATE) => {
  return {
    ...state,
    errorLogListLoadedMore: true,
    errorLogListLoaded: true
  }
}

/***
 * Get Error log By Id
 */

const getErrorLogByIdRequest = getBasicErrorLogState

const getErrorLogByIdSuccess = (
  state = INITIAL_STATE,
  action: LogsTypes.GetErrorLogByIdActionSuccess
) => {
  return {
    ...state,
    errorLog: action.payload
  }
}

const getErrorLogByIdFailure = getBasicErrorLogState

/***
 * Get Search Preview
 */

export const getSearchActivityPreviewRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isSearchLoaded: false,
    searchList: []
  }
}

export const getSearchActivityPreviewSuccess = (
  state = INITIAL_STATE,
  action: LogsTypes.GetSearchActivityPreviewActionSuccess
) => {
  const { results } = action.payload

  if (action.payload) {
    return {
      ...state,
      isSearchLoaded: true,
      searchList: results
    }
  }

  return {
    ...state,
    isSearchLoaded: true,
    searchList: []
  }
}

export const getSearchActivityPreviewFailure = (
  state = INITIAL_STATE,
  action: LogsTypes.GetSearchActivityPreviewActionFailure
) => {
  if (action.payload) {
    return {
      ...state,
      isSearchLoaded: true,
      searchList: []
    }
  }

  return state
}

/* ------------- Hookup Reducers To Types ------------- */

export const logsReducer = createReducer(INITIAL_STATE, {
  [LogsActionTypes.GET_ACTIVITY_LOG_LIST_REQUEST]: getActivityLogListRequest,
  [LogsActionTypes.GET_ACTIVITY_LOG_LIST_SUCCESS]: getActivityLogListSuccess,
  [LogsActionTypes.GET_ACTIVITY_LOG_LIST_FAILURE]: getActivityLogListFailure,

  [LogsActionTypes.GET_ACTIVITY_LOG_BY_ID_REQUEST]: getActivityLogByIdRequest,
  [LogsActionTypes.GET_ACTIVITY_LOG_BY_ID_SUCCESS]: getActivityLogByIdSuccess,
  [LogsActionTypes.GET_ACTIVITY_LOG_BY_ID_FAILURE]: getActivityLogByIdFailure,

  [LogsActionTypes.GET_ERROR_LOG_LIST_REQUEST]: getErrorLogListRequest,
  [LogsActionTypes.GET_ERROR_LOG_LIST_SUCCESS]: getErrorLogListSuccess,
  [LogsActionTypes.GET_ERROR_LOG_LIST_FAILURE]: getErrorLogListFailure,

  [LogsActionTypes.GET_ERROR_LOG_BY_ID_REQUEST]: getErrorLogByIdRequest,
  [LogsActionTypes.GET_ERROR_LOG_BY_ID_SUCCESS]: getErrorLogByIdSuccess,
  [LogsActionTypes.GET_ERROR_LOG_BY_ID_FAILURE]: getErrorLogByIdFailure,

  [LogsActionTypes.GET_SEARCH_ACTIVITY_PREVIEW_REQUEST]:
    getSearchActivityPreviewRequest,
  [LogsActionTypes.GET_SEARCH_ACTIVITY_PREVIEW_SUCCESS]:
    getSearchActivityPreviewSuccess,
  [LogsActionTypes.GET_SEARCH_ACTIVITY_PREVIEW_FAILURE]:
    getSearchActivityPreviewFailure
})
