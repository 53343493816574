import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  container: {
    flexFlow: 'row wrap',
    columnGap: '56px',
    rowGap: '18px',
    padding: '12px 12px 12px 0'
  },
  line: {
    '& > *': {
      userSelect: 'text'
    }
  }
})
