import { SVGProps } from 'react'

export enum IconName {
  LOGO = 'logo',
  LOGO_CSE = 'logo-cse',
  LOGO_TEXT = 'logo-text',
  SETTINGS = 'settings',
  DASHBOARD = 'dashboard',
  EXTENSION = 'extension',
  ARROW_DROP_UP = 'arrow-drop-up',
  ARROW_DROP_DOWN = 'arrow-drop-down',
  MONETIZATION = 'monetization',
  ARROW_BACK = 'arrow-back',
  WEIGHING_MACHINE = 'weighing-machine',
  RESTORE = 'restore',
  SAVE = 'save',
  PRINT = 'print',
  CARET_DOWN = 'caret-down',
  BREAD_CRUMBS = 'bread-crumbs',
  FILTER_DRAMA = 'filter-drama',
  CHECK_CIRCLE_OUTLINE = 'check-circle-outline',
  DOT = 'dot',
  RADIO_BUTTON_UNCHECKED = 'radio-button-unchecked',
  RADIO_BUTTON_ACTIVE = 'radio-button-active',
  RADIO_BUTTON_DONE = 'radio-button-done',
  CHECK_BOX_ACTIVE = 'check-box-active',
  CHECK_BOX_INACTIVE = 'check-box-inactive',
  CHECK_BOX_SELECTED = 'check-box-selected',
  DIRECTION_CAR = 'direction-car',
  EV_STATION = 'ev-station',
  MORE_HORIZON = 'more-horizon',
  UNDO = 'undo',
  VISIBILITY = 'visibility',
  VISIBILITY_OFF = 'visibility-off',
  ADD = 'add',
  CLEAR = 'clear',
  DELETE = 'delete',
  COMPARE = 'compare',
  STAR_RATE = 'star-rate',
  USER = 'user',
  ELLIPSE9 = 'ellipse9',
  ELLIPSE12 = 'ellipse12',
  ALERT_CIRCLE = 'alert-circle',
  PHASE_1 = 'phase-1',
  PHASE_2 = 'phase-2',
  TOTAL_COST = 'total-cost',
  CATEGORY = 'category',
  COLLAPSE = 'collapse',
  BAR_CHART = 'bar-chart',
  EXPAND_ALL = 'expand-all',
  OVAL = 'oval',
  EMPTY_LIST = 'empty-list',
  VECTOR = 'vector',
  MODE = 'mode',
  PLOT = 'plot',
  MONEY = 'money',
  DONUT_SMALL = 'donut-small',
  BATTERY = 'battery',
  REPLY_ALL = 'reply-all',
  VIEW_AGENDA = 'view-agenda',
  TABLE_CHART = 'table-chart',
  ATTACH_MONEY = 'attach-money',
  CLOUD_DOWNLOAD = 'cloud-download',
  TIMELAPSE = 'timelapse',
  FILTER_DRAMA_SMALL = 'filter-drama-small',
  EV_STATION_SMALL = 'ev-station-small',
  FRAME = 'frame',
  WIDGETS = 'widgets',
  EXCLAMATION_CIRCLE = 'exclamation-circle',
  TIMES_CIRCLE = 'times-circle',
  CHECK_CIRCLE = 'check-circle'
}

export interface IconProps extends SVGProps<SVGSVGElement> {
  name: IconName
  value?: string
}
