import { createUseStyles } from 'react-jss'

import { MenuSliderStyleProps } from '../../components'
import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    width: '100%'
  },
  arrow: {
    transform: ({ open }: MenuSliderStyleProps) =>
      open ? 'rotate(180deg)' : 'rotate(0deg)',
    transformOrigin: 'center',
    transition: 'transform 0.25s ease-in-out'
  },
  header: {
    padding: '0 !important',
    width: '100% !important'
  },
  headerTop: {
    padding: '16px 32px',
    width: 'calc(100% - 64px)',
    cursor: 'pointer'
  },
  headerBottom: {
    padding: '16px 32px',
    borderTop: `1px solid ${theme.colors.secondButton}`,
    width: 'calc(100% - 64px)'
  },
  badge: {
    border: `1px solid ${theme.colors.innerBorder}`
  },
  field: {
    marginLeft: 16
  },
  instructionValue: {
    marginTop: 8
  },
  type: {
    width: 286
  },
  value: {
    width: 410
  },
  resolution: {
    width: 326
  },
  content: {
    padding: '0px 16px',
    width: 'calc(100% - 32px)'
  },
  inputContainer: {
    marginLeft: 32,
    width: '100%'
  },
  input: {
    backgroundColor: theme.colors.tableRow,
    borderRadius: 12,
    width: '100%',
    '&:-internal-autofill-selected, &:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus,':
      {
        backgroundColor: 'auto',
        '-webkit-box-shadow': 'none'
      }
  },
  icon: {
    marginLeft: 8
  },
  item: {
    marginTop: 16
  }
}))
