import React, { FC } from 'react'
import { useTheme } from 'react-jss'
import { LightTheme } from '../theme'
import { IconProps } from './types'

export const EVStation: FC<IconProps> = (props) => {
  const theme: LightTheme = useTheme()
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.53 7.22l-.01.01c.45.45.73 1.08.73 1.77v9.5a2.5 2.5 0 01-5 0v-5h-1.5V21h-10V5c0-1.1.9-2 2-2h6c1.1 0 2 .9 2 2v7h1c1.1 0 2 .9 2 2v4.5c0 .55.45 1 1 1s1-.45 1-1v-7.21c-.31.13-.64.21-1 .21a2.5 2.5 0 01-2.5-2.5c0-1.07.67-1.97 1.61-2.33l-2.11-2.11 1.06-1.06 3.72 3.72zM11.75 19V5h-6v14h6zm6-9c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm-12 3.5l4-7.5v5h2l-4 7v-4.5h-2z"
        fill={props.fill || theme.colors.white}
      />
    </svg>
  )
}
