import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../theme'
import { DetailedHeaderStyleProps } from './detailed-header.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  title: {
    marginLeft: 8
  },
  separator: ({ vertical }: DetailedHeaderStyleProps) => ({
    borderLeft: vertical ? 'none' : `1px solid ${theme.colors.secondButton}`,
    width: vertical ? '100%' : 1,
    height: vertical ? 1 : '120px',
    marginLeft: vertical ? 0 : 40,
    marginRight: vertical ? 0 : 40, // indent only for separator,
    backgroundColor: vertical ? theme.colors.secondButton : 'none',
    transform: 'rotate(180deg)'
  }),
  content: {
    '& > :not(:first-child)': {
      marginTop: 16
    },
    '& > * > *': {
      userSelect: 'text'
    }
  },
  resultsHeader: {
    marginLeft: 48,
    '& > *': {
      width: 114
    }
  },
  resultsCell: {
    marginLeft: 16,
    width: 80
  },
  rowName: {
    width: 32
  },
  status: {
    marginLeft: 0
  }
}))
