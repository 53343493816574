import React, { useMemo } from 'react'

import { Icon, Row, Column, Text, LinkItem } from '../../../../components'
import { MainRoutes } from '../../../../routes'
import { formatDateBySlash } from '../../../helpers'

import { RuleItemProps } from './rule-item.types'
import { useStyle } from './rule-item.styles'

export const RuleItem = ({
  id,
  title,
  state,
  validationUpdateDt = new Date()
}: RuleItemProps) => {
  const classes = useStyle()
  const programDate = useMemo(
    () => formatDateBySlash(validationUpdateDt),
    [validationUpdateDt]
  )

  return (
    <LinkItem href={`${MainRoutes.PROGRAM_RULES}/${id}`}>
      <Column className={classes.container} justifyContent="flex-start">
        <Column fullWidth alignItems="flex-start">
          <Text text={state} preset="body" color="inactive" />
          <Text
            className={classes.title}
            text={title}
            preset="h2"
            color="darkBlue"
          />
        </Column>
        <Row fullWidth justifyContent="flex-end" className={classes.bottomInfo}>
          <Text text={programDate} />
          <Icon className={classes.icon} name="History" />
        </Row>
      </Column>
    </LinkItem>
  )
}
