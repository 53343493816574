import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../theme'
import { Icon } from '../icon'
import { Row } from '../row'
import { Text } from '../text'

import { RadioButtonProps } from './radio-button.types'
import { useStyle } from './radio-button.styles'

export const RadioButton: FC<RadioButtonProps> = ({
  className = '',
  defaultChecked,
  iconColor,
  textProps,
  preset,
  onChange
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })

  const icon = defaultChecked ? 'RADIO_BUTTON_ACTIVE' : 'RADIO_BUTTON_UNCHECKED'

  const handleOnChange = () => {
    const isFalse = !defaultChecked
    if (onChange) {
      onChange(isFalse)
    }
  }

  return (
    <Row
      className={className}
      justifyContent="flex-start"
      onClick={handleOnChange}
    >
      <Icon
        className={classes.radio}
        name={icon}
        fill={theme.colors[iconColor]}
      />
      {textProps && <Text preset={preset} {...textProps} />}
    </Row>
  )
}
