import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../../theme'
import { concatClassNames } from '../../helpers'

import { Icon } from '../../icon'
import { Row } from '../../row'
import { Text } from '../../text'

import { DocumentViewerHeaderProps } from './document-viewer-header.types'
import { useStyle } from './document-viewer-header.styles'

export const DocumentViewerHeader: FC<DocumentViewerHeaderProps> = ({
  className,
  children,
  pages,
  options,
  rotateIndex = 90,
  zoomIndex = 1.5,
  minZoom = 1,
  maxZoom = 5,
  onChangePage,
  onChangeZoom,
  onChangeRotate,
  onChangeOptions,
  onChangeFitToPage,
  onChangeFitToWidth,
  onChangePopOut
}) => {
  const theme = useTheme<LightTheme>()
  const classes = useStyle()
  const pagesString = String(pages)

  const handleOnChangePage = (page: number) => {
    const isNotMaxPage = page < pages + 1
    const isNotMinPage = page > 0

    if (isNotMaxPage && isNotMinPage) {
      if (onChangeOptions) {
        onChangeOptions({ ...options, page, rotate: 0 })
      }

      if (onChangePage) {
        onChangePage(page)
      }
    }
  }

  const handleOnChangeZoom = (zoom: number) => {
    if (onChangeZoom) {
      onChangeZoom(zoom)
    }

    if (onChangeOptions) {
      onChangeOptions({ ...options, zoom })
    }
  }

  const isOnePage = Number(pagesString) === 1
  const handleOnPrevPage = () => {
    if (isOnePage) {
      return
    }
    const page = options.page - 1
    const isNotMinPage = page > 0

    if (isNotMinPage) {
      handleOnChangePage(page)
    }
  }

  const handleOnNextPage = () => {
    if (isOnePage) {
      return
    }
    const page = options.page + 1
    const isNotMaxPage = page < pages + 1

    if (isNotMaxPage) {
      handleOnChangePage(page)
    }
  }

  const handleOnRotate = () => {
    const rotateDeg = rotateIndex + options.rotate

    const rotate = rotateDeg >= 360 ? rotateDeg - 360 : rotateDeg

    if (onChangeRotate) {
      onChangeRotate(rotate)
    }

    if (onChangeOptions) {
      onChangeOptions({ ...options, rotate })
    }
  }

  const handleOnChangeZoomIn = () => {
    const zoom = options.zoom * zoomIndex
    const isMaxZoom = options.zoom < maxZoom

    if (isMaxZoom) {
      handleOnChangeZoom(zoom)
    } else {
      handleOnChangeZoom(maxZoom)
    }
  }

  const handleOnChangeZoomOut = () => {
    const zoom = options.zoom / zoomIndex
    const isMinZoom = options.zoom > minZoom

    if (isMinZoom) {
      handleOnChangeZoom(zoom)
    } else {
      handleOnChangeZoom(minZoom)
    }
  }

  const fillIcon = isOnePage ? theme.colors.grey : theme.colors.darkBlue
  const textColor = isOnePage ? 'inactive' : 'darkBlue'

  return (
    <Row
      justifyContent="space-between"
      className={concatClassNames(className, classes.container)}
    >
      <Row>
        <Icon fill={fillIcon} name="FRAME" onClick={handleOnPrevPage} />
        <Text className={classes.text} color="inactive" text="Page" />
        <Row className={`${classes.activePage} ${classes.text}`}>
          <Text color={textColor} text={String(options.page)} />
        </Row>
        <Text className={classes.text} color="inactive" text="of" />
        <Text className={classes.text} color="inactive" text={pagesString} />
        <Icon
          className={`${classes.rightArrow} ${classes.text}`}
          fill={fillIcon}
          name="FRAME"
          onClick={handleOnNextPage}
        />
      </Row>
      <Row>
        <Icon
          className={classes.text}
          fill={theme.colors.darkBlue}
          name="Replay"
          onClick={handleOnRotate}
        />
        <Icon
          className={classes.text}
          fill={theme.colors.darkBlue}
          name="ZoomIn"
          onClick={handleOnChangeZoomIn}
        />
        <Icon
          className={classes.text}
          fill={theme.colors.darkBlue}
          name="ZoomOut"
          onClick={handleOnChangeZoomOut}
        />
        {onChangeFitToWidth && (
          <Icon
            className={classes.text}
            fill={theme.colors.darkBlue}
            name="FitToWidth"
            onClick={onChangeFitToWidth}
          />
        )}
        {onChangeFitToPage && (
          <Icon
            className={classes.text}
            fill={theme.colors.darkBlue}
            name="FitToPage"
            onClick={onChangeFitToPage}
          />
        )}
        {onChangePopOut && (
          <Icon
            className={classes.text}
            fill={theme.colors.darkBlue}
            name="PopOut"
            onClick={onChangePopOut}
          />
        )}
      </Row>
      {children}
    </Row>
  )
}
