import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../theme'
import { IconProps } from './types'

export const AttachMoney: FC<IconProps> = (props) => {
  const theme: LightTheme = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M8.25978 7.26667C6.74645 6.87333 6.25978 6.46667 6.25978 5.83333C6.25978 5.10667 6.93311 4.6 8.05978 4.6C9.24645 4.6 9.68645 5.16667 9.72645 6H11.1998C11.1531 4.85333 10.4531 3.8 9.05978 3.46V2H7.05978V3.44C5.76645 3.72 4.72645 4.56 4.72645 5.84667C4.72645 7.38667 5.99978 8.15333 7.85978 8.6C9.52645 9 9.85978 9.58667 9.85978 10.2067C9.85978 10.6667 9.53311 11.4 8.05978 11.4C6.68645 11.4 6.14645 10.7867 6.07311 10H4.60645C4.68645 11.46 5.77978 12.28 7.05978 12.5533V14H9.05978V12.5667C10.3598 12.32 11.3931 11.5667 11.3931 10.2C11.3931 8.30667 9.77311 7.66 8.25978 7.26667Z"
        fill={props.fill || theme.colors.button}
      />
    </svg>
  )
}
