import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

import { ValidationFieldStyleProps } from './validation-field.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    backgroundColor: ({ focused }: ValidationFieldStyleProps) =>
      focused && theme.colors.arctic,
    padding: '8px 16px'
  },
  valueContainer: {
    borderBottom: ({ focused }) =>
      `1px solid ${theme.colors[focused ? 'blue' : 'grey']}`,
    padding: '8px 0px',
    width: ({ multiValue }: ValidationFieldStyleProps) =>
      multiValue ? '50%' : '100%',
    overflow: 'hidden',
    '&:not(:first-child)': {
      marginLeft: 16
    }
  },
  label: {
    cursor: 'help',
    '&:hover': {
      color: theme.colors.orange
    }
  },
  prevValueTitle: {
    marginTop: 8
  },
  value: {
    marginLeft: 16
  },
  text: {
    maxHeight: 56,
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  }
}))
