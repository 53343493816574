import React, { useRef, useState } from 'react'

import { useOutsideAlerter } from '../../hooks'

import { Column } from '../column'
import { Row } from '../row'

import { ItemsList, PositionDropdown } from './items-list'
import { getListHeight } from './helpers'
import { DropdownItemsProps } from './dropdown-items.types'
import { useStyle } from './dropdown-items.styles'

export const DropdownItems = <T extends string>({
  className = '',
  containerClassName,
  position = PositionDropdown.BOTTOM,
  data,
  active,
  disabled,
  children,
  isScrollable = false,
  onChange,
  onOpen
}: DropdownItemsProps<T>) => {
  const [open, changeOpen] = useState(false)

  const ref = useRef<HTMLDivElement>(null)
  const itemsRef = useRef<HTMLDivElement>(null)
  const itemsScrollRef = useRef<HTMLDivElement>(null)

  const width = ref.current ? ref.current.offsetWidth : 0
  const height = ref.current ? ref.current.offsetHeight : 0

  const listHeight = getListHeight({ data })
  const classes = useStyle({
    active,
    disabled,
    width,
    height,
    listHeight,
    isScrollable
  })

  const handleOnOpen = (state?: boolean) => () => {
    if (!disabled) {
      return
    }

    if (typeof state === 'boolean') {
      changeOpen(state)
      if (onOpen) {
        onOpen(state)
      }
    }

    if (!state) {
      changeOpen(!open)
      if (onOpen) {
        onOpen(!open)
      }
    }
  }

  useOutsideAlerter(itemsRef, open, handleOnOpen(false))
  const scrollableRef = isScrollable ? itemsScrollRef : null

  return (
    <Column
      ref={itemsRef}
      className={classes.container}
      fullWidth
      onClick={handleOnOpen()}
    >
      <Row fullWidth ref={ref}>
        {children}
      </Row>
      {open && (
        <Column
          fullWidth
          justifyContent="flex-start"
          className={classes.content}
        >
          <Column
            fullWidth
            justifyContent="flex-start"
            ref={scrollableRef}
            className={`${containerClassName} ${classes.listContainer}`}
          >
            <ItemsList
              className={`${className} ${classes.scrollItemsContainer}`}
              open={open}
              active={active}
              // @ts-ignore
              data={data}
              width={width}
              height={height}
              position={position}
              isScrollable={isScrollable}
              // @ts-ignore
              onChange={onChange}
              onOpen={handleOnOpen}
            />
          </Column>
        </Column>
      )}
    </Column>
  )
}
