import React, { FC, useMemo } from 'react'

import { Icon, Row, Text } from '../../../../components'
import { formatDateBySlash } from '../../../helpers'

import { RightTitleContentProps } from './right-title-content.types'
import { useStyle } from './right-title-content.styles'

export const RightTitleContent: FC<RightTitleContentProps> = ({ date }) => {
  const classes = useStyle()
  const formatedUpdateDate = useMemo(() => formatDateBySlash(date), [date])

  return (
    <Row>
      <Text color="border" preset="caption" text={formatedUpdateDate} />
      <Icon className={classes.icon} name="History" />
    </Row>
  )
}
