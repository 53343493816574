import React, { FC, useMemo, useState } from 'react'
import { useTheme } from 'react-jss'

import {
  Button,
  Icon,
  ResolutionStatusBadge,
  Row,
  Text
} from '../../components'
import { RPSResolution } from '../../services'
import { LightTheme } from '../../theme'

import {
  RpsResolutionModal,
  RpsResolutionModalValue
} from '../rps-resolution-modal'

import { AppResolutionPickerProps } from './app-resolution-picker.types'
import { useStyle } from './app-resolution-picker.styles'

export const AppResolutionPicker: FC<AppResolutionPickerProps> = ({
  isAllowed = false,
  rpsReviewer,
  date,
  data,
  defaultValue,
  onClose,
  onSubmit
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle()

  const [openModal, changeOpenModal] = useState(false)
  const [isSelected, changeIsSelected] = useState(Boolean(rpsReviewer))

  const ResolutionValue = useMemo(
    () => defaultValue?.resolution || RPSResolution.IN_PROGRESS,
    [defaultValue?.resolution]
  )
  const ResolutionDate = useMemo(() => date || new Date(), [date])

  const handleOnOpenModal = (status: boolean) => () => {
    changeOpenModal(status)

    if (!status && onClose) {
      onClose()
    }
  }

  const handleOnSubmit = (value: RpsResolutionModalValue) => {
    changeOpenModal(false)

    changeIsSelected(true)
    if (onSubmit) {
      onSubmit(value)
    }
  }

  const badge = (
    <Row>
      <ResolutionStatusBadge
        date={ResolutionDate}
        rpsResolution={ResolutionValue}
      />
      {isAllowed && (
        <Row className={classes.row} onClick={handleOnOpenModal(true)}>
          <Icon fill={theme.colors.blue} name="Edit" />
          <Text className={classes.text} color="blue" text="Change" />
        </Row>
      )}
    </Row>
  )

  const button = (
    <Button
      disabled={!isAllowed}
      isHint={!isAllowed}
      message="This button is disabled because the current version is view-only."
      messageTx="rpsResolution.hint"
      className={classes.button}
      onClick={handleOnOpenModal(true)}
    >
      <Text
        text="App Resolution"
        tx="document.appResolution"
        preset="body"
        color="white"
      />
    </Button>
  )

  return (
    <>
      {isSelected ? badge : button}
      <RpsResolutionModal
        open={openModal}
        defaultValue={defaultValue}
        data={data}
        onClose={handleOnOpenModal(false)}
        onSubmit={handleOnSubmit}
      />
    </>
  )
}
