import React from 'react'
import { ThemeProvider } from 'react-jss'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'

import { LocaleProvider, UserProvider } from './providers'
import { MainRouter } from './routes'
import { lightTheme, LightTheme } from './theme'
import createStore from './store'

const store = createStore()

const App = () => {
  const theme: LightTheme = lightTheme

  return (
    <BrowserRouter>
      <Provider store={store}>
        <LocaleProvider>
          <UserProvider>
            <ThemeProvider theme={theme}>
              <MainRouter />
              <div id="modal" />
              <div id="popover" />
            </ThemeProvider>
          </UserProvider>
        </LocaleProvider>
      </Provider>
    </BrowserRouter>
  )
}

export default App
