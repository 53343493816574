import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'
import { InputFloatLabelProps } from './input-float-label.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'row',
    borderBottom: ({ isUndo }: InputFloatLabelProps) =>
      isUndo
        ? `1px solid ${theme.colors.blue}`
        : `1px solid ${theme.colors.grey}`,
    '&:hover, &:focus': {
      border: 'none',
      borderBottom: ({ isUndo }: InputFloatLabelProps) =>
        isUndo
          ? `1px solid ${theme.colors.blue}`
          : `1px solid ${theme.colors.grey}`
    }
  },
  focused: {
    borderBottom: ({ isUndo }: InputFloatLabelProps) =>
      isUndo ? '' : `1px solid ${theme.colors.blue}`,
    '&:hover, &:focus': {
      borderBottom: ({ isUndo }: InputFloatLabelProps) =>
        isUndo ? '' : `1px solid ${theme.colors.blue}`
    }
  },
  error: {
    borderBottom: `1px solid ${theme.colors.red}!important`
  },
  floatingLabel: {
    width: 'max-content',
    maxHeight: '18.4px', // equals to input text height
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    color: ({ isUndo }: InputFloatLabelProps) =>
      isUndo ? theme.colors.blue : theme.colors.darkBlue,
    position: 'absolute',
    transition: '0.2s ease all',
    zIndex: 1,
    pointerEvents: ({ isRegular }: InputFloatLabelProps) =>
      isRegular ? 'none' : 'auto',
    '&:hover': {
      color: ({ isRegular }: InputFloatLabelProps) =>
        isRegular ? theme.colors.darkBlue : theme.colors.orange,
      cursor: ({ isRegular }: InputFloatLabelProps) =>
        isRegular ? 'default' : 'help'
    }
  },
  labelUp: {
    top: 0
  },
  labelDown: {
    top: 22,
    left: 0,
    right: 14
  },
  fullWidth: {
    width: '100%'
  },
  statusIcon: {},
  input: {
    padding: '8px 16px 8px 0px',
    marginTop: 12,
    outline: 0,
    border: 'none',
    color: theme.colors.text,
    fontSize: theme.fonts.defaultSize,
    borderBottom: 'none',
    boxShadow: 'transparent 0px 0px 0px 1000px inset!important',
    backgroundColor: 'transparent!important',
    '&::placeholder': {
      color: theme.colors.darkBlue
    },
    '&:-internal-autofill-selected, &:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus,':
      {
        backgroundColor: 'transparent!important',
        boxShadow: 'transparent!important',
        '-webkit-box-shadow': '0 0 0px 1000px transparent inset!important',
        color: `${theme.colors.text}`
      },
    '&:focus': {
      '& + span': {
        color: theme.colors.blue
      }
    },
    '&::-ms-reveal': {
      display: 'none'
    }
  },
  iconName: {
    position: 'absolute',
    padding: 0,
    right: 0,
    bottom: 10,
    '& > *': {
      cursor: 'pointer'
    }
  },
  errorContainer: {
    position: 'absolute',
    color: theme.colors.red,
    top: '100%'
  },
  textIcon: {
    position: 'absolute',
    color: theme.colors.border,
    padding: 0,
    right: 0,
    bottom: 10,
    cursor: theme.cursor,
    '&:hover > *': {
      visibility: 'visible'
    }
  }
}))
