import React, { useMemo } from 'react'
import { useDefaultValue } from '@cse/ui'

import { getVerificationFields } from '../../../pages'
import { SettingsProps } from './types'

export const useSettings = ({
  docType,
  options,
  options2,
  outerOptions,
  outerOptions2,
  verificationFields,
  verificationFields2
}: SettingsProps) => {
  const Options = useDefaultValue(options, outerOptions)
  const Options2 = useDefaultValue(options2, outerOptions2)

  const Fields = useMemo(
    () => getVerificationFields(verificationFields),
    [verificationFields]
  )
  const Fields2 = useMemo(
    () => getVerificationFields(verificationFields2),
    [verificationFields2]
  )
  const Values = {
    docType: <>{docType}</>
  }

  return {
    Options,
    Options2,
    Fields,
    Fields2,
    Values
  }
}
