import React, { FC, useContext } from 'react'
import { Header as HeaderComponent } from '@cse/ui'

import { MainRoutes } from '../../routes'
import { UserContext } from '../../providers'
import { Icon } from '../icon'
import { HeaderNavLink } from '../header-nav-link'
import { Search } from '../search'

import { HeaderProps } from './header.types'
import { useStyle } from './header.styles'

export const Header: FC<HeaderProps> = (props) => {
  const { isRPS } = useContext(UserContext)
  const classes = useStyle()

  const leftIcon = <Icon name="LOGO_CSE" width={110} height={40} />

  return (
    <HeaderComponent leftIcon={leftIcon} {...props}>
      <HeaderNavLink
        className={classes.link}
        text="Applications"
        tx="header.applications"
        to={MainRoutes.APPLICATION}
      />
      <HeaderNavLink
        className={classes.link}
        text="Program Rules"
        tx="header.programRules"
        to={MainRoutes.PROGRAM_RULES}
      />
      {!isRPS && (
        <>
          <HeaderNavLink
            className={classes.link}
            text="Activity Log"
            tx="header.activityLog"
            to={MainRoutes.ACTIVITY_LOG}
          />
          <HeaderNavLink
            className={classes.link}
            text="Settings"
            tx="header.settings"
            to={MainRoutes.SETTINGS}
          />
        </>
      )}
      <Search className={classes.link} />
    </HeaderComponent>
  )
}
