import React, { useContext, useEffect, useRef } from 'react'

import { UserContext } from '../../../providers'
import { RPSResolution } from '../../../services'
import { Icon } from '../../icon'
import { Row } from '../../row'
import { useGetData, onActive, onRPSChange } from '../helpers'

import { ActionsProps } from './actions.types'
import { useStyle } from './actions.styles'

export const Actions = ({
  active,
  isEditable,
  focused,
  rpsResolution,
  value,
  changeRpsResolution,
  onApprove,
  onReject,
  onActivate
}: ActionsProps) => {
  const { isSysAdmin } = useContext(UserContext)
  const containerRef = useRef<HTMLDivElement>(null)
  const classes = useStyle()

  const { ApproveFill, RejectFill } = useGetData({
    rpsResolution,
    focused,
    value
  })

  const isResolutionSelected = rpsResolution !== null
  const isReviewed = !isEditable && isResolutionSelected

  const handleOnActivate = () =>
    onActive({
      active,
      containerRef,
      onActivate
    })

  const handleOnRPSChange = (nextRpsResolution: RPSResolution) => {
    if (isEditable) {
      return onRPSChange({
        resolution: nextRpsResolution,
        changeRpsResolution,
        onApprove,
        onReject
      })
    }
  }

  useEffect(handleOnActivate, [active])

  if (isSysAdmin) {
    return null
  }

  return (
    <Row className={classes.buttonContainer} justifyContent="space-around">
      {isEditable && (
        <>
          <Row>
            <Icon
              fill={ApproveFill}
              name="CheckCircleOutline"
              height={24}
              width={24}
              onClick={handleOnRPSChange(RPSResolution.APPROVED)}
            />
          </Row>
          <Row>
            <Icon
              fill={RejectFill}
              name="HighlightOff"
              height={24}
              width={24}
              onClick={handleOnRPSChange(RPSResolution.INELIGIBLE)}
            />
          </Row>
        </>
      )}
      {isReviewed && (
        <>
          {rpsResolution === RPSResolution.APPROVED && (
            <Row>
              <Icon
                fill={ApproveFill}
                name="CheckCircleOutline"
                height={24}
                width={24}
                onClick={handleOnRPSChange(RPSResolution.APPROVED)}
              />
            </Row>
          )}
          {rpsResolution === RPSResolution.INELIGIBLE && (
            <Row>
              <Icon
                fill={RejectFill}
                name="HighlightOff"
                height={24}
                width={24}
                onClick={handleOnRPSChange(RPSResolution.INELIGIBLE)}
              />
            </Row>
          )}
        </>
      )}
    </Row>
  )
}
