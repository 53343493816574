import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { DropdownItemData } from '@cse/ui'

import { Row, Content, Column } from '../../components'
import { State, LogsCreators } from '../../store'
import { MainRoutes } from '../../routes'
import { GetActivityLogListRequestQuery } from '../../services'

import { LogsHeader } from './logs-header'
import { LogsLoadMoreOptions, LogDetails } from './logs-list'
import { LogsView } from './logs-view'
import { Filters, LogsHeaderFilters } from './logs-header-filter'

import { ColumnKeys } from './logs.types'
import { useStyle } from './logs.styles'

export const LOGS_LIMIT = 25
export const DEFAULT_LOG_FILTERS = {
  program: undefined,
  userName: undefined
}

export const ActivityLogPage = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const classes = useStyle()
  const dispatch = useDispatch()
  const [openModal, changeOpenModal] = useState<boolean>(false)
  const BASIC_PARAMS = useMemo(
    () => ({
      offset: 0,
      limit: LOGS_LIMIT,
      ordering: `-${ColumnKeys.DATE_OF_ACTION}`
    }),
    []
  )
  const [query, changeQuery] = useState<GetActivityLogListRequestQuery>({
    ...BASIC_PARAMS,
    ...DEFAULT_LOG_FILTERS
  })
  const [values, changeValues] = useState<Filters>({
    program: 0,
    userName: undefined
  })
  const {
    catalog,
    current,
    activityLog,
    activityLogList: { results, next },
    activityLogListLoaded,
    activityLogListLoadedMore,
    searchList
  } = useSelector((state: State) => ({
    ...state.logs,
    catalog: state.app.catalog
  }))

  const handleOnLoadApps = useCallback(
    (nextQuery?: GetActivityLogListRequestQuery) => {
      dispatch(
        LogsCreators.getActivityLogListRequest({
          query: nextQuery || query
        })
      )
    },
    [query]
  )

  const handleOnChangeSort = (value: string) => {
    const ordering = value.includes(ColumnKeys.DATE_OF_ACTION)
      ? value
      : `${value},-${ColumnKeys.DATE_OF_ACTION}`
    const nextQuery = {
      ...query,
      offset: 0,
      limit: LOGS_LIMIT,
      ordering
    }

    changeQuery(nextQuery)
    handleOnLoadApps(nextQuery)
  }

  const handleOnLoadMore = useCallback(
    (value: LogsLoadMoreOptions) => {
      if (activityLogListLoaded && activityLogListLoadedMore) {
        const nextQuery = { ...query, ...value }
        changeQuery({ ...query, ...value })

        handleOnLoadApps(nextQuery)
      }
    },
    [activityLogListLoaded, activityLogListLoadedMore, query]
  )

  const handleOnItemClick = (id: string) => {
    changeOpenModal(true)
    dispatch(
      LogsCreators.getActivityLogByIdRequest({
        params: { id }
      })
    )
  }

  const handleOnChangeFilter = (
    prop: LogsHeaderFilters,
    value: DropdownItemData<any>
  ) => {
    const nextValue = value.id ? value.name : undefined
    const nextQuery = {
      ...query,
      [prop]: nextValue,
      limit: LOGS_LIMIT,
      offset: 0
    }

    changeValues({
      ...values,
      [prop]: value.id
    })
    changeQuery(nextQuery)
  }

  const handleOnCloseDetails = () => {
    changeOpenModal(false)
  }

  const handleOnClearChanges = useCallback(() => {
    changeQuery({
      ...query,
      ...DEFAULT_LOG_FILTERS
    })
    handleOnLoadApps(BASIC_PARAMS)
    changeValues({
      program: 0,
      userName: undefined
    })
  }, [query, DEFAULT_LOG_FILTERS, BASIC_PARAMS, handleOnLoadApps])

  const handleOnSearchClose = () => {
    if (searchList.length) {
      handleOnLoadApps(BASIC_PARAMS)
    }
  }

  useEffect(() => {
    handleOnLoadApps(query)
  }, [query])

  useEffect(() => {
    if (location.pathname === MainRoutes.HOME) {
      navigate(MainRoutes.APPLICATION)
    }
  }, [location])

  return (
    <Column fullWidth className={classes.container} justifyContent="flex-start">
      <LogsHeader
        values={values}
        onChangeFilter={handleOnChangeFilter}
        onClear={handleOnClearChanges}
        onSearchClose={handleOnSearchClose}
      />
      <Content className={classes.content}>
        <Row fullWidth className={classes.innerContent} alignItems="flex-start">
          <LogsView
            isLoadedMore={activityLogListLoadedMore}
            isLoaded={activityLogListLoaded}
            data={results}
            current={current}
            next={next}
            catalog={catalog}
            onClick={handleOnItemClick}
            onLoadMore={handleOnLoadMore}
            onSort={handleOnChangeSort}
          />
        </Row>
        <LogDetails
          open={openModal}
          data={activityLog}
          title="View log entry"
          titleTx="activityLog.viewLogEntry"
          onClose={handleOnCloseDetails}
        />
      </Content>
    </Column>
  )
}
