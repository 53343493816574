import { getStatusTableData } from '../../../../components'
import { formatDateBySlash, getCatalogData } from '../../../helpers'
import { getResultsTableData } from '../results-row'
import { SettingsProps } from './types'

export const useSettings = ({ catalog, application }: SettingsProps) => {
  const programC = getCatalogData({
    catalog,
    id: 'program',
    value: application?.program
  })
  const appDate = formatDateBySlash(application?.applicationDate)
  const runDate = formatDateBySlash(application?.lastRecognitionTime)
  const profileDate = formatDateBySlash(application?.lastValidationUpdate)
  const resolutionValues = getStatusTableData({
    data: application?.documents,
    catalog
  })
  const rpsResolutionValues = getResultsTableData({
    data: application?.documents,
    catalog
  })

  return {
    programC,
    appDate,
    runDate,
    profileDate,
    resolutionValues,
    rpsResolutionValues
  }
}
