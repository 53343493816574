import React, { FC } from 'react'

import { PopoverHint } from '../popover-hint'
import { Text } from '../text'
import { Column } from '../column'
import { StatusHintItem } from './status-hint-item'

import { StatusHintProps } from './status-hint.types'
import { useStyle } from './status-hint.styles'

export const StatusHint: FC<StatusHintProps> = ({
  open,
  title,
  titleTx,
  data,
  type,
  hintPosition
}) => {
  const classes = useStyle()

  if (!open) {
    return <></>
  }

  return (
    <PopoverHint open={open} hintPosition={hintPosition}>
      <Text
        className={classes.title}
        preset="bodyBold"
        text={title}
        tx={titleTx}
      />
      <Column
        className={classes.content}
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        {data.map((item) => (
          <StatusHintItem
            key={`status_hint_item_${item.title}`}
            className={classes.item}
            isTitle
            type={type}
            {...item}
          />
        ))}
      </Column>
    </PopoverHint>
  )
}
