import React, { useRef } from 'react'
import { useOutsideAlerter } from '@cse/ui'

import { Column } from '../../../components'

import { VerificationFields } from '../verification-fields'
import { ValidatedFields } from '../validated-fields'

import { MenuContentProps } from './menu-content.types'
import { useStyle } from './menu-content.styles'

export const MenuContent = ({
  activeField,
  isEditable,
  openMenu,
  fields,
  fieldType,
  changeOpenMenu,
  changeFieldType,
  onApprove,
  onReject,
  onFocusInput,
  onBlurInput
}: MenuContentProps) => {
  const rightPanelRef = useRef<HTMLDivElement>(null)
  const classes = useStyle()

  const handleOnCloseMenu = () => {
    changeOpenMenu(false)
    changeFieldType(undefined)
  }

  useOutsideAlerter(rightPanelRef, openMenu, handleOnCloseMenu)

  if (!openMenu) {
    return null
  }

  return (
    <Column
      className={classes.rightPanelMenu}
      justifyContent="flex-start"
      alignItems="flex-start"
      ref={rightPanelRef}
    >
      <VerificationFields
        className={classes.container}
        isEditable={isEditable}
        activeField={activeField}
        fieldType={fieldType}
        fields={fields}
        onApprove={onApprove}
        onReject={onReject}
        onFocusInput={onFocusInput}
        onBlurInput={onBlurInput}
      />
      <ValidatedFields
        className={classes.container}
        isEditable={isEditable}
        activeField={activeField}
        fieldType={fieldType}
        fields={fields}
        onApprove={onApprove}
        onReject={onReject}
        onFocusInput={onFocusInput}
        onBlurInput={onBlurInput}
      />
    </Column>
  )
}
