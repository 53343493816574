import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../theme'

import { Column } from '../column'
import { Modal } from '../modal'
import { Text } from '../text'
import { Row } from '../row'
import { Button } from '../button'

import { QuestionModalProps } from './question-modal.types'
import { useStyle } from './question-modal.styles'

export const QuestionModal: FC<QuestionModalProps> = ({
  children,
  className,
  title,
  titleTx,
  submitText = 'Submit',
  submitTx = 'btn.submit',
  disabled,
  values,
  onSubmit,
  onCancel,
  onClose
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })

  return (
    <Modal onClose={onClose}>
      <Column fullWidth fullHeight justifyContent="space-between">
        <Column fullWidth className={classes.title}>
          <Text text={title} tx={titleTx} values={values} preset="h1" />
        </Column>
        <Column
          fullHeight
          fullWidth
          className={className}
          justifyContent="flex-start"
        >
          {children}
        </Column>
        <Row
          className={classes.actions}
          justifyContent="space-around"
          fullWidth
        >
          <Button preset="secondary" onClick={onCancel || onClose}>
            <Text text="Cancel" tx="btn.cancel" preset="body" />
          </Button>
          <Button preset="accent" disabled={disabled} onClick={onSubmit}>
            <Text text={submitText} tx={submitTx} preset="body" />
          </Button>
        </Row>
      </Column>
    </Modal>
  )
}
