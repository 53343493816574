import { takeLatest, all } from 'redux-saga/effects'

import { config } from '../../config'
import API from '../../services/api'
import {
  AuthActionTypes,
  ApplicationsActionTypes,
  AppActionTypes,
  DocumentActionTypes,
  SettingsActionTypes,
  ValidationRuleActionTypes,
  LogsActionTypes
} from '../actions'
/* ------------- Types ------------- */

/* ------------- Sagas ------------- */

import { LoginOkta, Logout, RefreshToken, GetUserMe } from './auth'
import {
  DeleteApplication,
  GetApplication,
  GetApplications,
  GetSearchPreview
} from './applications'
import { GetCatalog } from './app'
import {
  GetDocument,
  GetFieldInfo,
  GetDocumentHeader,
  UpdateResolutionApp,
  UpdateResolutionDoc,
  UpdateResolutionField,
  GetRejectionReasonApp,
  GetRejectionReasonDoc,
  GetDocumentToCompare,
  GetDocumentHeaderToCompare,
  GetDocumentVersions
} from './document'
import { GetSettings, PostSettings } from './settings'
import {
  GetValidationPrograms,
  GetValidationRuleDocumentTypes,
  GetValidationRuleList
} from './validation-rule'
import {
  GetActivityLogById,
  GetActivityLogList,
  GetErrorLogById,
  GetErrorLogList,
  GetSearchActivityPreview
} from './logs'

/* ------------- API ------------- */

// The API we use is only used from Sagas, so we create it here and pass along
// to the sagas which need it.

const api = API.create(config.BASE_URL)

/* ------------- Connect Types To Sagas ------------- */

export default function* root() {
  yield all([
    // Login api request
    takeLatest(AuthActionTypes.OKTA_LOGIN_REQUEST, LoginOkta, api),
    takeLatest(AuthActionTypes.REFRESH_USER_REQUEST, RefreshToken, api),
    // Get user me api request
    takeLatest(AuthActionTypes.GET_USER_ME_REQUEST, GetUserMe, api),
    takeLatest(AuthActionTypes.LOGOUT, Logout, api),

    // Get Applications request
    takeLatest(
      ApplicationsActionTypes.GET_APPLICATIONS_REQUEST,
      GetApplications,
      api
    ),
    // Get Search Preview request
    takeLatest(
      ApplicationsActionTypes.GET_SEARCH_PREVIEW_REQUEST,
      GetSearchPreview,
      api
    ),
    // Get Application request
    takeLatest(
      ApplicationsActionTypes.GET_APPLICATION_REQUEST,
      GetApplication,
      api
    ),

    // Delete Application Request
    takeLatest(
      ApplicationsActionTypes.DELETE_APPLICATION_REQUEST,
      DeleteApplication,
      api
    ),

    // Get Catalog request
    takeLatest(AppActionTypes.GET_CATALOG_REQUEST, GetCatalog, api),

    // Get Document header request
    takeLatest(
      DocumentActionTypes.GET_DOCUMENT_HEADER_REQUEST,
      GetDocumentHeader,
      api
    ),
    // Get Document request
    takeLatest(DocumentActionTypes.GET_DOCUMENT_REQUEST, GetDocument, api),
    // Get Document versions to compare request
    takeLatest(
      DocumentActionTypes.GET_DOCUMENT_VERSIONS_REQUEST,
      GetDocumentVersions,
      api
    ),
    // Get Document to compare request
    takeLatest(
      DocumentActionTypes.GET_DOCUMENT_HEADER_TO_COMPARE_REQUEST,
      GetDocumentHeaderToCompare,
      api
    ),
    takeLatest(
      DocumentActionTypes.GET_DOCUMENT_TO_COMPARE_REQUEST,
      GetDocumentToCompare,
      api
    ),
    // Get Rejection Reason app list request
    takeLatest(
      DocumentActionTypes.GET_REJECTION_REASON_APP_REQUEST,
      GetRejectionReasonApp,
      api
    ),
    // Get Rejection Reason doc list request
    takeLatest(
      DocumentActionTypes.GET_REJECTION_REASON_DOC_REQUEST,
      GetRejectionReasonDoc,
      api
    ),
    // Get Field info request
    takeLatest(DocumentActionTypes.GET_FIELD_INFO_REQUEST, GetFieldInfo, api),
    takeLatest(
      DocumentActionTypes.UPDATE_RESOLUTION_APP_REQUEST,
      UpdateResolutionApp,
      api
    ),
    takeLatest(
      DocumentActionTypes.UPDATE_RESOLUTION_DOC_FIELD_REQUEST,
      UpdateResolutionDoc,
      api
    ),
    takeLatest(
      DocumentActionTypes.UPDATE_RESOLUTION_FIELD_REQUEST,
      UpdateResolutionField,
      api
    ),

    // Get, Post settings requests
    takeLatest(SettingsActionTypes.GET_SETTINGS_REQUEST, GetSettings, api),
    takeLatest(SettingsActionTypes.POST_SETTINGS_REQUEST, PostSettings, api),

    // Get Validation Programs requests
    takeLatest(
      ValidationRuleActionTypes.GET_VALIDATION_PROGRAMS_REQUEST,
      GetValidationPrograms,
      api
    ),
    // Get Program Rules requests
    takeLatest(
      ValidationRuleActionTypes.GET_VALIDATION_RULE_LIST_REQUEST,
      GetValidationRuleList,
      api
    ),
    takeLatest(
      ValidationRuleActionTypes.GET_VALIDATION_RULE_DOCUMENT_TYPES_REQUEST,
      GetValidationRuleDocumentTypes,
      api
    ),

    // Get Logs requests
    takeLatest(
      LogsActionTypes.GET_ACTIVITY_LOG_LIST_REQUEST,
      GetActivityLogList,
      api
    ),
    takeLatest(
      LogsActionTypes.GET_ACTIVITY_LOG_BY_ID_REQUEST,
      GetActivityLogById,
      api
    ),
    // Get Search Activity Preview request
    takeLatest(
      LogsActionTypes.GET_SEARCH_ACTIVITY_PREVIEW_REQUEST,
      GetSearchActivityPreview,
      api
    ),
    takeLatest(
      LogsActionTypes.GET_ERROR_LOG_LIST_REQUEST,
      GetErrorLogList,
      api
    ),
    takeLatest(
      LogsActionTypes.GET_ERROR_LOG_BY_ID_REQUEST,
      GetErrorLogById,
      api
    )
  ])
}

export type RootSaga = typeof root

export * from './types'
export * from './helpers'
