import { createUseStyles } from 'react-jss'

import { LightTheme } from '../theme'

import { HeaderStyleProps } from './header.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  header: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 0
  },
  navContainer: {
    '& + &': {
      marginLeft: 48
    }
  },
  navText: {
    padding: '8px 0px',
    marginLeft: 16,
    minWidth: 80,
    textAlign: 'center'
  },
  icon: {
    cursor: theme.cursor
  },
  complexContainer: {
    marginLeft: 32
  },
  menu: {
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 3,
    minWidth: 200
  },
  logo: {
    cursor: theme.cursor
  },
  filledBackground: {
    backgroundColor: ({ backgroundColor = 'white' }: HeaderStyleProps) =>
      theme.colors[backgroundColor]
  }
}))
