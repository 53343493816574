const RobotoBlack = require('./Roboto-Black.ttf')
const RobotoBlackItalic = require('./Roboto-BlackItalic.ttf')
const RobotoBold = require('./Roboto-Bold.ttf')
const RobotoBoldItalic = require('./Roboto-BoldItalic.ttf')
const RobotoItalic = require('./Roboto-Italic.ttf')
const RobotoLight = require('./Roboto-Light.ttf')
const RobotoLightItalic = require('./Roboto-LightItalic.ttf')
const RobotoMedium = require('./Roboto-Medium.ttf')
const RobotoMediumItalic = require('./Roboto-MediumItalic.ttf')
const RobotoRegular = require('./Roboto-Regular.ttf')
const RobotoThin = require('./Roboto-Thin.ttf')
const RobotoThinItalic = require('./Roboto-ThinItalic.ttf')

export {
  RobotoBlack,
  RobotoBlackItalic,
  RobotoBold,
  RobotoBoldItalic,
  RobotoItalic,
  RobotoLight,
  RobotoLightItalic,
  RobotoMedium,
  RobotoMediumItalic,
  RobotoRegular,
  RobotoThin,
  RobotoThinItalic
}
