import { ApisauceInstance } from 'apisauce'
import camelcaseKeys from 'camelcase-keys'

import { getGeneralApiProblem } from '../api-problem'

import { AppPathes, GetCatalogResponse, GetCatalogResults } from './app.types'

// our "constructor"
export const appService = (api: ApisauceInstance) => {
  /**
   * Login user request from service
   * @param userData - Data about user to login auth
   */
  const getCatalog = async (): Promise<GetCatalogResults> => {
    const response = await api.get<GetCatalogResponse>(AppPathes.GET_CATALOG)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) {
        return problem
      }
    }
    try {
      if (response.data) {
        const data = camelcaseKeys(response.data, {
          deep: true
        })
        return { kind: 'ok', catalog: data }
      }

      return { kind: 'bad-data' }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  return {
    getCatalog
  }
}
