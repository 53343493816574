import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../../theme'

import { SVGIconProps } from './icons.types'

export const Date: FC<SVGIconProps> = (props) => {
  const theme: LightTheme = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 4H19C20.1 4 21 4.9 21 6V20C21 21.1 20.1 22 19 22H5C3.89 22 3 21.1 3 20L3.01 6C3.01 4.9 3.89 4 5 4H6V2H8V4H16V2H18V4ZM5 10V20H19V10H5ZM19 8H5V6H19V8ZM12 13H17V18H12V13Z"
        fill={props.fill || theme.colors.orange}
      />
    </svg>
  )
}
