import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../../theme'

import { SVGIconProps } from './icons.types'

export const ExclamationTriangle: FC<SVGIconProps> = (props) => {
  const theme: LightTheme = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1333 11.6667L9.73326 2.33332C9.19993 1.39999 7.93326 1.06666 6.99993 1.59999C6.66659 1.79999 6.39993 2.06666 6.26659 2.33332L0.866593 11.6667C0.33326 12.6 0.666593 13.8667 1.59993 14.4C1.93326 14.6 2.26659 14.6667 2.59993 14.6667H13.3333C14.4666 14.6667 15.3333 13.7333 15.3333 12.6667C15.3999 12.2667 15.2666 11.9333 15.1333 11.6667ZM7.99993 12C7.59993 12 7.33326 11.7333 7.33326 11.3333C7.33326 10.9333 7.59993 10.6667 7.99993 10.6667C8.39993 10.6667 8.66659 10.9333 8.66659 11.3333C8.66659 11.7333 8.39993 12 7.99993 12ZM8.66659 8.66666C8.66659 9.06666 8.39993 9.33332 7.99993 9.33332C7.59993 9.33332 7.33326 9.06666 7.33326 8.66666V5.99999C7.33326 5.59999 7.59993 5.33332 7.99993 5.33332C8.39993 5.33332 8.66659 5.59999 8.66659 5.99999V8.66666Z"
        fill={props.fill || theme.colors.orange}
      />
    </svg>
  )
}
