import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

import { MenuSliderStyleProps } from './menu-slider.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  arrow: {
    marginLeft: 16,
    transform: ({ open }: MenuSliderStyleProps) =>
      open ? 'rotate(180deg)' : 'rotate(0deg)',
    transformOrigin: 'center',
    transition: 'transform 0.25s ease-in-out'
  },
  container: {
    borderRadius: 12,
    background: theme.colors.white
  },
  content: {
    borderTop: ({ open }: MenuSliderStyleProps) =>
      open && `1px solid ${theme.colors.secondButton}`,
    padding: ({ open }: MenuSliderStyleProps) => (open ? 16 : 0),
    transition: 'all 0.25s ease-in-out',
    width: 'calc(100% - 32px)',
    maxHeight: 420,
    overflowY: 'auto'
  },
  header: {
    padding: '16px 32px',
    width: 'calc(100% - 64px)'
  }
}))
