import React, { FC } from 'react'

import { QuestionModal, Column, Text } from '../../components'

import { DeleteAppModalProps } from './delete-app-modal.types'
import { useStyle } from './delete-app-modal.styles'

export const DeleteAppModal: FC<DeleteAppModalProps> = ({
  open,
  className,
  children,
  description,
  descriptionTx,
  appTitle,
  disabled,
  title,
  titleTx,
  onClose,
  onSubmit
}) => {
  const classes = useStyle()

  const handleOnSubmit = () => {
    if (onSubmit) {
      onSubmit(appTitle)
    }
  }

  if (!open) {
    return <></>
  }

  const values = {
    title: <Text text={appTitle} color="text" preset="bodyBold" />
  }

  return (
    <QuestionModal
      className={className}
      title={title}
      titleTx={titleTx}
      disabled={disabled}
      onSubmit={handleOnSubmit}
      onClose={onClose}
    >
      <Column fullWidth fullHeight className={classes.description}>
        <Text
          className={classes.text}
          text={description}
          tx={descriptionTx}
          values={values}
          preset="body"
          color="inactive"
        />
        <Text
          className={classes.extra}
          text="This process cannot be undone."
          tx="documents.modal.undone"
          preset="body"
          color="inactive"
        />
      </Column>
      {children}
    </QuestionModal>
  )
}
