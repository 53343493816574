import { createActions, createTypes } from 'reduxsauce'

import {
  ValidationRuleActions,
  ValidationRuleActionsTypes
} from './validation-rule.types'

export const validationRuleStringTypes = createTypes(`
  GET_VALIDATION_PROGRAMS_REQUEST
  GET_VALIDATION_PROGRAMS_SUCCESS
  GET_VALIDATION_PROGRAMS_FAILURE

  GET_VALIDATION_RULE_LIST_REQUEST
  GET_VALIDATION_RULE_LIST_SUCCESS
  GET_VALIDATION_RULE_LIST_FAILURE

  GET_VALIDATION_RULE_DOCUMENT_TYPES_REQUEST
  GET_VALIDATION_RULE_DOCUMENT_TYPES_SUCCESS
  GET_VALIDATION_RULE_DOCUMENT_TYPES_FAILURE
`)

const { Types: ValidationRuleActionTypes, Creators: ValidationRuleCreators } =
  createActions<ValidationRuleActionsTypes, ValidationRuleActions>({
    getValidationProgramsRequest: ['payload'],
    getValidationProgramsSuccess: ['payload'],
    getValidationProgramsFailure: ['payload'],

    getValidationRuleListRequest: ['payload'],
    getValidationRuleListSuccess: ['payload'],
    getValidationRuleListFailure: ['payload'],

    getValidationRuleDocumentTypesRequest: ['payload'],
    getValidationRuleDocumentTypesSuccess: ['payload'],
    getValidationRuleDocumentTypesFailure: ['payload']
  })

export { ValidationRuleCreators, ValidationRuleActionTypes }
