import { createUseStyles } from 'react-jss'

import { MenuSliderStyleProps } from '../../../components'
import { LightTheme } from '../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  arrow: {
    transform: ({ open }: MenuSliderStyleProps) =>
      open ? 'rotate(180deg)' : 'rotate(0deg)',
    transformOrigin: 'center',
    transition: 'transform 0.25s ease-in-out'
  },
  headerTop: {
    padding: '16px 32px',
    width: 'calc(100% - 64px)',
    cursor: 'pointer'
  },
  badge: {
    border: `1px solid ${theme.colors.innerBorder}`
  },
  field: {
    marginLeft: 16
  },
  icon: {
    marginLeft: 8
  },
  headerBottom: {
    padding: '16px 32px',
    borderTop: `1px solid ${theme.colors.secondButton}`,
    width: 'calc(100% - 64px)'
  },
  type: {
    width: 286
  },
  value: {
    width: 410
  },
  resolution: {
    width: 326
  }
}))
