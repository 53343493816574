import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useOutsideAlerter } from '@cse/ui'

import { Row, IconButton, Input } from '../../../components'

import { ColumnKeys } from '../../logs/logs.types'
import { LogsCreators, State } from '../../../store'
import { ActivityLog } from '../../../services'
import { useDebounceInput } from '../../../hooks'
import {
  changeDataBySeach,
  getContainerHeight,
  useGetData,
  getHeight,
  resetHeight
} from './helpers'
import { ItemsList } from './items-list'

import { SearchProps } from './logs-search.types'
import { useStyle } from './logs-search.styles'

export const Search = ({
  className,
  minHeight = 40,
  maxHeight = 200,
  onClose = () => {}
}: SearchProps) => {
  const [isOpen, changeIsOpen] = useState(false)
  const iconButton = useMemo(() => (isOpen ? 'Clear' : 'Search'), [isOpen])
  const dispatch = useDispatch()
  const { searchList } = useSelector((state: State) => ({
    ...state.logs
  }))
  const searchRef = useRef<HTMLDivElement>(null)
  const [data, changeData] = useState<ActivityLog>([])
  const [searchById, changeSearchById] = useState<string>('')

  const listRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)
  const itemsContainer = listRef.current
  const containerHeight = getContainerHeight(itemsContainer, minHeight)
  const [height, changeHeight] = useState(containerHeight)

  const { open } = useGetData({ data, searchById })
  const classes = useStyle({ open, height })

  const changeValue = useCallback((value: string) => {
    changeSearchById(value)
  }, [])

  const handleOnLoadApps = useCallback((search: string) => {
    const ordering = `-${ColumnKeys.DATE_OF_ACTION}`

    dispatch(
      LogsCreators.getSearchActivityPreviewRequest({
        query: {
          search,
          preview: true,
          ordering
        }
      })
    )
  }, [])

  const handleOnSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value
      changeValue(value)
    },
    [changeValue]
  )

  const handleOnOpenSearch = (state?: boolean) => () => {
    if (state) {
      changeIsOpen(state)
      return
    }
    changeIsOpen(!isOpen)

    if (isOpen) {
      onClose()
      changeSearchById('')
    }
  }

  const handleOnSearchClick = useCallback(() => {
    changeSearchById('')
    changeData([])
    handleOnOpenSearch(false)()
  }, [])

  const getOffsetHeight = useCallback(
    () =>
      getHeight({
        contentRef,
        maxHeight,
        minHeight,
        changeHeight
      }),
    [contentRef]
  )

  const handleOnItemClick = (search: string) => () => {
    const ordering = `-${ColumnKeys.DATE_OF_ACTION}`

    if (search) {
      dispatch(
        LogsCreators.getActivityLogListRequest({
          query: {
            search,
            preview: true,
            ordering
          }
        })
      )
    }
    handleOnSearchClick()
  }

  useEffect(() => {
    resetHeight({ open, getOffsetHeight })
  }, [listRef, data, open])

  useEffect(() => {
    changeDataBySeach({
      searchById,
      searchList,
      changeData
    })
  }, [searchById, searchList])

  useDebounceInput({ value: searchById, onChange: handleOnLoadApps })
  useOutsideAlerter(searchRef, Boolean(searchById), handleOnSearchClick)

  return (
    <Row ref={searchRef} className={className} fullWidth>
      {isOpen && (
        <Input
          classNameInput={classes.searchInput}
          placeholder="Search by App ID"
          placeholderTx="header.searchByAppId"
          defaultValue={searchById}
          value={searchById}
          onChange={handleOnSearch}
          onIconClick={handleOnSearchClick}
        />
      )}
      <IconButton
        className={classes.iconButton}
        name={iconButton}
        preset="search"
        onClick={handleOnOpenSearch()}
      />
      <ItemsList
        listRef={listRef}
        contentRef={contentRef}
        open={open}
        height={height}
        data={data}
        onItemClick={handleOnItemClick}
      />
    </Row>
  )
}
