import { createActions, createTypes } from 'reduxsauce'

import { DocumentActions, DocumentActionsTypes } from './document.types'

export const documentStringTypes = createTypes<DocumentActionsTypes>(`
    GET_DOCUMENT_HEADER_REQUEST
    GET_DOCUMENT_HEADER_SUCCESS
    GET_DOCUMENT_HEADER_FAILURE

    GET_DOCUMENT_REQUEST
    GET_DOCUMENT_SUCCESS
    GET_DOCUMENT_FAILURE

    GET_DOCUMENT_VERSIONS_REQUEST
    GET_DOCUMENT_VERSIONS_SUCCESS
    GET_DOCUMENT_VERSIONS_FAILURE

    GET_DOCUMENT_HEADER_TO_COMPARE_REQUEST
    GET_DOCUMENT_HEADER_TO_COMPARE_SUCCESS
    GET_DOCUMENT_HEADER_TO_COMPARE_FAILURE

    GET_DOCUMENT_TO_COMPARE_REQUEST
    GET_DOCUMENT_TO_COMPARE_SUCCESS
    GET_DOCUMENT_TO_COMPARE_FAILURE

    UPDATE_RESOLUTION_APP_REQUEST
    UPDATE_RESOLUTION_APP_SUCCESS
    UPDATE_RESOLUTION_APP_FAILURE

    UPDATE_RESOLUTION_DOC_FIELD_REQUEST
    UPDATE_RESOLUTION_DOC_FIELD_SUCCESS
    UPDATE_RESOLUTION_DOC_FIELD_FAILURE

    UPDATE_RESOLUTION_FIELD_REQUEST
    UPDATE_RESOLUTION_FIELD_SUCCESS
    UPDATE_RESOLUTION_FIELD_FAILURE

    GET_FIELD_INFO_REQUEST
    GET_FIELD_INFO_SUCCESS
    GET_FIELD_INFO_FAILURE

    GET_REJECTION_REASON_APP_REQUEST
    GET_REJECTION_REASON_APP_SUCCESS
    GET_REJECTION_REASON_APP_FAILURE

    GET_REJECTION_REASON_DOC_REQUEST
    GET_REJECTION_REASON_DOC_SUCCESS
    GET_REJECTION_REASON_DOC_FAILURE

    CLEAR_DOCUMENTS
    CLEAR_DOCUMENTS_TO_COMPARE
`)

const { Types: DocumentActionTypes, Creators: DocumentCreators } =
  createActions<DocumentActionsTypes, DocumentActions>({
    getDocumentHeaderRequest: ['payload'],
    getDocumentHeaderSuccess: ['payload'],
    getDocumentHeaderFailure: ['payload'],

    getDocumentRequest: ['payload'],
    getDocumentSuccess: ['payload'],
    getDocumentFailure: ['payload'],

    getDocumentVersionsRequest: ['payload'],
    getDocumentVersionsSuccess: ['payload'],
    getDocumentVersionsFailure: ['payload'],

    getDocumentHeaderToCompareRequest: ['payload'],
    getDocumentHeaderToCompareSuccess: ['payload'],
    getDocumentHeaderToCompareFailure: ['payload'],

    getDocumentToCompareRequest: ['payload'],
    getDocumentToCompareSuccess: ['payload'],
    getDocumentToCompareFailure: ['payload'],

    getRejectionReasonAppRequest: ['payload'],
    getRejectionReasonAppSuccess: ['payload'],
    getRejectionReasonAppFailure: ['payload'],

    getRejectionReasonDocRequest: ['payload'],
    getRejectionReasonDocSuccess: ['payload'],
    getRejectionReasonDocFailure: ['payload'],

    updateResolutionAppRequest: ['payload'],
    updateResolutionAppSuccess: ['payload'],
    updateResolutionAppFailure: ['payload'],

    updateResolutionDocFieldRequest: ['payload'],
    updateResolutionDocFieldSuccess: ['payload'],
    updateResolutionDocFieldFailure: ['payload'],

    updateResolutionFieldRequest: ['payload'],
    updateResolutionFieldSuccess: ['payload'],
    updateResolutionFieldFailure: ['payload'],

    getFieldInfoRequest: ['payload'],
    getFieldInfoSuccess: ['payload'],
    getFieldInfoFailure: ['payload'],

    clearDocuments: ['payload'],
    clearDocumentsToCompare: ['payload']
  })

export { DocumentCreators, DocumentActionTypes }
