import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { Row, Content, Column } from '../../components'
import { State, ApplicationsCreators } from '../../store'
import { MainRoutes } from '../../routes'
import { GetApplicationsRequestQuery } from '../../services'

import { ListView } from './list-view'
import { ApplicationLoadMoreOptions } from './apps-list'
import { AppHeaderFilterValue, AppsHeader } from './apps-header'
import { Filters } from './apps-header-filter'

import { ColumnKeys } from './application-list.types'
import { useStyle } from './application-list.styles'

export const LIMIT = 30
export const DEFAULT_APPS_FILTERS = {
  program: undefined,
  rps_reviewer: undefined,
  rps_resolution: undefined
}

export const ApplicationListPage = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const classes = useStyle()
  const dispatch = useDispatch()
  const BASIC_PARAMS = useMemo(
    () => ({
      offset: 0,
      limit: LIMIT,
      ordering: `-${ColumnKeys.IQR_RUN_DATE},-${ColumnKeys.RPS_REWIEW_DATE}`
    }),
    []
  )
  const [query, changeQuery] = useState<GetApplicationsRequestQuery>({
    ...BASIC_PARAMS,
    ...DEFAULT_APPS_FILTERS
  })
  const { catalog, applications, next, current, isLoaded, isLoadedMore } =
    useSelector((state: State) => ({
      ...state.applications,
      catalog: state.app.catalog
    }))

  useEffect(() => {
    if (location.pathname === MainRoutes.HOME) {
      navigate(MainRoutes.APPLICATION)
    }
  }, [location])

  useEffect(() => {
    dispatch(
      ApplicationsCreators.getApplicationsRequest({
        query
      })
    )
  }, [query])

  const handleOnChangeSort = (value: string) => {
    const ordering = value.includes(ColumnKeys.IQR_RUN_DATE)
      ? value
      : `${value},-${ColumnKeys.IQR_RUN_DATE}`
    const nextQuery = {
      ...query,
      offset: 0,
      limit: LIMIT,
      ordering
    }

    changeQuery(nextQuery)
  }

  const handleOnChangeFilter = (value: AppHeaderFilterValue) => {
    const nextQuery = {
      ...query,
      offset: 0,
      ...value
    }
    changeQuery(nextQuery)
  }

  const handleOnClearChanges = useCallback(() => {
    changeQuery({
      ...BASIC_PARAMS,
      ...DEFAULT_APPS_FILTERS
    })
  }, [query, DEFAULT_APPS_FILTERS, BASIC_PARAMS])

  const handleOnLoadMore = (value: ApplicationLoadMoreOptions) => {
    if (isLoaded && isLoadedMore) {
      changeQuery({ ...query, ...value })
    }
  }
  const Values: Filters = useMemo(
    () => ({
      program: query?.program
        ? Number(query?.program)
        : DEFAULT_APPS_FILTERS.program,
      rps_reviewer: query?.rps_reviewer,
      rps_resolution: query?.rps_resolution
        ? Number(query?.rps_resolution)
        : DEFAULT_APPS_FILTERS.rps_resolution
    }),
    [query]
  )

  return (
    <Column fullWidth className={classes.container} justifyContent="flex-start">
      <AppsHeader
        defaultValues={Values}
        onChangeFilter={handleOnChangeFilter}
        onClear={handleOnClearChanges}
      />
      <Content className={classes.content}>
        <Row fullWidth className={classes.innerContent} alignItems="flex-start">
          <ListView
            isLoadedMore={isLoadedMore}
            isLoaded={isLoaded}
            data={applications}
            search={query.search}
            current={current}
            next={next}
            catalog={catalog}
            onLoadMore={handleOnLoadMore}
            onSort={handleOnChangeSort}
          />
        </Row>
      </Content>
    </Column>
  )
}
