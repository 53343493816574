import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  container: {
    width: '100%'
  },
  spinner: {
    position: 'absolute',
    height: 504
  },
  loadMore: {
    marginTop: 16,
    padding: '8px 0'
  },
  bottomGradient: {
    position: 'absolute',
    bottom: 16,
    height: 24,
    background:
      'linear-gradient(-180deg, #F5F6F6 0%, rgba(245, 246, 246, 0) 100%)',
    zIndex: 2
  }
})
