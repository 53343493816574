import { GetCurrentPartProps } from './documents-list.types'

export const getCurrentPart = ({
  count,
  current,
  limit = 10
}: GetCurrentPartProps) => {
  if (!count) {
    return 0
  }

  const left = count - current
  const isBetterLimit = left < limit
  return isBetterLimit ? left : limit
}
