import { GeneralApiProblem } from '../api-problem'
import { DocumentType, Resolution, RPSResolution } from '../app'
import { Application, ApplicationSmall } from '../applications'

export type Document = {
  id: number
  textractPk: number
  dateModified: Date | null
  documentType: DocumentType
  validationRate: string
  resolution: Resolution
  rpsResolution: RPSResolution
  fieldsVerified: string
  version: number
  document: number
}

export enum StatusType {
  RES = 'res',
  RPS = 'rps'
}

export interface DocumentSmall
  extends Pick<Document, 'id' | 'document' | 'resolution' | 'documentType'> {
  rpsResolution: RPSResolution
  rejectionReason: string | number | null
  rpsResolutionDt: Date | null
  pageCount: number
  documentName: string
  warnings: [string]
  hasRecognitionUpdate: boolean
  hasDocumentUpdate: boolean
  hasOtherVersions: boolean
}

export interface SubmissionVersion {
  id: number
  submissionVersion: number | null
  submissionVersionDt: Date | null
}

export type PolygonDot = {
  x: number
  y: number
}

export type Polygon = PolygonDot[]

export type FieldLocation = {
  page: number
  polygon: Polygon
}

export type FieldVerification = {
  id: number
  title: string
  description: string
  referenceValue: string | null
  recognizedValue: string
  resolution: Resolution
  page: number
  previewDisplay: boolean
  previewTitle: string | null
  rpsResolution: RPSResolution | null
}

export interface PolygonField
  extends Pick<FieldVerification, 'id' | 'recognizedValue' | 'resolution'> {
  location: FieldLocation[]
}

export type PageVerification = {
  id: number
  number: number
  pageImage: string | null
  fields: PolygonField[]
}

export interface DocumentFieldVerification
  extends Pick<FieldVerification, 'rpsResolution'> {}

export interface DocumentVerification
  extends Pick<DocumentSmall, 'rpsResolution'> {
  fields: DocumentFieldVerification[]
}

export interface ApplicationVerification
  extends Pick<Application, 'id' | 'rpsResolution'> {
  documents: DocumentVerification[]
  rejectionReason: string | number | null
}

export enum DocumentPathes {
  HEADER = '/verification/header',
  DOCUMENT = '/verification',
  RESOLUTION_APP_UPDATE = '/verification/resolution/application',
  RESOLUTION_DOC_FIELD = '/verification/resolution/document',
  RESOLUTION_FIELD_UPDATE = '/verification/resolution/field',
  FIELD_INFO = '/verification/field',
  REJECTION_REASON_APP = '/verification/resolution/rejection-reason/application/list',
  REJECTION_REASON_DOC = '/verification/resolution/rejection-reason/document/list',
  DOCUMENT_VERSIONS = '/verification/versions/document'
}

export type FieldInfo = {
  textractPk: number
  title: string
  description: string
  resolution: number
  comment: string
}

export type GetDocumentHeaderRequestParams = {
  appId: string
}

export type GetDocumentHeaderRequest = {
  params: GetDocumentHeaderRequestParams
}

export enum DocumentOrdering {
  ASC_NUMBER = 'number',
  DESC_NUMBER = '-number'
}

export type GetDocumentRequestParams = {
  docId: PageVerification['id']
}

export type GetRejectionReasonAppParams = {
  rpsResolution: string
}

export type GetRejectionReasonDocParams = {
  rpsResolution: string
}

export type GetDocumentRequestQuery = {
  ordering: DocumentOrdering
}

export type GetFieldInfoRequestParams = {
  fieldPk: number
}

export type GetFieldInfoRequest = {
  params: GetFieldInfoRequestParams
}

export type GetDocumentRequest = {
  params: GetDocumentRequestParams
  query?: GetDocumentRequestQuery
}

export type GetDocumentVersionsRequest = {
  params: GetDocumentRequestParams
}

export type GetRejectionReasonAppRequest = {
  query: GetRejectionReasonAppParams
  reset?: boolean
}

export type GetRejectionReasonDocRequest = {
  query: GetRejectionReasonDocParams
  reset?: boolean
}

export type GetFieldInfoResponse = {
  results: FieldInfo[]
}

export type GetDocumentResponse = {
  count: number
  pages: PageVerification[]
  id: GetDocumentRequestParams['docId']
  fields: FieldVerification[]
}

export type DocumentVersion = {
  documentId: number
  applicationId: number
  submissionVersion: number
  submissionVersionDt: Date
}

export type GetDocumentVersionsResponse = {
  id: number
  count: number
  results: [DocumentVersion]
}

export type RejectionReason = {
  id: number
  reasonCode: number
  title: string
  rpsResolution: [RPSResolution]
}

export type GetRejectionReasonAppResponse = {
  count: number
  results: [RejectionReason]
}

export type GetRejectionReasonDocResponse = {
  count: number
  results: [RejectionReason]
}

export type UpdateResolutionAppRequestParams = {
  appId?: string
}

export type UpdateResolutionAppRequestBody = {
  rpsResolution: RPSResolution
  rejectionReason?: string | number | null
}

export type UpdateResolutionDocRequestParams = {
  docId?: string
}

export type UpdateResolutionDocRequestBody = {
  rpsResolution: RPSResolution
  rejectionReason: string | number | null
}

export type UpdateResolutionFieldRequestParams = {
  id?: string
}

export type UpdateResolutionFieldRequestBody = {
  rpsResolution: RPSResolution
}

export type UpdateResolutionAppRequest = {
  params: UpdateResolutionAppRequestParams
  body: UpdateResolutionAppRequestBody
}

export type UpdateResolutionDocRequest = {
  params: UpdateResolutionDocRequestParams
  body: UpdateResolutionDocRequestBody
}

export type UpdateResolutionFieldRequest = {
  params: UpdateResolutionFieldRequestParams
  body: UpdateResolutionFieldRequestBody
}

export type GetFieldInfoResults =
  | {
      kind?: string
      data: GetFieldInfoResponse
    }
  | GeneralApiProblem

export type GetDocumentResults =
  | {
      kind?: string
      data: GetDocumentResponse
    }
  | GeneralApiProblem

export type GetDocumentVersionsResults =
  | {
      kind?: string
      data: GetDocumentVersionsResponse
    }
  | GeneralApiProblem

export type GetRejectionReasonAppResults =
  | {
      kind?: string
      data: GetRejectionReasonAppResponse
    }
  | GeneralApiProblem

export type GetRejectionReasonDocResults =
  | {
      kind?: string
      data: GetRejectionReasonDocResponse
    }
  | GeneralApiProblem

export type GetDocumentHeaderResponse = ApplicationSmall

export type GetDocumentHeaderResults =
  | {
      kind?: string
      data: GetDocumentHeaderResponse
    }
  | GeneralApiProblem

export type UpdateResolutionAppResponse = ApplicationVerification

export interface UpdateResolutionDocResponse
  extends Pick<DocumentSmall, 'id' | 'rpsResolution' | 'rejectionReason'> {
  application: ApplicationSmall['id']
}

export interface UpdateResolutionFieldResponse
  extends Pick<FieldVerification, 'id' | 'rpsResolution'> {
  document: DocumentSmall['id']
  application: ApplicationSmall['id']
}

export type UpdateResolutionAppResults =
  | {
      kind?: string
      data: UpdateResolutionAppResponse
    }
  | GeneralApiProblem

export type UpdateResolutionDocResults =
  | {
      kind?: string
      data: UpdateResolutionDocResponse
    }
  | GeneralApiProblem

export type UpdateResolutionFieldResults =
  | {
      kind?: string
      data: UpdateResolutionFieldResponse
    }
  | GeneralApiProblem

export type DocumentApi = {
  getDocumentHeader: (
    options: GetDocumentHeaderRequest
  ) => Promise<GetDocumentHeaderResults>
  getDocument: (options: GetDocumentRequest) => Promise<GetDocumentResults>
  getDocumentVersions: (
    options: GetDocumentVersionsRequest
  ) => Promise<GetDocumentVersionsResults>
  getDocumentHeaderToCompare: (
    options: GetDocumentHeaderRequest
  ) => Promise<GetDocumentHeaderResults>
  getDocumentToCompare: (
    options: GetDocumentRequest
  ) => Promise<GetDocumentResults>

  getRejectionReasonApp: (
    options: GetRejectionReasonAppRequest
  ) => Promise<GetRejectionReasonAppResults>
  getRejectionReasonDoc: (
    options: GetRejectionReasonDocRequest
  ) => Promise<GetRejectionReasonDocResults>

  updateResolutionApp: (
    options: UpdateResolutionAppRequest
  ) => Promise<UpdateResolutionAppResults>
  updateResolutionDocField: (
    options: UpdateResolutionDocRequest
  ) => Promise<UpdateResolutionDocResults>
  updateResolutionField: (
    options: UpdateResolutionFieldRequest
  ) => Promise<UpdateResolutionFieldResults>

  getFieldInfo: (options: GetFieldInfoRequest) => Promise<GetFieldInfoResults>
}
