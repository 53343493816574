import React, { FC } from 'react'
import { IconName as IconNameUI, Icon as IconUI } from '@cse/ui'

import { Row } from '../row'
import { concatClassNames } from '../helpers'

import * as Icons from './icons'
import { IconProps } from './types'
import { useStyle } from './icon.styles'

export const Icon: FC<IconProps> = (props) => {
  const classes = useStyle(props)
  if (Object.keys(IconNameUI).includes(props.name)) {
    // @ts-ignore
    const name: IconNameUI = IconNameUI[props.name]
    return (
      <IconUI
        {...props}
        className={concatClassNames(classes.icon, props.className)}
        name={name}
      />
    )
  }

  const Component = Object.keys(Icons).includes(props.name)
    ? // @ts-ignore
      Icons[props.name]
    : Row

  return (
    <Component
      {...props}
      className={concatClassNames(classes.icon, props.className)}
    />
  )
}
