import React, { FC } from 'react'

import { Counter, Icon, Row, Text } from '../../../components'

import { FieldsMenuHeaderProps } from './fields-menu-header.types'
import { useStyle } from './fields-menu-header.styles'

export const FieldsMenuHeader: FC<FieldsMenuHeaderProps> = ({
  className = '',
  isSelected,
  count,
  icon,
  title,
  titleTx
}) => {
  const classes = useStyle()
  const textColor = isSelected ? 'blue' : 'text'

  return (
    <Row justifyContent="space-between" className={className}>
      <Row>
        <Icon name={icon} />
        <Text
          className={classes.title}
          preset="h2"
          text={title}
          tx={titleTx}
          color={textColor}
        />
      </Row>
      <Counter className={classes.counter} count={count} />
    </Row>
  )
}
