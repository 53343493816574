import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../theme'
import { IconProps } from './types'

export const CloudDownload: FC<IconProps> = (props) => {
  const theme: LightTheme = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9 6.69317C12.4467 4.39317 10.4267 2.6665 8 2.6665C6.07333 2.6665 4.4 3.75984 3.56667 5.35984C1.56 5.57317 0 7.27317 0 9.33317C0 11.5398 1.79333 13.3332 4 13.3332H12.6667C14.5067 13.3332 16 11.8398 16 9.99984C16 8.23984 14.6333 6.81317 12.9 6.69317ZM12.6667 11.9998H4C2.52667 11.9998 1.33333 10.8065 1.33333 9.33317C1.33333 7.9665 2.35333 6.8265 3.70667 6.6865L4.42 6.61317L4.75333 5.97984C5.38667 4.75984 6.62667 3.99984 8 3.99984C9.74667 3.99984 11.2533 5.23984 11.5933 6.95317L11.7933 7.95317L12.8133 8.0265C13.8533 8.09317 14.6667 8.9665 14.6667 9.99984C14.6667 11.0998 13.7667 11.9998 12.6667 11.9998ZM8.66667 11.6667H7.33333V11H6V9.66667H8.66667V9H6.66667C6.3 9 6 8.7 6 8.33333V6.33333C6 5.96667 6.3 5.66667 6.66667 5.66667H7.33333V5H8.66667V5.66667H10V7H7.33333V7.66667H9.33333C9.7 7.66667 10 7.96667 10 8.33333V10.3333C10 10.7 9.7 11 9.33333 11H8.66667V11.6667Z"
        fill={props.fill || theme.colors.button}
      />
    </svg>
  )
}
