import React, { FC } from 'react'
import { DropdownItemData } from '@cse/ui'
import { useSelector } from 'react-redux'

import { DropdownFloatLabel, Row, TextTranslate } from '../../../components'
import { State } from '../../../store'

import { RejectionListProps } from './rejection-list.types'
import { useStyle } from './rejection-list.styles'

export const RejectionList: FC<RejectionListProps> = ({ data, onChange }) => {
  const classes = useStyle()

  const { rejectionReasonList } = useSelector((state: State) => ({
    ...state.document
  }))

  const handleOnChangeCode = (item: DropdownItemData<TextTranslate>) => {
    onChange({
      ...data,
      rejectionReason: Number(item.id)
    })
  }

  if (!rejectionReasonList) {
    return null
  }

  const { rejectionReason } = data
  return (
    <Row fullWidth>
      <DropdownFloatLabel
        className={classes.dropdown}
        label="Reason Code"
        preset="body"
        isPreSetted={Boolean(rejectionReason)}
        data={rejectionReasonList}
        active={rejectionReason}
        defaultValue={String(rejectionReason)}
        value={String(rejectionReason)}
        onChange={handleOnChangeCode}
      />
    </Row>
  )
}
