import { createUseStyles } from 'react-jss'

import { DropdownFloatLabelStyleProps } from '../dropdown-float-label.types'

export const useStyle = createUseStyles({
  caret: {
    marginTop: ({ custom }: DropdownFloatLabelStyleProps<any>) =>
      !custom ? 0 : custom.caret
  },
  container: {
    height: 24,
    width: 24
  },
  caretDown: {
    position: 'absolute',
    right: 0,
    transform: ({ open }: DropdownFloatLabelStyleProps<any>) =>
      open ? 'rotate(180deg) translateY(2px)' : 'rotate(0deg)',
    transition: 'transform 0.25s ease-in-out'
  }
})
