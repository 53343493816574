import { DropdownItemsData } from '@cse/ui'
import { useContext, useMemo } from 'react'

import { TextTranslate } from '../components'
import { DataProps } from '../pages'
import { UserContext } from '../providers'

import { useUserPrograms } from './useUserPrograms'

export const useUserData = ({ validationRule, catalog, user }: DataProps) => {
  const { isSuperUser, isSysAdmin, isRPS } = useContext(UserContext)

  const Results = useMemo(
    () => (validationRule ? validationRule.results : null),
    [validationRule?.results]
  )
  const UserProgramsData = useUserPrograms({ user, program: catalog.program })

  const UserPrograms: DropdownItemsData<TextTranslate> = useMemo(
    () => UserProgramsData.map((item) => ({ ...item, value: item.name })),
    [UserProgramsData]
  )
  const AllPrograms: DropdownItemsData<TextTranslate> = useMemo(
    () => catalog.program.map((item) => ({ ...item, value: item.name })),
    [catalog.program]
  )
  const ProgramsPageData = useMemo(
    () => (isRPS ? UserPrograms : AllPrograms),
    [isRPS, UserPrograms, AllPrograms]
  )

  const GeneralProgramsData = useMemo(
    () => (isSuperUser || isSysAdmin ? AllPrograms : UserPrograms),
    [isSuperUser, isSysAdmin, AllPrograms, UserPrograms]
  )

  return {
    Results,
    UserPrograms,
    AllPrograms,
    ProgramsPageData,
    GeneralProgramsData
  }
}
