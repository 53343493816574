import { lazy } from 'react'
import {
  Polygon,
  Resolution,
  RPSResolution,
  RPSResolutionNames
} from '../services'
import { Color } from '../theme'

import { IconName } from './icon'
import { StatusBadgeTypeName } from './status-badge'

export type ClassName = string | undefined

export type PolygonPositionCalculate = {
  left: number
  top: number
  leftEnd: number
  topEnd: number
}

export const arrayHasElements = <T>(data?: T[]) => {
  if (!data) {
    return false
  }

  return data.length > 0
}

export const getDashedFromSpace = (str: string) => str.replace(' ', '_')

export const concatClassNames = (...arr: ClassName[]) =>
  arr.reduce((acc, className) => {
    if (acc && className) {
      return `${acc} ${className}`
    }

    if (className) {
      return className
    }

    return acc
  }, '')

export const getColorByStatusResolution = (resolution: Resolution): Color => {
  switch (resolution) {
    case Resolution.PASS:
      return 'button'
    case Resolution.VERIFICATION:
      return 'orange'
    case Resolution.DECLINE:
      return 'hoverRedButton'
    case Resolution.IGNORE:
      return 'text'
    case Resolution.FYI:
      return 'purple'
    default:
      return 'text'
  }
}

export const getColorByStatusVerification = (resolution: Resolution): Color => {
  if (resolution === Resolution.PASS) {
    return 'button'
  }
  return 'hoverRedButton'
}

export const getResolutionTypeName = (
  resolution: Resolution
): StatusBadgeTypeName => {
  switch (resolution) {
    case Resolution.PASS:
      return StatusBadgeTypeName.PASS
    case Resolution.VERIFICATION:
      return StatusBadgeTypeName.VERIFICATION
    case Resolution.DECLINE:
      return StatusBadgeTypeName.DECLINE
    case Resolution.IGNORE:
      return StatusBadgeTypeName.IGNORE
    case Resolution.FYI:
      return StatusBadgeTypeName.INFORMATION
    default:
      return StatusBadgeTypeName.IGNORE
  }
}

export const getColorByStatusRpsResolution = (
  rpsResolution: RPSResolution
): Color => {
  switch (rpsResolution) {
    case RPSResolution.APPROVED:
      return 'button'
    case RPSResolution.IN_PROGRESS:
      return 'blue'
    case RPSResolution.INELIGIBLE:
      return 'hoverRedButton'
    case RPSResolution.INCOMPLETE:
      return 'orange'
    default:
      return 'grey'
  }
}

export const getResolutionIcon = (
  resolution: RPSResolution
): IconName | null => {
  switch (resolution) {
    case RPSResolution.APPROVED:
      return 'CheckCircle'
    case RPSResolution.IN_PROGRESS:
      return 'TIMELAPSE'
    case RPSResolution.INELIGIBLE:
      return 'TimesCircle'
    case RPSResolution.INCOMPLETE:
      return 'ExclamationCircle'
    default:
      return null
  }
}

export const getResolutionIconOutline = (
  resolution: RPSResolution
): IconName | null => {
  switch (resolution) {
    case RPSResolution.APPROVED:
      return 'CheckCircleOutline'
    case RPSResolution.IN_PROGRESS:
      return 'TIMELAPSE'
    case RPSResolution.INELIGIBLE:
      return 'HighlightOff'
    case RPSResolution.INCOMPLETE:
      return 'ExclamationCircle'
    default:
      return null
  }
}

export const getRpsResolutionTypeName = (
  rpsResolution: RPSResolution
): RPSResolutionNames | null => {
  switch (rpsResolution) {
    case RPSResolution.APPROVED:
      return RPSResolutionNames.APPROVED
    case RPSResolution.IN_PROGRESS:
      return RPSResolutionNames.IN_PROGRESS
    case RPSResolution.INELIGIBLE:
      return RPSResolutionNames.INELIGIBLE
    case RPSResolution.INCOMPLETE:
      return RPSResolutionNames.INCOMPLETE
    default:
      return null
  }
}

export const formatToNumber = (pureString: string): number => {
  const letterSymbolRegex = new RegExp(/[A-Za-z!@#$%^&*/,.()]/g)
  const alignedString = pureString.replace(letterSymbolRegex, '')
  return Number(alignedString)
}

export const getPolygonPosition = (data: Polygon) =>
  data.reduce<PolygonPositionCalculate>(
    (acc, dot) => {
      const nextLeft = acc.left > dot.x ? dot.x : acc.left
      const left = acc.left ? nextLeft : dot.x

      const nextLeftEnd = acc.leftEnd < dot.x ? dot.x : acc.leftEnd
      const leftEnd = acc.leftEnd ? nextLeftEnd : dot.x

      const nextTop = acc.top > dot.y ? dot.y : acc.top
      const top = acc.top ? nextTop : dot.y

      const nextTopEnd = acc.topEnd < dot.y ? dot.y : acc.topEnd
      const topEnd = acc.topEnd ? nextTopEnd : dot.y

      return {
        left,
        leftEnd,
        top,
        topEnd
      }
    },
    {
      left: 0,
      leftEnd: 0,
      top: 0,
      topEnd: 0
    }
  )

export const getComponent = (name: string) => (mod: any) => ({
  default: mod[name]
})

export const lazyComponent = <T>(
  componentImport: Promise<T>,
  component: string
) => lazy(() => componentImport.then(getComponent(component)))
