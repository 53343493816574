import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'
import { IconButtonStyleProps } from './icon-button.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    cursor: 'pointer',
    borderRadius: 12,
    padding: 8
  },
  hover: {
    '&:hover': {
      boxShadow: 'inset 0px 2px 2px rgba(0, 0, 0, 0.25)'
    },
    '&:active': {
      boxShadow: 'none'
    }
  },
  disabled: {
    backgroundColor: `${theme.colors.grey}!important`,
    cursor: 'default',
    '& *': {
      fill: `${theme.colors.inactive}!important`,
      cursor: 'default'
    }
  },
  primary: {
    backgroundColor: theme.colors.darkBlue,
    '& *': {
      fill: theme.colors.white
    },
    '&:hover': {
      backgroundColor: theme.colors.hoverBlueButton
    },
    '&:active, &[active]': {
      backgroundColor: theme.colors.darkBlue
    }
  },
  warning: {
    backgroundColor: theme.colors.orange,
    '& *': {
      fill: theme.colors.white
    },
    '&:hover': {
      backgroundColor: theme.colors.hoverYellowButton
    },
    '&:active, &[active]': {
      backgroundColor: theme.colors.orange
    }
  },
  danger: {
    backgroundColor: theme.colors.red,
    '& *': {
      fill: theme.colors.white
    },
    '&:hover': {
      backgroundColor: theme.colors.hoverRedButton
    },
    '&:active, &[active]': {
      backgroundColor: theme.colors.red
    }
  },
  success: {
    backgroundColor: theme.colors.button,
    '& *': {
      fill: theme.colors.white
    },
    '&:hover': {
      backgroundColor: theme.colors.hoverGreenButton
    },
    '&:active, &[active]': {
      backgroundColor: theme.colors.button
    }
  },
  search: {
    backgroundColor: theme.colors.purple,
    '& *': {
      fill: theme.colors.white
    },
    '&:hover': {
      backgroundColor: theme.colors.hoverPurpleButton
    },
    '&:active, &[active]': {
      backgroundColor: theme.colors.purple
    }
  },
  up: {
    borderRadius: '50%',
    backgroundColor: theme.colors.headerGradient,
    '&:hover *': {
      opacity: 0.7
    },
    '&:hover': {
      boxShadow: 'none'
    },
    '&:active *, &[active] *': {
      opacity: 1
    }
  },
  remove: {
    backgroundColor: theme.colors.blue,
    '& *': {
      fill: theme.colors.white
    },
    '&:hover': {
      backgroundColor: theme.colors.hoverBlueButton
    },
    '&:active, &[active]': {
      backgroundColor: theme.colors.blue
    }
  },
  transparent: {},
  hintContainer: {
    position: 'absolute',
    right: 0,
    top: 24,
    left: 30,
    width: 'max-content',
    padding: '4px 12px',
    backgroundColor: theme.colors.white,
    border: `1px solid ${theme.colors.border}`,
    borderRadius: 4,
    visibility: ({ hover }: IconButtonStyleProps) =>
      hover ? 'visible' : 'hidden',
    zIndex: 2
  },
  hint: {
    position: 'absolute'
  },
  message: {
    whiteSpace: 'break-spaces'
  },
  messageHint: {
    visibility: ({ hover }: IconButtonStyleProps) =>
      hover ? 'visible' : 'hidden'
  },
  messageContainer: {
    padding: '16px !important',
    maxWidth: 164
  }
}))
