import React, { useMemo } from 'react'

import { VersionLine } from '../../../../components'
import { DocumentSmall, DocumentVersion } from '../../../../services'
import { formatDateBySlash, getVerificationFields } from '../../../helpers'

import { SettingsProps } from './types'

export const useSettings = ({
  documentHeader,
  versionsToCompare,
  docId,
  fields,
  comparisonFields,
  activeField,
  activeComparisonField,
  options,
  options2,
  data,
  pages,
  comparisonData,
  comparisonPages
}: SettingsProps) => {
  const Versions = useMemo(() => {
    if (versionsToCompare !== null) {
      return versionsToCompare?.map((item: DocumentVersion) => {
        const date = formatDateBySlash(item.submissionVersionDt)
        const { applicationId: versionId, submissionVersion } = item

        return {
          id: String(versionId),
          name: (
            <VersionLine submissionVersion={submissionVersion} date={date} />
          ),
          value: `Version #${submissionVersion} from ${date}`
        }
      })
    }

    return []
  }, [versionsToCompare])

  const DocName = useMemo(() => {
    const doc =
      documentHeader &&
      documentHeader.documents.find(
        (item: DocumentSmall) => item.id === Number(docId)
      )
    const name = doc ? doc.documentName : ''

    return name
  }, [docId])

  // get the document tab number to get correct doc tab for comparison
  const DocNumber = useMemo(() => {
    const doc =
      documentHeader &&
      documentHeader.documents.find(
        (item: DocumentSmall) => item.id === Number(docId)
      )
    const tabDocNumber = doc ? doc.document : ''

    return Number(tabDocNumber)
  }, [docId])

  const Document = useMemo(() => {
    if (documentHeader) {
      return documentHeader.documents.find(
        (item: DocumentSmall) => item.id === Number(docId)
      )
    }
  }, [docId])

  const Fields = useMemo(() => getVerificationFields(fields), [fields])

  const ComparisonFields = useMemo(
    () => getVerificationFields(comparisonFields),
    [comparisonFields]
  )

  const FirstDocData = useMemo(
    () => ({
      activeField,
      defaultValue: options,
      data,
      verificationFields: fields,
      pages,
      options,
      fields: Fields
    }),
    [activeField, options, data, fields, Fields, pages]
  )

  const SecondDocData = useMemo(
    () => ({
      activeField: activeComparisonField,
      defaultValue: options2,
      data: comparisonData,
      verificationFields: comparisonFields,
      pages: comparisonPages,
      options: options2,
      fields: ComparisonFields
    }),
    [
      activeComparisonField,
      options2,
      ComparisonFields,
      comparisonData,
      comparisonFields,
      comparisonPages
    ]
  )

  return {
    Versions,
    DocNumber,
    DocName,
    Document,
    Fields,
    ComparisonFields,
    FirstDocData,
    SecondDocData
  }
}
