import React from 'react'
import { MEDIUM_SCREEN_WIDTH } from '../content'

import { ContentTypeName } from '../content.types'
import { OutSettingsProps, SettingsProps } from './types'

export const useSettings = ({ preset }: SettingsProps): OutSettingsProps => {
  const width = window.outerWidth
  const isSettings = preset === ContentTypeName.settings
  const isMiddleScreen = width < MEDIUM_SCREEN_WIDTH || isSettings
  const alignedPreset = isMiddleScreen ? preset : ContentTypeName.extraLarge

  return {
    alignedPreset
  }
}
