import React, { FC } from 'react'

import { IconName, IconProps } from './types'

import { Dashboard } from './dashboard'
import { Logo } from './logo'
import { LogoCse } from './logo-cse'
import { Settings } from './settings'
import { Extension } from './extension'
import { ArrowDropDown } from './arrow-drop-down'
import { ArrowDropUp } from './arrow-drop-up'
import { WeighingMachine } from './weighing-machine'
import { Restore } from './restore'
import { Save } from './save'
import { Print } from './print'
import { CaretDown } from './caret-down'
import { BreadCrumbs } from './bread-crumbs'
import { FilterDrama } from './filter-drama'
import { CheckCircleOutline } from './check-circle-outline'
import { Dot } from './dot'
import { RadioButtonUnchecked } from './radio-button-unchecked'
import { RadioButtonActive } from './radio-button-active'
import { RadioButtonDone } from './radio-button-done'
import { CheckboxActive } from './checkbox-active'
import { CheckboxInactive } from './checkbox-inactive'
import { CheckboxSelected } from './checkbox-selected'
import { EVStation } from './ev-station'
import { DirectionsCar } from './directions-car'
import { Monetization } from './monetization'
import { MoreHorizontal } from './more-horizontal'
import { Undo } from './undo'
import { Visibility } from './visibility'
import { VisibilityOff } from './visibility-off'
import { Add } from './add'
import { Clear } from './clear'
import { Delete } from './delete'
import { Compare } from './compare'
import { StarRate } from './star-rate'
import { ArrowBack } from './arrow-back'
import { User } from './user'
import { Ellipse9 } from './ellipse9'
import { Ellipse12 } from './ellipse12'
import { AlertCircle } from './alert-circle'
import { Phase1 } from './phase-1'
import { Phase2 } from './phase-2'
import { Category } from './category'
import { TotalCost } from './total-cost'
import { Collapse } from './collapse'
import { BarChart } from './bar-chart'
import { ExpandAll } from './expand-all'
import { Oval } from './oval'
import { EmptyList } from './empty-list'
import { Vector } from './vector'
import { Mode } from './mode'
import { Plot } from './plot'
import { Money } from './money'
import { DonutSmall } from './donut-small'
import { Battery } from './battery'
import { ReplyAll } from './reply-all'
import { ViewAgenda } from './view-agenda'
import { TableChart } from './table-chart'
import { Timelapse } from './timelapse'
import { CloudDownload } from './cloud-download'
import { AttachMoney } from './attach-money'
import { EvStationSmall } from './ev-station-small'
import { FilterDramaSmall } from './filter-drama-small'
import { Frame } from './frame'
import { Widgets } from './widgets'
import { ExclamationCircle } from './exclamation-circle'
import { TimesCircle } from './times-circle'
import { CheckCircle } from './check-circle'

export const Icon: FC<IconProps> = (props) => {
  switch (props.name) {
    case IconName.LOGO:
      return <Logo {...props} />
    case IconName.LOGO_CSE:
      return <LogoCse {...props} />
    case IconName.SETTINGS:
      return <Settings {...props} />
    case IconName.DASHBOARD:
      return <Dashboard {...props} />
    case IconName.EXTENSION:
      return <Extension {...props} />
    case IconName.ARROW_DROP_DOWN:
      return <ArrowDropDown {...props} />
    case IconName.WEIGHING_MACHINE:
      return <WeighingMachine {...props} />
    case IconName.RESTORE:
      return <Restore {...props} />
    case IconName.SAVE:
      return <Save {...props} />
    case IconName.PRINT:
      return <Print {...props} />
    case IconName.CARET_DOWN:
      return <CaretDown {...props} />
    case IconName.BREAD_CRUMBS:
      return <BreadCrumbs {...props} />
    case IconName.ARROW_DROP_UP:
      return <ArrowDropUp {...props} />
    case IconName.FILTER_DRAMA:
      return <FilterDrama {...props} />
    case IconName.CHECK_CIRCLE_OUTLINE:
      return <CheckCircleOutline {...props} />
    case IconName.DOT:
      return <Dot {...props} />
    case IconName.RADIO_BUTTON_UNCHECKED:
      return <RadioButtonUnchecked {...props} />
    case IconName.RADIO_BUTTON_ACTIVE:
      return <RadioButtonActive {...props} />
    case IconName.RADIO_BUTTON_DONE:
      return <RadioButtonDone {...props} />
    case IconName.CHECK_BOX_ACTIVE:
      return <CheckboxActive {...props} />
    case IconName.CHECK_BOX_INACTIVE:
      return <CheckboxInactive {...props} />
    case IconName.CHECK_BOX_SELECTED:
      return <CheckboxSelected {...props} />
    case IconName.EV_STATION:
      return <EVStation {...props} />
    case IconName.DIRECTION_CAR:
      return <DirectionsCar {...props} />
    case IconName.MORE_HORIZON:
      return <MoreHorizontal {...props} />
    case IconName.UNDO:
      return <Undo {...props} />
    case IconName.VISIBILITY:
      return <Visibility {...props} />
    case IconName.VISIBILITY_OFF:
      return <VisibilityOff {...props} />
    case IconName.ADD:
      return <Add {...props} />
    case IconName.CLEAR:
      return <Clear {...props} />
    case IconName.DELETE:
      return <Delete {...props} />
    case IconName.COMPARE:
      return <Compare {...props} />
    case IconName.STAR_RATE:
      return <StarRate {...props} />
    case IconName.ARROW_BACK:
      return <ArrowBack {...props} />
    case IconName.USER:
      return <User {...props} />
    case IconName.MONETIZATION:
      return <Monetization {...props} />
    case IconName.ELLIPSE9:
      return <Ellipse9 {...props} />
    case IconName.ELLIPSE12:
      return <Ellipse12 {...props} />
    case IconName.ALERT_CIRCLE:
      return <AlertCircle {...props} />
    case IconName.PHASE_1:
      return <Phase1 {...props} />
    case IconName.PHASE_2:
      return <Phase2 {...props} />
    case IconName.CATEGORY:
      return <Category {...props} />
    case IconName.TOTAL_COST:
      return <TotalCost {...props} />
    case IconName.COLLAPSE:
      return <Collapse {...props} />
    case IconName.BAR_CHART:
      return <BarChart {...props} />
    case IconName.EXPAND_ALL:
      return <ExpandAll {...props} />
    case IconName.OVAL:
      return <Oval {...props} />
    case IconName.EMPTY_LIST:
      return <EmptyList {...props} />
    case IconName.VECTOR:
      return <Vector {...props} />
    case IconName.MODE:
      return <Mode {...props} />
    case IconName.PLOT:
      return <Plot {...props} />
    case IconName.MONEY:
      return <Money {...props} />
    case IconName.DONUT_SMALL:
      return <DonutSmall {...props} />
    case IconName.BATTERY:
      return <Battery {...props} />
    case IconName.REPLY_ALL:
      return <ReplyAll {...props} />
    case IconName.VIEW_AGENDA:
      return <ViewAgenda {...props} />
    case IconName.TABLE_CHART:
      return <TableChart {...props} />
    case IconName.ATTACH_MONEY:
      return <AttachMoney {...props} />
    case IconName.CLOUD_DOWNLOAD:
      return <CloudDownload {...props} />
    case IconName.TIMELAPSE:
      return <Timelapse {...props} />
    case IconName.FILTER_DRAMA_SMALL:
      return <FilterDramaSmall {...props} />
    case IconName.EV_STATION_SMALL:
      return <EvStationSmall {...props} />
    case IconName.FRAME:
      return <Frame {...props} />
    case IconName.WIDGETS:
      return <Widgets {...props} />
    case IconName.EXCLAMATION_CIRCLE:
      return <ExclamationCircle {...props} />
    case IconName.TIMES_CIRCLE:
      return <TimesCircle {...props} />
    case IconName.CHECK_CIRCLE:
      return <CheckCircle {...props} />

    default:
      return <div />
  }
}
