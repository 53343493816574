import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'
import { DocumentInfoStyleProps } from './document-info.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    padding: '0 16px'
  },
  detailedHeader: ({ open }: DocumentInfoStyleProps) => ({
    height: open ? 'auto' : 0,
    transition: 'all 0.25s ease-in-out',
    background: theme.colors.detailedHeader
  }),
  loadingContainer: {
    minHeight: 768
  },
  noPadding: {
    padding: 0
  }
}))
