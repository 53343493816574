import { ForwardedRef, useLayoutEffect, useRef } from 'react'

export const useForwardedRef = <T>(forwardedRef?: ForwardedRef<T>) => {
  const ref = useRef<T>(null)
  useLayoutEffect(() => {
    if (forwardedRef) {
      if (typeof forwardedRef === 'function') {
        forwardedRef(ref.current)
      } else {
        forwardedRef.current = ref.current
      }
    }
  }, [ref])

  return ref
}
