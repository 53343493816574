import React, { FC } from 'react'

import { EmptySearchResults } from '../empty-search-results'

import { EmptyListProps } from './empty-list.types'

export const EmptyList: FC<EmptyListProps> = ({ search }: EmptyListProps) => {
  if (search) {
    return <EmptySearchResults />
  }

  return <EmptySearchResults text={'request'} />
}
