import React, { FC, useMemo } from 'react'

import { Column, Row, Text, concatClassNames } from '../../../../components'
import { formatDateByTextDetailed } from '../../../helpers'

import { ListItemProps } from './logs-list-item.types'
import { useStyle } from './logs-list-item.styles'

export const ListItem: FC<ListItemProps> = ({
  className = '',
  id,
  program,
  userName,
  createdAt,
  message,
  application,
  onClick
}) => {
  const classes = useStyle()

  const logDate = useMemo(
    () => formatDateByTextDetailed(createdAt),
    [createdAt]
  )

  const handleOnItemClick = () => {
    onClick(id)
  }

  return (
    <Row
      className={concatClassNames(className, classes.container)}
      justifyContent="space-between"
    >
      <Row
        className={classes.subContainer}
        fullWidth
        justifyContent="space-between"
        onClick={handleOnItemClick}
      >
        <Column
          className={classes.program}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Text className={classes.text} text={program} preset="caption" />
        </Column>
        <Column
          className={classes.column}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Text
            className={concatClassNames(classes.text, classes.dotsOverflow)}
            text={application?.name}
            preset="caption"
            color="blue"
          />
        </Column>
        <Column
          className={concatClassNames(classes.message, classes.dotsOverflow)}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Text text={message} preset="caption" />
        </Column>
        <Column
          className={classes.date}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Text
            className={concatClassNames(classes.dotsOverflow)}
            text={userName}
            preset="caption"
          />
        </Column>
        <Column
          className={classes.date}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Text text={logDate} preset="caption" />
        </Column>
      </Row>
    </Row>
  )
}
