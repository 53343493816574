import React, { FC } from 'react'

import { Row } from '../row'
import { Spinner } from '../spinner'

import { LoadingContainerProps } from './loading-container.types'

export const LoadingContainer: FC<LoadingContainerProps> = ({
  children,
  className = '',
  loading
}) => {
  if (loading) {
    return (
      <Row className={className}>
        <Spinner isLoading={loading} />
      </Row>
    )
  }

  return <>{children}</>
}
