import React, { FC } from 'react'

import { IconProps } from './types'

export const Money: FC<IconProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 4V20H22V4H2ZM7 8H5V16H7V8ZM12 16H9C8.45 16 8 15.55 8 15V9C8 8.45 8.45 8 9 8H12C12.55 8 13 8.45 13 9V15C13 15.55 12.55 16 12 16ZM15 16H18C18.55 16 19 15.55 19 15V9C19 8.45 18.55 8 18 8H15C14.45 8 14 8.45 14 9V15C14 15.55 14.45 16 15 16ZM16 10H17V14H16V10ZM10 10H11V14H10V10ZM4 18H20V6H4V18Z"
        fill="#59B3B9"
      />
    </svg>
  )
}
