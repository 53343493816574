import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../../theme'
import { SVGIconProps } from './icons.types'

export const Breadcrumbs: FC<SVGIconProps> = (props) => {
  const theme: LightTheme = useTheme()
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2803 16.5303C11.9874 16.8232 11.5125 16.8232 11.2197 16.5303L7.21967 12.5303C6.92677 12.2374 6.92677 11.7626 7.21967 11.4697L11.2197 7.46967C11.5125 7.17678 11.9874 7.17678 12.2803 7.46967C12.5732 7.76256 12.5732 8.23744 12.2803 8.53033L8.81067 12L12.2803 15.4697C12.5732 15.7626 12.5732 16.2374 12.2803 16.5303Z"
        fill={props.fill || theme.colors.text}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5303 16.2803C16.2374 16.5732 15.7625 16.5732 15.4697 16.2803L11.4697 12.2803C11.1768 11.9874 11.1768 11.5126 11.4697 11.2197L15.4697 7.21967C15.7625 6.92678 16.2374 6.92678 16.5303 7.21967C16.8232 7.51256 16.8232 7.98744 16.5303 8.28033L13.0607 11.75L16.5303 15.2197C16.8232 15.5126 16.8232 15.9874 16.5303 16.2803Z"
        fill={props.fill || theme.colors.text}
      />
    </svg>
  )
}
