import React, { FC } from 'react'

import { Row } from '../row'
import { Text } from '../text'
import { Item } from '../item'

import { OptionLineProps } from './option-line.types'
import { useStyle } from './option-line.styles'

export const OptionLine: FC<OptionLineProps> = ({
  className = '',
  title,
  titleTx,
  value,
  valueTx,
  preset
}) => {
  const classes = useStyle()

  return (
    <Row className={className}>
      {title && (
        <Text text={title} tx={titleTx} preset="caption" color="border" />
      )}
      <Item className={classes.description} value={value}>
        <Text
          className={classes.description}
          text={String(value)}
          tx={valueTx}
          preset={preset}
        />
      </Item>
    </Row>
  )
}
