import React, { FC, useEffect, useState, useMemo } from 'react'
import { DropdownItemData } from '@cse/ui'

import {
  Button,
  concatClassNames,
  Dropdown,
  PageHeader,
  Row,
  TextTranslate
} from '../../../components'
import { MainRoutes } from '../../../routes'
import { RightTitleContent } from './right-title-content'
import { useData } from './helpers'

import { Filters, ProgramHeaderFilters } from '../program.types'
import { ProgramHeaderProps } from './program-header.types'
import { useStyle } from './program-header.styles'

export const ProgramHeader: FC<ProgramHeaderProps> = ({
  filteredCatalog,
  programs,
  updateDate,
  programId,
  defaultValues,
  onChange,
  onClear
}) => {
  const classes = useStyle()
  const [values, changeValues] = useState<Filters>(defaultValues)

  const { Documents, DocumentsTypes, Fields, isFilter } = useData({
    filteredCatalog,
    values
  })

  useEffect(() => {
    changeValues(defaultValues)
  }, [defaultValues])

  const handleOnChange =
    (prop: ProgramHeaderFilters) => (item: DropdownItemData<TextTranslate>) => {
      if (onChange) {
        onChange(prop, item.id)
      }
    }

  const programFilter = useMemo(
    () => (
      <Dropdown
        className={concatClassNames(
          classes.program,
          classes.field,
          classes.dropdown
        )}
        isScrollable
        isPreSetted
        data={programs}
        preset="body"
        label="Program"
        active={programId}
        defaultValue={programId}
        onChange={handleOnChange('program')}
      />
    ),
    [programs, programId]
  )

  return (
    <PageHeader
      back
      backLink={MainRoutes.PROGRAM_RULES}
      rightTitleContent={<RightTitleContent date={updateDate} />}
      programFilter={programFilter}
      title="Rules by program"
      titleTx="program.header.title"
    >
      <Dropdown
        className={concatClassNames(
          classes.docs,
          classes.field,
          classes.dropdown
        )}
        isScrollable
        data={Documents}
        preset="body"
        label="Document"
        active={values.document}
        defaultValue={values.document}
        value={values.document}
        onChange={handleOnChange('document')}
      />
      <Dropdown
        className={concatClassNames(
          classes.docTypes,
          classes.field,
          classes.dropdown
        )}
        isScrollable
        data={DocumentsTypes}
        label="Document type"
        preset="body"
        active={values.documentTypes}
        defaultValue={values.documentTypes}
        value={values.documentTypes}
        onChange={handleOnChange('documentTypes')}
      />
      <Dropdown
        className={concatClassNames(
          classes.docTypes,
          classes.field,
          classes.dropdown
        )}
        isScrollable
        data={Fields}
        label="Field"
        preset="body"
        active={values.field}
        defaultValue={values.field}
        value={values.field}
        onChange={handleOnChange('field')}
      />
      {isFilter && (
        <Row className={classes.actions}>
          <Button
            className={classes.button}
            preset="secondary"
            text="Clear"
            tx="program.clear"
            onClick={onClear}
          />
        </Row>
      )}
    </PageHeader>
  )
}
