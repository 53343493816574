import { GeneralApiProblem } from '../api-problem'
import { List, ListQuery } from '../api.types'
import { DocumentType, RawCoreItem, Resolution } from '../app'
import { Document, FieldVerification } from '../document'
import { ProgramSettings } from '../settings'

export enum ValidationRulePath {
  PROGRAM_RULES = '/validation-rules/programs/list',
  LIST = '/validation-rules/by-field/list',
  DOCUMENT_TYPES = '/validation-rules/by-field/document_types'
}

export type ValidationRule = {
  id: number
  title: string
  program: ProgramSettings['id']
  type: number
  value: number
  manualValue: number | null
  resolution: Resolution
  field: number
  document: number
  updatedAt: Date
}

export type ValidationDocumentType = {
  id: number
  title: string
}

export interface GetValidationRuleListRequestQuery extends ListQuery {
  program?: ProgramSettings['id']
  field?: FieldVerification['id']
  document?: Document['id']
  documentTypes?: DocumentType
}

export interface GetValidationRuleDocumentTypesQuery {
  rulePk: ValidationRule['id']
  search?: string
}

export type GetValidationRuleListRequest = {
  query?: GetValidationRuleListRequestQuery
}

export type GetValidationRuleDocumentTypesRequest = {
  query: GetValidationRuleDocumentTypesQuery
}

export type GetValidationProgramsRequest = {
  query: any
}

export type GetValidationRuleFilteredList = {
  fields: RawCoreItem[]
  documentTypes: RawCoreItem[]
  document: RawCoreItem[]
  program: RawCoreItem[]
}

export type ValidationProgram = {
  id: number
  title: string
  state: string
  updatedAt: Date
  validationUpdateDt: Date
}

export interface GetValidationRuleListResponse extends List<ValidationRule> {
  filteredCatalog: GetValidationRuleFilteredList
}

export interface GetValidationRuleDocumentTypesResponse
  extends Pick<List<ValidationDocumentType>, 'count' | 'results'> {
  rulePk: GetValidationRuleDocumentTypesQuery['rulePk']
}

export interface GetValidationProgramsResponse {
  count: number
  results: ValidationProgram[]
}

export type GetValidationRuleListResults =
  | {
      kind?: string
      data: GetValidationRuleListResponse
    }
  | GeneralApiProblem

export type GetValidationRuleDocumentTypesResults =
  | {
      kind?: string
      data: GetValidationRuleDocumentTypesResponse
    }
  | GeneralApiProblem

export type GetValidationProgramsResults =
  | {
      kind?: string
      data: GetValidationProgramsResponse
    }
  | GeneralApiProblem

export type ValidationRuleApi = {
  getValidationPrograms: () => Promise<GetValidationProgramsResults>
  list: (
    options: GetValidationRuleListRequest
  ) => Promise<GetValidationRuleListResults>
  documentTypes: (
    options: GetValidationRuleDocumentTypesRequest
  ) => Promise<GetValidationRuleDocumentTypesResults>
}
