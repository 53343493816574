import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

import { SpinnerProps } from './spinner.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  '@keyframes spinner': {
    from: {
      boxShadow: `0 50px 0 ${theme.colors.blue}`
    },
    to: {
      boxShadow: ({ isBlue }: SpinnerProps) =>
        isBlue
          ? `0 40px 0 ${theme.colors.blue}`
          : `0 20px 0 ${theme.colors.orange}`
    }
  },
  circle: {
    width: 10,
    height: 10,
    marginTop: '-78px',
    borderRadius: '50%',
    boxShadow: `0 20px 0 ${theme.colors.blue}`,
    animation: '$spinner 1s ease-in-out alternate infinite',
    '-webkit-animation': '$spinner 1s ease-in-out alternate infinite',
    animationDelay: '0.32s',

    '&:after, &:before': {
      content: '""',
      position: 'absolute',
      width: 10,
      height: 10,
      borderRadius: '50%',
      boxShadow: `0 20px 0 ${theme.colors.blue}`,
      animation: '$spinner 1s ease-in-out alternate infinite',
      '-webkit-animation': '$spinner 1s ease-in-out alternate infinite'
    },
    '&:before': {
      left: '-18px',
      animationDelay: '0.48s'
    },
    '&:after': {
      right: '-18px',
      animationDelay: '0.16s'
    }
  },
  medium: {
    width: 70,
    height: 20
  },
  small: {
    width: 70,
    height: 20
  }
}))
