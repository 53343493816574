import React, { FC } from 'react'

import { Counter, Icon, Row, Text } from '../../../components'

import { useStyle } from './fields-menu-header.styles'
import { FieldsMenuHeaderProps } from './fields-menu-header.types'

export const FieldsMenuHeader: FC<FieldsMenuHeaderProps> = ({
  className = '',
  count,
  icon,
  title,
  titleTx
}) => {
  const classes = useStyle()

  return (
    <Row fullWidth justifyContent="space-between" className={className}>
      <Row>
        <Icon name={icon} />
        <Text className={classes.title} preset="h2" text={title} tx={titleTx} />
      </Row>
      <Counter className={classes.counter} count={count} />
    </Row>
  )
}
