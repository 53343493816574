import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  title: {
    marginLeft: 8
  },
  counter: {
    marginLeft: 16
  }
})
