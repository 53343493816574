import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../theme'

import { FieldValueTooltipStyleProps } from './field-value-tooltip.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    zIndex: 100,
    position: 'fixed',
    top: ({ position }: FieldValueTooltipStyleProps) => position.y,
    left: '70%'
  },
  arrow: {
    position: 'absolute',
    bottom: '-6px',
    left: '5%',
    background: theme.colors.white,
    borderBottom: `1px solid ${theme.colors.footerBackground}`,
    borderLeft: `1px solid ${theme.colors.footerBackground}`,
    padding: 7,
    transform: ({ position }: FieldValueTooltipStyleProps) =>
      `translateX(8px) translateY(${position.arrowY}px) rotate(-45deg)`,
    transformOrigin: 'center',
    zIndex: 1
  },
  content: {
    background: theme.colors.white,
    boxShadow: theme.boxShadow,
    border: `1px solid ${theme.colors.footerBackground}`,
    borderRadius: 12,
    padding: 16,
    width: ({ position }: FieldValueTooltipStyleProps) => position.x
  }
}))
