import { createUseStyles } from 'react-jss'

import { LightTheme } from '../theme'

import { DropdownItemsStyleProps } from './dropdown-items.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    cursor: ({ disabled }: DropdownItemsStyleProps<any>) =>
      disabled ? 'default' : theme.cursor
  },
  scrollItemsContainer: {
    scrollbarFaceColor: theme.colors.grey /* Firefox 63 compatibility */,
    scrollbarTrackColor: 'transparent',
    scrollbarColor: `${theme.colors.grey} ${theme.colors.white}`,
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      width: 4
    },
    /* Track */
    '&::-webkit-scrollbar-track, ::-webkit-scrollbar-track-piece': {
      backgroundColor: theme.colors.white
    },
    /* Handle */
    '&::-webkit-scrollbar-thumb, &::-webkit-scrollbar-thumb:vertical': {
      backgroundColor: theme.colors.grey,
      borderRadius: 14
    },
    /* Handle on hover */
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: theme.colors.hoverGrey
    }
  },
  listContainer: {
    height: ({ listHeight, isScrollable }: DropdownItemsStyleProps<any>) =>
      isScrollable ? listHeight : 'auto',
    overflow: ({ isScrollable }: DropdownItemsStyleProps<any>) =>
      isScrollable ? 'auto' : 'visible'
  },
  content: {
    zIndex: 10
  }
}))
