import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Icon, Row, Text } from '../../components'

import { BreadcrumbsProps } from './breadcrumbs.types'
import { useStyle } from './breadcrumbs.styles'

export const Breadcrumbs = ({ text, tx, to }: BreadcrumbsProps) => {
  const classes = useStyle()
  const navigate = useNavigate()

  const goBack = () => {
    if (to) {
      navigate(to)
    } else {
      navigate(-1)
    }
  }

  return (
    <Row className={classes.breadcrumbs} onClick={goBack}>
      <Icon name="Breadcrumbs" />
      {text && <Text tx={tx} text={text} preset="body" />}
    </Row>
  )
}
