import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  modal: {
    height: 'auto'
  },
  title: {
    marginLeft: 16,
    '& > *': {
      padding: '32px 0'
    }
  },
  description: {
    width: '100%',
    '& > *': {
      paddingBottom: 40
    }
  }
})
