import React, { FC, useMemo, useState } from 'react'
import { useDefaultValue } from '@cse/ui'

import { Column } from '../column'
import { concatClassNames } from '../helpers'
import { Icon } from '../icon'
import { Row } from '../row'

import { MenuSliderProps } from './menu-slider.types'
import { useStyle } from './menu-slider.styles'

export const MenuSlider: FC<MenuSliderProps> = ({
  arrow = true,
  className,
  children,
  open: value,
  headerClassName,
  defaultOpen,
  header,
  headerClose,
  contentRef,
  onOpen
}) => {
  const [open, changeOpen] = useState(Boolean(defaultOpen))
  const Value = useDefaultValue(open, value)
  const classes = useStyle({ open: Value })

  const headerCloseState = useMemo(
    () => headerClose || header,
    [headerClose, header]
  )
  const headerByState = useMemo(
    () => (Value ? header : headerCloseState),
    [headerCloseState, header, Value]
  )

  const handleOnOpen = () => {
    changeOpen(!Value)

    if (onOpen) {
      onOpen(!Value)
    }
  }

  const content = Value ? <>{children}</> : <></>

  return (
    <Column className={concatClassNames(className, classes.container)}>
      <Row
        fullWidth
        className={concatClassNames(headerClassName, classes.header)}
        justifyContent="space-between"
        onClick={handleOnOpen}
      >
        {headerByState}
        {arrow && <Icon className={classes.arrow} name="ARROW_DROP_DOWN" />}
      </Row>
      <Column
        className={classes.content}
        justifyContent="flex-start"
        ref={contentRef}
      >
        {content}
      </Column>
    </Column>
  )
}
