import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  rightArrow: {
    transform: 'rotate(180deg)',
    transformOrigin: 'center'
  },
  container: {
    background: theme.colors.white,
    padding: 16,
    width: 'calc(100% - 32px)'
  },
  activePage: {
    borderRadius: 6,
    border: `1px solid ${theme.colors.border}`,
    height: 24,
    minWidth: 24
  },
  text: {
    marginLeft: 8
  }
}))
