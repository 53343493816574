import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  description: {
    textAlign: 'center',
    marginTop: 32
  },
  resolution: {
    marginTop: 32
  },
  types: {
    flexWrap: 'wrap',
    '& > *:nth-child(n%2)': {
      marginLeft: 8
    },
    '& > *': {
      marginTop: 8
    }
  }
})
