import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  saveSettings: {
    marginTop: 48
  },
  spinner: {
    height: 40
  }
})
