import { ReactNode } from 'react'

export enum ContentTypeName {
  basic = 'basic',
  large = 'large',
  extraLarge = 'extraLarge',
  settings = 'settings'
}

export interface ContentPresetName {
  basic: string
  large: string
  extraLarge: string
  settings: string
}

export type ContentProps = {
  className?: string
  preset?: keyof ContentPresetName
  children?: ReactNode
}
