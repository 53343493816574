import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import {
  Breadcrumbs,
  Column,
  Content,
  IconButton,
  OptionLine,
  Row,
  StatusBadge,
  Text
} from '../../../components'
import { MainRoutes } from '../../../routes'
import { DeleteAppModal } from '../../../views'
import { ApplicationsCreators } from '../../../store'
import { useSettings } from './hooks'

import { DocsHeaderProps } from './docs-header.types'
import { useStyle } from './docs-header.styles'

export const DocsHeader = ({ data, catalog }: DocsHeaderProps) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const classes = useStyle()
  const [openModal, changeOpenModal] = useState(false)

  const { title, resolution, applicantName, rpsReviewer } = data
  const { reviewer, isDeleteAvailable } = useSettings({
    catalog,
    rpsReviewer
  })

  const handleOnOpenModal = useCallback(
    (state: boolean) => () => {
      changeOpenModal(state)
    },
    [openModal]
  )

  const handleOnSubmit = () => {
    if (data) {
      const deleteApplicationRequestData = {
        params: { id: String(data.id) }
      }

      dispatch(
        ApplicationsCreators.deleteApplicationRequest(
          deleteApplicationRequestData
        )
      )
    }

    navigate(MainRoutes.APPLICATION)
    handleOnOpenModal(false)()
  }

  if (!data) {
    return null
  }

  return (
    <Column fullWidth className={classes.container}>
      <Content className={classes.noPadding}>
        <Row fullWidth justifyContent="space-between">
          <Breadcrumbs
            tx="breadcrumbs.backToApplications"
            text="Back to Applications"
            to={MainRoutes.APPLICATION}
          />
        </Row>
        <Column fullWidth>
          <Row fullWidth justifyContent="space-between" className={classes.row}>
            <Row>
              <Text text={title} preset="h1" />
              <StatusBadge className={classes.badge} value={resolution} />
            </Row>
            {isDeleteAvailable && (
              <IconButton
                name="DELETE"
                preset="remove"
                onClick={handleOnOpenModal(true)}
              />
            )}
          </Row>
          <Row fullWidth justifyContent="flex-start" className={classes.row}>
            <Row>
              <Text
                tx="document.applicant"
                text="Applicant:"
                preset="caption"
                color="border"
              />
              <OptionLine
                className={classes.text}
                preset="body"
                value={applicantName}
              />
            </Row>
            <Row className={classes.info}>
              <Text
                tx="document.rps"
                text="RPS:"
                preset="caption"
                color="border"
              />
              <OptionLine
                className={classes.text}
                preset="body"
                value={reviewer}
              />
            </Row>
          </Row>
        </Column>
        <DeleteAppModal
          open={openModal}
          className={classes.deleteApp}
          title="Are you sure?"
          titleTx="documents.questionModal.title"
          description="Do you want to delete the “{title}” application?"
          descriptionTx="documents.deleteModal.text"
          appTitle={data?.title}
          onClose={handleOnOpenModal(false)}
          onSubmit={handleOnSubmit}
        />
      </Content>
    </Column>
  )
}
