import React, { FC } from 'react'

import { IconProps } from './types'

export const Battery: FC<IconProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.67 4H14V2H10V4H8.33C7.6 4 7 4.6 7 5.33V20.66C7 21.4 7.6 22 8.33 22H15.66C16.4 22 17 21.4 17 20.67V5.33C17 4.6 16.4 4 15.67 4ZM13 16V18H11V16H13ZM13.63 13.4C13.92 13.11 14.3 12.69 14.3 12.69C14.73 12.26 15 11.66 15 11C15 9.34 13.66 8 12 8C10.34 8 9 9.34 9 11H10.5C10.5 10.17 11.17 9.5 12 9.5C12.83 9.5 13.5 10.17 13.5 11C13.5 11.41 13.33 11.79 13.06 12.06L12.13 13C11.66 13.48 11.2 14.17 11.2 15H12.8C12.8 14.55 13.15 13.88 13.63 13.4Z"
        fill="#5B59B9"
      />
    </svg>
  )
}
