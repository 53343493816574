import { GeneralApiProblem } from '../api-problem'

export enum AuthPathes {
  GET_OKTA_URL = '/user/okta/url',
  REFRESH_TOKEN = '/user/token-refresh',
  VERIFICATION_TOKEN = '/user/token-verify',
  GET_USER_ME = '/user/me'
}

export enum CustomRole {
  RPS = 'RPS',
  COORDINATOR = 'COORDINATOR',
  MANAGER = 'MANAGER',
  SYSADMIN = 'SYSADMIN',
  SUPERUSER = 'SUPERUSER'
}

export type User = {
  id: string
  email: string
  username: string
  displayName: string
  customRole: CustomRole
  programs: [number]
}

export type GetOktaURLRequestQuery = {
  successUrl: string
  cancelUrl: string
}

export type GetOktaURLRequest = {
  query: GetOktaURLRequestQuery
}

export type RefreshTokenRequestBody = {
  token: string
}

export type RefreshTokenRequest = {
  body: RefreshTokenRequestBody
}

export type GetOktaUrlResponse = {
  oktaUrl: string
}

export type RefreshTokenResponse = {
  token: string
}

export type GetUserMeResponse = User

export type GetOktaUrlResults =
  | {
      kind?: string
      data: GetOktaUrlResponse
    }
  | GeneralApiProblem

export type RefreshTokenResults =
  | {
      kind?: string
      data: RefreshTokenResponse
    }
  | GeneralApiProblem

export type GetUserMeResults =
  | {
      kind?: string
      user: User
    }
  | GeneralApiProblem

export type AuthApi = {
  getUrlOkta: (data: GetOktaURLRequest) => Promise<GetOktaUrlResults>
  refreshToken: (data: RefreshTokenRequest) => Promise<RefreshTokenResults>
  getUserMe: (user: User) => Promise<GetUserMeResults>
}
