import moment from 'moment'
import { getPolygonPosition } from '../components'
import { config, Env } from '../config'
import {
  CommonNames,
  FieldLocation,
  FieldVerification,
  PolygonDot,
  PolygonField,
  Resolution,
  User
} from '../services'
import { CoreItem } from '../store'

import {
  GetCatalogDataProps,
  MenuFieldsData,
  CoreStateObjectProps,
  MenuFieldsDataType,
  GetFieldTypesProps,
  DocumentViewerOptions,
  GetDefaultValueProps
} from './types'

export const formatDateByText = (date?: Date | null) =>
  date ? moment(date).format('MMM D, YYYY') : ''

export const formatDateByTextDetailed = (date?: Date | null) =>
  date ? moment(date).format('MM/DD/YY, h:mm A') : ''

export const formatDateBySlash = (date?: Date | null) =>
  date ? moment(date).format('MM/DD/YY') : ''

export const getCatalogData = ({
  catalog,
  id,
  value
}: GetCatalogDataProps): string => {
  const values = catalog[id]

  const result = values.find((val) => {
    return val.id === value
  })

  const alignedName = result ? result.name : ''
  return alignedName === CommonNames.NONE ? '' : alignedName
}

export const getVerificatedStatus = (resolution: Resolution) =>
  resolution === Resolution.PASS

export const getVerificationFields = (fields: FieldVerification[]) =>
  fields.reduce<MenuFieldsData>(
    (acc, field) => {
      const previewFields = field.previewDisplay
        ? [...acc.preview, field]
        : acc.preview

      if (getVerificatedStatus(field.resolution)) {
        return {
          ...acc,
          validated: [...acc.validated, field],
          preview: previewFields
        }
      }

      return {
        ...acc,
        verification: [...acc.verification, field],
        preview: previewFields
      }
    },
    {
      verification: [],
      validated: [],
      preview: []
    }
  )
export const getIsValueExist = (values: any, id: string) =>
  values[id] !== undefined

const isLeapYear = (year: number) => {
  return year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)
}

export const getDaysInYear = (): number => {
  const year = new Date().getFullYear()

  return isLeapYear(year) ? 366 : 365
}

export const getIsAllTrue = (obj?: CoreStateObjectProps<boolean>) =>
  obj ? Object.values(obj).every((value) => value === true) : false

export const getOriginUrl = (additionalPath: string) =>
  config.ENV === Env.DEV
    ? `${config.DEV_ENVIRONMENT_LINK}${additionalPath}`
    : `${window.origin}${additionalPath}`

export const getRotateIndex = (rotate: number) => {
  return Math.ceil(rotate / 90)
}

export const getScrollDot = (location: FieldLocation[], rotate: number) => {
  const rotateIndex = Math.ceil(rotate / 90)
  const fieldLocation = location[0]

  const polygonPosition = getPolygonPosition(fieldLocation.polygon)

  switch (rotateIndex) {
    case 1:
      return {
        y: polygonPosition.left,
        x: 1 - polygonPosition.topEnd
      }
    case 2:
      return {
        y: 1 - polygonPosition.topEnd,
        x: 1 - polygonPosition.leftEnd
      }
    case 3:
      return {
        y: 1 - polygonPosition.leftEnd,
        x: polygonPosition.top
      }
    default:
      return {
        y: polygonPosition.top,
        x: polygonPosition.left
      }
  }
}

export const getPoligonPositionByScroll = (
  element: HTMLDivElement,
  polygon: PolygonDot,
  zoom: number
) => {
  const diff = 0.015 / zoom
  const x = polygon.x - diff
  const y = polygon.y - diff

  const top = element.scrollHeight * y
  const left = element.scrollWidth * x

  return {
    top,
    left
  }
}

export const getIsProgramExist = (item: CoreItem, user: User) =>
  user.programs.find((programId: number) => Number(item.id) === programId)

export const getUserPrograms = (user: User | null, program: CoreItem[]) => {
  if (user) {
    return program.filter((item: CoreItem) => {
      const isExist = getIsProgramExist(item, user)

      if (isExist) {
        return item
      }
    })
  }
  return []
}

export const getFieldTypeByResolution = (
  resolution: Resolution
): MenuFieldsDataType => {
  if (resolution === Resolution.PASS) {
    return 'validated'
  }
  return 'verification'
}

export const getFieldTypeByActiveField = (
  activeField: number,
  fields: PolygonField[]
) => {
  const currentField = fields.find((field) => field.id === activeField)

  if (currentField) {
    const fieldTypeMenu: MenuFieldsDataType = getFieldTypeByResolution(
      currentField.resolution
    )

    return fieldTypeMenu
  }
}

export const getFieldTypes = ({ fieldType }: GetFieldTypesProps) => {
  const isValidated = fieldType === 'validated'
  const isVerification = fieldType === 'verification'

  return {
    isValidated,
    isVerification
  }
}

export const getDefaultValue = ({ defaultValue }: GetDefaultValueProps) => {
  const defaultValueOptions: DocumentViewerOptions = {
    zoom: 1,
    page: 1,
    rotate: 0,
    fitToPage: false
  }

  return defaultValue || defaultValueOptions
}
