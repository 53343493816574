import { createUseStyles } from 'react-jss'

import { LightTheme } from '../theme'

import { DropdownStyleProps } from './dropdown.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    cursor: ({ disabled }: DropdownStyleProps) =>
      disabled ? 'default' : theme.cursor,
    border: ({ open }: DropdownStyleProps) =>
      open
        ? `2px solid ${theme.colors.background}`
        : `2px solid ${theme.colors.tableRow}`,
    borderBottom: ({ open }: DropdownStyleProps) =>
      open ? 'none' : `2px solid ${theme.colors.tableRow}`,
    padding: '8px 16px',
    borderRadius: ({ open }: DropdownStyleProps) =>
      open ? '12px 12px 0 0' : 12,
    width: 'calc(100% - 36px)',
    backgroundColor: ({ isPreSetted }: DropdownStyleProps) =>
      isPreSetted ? theme.colors.arctic : theme.colors.white
  },
  itemsContainer: {
    overflowX: 'hidden',
    boxShadow: '2px 4px 8px #00000014',
    borderTop: 'none',
    border: `2px solid ${theme.colors.background}`,
    borderRadius: '0 0 12px 12px',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    position: 'absolute',
    width: 'calc(100% - 4px)',
    backgroundColor: theme.colors.white,
    zIndex: 3
  },
  innerItemsContainer: {
    position: 'relative',
    border: 'none',
    boxShadow: 'none',
    borderRadius: 'none',
    width: '100%',
    overflowY: 'auto',
    top: 0
  },
  label: {
    color: ({ open }: DropdownStyleProps) =>
      open ? theme.colors.blue : theme.colors.darkBlue
  },
  disabled: {
    color: theme.colors.inactive
  },
  disabledLabel: {
    color: `${theme.colors.border}!important`
  },
  undoIcon: {
    position: 'absolute',
    right: -40,
    bottom: 10,
    cursor: theme.cursor,
    '& > *:not(:first-child)': {
      marginTop: 6
    }
  },
  error: {
    borderBottom: `1px solid ${theme.colors.red}!important`
  },
  textIcon: {
    position: 'absolute',
    color: theme.colors.border,
    padding: 0,
    right: 40,
    bottom: 10,
    cursor: theme.cursor,
    '&:hover > *': {
      visibility: 'visible'
    }
  },
  value: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflowX: 'hidden'
  },
  valueContainer: {
    overflowX: 'hidden',
    width: '90%'
  }
}))
