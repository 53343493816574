import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../theme'
import { IconProps } from './types'

export const EvStationSmall: FC<IconProps> = (props) => {
  const theme: LightTheme = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.02 4.81333L13.0133 4.82C13.3133 5.12 13.5 5.54 13.5 6V12.3333C13.5 13.2533 12.7533 14 11.8333 14C10.9133 14 10.1667 13.2533 10.1667 12.3333V9H9.16667V14H2.5V3.33333C2.5 2.6 3.1 2 3.83333 2H7.83333C8.56667 2 9.16667 2.6 9.16667 3.33333V8H9.83333C10.5667 8 11.1667 8.6 11.1667 9.33333V12.3333C11.1667 12.7 11.4667 13 11.8333 13C12.2 13 12.5 12.7 12.5 12.3333V7.52667C12.2933 7.61333 12.0733 7.66667 11.8333 7.66667C10.9133 7.66667 10.1667 6.92 10.1667 6C10.1667 5.28667 10.6133 4.68667 11.24 4.44667L9.83333 3.04L10.54 2.33333L13.02 4.81333ZM7.83333 12.6667V7.33333V3.33333H3.83333V9V12.6667H7.83333ZM11.8333 6.66667C11.4667 6.66667 11.1667 6.36667 11.1667 6C11.1667 5.63333 11.4667 5.33333 11.8333 5.33333C12.2 5.33333 12.5 5.63333 12.5 6C12.5 6.36667 12.2 6.66667 11.8333 6.66667ZM3.83333 9L6.5 4V7.33333H7.83333L5.16667 12V9H3.83333Z"
        fill={props.fill || theme.colors.button}
      />
    </svg>
  )
}
