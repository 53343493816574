import { getCatalogData } from '../../pages'
import { DocumentSmall, Resolution } from '../../services'

import { IconName } from '../icon'
import {
  getColorByStatusResolution,
  getColorByStatusRpsResolution,
  getResolutionIcon
} from '../helpers'

import { StatusExtendedItemProps } from './status-table-item.types'
import { Catalog } from '../../store'

export const getStatusIconByColor = (
  resolution: Resolution
): IconName | null => {
  switch (resolution) {
    case Resolution.PASS:
      return 'CheckCircle'
    case Resolution.VERIFICATION:
      return 'EmptyCircle'
    case Resolution.DECLINE:
      return 'TimesCircle'
    case Resolution.IGNORE:
      return 'TimesCircle'
    case Resolution.FYI:
      return 'InfoFilled'
    default:
      return null
  }
}

export type GetStatusTableOptions = {
  catalog: Catalog
  data: DocumentSmall[]
}

export const getStatusTableData = ({
  data,
  catalog
}: GetStatusTableOptions): StatusExtendedItemProps[] =>
  data.map((item) => {
    const document = getCatalogData({
      catalog,
      id: 'document',
      value: item.document
    })
    return {
      title: document,
      document,
      iconColor: getColorByStatusResolution(item.resolution),
      icon: getStatusIconByColor(item.resolution),
      color: getColorByStatusResolution(item.resolution),

      iconColorRps: getColorByStatusRpsResolution(item.rpsResolution),
      iconRps: getResolutionIcon(item.rpsResolution),
      colorRps: getColorByStatusRpsResolution(item.rpsResolution),

      resolution: item.resolution,
      statusProps: {
        text:
          getCatalogData({
            catalog,
            id: 'resolution',
            value: item.resolution
          }) || ''
      },
      statusRpsProps: {
        text:
          getCatalogData({
            catalog,
            id: 'rpsResolution',
            value: item.rpsResolution
          }) || ''
      }
    }
  })
