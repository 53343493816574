import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    backgroundColor: theme.colors.tableRow,
    borderRadius: '12px 12px 0 0 ',
    width: 'calc(100% - 32px)',
    height: 46,
    padding: '0 16px',
    borderBottom: `1px solid ${theme.colors.grey}`,
    boxShadow: '2px 4px 6px 0px #0000000f',
    '&:hover': {
      boxShadow: '2px 4px 8px #00000029'
    }
  },
  menu: {
    marginLeft: 16,
    '& > *': {
      cursor: 'pointer'
    }
  },
  row: {
    cursor: 'pointer',
    '& > *': {
      cursor: 'pointer'
    }
  },
  name: {
    width: 80
  },
  appId: {
    width: 100
  },
  reviewedBy: {
    width: 120
  },
  iqr: {
    width: 150
  },
  applDate: {
    width: 80
  },
  runDate: {
    width: 100
  },
  reviewedDate: {
    width: 100
  },
  resolution: {
    width: 116
  },
  iqrRes: {
    width: 96
  },
  indent: {
    marginLeft: 8
  },
  text: {
    cursor: 'pointer',
    marginTop: 16,
    maxWidth: 123,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  '@media screen and (max-width: 800px)': {
    name: {
      width: 100
    },
    text: {
      maxWidth: 90
    },
    column: {
      width: 'auto'
    }
  },
  '@media screen and (max-width: 1400px)': {
    name: {
      minWidth: 75,
      maxWidth: 75
    },
    appId: {
      minWidth: 84,
      maxWidth: 84
    },
    applDate: {
      minWidth: 70,
      maxWidth: 70
    },
    iqrRes: {
      minWidth: 85,
      maxWidth: 85
    },
    iqr: {
      minWidth: 134,
      maxWidth: 134
    },
    runDate: {
      minWidth: 81,
      maxWidth: 81
    },
    reviewedBy: {
      minWidth: 100,
      maxWidth: 100
    },
    reviewedDate: {
      minWidth: 89,
      maxWidth: 89
    },
    resolution: {
      minWidth: 104,
      maxWidth: 104
    }
  }
}))
