import React, { FC } from 'react'

import { Column, RoundColorIcon, Row, Text } from '../../../components'

import { useStyle } from './column-icon-value.styles'
import { ColumnIconValueProps } from './column-icon-value.types'

export const ColumnIconValue: FC<ColumnIconValueProps> = ({
  className = '',
  colorIcon,
  iconName,
  title,
  titleTx,
  value,
  valueTx
}) => {
  const classes = useStyle()

  return (
    <Column alignItems="flex-start" className={className}>
      <Row>
        <RoundColorIcon color={colorIcon} name={iconName} size={24} />
        <Text
          className={classes.title}
          color="border"
          preset="caption"
          text={title}
          tx={titleTx}
        />
      </Row>
      <Text className={classes.value} text={value} tx={valueTx} />
    </Column>
  )
}
