import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { DropdownItemData } from '@cse/ui'

import { Column } from '../../components'
import { State, ValidationRuleCreators } from '../../store'
import { MainRoutes } from '../../routes'
import {
  GetValidationRuleListRequestQuery,
  ValidationRule
} from '../../services'
import { useUserData } from '../../hooks'

import { ProgramCardsList, ProgramLoadMoreOptions } from './program-cards-list'
import { ProgramHeader } from './program-header'
import { getProgramState, getProgramUpdateDt } from './helpers'

import { ProgramHeaderFilters, ProgramParams } from './program.types'

export const PROGRAM_CARDS_LIMIT = 11
export const DEFAULT_FILTERS = {
  field: undefined,
  document: undefined,
  documentTypes: undefined
}

export const ProgramPage = () => {
  const { id: programId } = useParams<ProgramParams>()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { catalog, validationRule, user } = useSelector((state: State) => ({
    catalog: state.app.catalog,
    validationRule: state.validationRule,
    ...state.auth
  }))
  const BASIC_PARAMS = useMemo(
    () => ({
      program: Number(programId),
      offset: 0,
      limit: PROGRAM_CARDS_LIMIT
    }),
    [programId]
  )
  const [programStateName, changeProgramStateName] = useState('')
  const [query, changeQuery] = useState<GetValidationRuleListRequestQuery>({
    ...DEFAULT_FILTERS,
    ...BASIC_PARAMS
  })

  const { Results, ProgramsPageData } = useUserData({
    validationRule,
    catalog,
    user
  })

  const handleOnChangeFilter = (
    prop: ProgramHeaderFilters,
    value: DropdownItemData<any>['id']
  ) => {
    if (prop === 'program') {
      navigate(`${MainRoutes.PROGRAM_RULES}/${value}`)
    }

    const newValue = value ? value : undefined
    const nextQuery = {
      ...query,
      [prop]: newValue,
      limit: PROGRAM_CARDS_LIMIT,
      offset: 0
    }

    changeQuery(nextQuery)
  }

  const handleOnOpenValidationRule =
    (id: ValidationRule['id']) => (open: boolean) => {
      if (open) {
        dispatch(
          ValidationRuleCreators.getValidationRuleDocumentTypesRequest({
            query: {
              rulePk: id
            }
          })
        )
      }
    }

  const handleOnSearchDocumentType =
    (id: ValidationRule['id']) => (search: string) => {
      dispatch(
        ValidationRuleCreators.getValidationRuleDocumentTypesRequest({
          query: {
            rulePk: id,
            search
          }
        })
      )
    }

  const handleOnLoadApps = useCallback(
    (nextQuery?: GetValidationRuleListRequestQuery) => {
      const updatedQuery = nextQuery || query

      dispatch(
        ValidationRuleCreators.getValidationRuleListRequest({
          query: { ...updatedQuery }
        })
      )
    },
    [query]
  )

  const handleOnLoadMore = useCallback(
    (value: ProgramLoadMoreOptions) => {
      const nextQuery = { ...query, ...value }
      changeQuery(nextQuery)
    },
    [query]
  )

  const handleOnClearChanges = useCallback(() => {
    changeQuery({
      ...query,
      ...DEFAULT_FILTERS
    })
    handleOnLoadApps(BASIC_PARAMS)
  }, [query, DEFAULT_FILTERS, BASIC_PARAMS, handleOnLoadApps])

  useEffect(() => {
    changeQuery({ ...query, program: Number(programId) })
  }, [programId])

  useEffect(() => {
    handleOnLoadApps(query)
  }, [query])

  useEffect(() => {
    const state = getProgramState(Number(programId), validationRule)
    changeProgramStateName(state)
  }, [programId, validationRule])

  if (!programId) {
    return null
  }

  return (
    <Column
      fullWidth
      justifyContent="flex-start"
      key={`program_rules_${programId}`}
    >
      <ProgramHeader
        filteredCatalog={validationRule.filteredCatalog}
        programs={ProgramsPageData}
        programId={Number(programId)}
        programStateName={programStateName}
        updateDate={getProgramUpdateDt(Number(programId), validationRule)}
        defaultValues={query}
        onChange={handleOnChangeFilter}
        onClear={handleOnClearChanges}
      />
      <ProgramCardsList
        isLoaded={validationRule.isLoaded}
        data={Results}
        catalog={catalog}
        current={validationRule.current}
        next={validationRule.next}
        onLoadMore={handleOnLoadMore}
        onOpenValidationRule={handleOnOpenValidationRule}
        onSearchDocumentType={handleOnSearchDocumentType}
      />
    </Column>
  )
}
