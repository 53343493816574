import { useMemo } from 'react'

import { IconName } from '../../../components'
import { ActivityLogDetail } from '../../../services'

import {
  ChangeDataBySeachProps,
  GetDataProps,
  GetHeightProps,
  ResetHeightProps
} from './logs-search.types'

export const getContainerHeight = (
  itemsContainer: HTMLDivElement | null,
  minHeight: number
) => (itemsContainer ? itemsContainer.offsetHeight : minHeight)

export const isSearchOpen = (data: ActivityLogDetail[], searchById: string) => {
  const isData = data && data.length
  const isTextTyped = isData && searchById

  return Boolean(isTextTyped)
}

export const useGetData = ({ data, searchById }: GetDataProps) => {
  const searchIcon: IconName | undefined = searchById ? 'Clear' : undefined
  const open = useMemo(() => isSearchOpen(data, searchById), [data, searchById])

  return {
    searchIcon,
    open
  }
}

export const getHeight = ({
  contentRef,
  maxHeight,
  minHeight,
  changeHeight
}: GetHeightProps) => {
  if (contentRef) {
    const content = contentRef.current
    const contentHeight = content?.offsetHeight

    if (contentHeight) {
      const isLargeContent = contentHeight > maxHeight
      const alignedHeight = isLargeContent ? maxHeight : contentHeight
      const resHeight = alignedHeight || minHeight

      changeHeight(resHeight)
      return alignedHeight
    }
  }
  return minHeight
}

export const resetHeight = ({ open, getOffsetHeight }: ResetHeightProps) => {
  if (open) {
    const sliderHeight = getOffsetHeight()

    if (sliderHeight) {
      getOffsetHeight()
    }
  }
}

export const changeDataBySeach = ({
  searchById,
  searchList,
  changeData
}: ChangeDataBySeachProps) => {
  if (searchById) {
    changeData(searchList)
  }
}
