import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  container: {
    padding: '0 16px',
    minHeight: 660
  },
  content: {
    padding: 0
  },
  listView: {
    minHeight: 660
  },
  iconButtons: {
    '& > *': {
      marginLeft: 24
    }
  },
  innerContent: {},
  '@media screen and (max-width: 1170px)': {
    content: {
      maxWidth: 760,
      minWidth: 760,
      minHeight: 860,
      justifyContent: 'center',
      alignContent: 'center'
    },
    innerContent: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      overflowX: 'scroll',
      overflowY: 'hidden',
      justifyContent: 'flex-start'
    }
  },
  '@media screen and (max-width: 800px)': {
    content: {
      maxWidth: 660,
      minWidth: 660,
      minHeight: 860,
      justifyContent: 'center',
      alignContent: 'center'
    }
  }
})
