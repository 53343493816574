import React, { FC } from 'react'

import { Row, Text } from '../../components'
import { config } from '../../config'

import { useStyle } from './version-block.styles'
import { VersionBlockProps } from './version-block.types'

export const VersionBlock: FC<VersionBlockProps> = ({
  versionTitle,
  versionTitleTx,
  count
}) => {
  const classes = useStyle()

  if (!config.HIDDEN_VERSION) {
    return <></>
  }

  return (
    <Row className={classes.container}>
      <Text
        preset="smallSubTitleSetting"
        text={versionTitle}
        tx={versionTitleTx}
      />
      <Text preset="smallSubTitleSetting" text={count} />
    </Row>
  )
}
