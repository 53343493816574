import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../theme'

import { DocumentValidationHeaderStyleProps } from './document-validation-header.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    backgroundColor: ({ hasElements }: DocumentValidationHeaderStyleProps) =>
      hasElements && theme.colors.white,
    borderRadius: 12,
    padding: '8px 32px',
    width: 'calc(100% - 64px)'
  },
  version: {
    display: 'inline-block'
  },
  iconButton: {
    marginLeft: 24
  },
  list: {
    marginTop: 24,
    height: 250,
    overflowY: 'scroll'
  },
  listContainer: {
    height: 'auto',
    position: 'absolute',
    top: 0,
    '& > *:not(:first-child)': {
      marginTop: 16
    },
    '& > *': {
      flexFlow: 'row wrap',
      gap: '122px'
    }
  },
  name: {
    minWidth: 154
  },
  versionName: {
    marginTop: 8
  }
}))
