import React, { FC } from 'react'

import { Row, OptionLine, concatClassNames } from '../../../../components'
import { FieldVerification } from '../../../../services'

import { FieldsProps } from './fields.types'
import { useStyle } from './fields.styles'

export const Fields: FC<FieldsProps> = ({ className = '', list }) => {
  const classes = useStyle()

  return (
    <Row
      className={concatClassNames(classes.container, className)}
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      {list.map((field: FieldVerification) =>
        field.previewTitle ? (
          <OptionLine
            key={`header_field_${field.id}`}
            className={classes.line}
            title={field.previewTitle}
            value={field.referenceValue}
          />
        ) : null
      )}
    </Row>
  )
}
