import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  titleSeparator: {
    borderBottom: `1px solid ${theme.colors.secondButton}`,
    width: '100%',
    height: 1
  },
  noPadding: {
    padding: 0
  },
  container: {
    background: theme.colors.white
  },
  breadcrumbs: {
    marginTop: 8
  },
  box: {
    transition: 'all 0.25s ease-in-out'
  }
}))
