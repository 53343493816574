import React, { FC } from 'react'

import { IconProps } from './types'

export const EmptyList: FC<IconProps> = (props) => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
      width={412}
      height={172}
      viewBox="0 0 412 172"
      fill="none"
      {...props}
    >
      <g filter="url(#filter0_d)">
        <rect x="44" y="42" width="320" height="120" rx="16" fill="white" />
      </g>
      <g filter="url(#filter1_d)">
        <rect x="24" y="22" width="360" height="120" rx="16" fill="white" />
      </g>
      <g filter="url(#filter2_d)">
        <rect x="4" y="2" width="400" height="120" rx="16" fill="white" />
      </g>
      <rect x="24" y="26" width="157" height="8" rx="4" fill="#D1F1F9" />
      <rect x="189" y="26" width="56" height="8" rx="4" fill="#D1F1F9" />
      <rect x="253" y="26" width="131" height="8" rx="4" fill="#D1F1F9" />
      <rect x="24" y="42" width="88" height="8" rx="4" fill="#D1F1F9" />
      <rect x="120" y="42" width="204" height="8" rx="4" fill="#D1F1F9" />
      <rect x="332" y="42" width="52" height="8" rx="4" fill="#D1F1F9" />
      <rect x="24" y="58" width="197" height="8" rx="4" fill="#D1F1F9" />
      <rect x="229" y="58" width="155" height="8" rx="4" fill="#D1F1F9" />
      <rect x="84" y="74" width="300" height="8" rx="4" fill="#D1F1F9" />
      <rect x="24" y="74" width="52" height="8" rx="4" fill="#D1F1F9" />
      <rect x="24" y="90" width="131" height="8" rx="4" fill="#D1F1F9" />
      <defs>
        <filter
          id="filter0_d"
          x="40"
          y="40"
          width="332"
          height="132"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx="2" dy="4" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d"
          x="20"
          y="20"
          width="372"
          height="132"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx="2" dy="4" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d"
          x="0"
          y="0"
          width="412"
          height="132"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx="2" dy="4" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}
