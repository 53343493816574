import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    background: theme.colors.white,
    boxShadow: '0px 6px 16px rgba(0, 0, 0, 0.06)'
  },
  filter: {
    marginLeft: 24
  },
  text: {
    whiteSpace: 'nowrap'
  },
  input: {
    '&:not(:first-child)': {
      marginLeft: 16
    }
  }
}))
