import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../../theme'
import { SVGIconProps } from './icons.types'

export const NewReleasesFilled: FC<SVGIconProps> = (props) => {
  const theme: LightTheme = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3327 8.00019L13.706 6.14686L13.9327 3.69352L11.526 3.14686L10.266 1.02686L7.99935 2.00019L5.73268 1.02686L4.47268 3.14686L2.06602 3.68686L2.29268 6.14019L0.666016 8.00019L2.29268 9.85352L2.06602 12.3135L4.47268 12.8602L5.73268 14.9802L7.99935 14.0002L10.266 14.9735L11.526 12.8535L13.9327 12.3069L13.706 9.85352L15.3327 8.00019ZM8.66602 11.3335H7.33268V10.0002H8.66602V11.3335ZM7.33268 8.66686H8.66602V4.66686H7.33268V8.66686Z"
        fill={props.fill || theme.colors.green}
      />
    </svg>
  )
}
