import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../../../theme'
import { Column, Row, Text } from '../../../../components'
import { ListHeaderProps } from './docs-list-header.types'
import { useStyle } from './docs-list-header.styles'

export const ListHeader: FC<ListHeaderProps> = ({ className }) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })

  return (
    <Row
      className={`${className} ${classes.container}`}
      justifyContent="space-between"
    >
      <Row fullWidth justifyContent="space-between">
        <Column
          className={classes.name}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Row>
            <Text
              text="Document Type"
              tx="document.docType"
              preset="boldHeader"
            />
          </Row>
        </Column>
        <Column
          className={classes.column}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Row>
            <Text
              text="Validation Rate"
              tx="document.validationRate"
              preset="boldHeader"
            />
          </Row>
        </Column>
        <Column
          className={classes.column}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Row>
            <Text
              text="Fields Verified"
              tx="document.fieldsVerified"
              preset="boldHeader"
            />
          </Row>
        </Column>
        <Column
          className={classes.resolution}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Text
            text="Overall Resolution"
            tx="document.overallResolution"
            preset="boldHeader"
          />
        </Column>
        <Column
          className={classes.column}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Text
            text="Application Date"
            tx="document.appDate"
            preset="boldHeader"
          />
        </Column>
        <Column
          className={classes.column}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Row>
            <Text
              text="Date Modified"
              tx="document.dateModified"
              preset="boldHeader"
            />
          </Row>
        </Column>
        <Column
          className={classes.version}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Row>
            <Text text="Version" tx="document.version" preset="boldHeader" />
          </Row>
        </Column>
      </Row>
    </Row>
  )
}
