import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    background: theme.colors.tableHeader,
    borderRadius: 4,
    padding: '4px 8px'
  }
}))
