import React, { FC } from 'react'

import { IconProps } from './types'

export const DonutSmall: FC<IconProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.02539 12C2.02539 17.19 5.97539 21.45 11.0254 21.95V14.82C9.86539 14.4 9.02539 13.3 9.02539 12C9.02539 10.7 9.86539 9.60005 11.0254 9.18005V2.05005C5.97539 2.55005 2.02539 6.81005 2.02539 12ZM21.9754 11H14.8454C14.5354 10.15 13.8754 9.49005 13.0254 9.18005V2.05005C17.7454 2.52005 21.5054 6.28005 21.9754 11ZM19.4454 9.00005C18.6254 7.00005 17.0254 5.40005 15.0254 4.58005V8.01005C15.3954 8.29005 15.7354 8.63005 16.0154 9.00005H19.4454ZM9.02539 8.02005V4.58005C6.06539 5.76005 4.02539 8.65005 4.02539 12C4.02539 15.35 6.06539 18.24 9.02539 19.43V15.99C7.79539 15.06 7.02539 13.59 7.02539 12C7.02539 10.41 7.79539 8.94005 9.02539 8.02005ZM13.0254 14.82V21.95C17.7454 21.48 21.5054 17.72 21.9754 13H14.8454C14.5354 13.85 13.8754 14.51 13.0254 14.82ZM16.0154 15C15.7354 15.38 15.3954 15.71 15.0254 15.99V19.42C17.0254 18.6 18.6254 17 19.4454 15H16.0154Z"
        fill="#9AB959"
      />
    </svg>
  )
}
