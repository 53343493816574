import React from 'react'

import { AppsList } from '../apps-list'

import { ListViewProps } from './list-view.types'
import { useStyle } from './list-view.styles'

export const ListView = ({
  catalog,
  current,
  data,
  isLoadedMore,
  isLoaded,
  next,
  search,
  onLoadMore,
  onSort
}: ListViewProps) => {
  const classes = useStyle({ isLoaded })

  return (
    <AppsList
      catalog={catalog}
      className={classes.applicationList}
      current={current}
      data={data}
      isLoadedMore={isLoadedMore}
      isLoaded={isLoaded}
      next={next}
      search={search}
      onLoadMore={onLoadMore}
      onSort={onSort}
    />
  )
}
