import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { arrayHasElements, TabsNav } from '../../../components'
import { MainRoutes } from '../../../routes'
import { State } from '../../../store'

import { SEARCH_PARAM_KEY } from '../document-info'

import { useGetTabsNavData, setTabClick } from './helpers'
import { TabsContentProps } from './tabs-content.types'

export const TabsContent = ({
  docId,
  isVersionChanged,
  versionId,
  document
}: TabsContentProps) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { documentHeader, documents } = useSelector((state: State) => ({
    ...state.applications,
    ...state.app,
    ...state.document
  }))
  const [currentDocId, changeCurrentDocId] = useState(docId)
  const [page, changePage] = useState<number>(1)
  const [comparisonPage, changeComparisonPage] = useState<number>(1)

  const handleOnChangePage = (nextPage: number) => {
    changePage(nextPage)
  }
  const handleOnChangeComparisonPage = (nextPage: number) => {
    changeComparisonPage(nextPage)
  }

  const handleOnClickTab = (id: number) => {
    setTabClick({
      docId: id,
      documentHeader,
      documents,
      changePage,
      dispatch
    })
  }

  useEffect(() => {
    if (!versionId) {
      handleOnClickTab(docId)
    }
  }, [documentHeader])

  useEffect(() => {
    if (versionId && isVersionChanged) {
      const doc = documentHeader?.documents[0]
      const newDocId = doc ? doc.id : 0

      changeCurrentDocId(newDocId)

      const query = `?document_id=${newDocId}`
      navigate(`${MainRoutes.DOCUMENT}/${versionId}${query}`)
    }
  }, [documentHeader, versionId])

  useEffect(() => {
    if (versionId) {
      handleOnClickTab(currentDocId)
    }
  }, [currentDocId])

  useEffect(() => {
    changeCurrentDocId(docId)
  }, [docId])

  const TabsData = useGetTabsNavData({
    docId: currentDocId,
    page,
    document,
    comparisonPage,
    onChangePage: handleOnChangePage,
    onChangeComparisonPage: handleOnChangeComparisonPage
  })

  if (!arrayHasElements(TabsData)) {
    return null
  }

  return (
    <TabsNav
      data={TabsData}
      param={SEARCH_PARAM_KEY}
      onClick={handleOnClickTab}
    />
  )
}
