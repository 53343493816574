import { useDefaultValue, useOutsideAlerter } from '@cse/ui'
import React, {
  FC,
  MouseEventHandler,
  useEffect,
  useRef,
  useState
} from 'react'
import { useDispatch } from 'react-redux'

import { DocumentCreators } from '../../store'

import { Column } from '../column'
import { concatClassNames } from '../helpers'
import { Row } from '../row'
import { Text } from '../text'
import { ValidationFieldHint } from './validation-field-hint'
import { useGetData, getOnBlur, getOnFocus } from './helpers'
import { MultiLine } from './multi-line'
import { Actions } from './actions'

import { ValidationFieldProps } from './validation-field.types'
import { useStyle } from './validation-field.styles'

export const ValidationField: FC<ValidationFieldProps> = ({
  active,
  className = '',
  isEditable,
  id,
  multiValue,
  description,
  descriptionTx,
  label,
  labelTx,
  prevValue,
  defaultRpsResolution,
  value,
  onApprove,
  onReject,
  onFocus,
  onBlur,
  onActivate
}) => {
  const dispatch = useDispatch()
  const containerRef = useRef<HTMLDivElement>(null)
  const inputContainerRef = useRef<HTMLDivElement>(null)

  const [focused, changeFocused] = useState(false)
  const [open, changeOpen] = useState(false)
  const [rpsResolution, changeRpsResolution] = useState(defaultRpsResolution)
  const Value = useDefaultValue(focused, active)

  const classes = useStyle({ multiValue, focused: Value })

  const { FocusedFieldPreset, FocusedLabelFieldColor, FirstValue } = useGetData(
    {
      rpsResolution,
      focused,
      value,
      prevValue,
      multiValue
    }
  )

  const handleOnOpenHint: MouseEventHandler = (event) => {
    event.preventDefault()
    event.stopPropagation()
    dispatch(
      DocumentCreators.getFieldInfoRequest({
        params: { fieldPk: id }
      })
    )
    changeOpen(true)
  }

  const handleOnCloseHint = () => {
    changeOpen(false)
  }

  const handleOnFocus = getOnFocus({
    onFocus,
    changeFocused
  })

  const handleOnBlur = getOnBlur({
    value: Value,
    changeFocused,
    onBlur
  })

  useEffect(() => {
    changeRpsResolution(defaultRpsResolution)
  }, [defaultRpsResolution])

  useOutsideAlerter(inputContainerRef, Value, handleOnBlur)

  return (
    <Row
      fullWidth
      className={concatClassNames(className, classes.container)}
      justifyContent="flex-start"
      ref={containerRef}
    >
      <Row
        fullWidth
        alignItems="flex-end"
        justifyContent="flex-start"
        ref={inputContainerRef}
        onClick={handleOnFocus}
      >
        <Column fullWidth alignItems="flex-start">
          <Text
            className={classes.label}
            color={FocusedLabelFieldColor}
            preset="caption"
            text={label}
            tx={labelTx}
            onClick={handleOnOpenHint}
          />
          <Row fullWidth alignItems="flex-end">
            <Row
              fullWidth
              className={classes.valueContainer}
              justifyContent="flex-start"
            >
              <Text
                className={classes.prevValueTitle}
                preset={FocusedFieldPreset}
                text={FirstValue}
              />
            </Row>
            <MultiLine
              active={active}
              multiValue={multiValue}
              value={value}
              rpsResolution={rpsResolution}
              focused={focused}
              onActivate={onActivate}
            />
          </Row>
        </Column>
      </Row>
      <Actions
        active={active}
        isEditable={isEditable}
        focused={focused}
        value={value}
        rpsResolution={rpsResolution}
        changeRpsResolution={changeRpsResolution}
        onApprove={onApprove}
        onReject={onReject}
        onActivate={onActivate}
      />
      <ValidationFieldHint
        open={open}
        description={description}
        descriptionTx={descriptionTx}
        title={label}
        titleTx={labelTx}
        onClose={handleOnCloseHint}
      />
    </Row>
  )
}
