import { TabsNavItem } from '../../components'
import { PageVerification } from '../../services'
import { RpsResolutionModalValue } from '../../views'
import { getCatalogData } from '../helpers'
import { SEARCH_PARAM_KEY } from './document-info'

import {
  GetCurrentDataProps,
  GetDefValueProps,
  GetDocHeaderLoadsProps,
  GetDocIdProps,
  UpdateHeaderDocumentsProps
} from './document-info.types'

export const getDefValue = ({ doc }: GetDefValueProps) => {
  if (doc) {
    return {
      resolution: doc.rpsResolution,
      rejectionReason: doc.rejectionReason
    }
  }
  return {
    resolution: 0,
    rejectionReason: null
  }
}

export const getCurrentData = ({
  document,
  page
}: GetCurrentDataProps): PageVerification | undefined => {
  if (!document) {
    return
  }
  return document.pages.find((docPage) => docPage.number === page)
}

export const getUpdatedHeaderDocuments = ({
  documentHeader,
  catalog,
  content
}: UpdateHeaderDocumentsProps): TabsNavItem[] =>
  documentHeader.documents.map((documentItem) => {
    const documentType = getCatalogData({
      catalog,
      id: 'document',
      value: documentItem.document
    })

    return {
      text: documentType,
      link: String(documentItem.id),
      content,
      allowRpsResolutions: documentHeader.allowRpsResolutions,
      warnings: documentItem.warnings,
      hasRecognitionUpdate: documentItem.hasRecognitionUpdate,
      hasDocumentUpdate: documentItem.hasDocumentUpdate,
      hasOtherVersions: documentItem.hasOtherVersions
    }
  })

export const getRejectionReason = (data: RpsResolutionModalValue) =>
  data.rejectionReason || null

export const getDocId = ({ searchParams }: GetDocIdProps) => {
  const docId = searchParams.get(SEARCH_PARAM_KEY)
  if (!docId) {
    return 0
  }

  return Number(docId)
}

export const getIsDocHeaderLoads = ({
  documentHeader,
  documentHeaderLoading
}: GetDocHeaderLoadsProps) => !documentHeader || documentHeaderLoading
