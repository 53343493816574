import { TextPresets } from '@cse/ui'
import { MouseEventHandler, useMemo } from 'react'
import { useTheme } from 'react-jss'

import { RPSResolution } from '../../services'
import { Color, LightTheme } from '../../theme'
import {
  GetDataProps,
  ActiveProps,
  SetPositioningTimeoutProps,
  RPSChangeProps,
  OnFocusProps,
  OnBlurProps
} from './validation-field.types'

export const useGetData = ({
  rpsResolution,
  focused,
  value,
  prevValue,
  multiValue
}: GetDataProps) => {
  const theme = useTheme<LightTheme>()

  const ApproveFill = useMemo(() => {
    const color: Color =
      rpsResolution === RPSResolution.APPROVED ? 'green' : 'innerBorder'

    return theme.colors[color]
  }, [rpsResolution])

  const RejectFill = useMemo(() => {
    const color: Color =
      rpsResolution === RPSResolution.INELIGIBLE ? 'red' : 'innerBorder'

    return theme.colors[color]
  }, [rpsResolution])

  const FocusedFieldPreset: TextPresets = useMemo(
    () => (focused ? 'bodyBold' : 'body'),
    [focused]
  )

  const FocusedLabelFieldColor: Color = useMemo(
    () => (focused ? 'blue' : 'darkBlue'),
    [focused]
  )
  const ValueStringify = value || ''
  const prevValueStringify = prevValue || ''
  const FirstValue = multiValue ? prevValueStringify : ValueStringify

  return {
    ApproveFill,
    RejectFill,
    FocusedFieldPreset,
    FocusedLabelFieldColor,
    ValueStringify,
    FirstValue
  }
}

export const onActive = ({ active, containerRef, onActivate }: ActiveProps) => {
  const isActive = active && containerRef.current
  if (isActive) {
    if (onActivate) {
      onActivate(containerRef.current.offsetTop)
    }
  }
}

export const setPositionTimeout = ({
  valueRef,
  changePosition
}: SetPositioningTimeoutProps) => {
  if (valueRef.current) {
    const domRect = valueRef.current.getBoundingClientRect()
    const iqrHeight = domRect.height
    const y = domRect.top - iqrHeight - 10
    const arrowY = 0
    const { offsetWidth = 0 } = valueRef.current
    const x = offsetWidth - 30

    changePosition({ x, y, arrowY })
  }
}

export const onRPSChange =
  ({
    resolution,
    changeRpsResolution,
    onApprove,
    onReject
  }: RPSChangeProps): MouseEventHandler =>
  (event) => {
    changeRpsResolution(resolution)

    switch (resolution) {
      case RPSResolution.APPROVED:
        if (onApprove) {
          onApprove(event)
        }
        break
      case RPSResolution.INELIGIBLE:
        if (onReject) {
          onReject(event)
        }
        break
      default:
        break
    }
  }

export const getOnFocus =
  ({ onFocus, changeFocused }: OnFocusProps): MouseEventHandler =>
  (event) => {
    changeFocused(true)

    if (onFocus) {
      onFocus(event)
    }
  }

export const getOnBlur =
  ({ value, changeFocused, onBlur }: OnBlurProps) =>
  () => {
    if (value) {
      changeFocused(false)

      if (onBlur) {
        onBlur()
      }
    }
  }
