import React, { FC, useMemo, useRef } from 'react'

import {
  arrayHasElements,
  Column,
  LoadingContainer,
  EmptyList
} from '../../../components'

import { ItemsList } from './items-list'
import { ListHeader } from './apps-list-header'

import { ColumnKeys, SortTypes } from '../application-list.types'
import { AppsListProps } from './apps-list.types'
import { useStyle } from './apps-list.styles'

export const AppsList: FC<AppsListProps> = ({
  catalog,
  className,
  current,
  data,
  isLoadedMore,
  isLoaded,
  next,
  search,
  onLoadMore,
  onSort
}) => {
  const classes = useStyle()
  const ref = useRef<HTMLDivElement>(null)

  const handleOnSort = (id: ColumnKeys, sortBy: SortTypes) => {
    if (onSort) {
      if (sortBy === SortTypes.DESC) {
        onSort(`-${id}`)
      } else {
        onSort(id)
      }
    }
  }

  const HasElement = useMemo(() => arrayHasElements(data), [data])

  return (
    <Column fullWidth justifyContent="flex-start">
      <Column
        className={`${className} ${classes.container}`}
        justifyContent="flex-start"
      >
        <Column ref={ref} fullWidth justifyContent="flex-start">
          <ListHeader onSort={handleOnSort} />
          <LoadingContainer className={classes.spinner} loading={!isLoaded}>
            {HasElement ? (
              <ItemsList
                isLoaded={isLoadedMore}
                data={data}
                catalog={catalog}
                current={current}
                next={next}
                onLoadMore={onLoadMore}
              />
            ) : (
              <EmptyList search={search} />
            )}
          </LoadingContainer>
        </Column>
      </Column>
    </Column>
  )
}
