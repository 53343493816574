import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    borderRadius: 12,
    overflow: 'hidden'
  },
  content: {
    borderTop: `1px solid ${theme.colors.secondButton}`
  }
}))
