import React, { FC } from 'react'
import { useTheme } from 'react-jss'
import { LightTheme } from '../theme'
import { IconProps } from './types'

export const RadioButtonDone: FC<IconProps> = (props) => {
  const theme: LightTheme = useTheme()
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 16A8 8 0 108 0a8 8 0 000 16zm3.014-10.571a.432.432 0 01.588.042.488.488 0 010 .666l-4.181 4.392-.046.042a.432.432 0 01-.588-.042l-2.39-2.51-.04-.048a.488.488 0 01.04-.617.433.433 0 01.635 0L7.104 9.53l3.865-4.06.046-.042z"
        stroke={props.fill || theme.colors.button}
      />
    </svg>
  )
}
