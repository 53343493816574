import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../../theme'
import { StatusType } from '../../../services'
import { Icon } from '../../icon'
import { Row } from '../../row'
import { Text } from '../../text'
import { concatClassNames } from '../../helpers'

import { StatusHintItemProps } from './status-hint-item.types'
import { useStyle } from './status-hint-item.styles'

export const StatusHintItem: FC<StatusHintItemProps> = ({
  className = '',
  type,
  icon,
  iconColor,
  iconRps,
  iconColorRps,
  statusProps,
  statusRpsProps,
  title,
  titleTx,
  isTitle = false,
  warnings
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ title })
  const isRps = type === StatusType.RES
  const alignedIcon = isRps ? icon : iconRps
  const alignedIconColor = isRps ? iconColor : iconColorRps
  const textProps = isRps ? statusProps : statusRpsProps
  const itemClass = warnings ? classes.itemWarn : ''

  return (
    <Row
      fullWidth
      className={concatClassNames(className, itemClass)}
      justifyContent="flex-start"
    >
      {isTitle && title && (
        <Text
          className={classes.title}
          color="border"
          text={title}
          tx={titleTx}
        />
      )}
      {alignedIcon && alignedIconColor && (
        <Row className={classes.icon}>
          <Icon
            name={alignedIcon}
            fill={theme.colors[alignedIconColor]}
            height={16}
            width={16}
          />
        </Row>
      )}
      {textProps && <Text className={classes.status} {...textProps} />}
      {warnings && (
        <Icon className={classes.warning} name="ExclamationTriangle" />
      )}
    </Row>
  )
}
