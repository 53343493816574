import { createReducer } from 'reduxsauce'

import { ApplicationsActionTypes, ApplicationsTypes } from '../../actions'

import { ApplicationsState } from './applications.types'

/* ------------- Reducers ------------- */

const INITIAL_STATE: ApplicationsState = {
  isLoaded: false,
  isLoadedMore: true,
  isSearchLoaded: true,
  applications: [],
  application: null,
  count: 0,
  next: null,
  previous: null,
  current: 0,
  error: '',
  searchList: []
}

const dataRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoaded: false
  }
}

const dataRequestApplications = (
  state = INITIAL_STATE,
  action: ApplicationsTypes.GetApplicationsActionRequest
) => {
  if (!action.payload.query?.offset) {
    return {
      ...state,
      isLoaded: false,
      count: 0,
      next: '',
      previous: '',
      current: 0,
      error: ''
    }
  }

  return {
    ...state,
    isLoadedMore: false
  }
}

/***
 * Get Applications list
 */

export const getApplicationsSuccess = (
  state = INITIAL_STATE,
  action: ApplicationsTypes.GetApplicationsActionSuccess
) => {
  const { results, count, next, previous } = action.payload

  if (action.payload.reset) {
    return {
      ...state,
      isLoaded: true,
      isLoadedMore: true,
      applications: results,
      count,
      next,
      previous,
      current: results.length
    }
  }

  if (action.payload) {
    const applications = [...state.applications, ...results]

    return {
      ...state,
      isLoaded: true,
      isLoadedMore: true,
      applications,
      count,
      next,
      previous,
      current: applications.length
    }
  }

  return {
    ...state,
    isLoaded: true,
    isLoadedMore: true,
    applications: [],
    current: 0
  }
}

export const getApplicationsFailure = (
  state = INITIAL_STATE,
  action: ApplicationsTypes.GetApplicationsActionFailure
) => {
  if (action.payload) {
    return {
      ...state,
      isLoaded: true,
      isLoadedMore: true,
      applications: [],
      next: '',
      previous: '',
      current: 0
    }
  }

  return state
}

/***
 * Get Search Preview
 */

export const getSearchPreviewRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isSearchLoaded: false
  }
}

export const getSearchPreviewSuccess = (
  state = INITIAL_STATE,
  action: ApplicationsTypes.GetSearchPreviewActionSuccess
) => {
  const { results } = action.payload

  if (action.payload) {
    return {
      ...state,
      isSearchLoaded: true,
      searchList: results
    }
  }

  return {
    ...state,
    isSearchLoaded: true,
    searchList: []
  }
}

export const getSearchPreviewFailure = (
  state = INITIAL_STATE,
  action: ApplicationsTypes.GetSearchPreviewActionFailure
) => {
  if (action.payload) {
    return {
      ...state,
      isSearchLoaded: true,
      searchList: []
    }
  }

  return state
}

/***
 * Get Application
 */

export const getApplicationSuccess = (
  state = INITIAL_STATE,
  action: ApplicationsTypes.GetApplicationActionSuccess
) => {
  const results = action.payload

  if (action.payload) {
    return {
      ...state,
      isLoaded: true,
      application: results
    }
  }

  return {
    ...state,
    isLoaded: true,
    application: null
  }
}

export const getApplicationFailure = (
  state = INITIAL_STATE,
  action: ApplicationsTypes.GetApplicationActionFailure
) => {
  if (action.payload) {
    return {
      ...state,
      isLoaded: true,
      application: null
    }
  }

  return state
}

/***
 * Delete Application
 */

export const deleteApplicationSuccess = (
  state = INITIAL_STATE,
  action: ApplicationsTypes.DeleteApplicationActionSuccess
) => {
  if (action.payload) {
    const id = action.payload
    const newState = state.applications.filter((item) => item.id !== Number(id))
    const count = state.count ? state.count - 1 : 0

    return {
      ...state,
      isLoaded: true,
      isLoadedMore: true,
      applications: newState,
      count
    }
  }

  return {
    ...state,
    isLoaded: true,
    isLoadedMore: true,
    applications: []
  }
}

export const deleteApplicationFailure = (
  state = INITIAL_STATE,
  action: ApplicationsTypes.DeleteApplicationActionFailure
) => {
  if (action.payload) {
    return {
      ...state,
      isLoaded: true,
      isLoadedMore: true
    }
  }

  return state
}

/* ------------- Hookup Reducers To Types ------------- */

export const applicationsReducer = createReducer(INITIAL_STATE, {
  [ApplicationsActionTypes.GET_APPLICATIONS_REQUEST]: dataRequestApplications,
  [ApplicationsActionTypes.GET_APPLICATIONS_SUCCESS]: getApplicationsSuccess,
  [ApplicationsActionTypes.GET_APPLICATIONS_FAILURE]: getApplicationsFailure,

  [ApplicationsActionTypes.GET_APPLICATION_REQUEST]: dataRequest,
  [ApplicationsActionTypes.GET_APPLICATION_SUCCESS]: getApplicationSuccess,
  [ApplicationsActionTypes.GET_APPLICATION_FAILURE]: getApplicationFailure,

  [ApplicationsActionTypes.GET_SEARCH_PREVIEW_REQUEST]: getSearchPreviewRequest,
  [ApplicationsActionTypes.GET_SEARCH_PREVIEW_SUCCESS]: getSearchPreviewSuccess,
  [ApplicationsActionTypes.GET_SEARCH_PREVIEW_FAILURE]: getSearchPreviewFailure,

  [ApplicationsActionTypes.DELETE_APPLICATION_SUCCESS]:
    deleteApplicationSuccess,
  [ApplicationsActionTypes.DELETE_APPLICATION_FAILURE]: deleteApplicationFailure
})
