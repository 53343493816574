import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  input: {
    '&:not(:first-child)': {
      marginLeft: 16
    }
  },
  actions: {
    marginLeft: 16,
    '& > *:not(:first-child)': {
      marginLeft: 16
    }
  },
  button: {
    width: '64px!important'
  },
  program: {
    maxWidth: 218,
    minWidth: 216
  },
  user: {
    maxWidth: 342,
    minWidth: 340
  }
})
