import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    width: 'calc(260px - 32px)',
    padding: 16,
    borderRadius: 16,
    backgroundColor: theme.colors.white,
    border: `1px solid ${theme.colors.disableRadioBackground}`,
    boxShadow: '4px 8px 12px rgba(0, 0, 0, 0.06)',
    '&:hover': {
      backgroundColor: theme.colors.arctic,
      cursor: 'pointer'
    }
  },
  title: {
    marginTop: 16,
    '&:hover': {
      color: theme.colors.blue,
      cursor: 'pointer'
    }
  },
  bottomInfo: {
    marginTop: 16
  },
  icon: {
    marginLeft: 8
  }
}))
