import { DetailedHTMLProps, HTMLAttributes, MouseEvent, ReactNode } from 'react'

import { Color } from '../theme'

export enum ButtonTypeName {
  accent = 'accent',
  secondary = 'secondary',
  disabled = 'disabled',
  defaultSmall = 'defaultSmall'
}

export interface ButtonPresetName {
  button: string
  accent: string
  secondary: string
  disabled: string
  defaultSmall: string
}

export interface ButtonProps
  extends DetailedHTMLProps<
    HTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  children?: ReactNode
  preset?: keyof ButtonPresetName
  type?: 'submit' | 'button' | 'reset'
  disabled?: boolean
  className?: string
  isSmall?: boolean
  isLoaded?: boolean
  color?: Color
  text?: string
  tx?: string
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void
}
