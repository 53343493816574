import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  content: {
    marginTop: 20,
    maxHeight: 296,
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  info: {
    marginLeft: 32,
    marginRight: 32,
    '& > *:not(:first-child)': {
      marginTop: 8
    },
    '& > *:last-child': {
      marginBottom: 32
    }
  },
  item: {
    borderRadius: 4,
    backgroundColor: theme.colors.footerBackground,
    width: 'calc(100% - 16px)',
    padding: 8,
    '& > *:not(:first-child)': {
      marginTop: 8
    }
  },
  comment: {
    marginLeft: 8
  }
}))
