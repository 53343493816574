import { ApisauceInstance } from 'apisauce'
import camelcaseKeys from 'camelcase-keys'

import { decamelizeObject, filterUniqueLogs } from '../../utils'

import { getGeneralApiProblem } from '../api-problem'
import {
  LogsPathes,
  GetActivityLogListRequest,
  GetActivityLogListResponse,
  GetActivityLogListResults,
  GetActivityLogByIdResults,
  GetActivityLogByIdRequest,
  GetActivityLogByIdResponse,
  GetErrorLogListRequest,
  GetErrorLogListResponse,
  GetErrorLogListResults,
  GetErrorLogByIdResults,
  GetErrorLogByIdRequest,
  GetErrorLogByIdResponse,
  LogsApi,
  GetSearchActivityPreviewRequest,
  GetSearchActivityPreviewResponse,
  GetSearchActivityPreviewResults
} from './logs.types'

// our "constructor"
export const logsService = (api: ApisauceInstance): LogsApi => {
  /**
   * Get list of activity logs from api service
   * @param query - query parameters to filter and work with pagination of activity logs
   */
  const activityList = async ({
    query
  }: GetActivityLogListRequest): Promise<GetActivityLogListResults> => {
    const queryDecamelize = decamelizeObject(query)

    const response = await api.get<GetActivityLogListResponse>(
      LogsPathes.ACTIVITIES,
      queryDecamelize
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) {
        return problem
      }
    }
    try {
      if (response.data) {
        const data = camelcaseKeys(response.data, {
          deep: true
        })

        return { kind: 'ok', data }
      }

      return { kind: 'bad-data' }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  /**
   * Get activity log by id from api service
   * @param params - parameters to identifier activity log
   */

  const activityById = async ({
    params
  }: GetActivityLogByIdRequest): Promise<GetActivityLogByIdResults> => {
    if (!params.id) {
      return { kind: 'bad-data' }
    }

    const response = await api.get<GetActivityLogByIdResponse>(
      `${LogsPathes.ACTIVITIES}/${params.id}`
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) {
        return problem
      }
    }

    try {
      if (response.data) {
        const data = camelcaseKeys(response.data, {
          deep: true
        })

        return { kind: 'ok', data }
      }

      return { kind: 'bad-data' }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  /**
   * Get list of error logs from api service
   * @param query - query parameters to filter and work with pagination of activity logs
   */

  const errorList = async ({
    query
  }: GetErrorLogListRequest): Promise<GetErrorLogListResults> => {
    const queryDecamelize = decamelizeObject(query)

    const response = await api.get<GetErrorLogListResponse>(
      LogsPathes.ERRORS,
      queryDecamelize
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) {
        return problem
      }
    }
    try {
      if (response.data) {
        const data = camelcaseKeys(response.data, {
          deep: true
        })

        return { kind: 'ok', data }
      }

      return { kind: 'bad-data' }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  /**
   * Get error log by id from api service
   * @param params - parameters to identifier error log
   */

  const errorById = async ({
    params
  }: GetErrorLogByIdRequest): Promise<GetErrorLogByIdResults> => {
    if (!params.id) {
      return { kind: 'bad-data' }
    }

    const response = await api.get<GetErrorLogByIdResponse>(
      `${LogsPathes.ERRORS}/${params.id}`
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) {
        return problem
      }
    }

    try {
      if (response.data) {
        const data = camelcaseKeys(response.data, {
          deep: true
        })

        return { kind: 'ok', data }
      }

      return { kind: 'bad-data' }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  /**
   * Get search activity preview list from api service
   */
  const getSearchActivityPreview = async ({
    query
  }: GetSearchActivityPreviewRequest): Promise<GetSearchActivityPreviewResults> => {
    if (!query) {
      return { kind: 'bad-data' }
    }

    const response = await api.get<GetSearchActivityPreviewResponse>(
      LogsPathes.ACTIVITIES,
      query
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) {
        return problem
      }
    }
    try {
      if (response.data) {
        const data = camelcaseKeys(response.data, {
          deep: true
        })
        const uniqueList = filterUniqueLogs(data.results)

        return {
          kind: 'ok',
          data: { ...data, results: uniqueList },
          reset: !query.offset
        }
      }

      return { kind: 'bad-data' }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  return {
    activityList,
    activityById,
    errorList,
    errorById,
    getSearchActivityPreview
  }
}
