import { createUseStyles } from 'react-jss'

import { LogsViewStyleProps } from './logs-view.types'

export const useStyle = createUseStyles({
  applicationList: {
    padding: ({ isLoaded }: LogsViewStyleProps) =>
      isLoaded ? '24px 8px 16px' : '24px 8px 64px',
    height: '100%',
    borderRadius: '0 0 12px 12px',
    '&::-webkit-scrollbar': {
      width: 0
    }
  }
})
