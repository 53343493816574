import React, { FormEvent, useEffect, useState, useMemo } from 'react'

import {
  Content,
  Column,
  Text,
  Row,
  InputFloatLabel,
  formatToNumber
} from '../../../components'
import { CoreItem } from '../../../store'
import { useUserData } from '../../../hooks'
import { getDaysInYear, getIsValueExist } from '../../helpers'
import { daysIconProps } from '../label-props'
import { getError } from '../helpers'
import { useAutoDeletionData } from './helpers'
import { SubmitButton } from '../submit-button'

import {
  AppsAutoDeletionProps,
  AutoDeletionProps,
  AutoDeletionStatusProps
} from './apps-auto-deletion.types'
import { ErrorType, ProgramIds } from '../settings.types'
import { useStyle } from './apps-auto-deletion.styles'

const ERROR: ErrorType = {
  id: 'C', // e.x ProgramIds.CHEAPR
  textProps: {
    text: ''
  }
}

export const AppsAutoDeletion = ({
  user,
  catalog,
  settings,
  isLoaded,
  onSave
}: AppsAutoDeletionProps) => {
  const classes = useStyle()
  const [daysByProgram, changeDaysByProgram] = useState<
    AutoDeletionStatusProps | undefined
  >()
  const { isSaveAvailable, isGrantedUser } = useAutoDeletionData({
    daysByProgram,
    settings
  })
  const { GeneralProgramsData } = useUserData({
    validationRule: null,
    catalog,
    user
  })
  const Programs = useMemo(
    () => GeneralProgramsData,
    [GeneralProgramsData]
  ) as CoreItem[]

  const handleOnChange = (id: string) => (e: FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLTextAreaElement
    const { value } = target

    const stabValue = formatToNumber(value)

    if (daysByProgram) {
      changeDaysByProgram({ ...daysByProgram, [id]: stabValue })
    }
  }

  const handleOnBlur = () => (e: FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLTextAreaElement
    const { value } = target
    const isValid = Number(value) <= getDaysInYear()
    console.log('is valid value = ', isValid)
  }

  const handleOnGetError = (id: ProgramIds | string) => getError(id, ERROR)

  const handleOnSaveSettings = () => {
    const updatedSettings: AutoDeletionProps[] = settings
      ? settings.map((item) => {
          const autoDeletionTerm: number = daysByProgram
            ? daysByProgram[item.id]
            : 0

          return {
            id: item.id,
            title: item.title,
            emailOnSubmission: item.emailOnSubmission,
            autoDeletionTerm
          }
        })
      : []

    onSave(updatedSettings)
  }

  useEffect(() => {
    let obj: AutoDeletionStatusProps = {}
    settings?.forEach((item: AutoDeletionProps) => {
      obj[item.id] = item.autoDeletionTerm
    })

    changeDaysByProgram({
      ...daysByProgram,
      ...obj
    })
  }, [settings])

  return (
    <Content className={classes.content} preset="settings">
      <Column fullWidth className={classes.box}>
        <Text
          text="Applications auto deletion"
          tx="settings.autoDeletion"
          preset="h2"
          color="darkBlue"
        />
        <Column fullWidth alignItems="flex-start">
          <Text
            className={classes.description}
            text="Auto deletion rule deletes applications aftes the setted days gone beggining from the Application date and oriented to the server timezone."
            tx="settings.autoDeletion.desc"
            preset="body"
            color="border"
          />
          <Text
            text="Deletion applications on IQR MVS service doesn't influence on IQR Automation service's applications."
            tx="settings.deletion"
            preset="body"
            color="border"
          />
          <Text
            className={classes.emailApplicant}
            text="Set the number of days to automatically delete applications for each program:"
            tx="settings.autoDeletion.description"
            preset="bodyBold"
          />
          <Row
            className={classes.inputs}
            justifyContent="flex-start"
            alignItems="flex-end"
            fullWidth
          >
            {daysByProgram &&
              Programs.map((item: CoreItem) => (
                <InputFloatLabel
                  key={`days-by-program-${item.name}`}
                  alignItems="flex-start"
                  className={classes.cell}
                  id={item.name}
                  labelProps={{
                    text: item.name,
                    color: 'darkBlue'
                  }}
                  readOnly={!isGrantedUser}
                  defaultValue={daysByProgram[item.id]}
                  iconProps={daysIconProps}
                  isFocused={getIsValueExist(daysByProgram, item.id)}
                  errorProps={handleOnGetError(item.id)}
                  onBlur={handleOnBlur()}
                  onChange={handleOnChange(item.id)}
                />
              ))}
          </Row>
        </Column>
        <SubmitButton
          isLoaded={isLoaded}
          disabled={!isSaveAvailable}
          onClick={handleOnSaveSettings}
        />
      </Column>
    </Content>
  )
}
