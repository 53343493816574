import { ReactNode } from 'react'
import { RPSResolution, RPSResolutionNames } from '../../../../services'

export type ResolutionItemProps = {
  className?: string
  text: string
  value: RPSResolution
  children?: ReactNode
}

export type ResolutionKeys = {
  [key: number]: RPSResolutionNames | null
}

export const RESOLUTION: ResolutionKeys = {
  5: RPSResolutionNames.APPROVED,
  10: RPSResolutionNames.IN_PROGRESS,
  15: RPSResolutionNames.INELIGIBLE,
  20: RPSResolutionNames.INCOMPLETE,
  25: null
}
