import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../theme'

import { concatClassNames } from '../helpers'
import { Icon } from '../icon'
import { Row } from '../row'

import { useStyle } from './round-color-icon.styles'
import { RoundColorIconProps } from './round-color-icon.types'

export const RoundColorIcon: FC<RoundColorIconProps> = ({
  className,
  color,
  size,
  width = 24,
  height = 24,
  ...props
}) => {
  const classes = useStyle({ color, size, width, height })
  const theme = useTheme<LightTheme>()

  const getIconSize = (iconSize: number) => iconSize * 0.75

  const fill = theme.colors[color]

  return (
    <Row className={concatClassNames(className, classes.container)}>
      <Icon
        fill={fill}
        {...props}
        height={getIconSize(size || Number(height))}
        width={getIconSize(size || Number(width))}
      />
    </Row>
  )
}
