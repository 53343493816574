import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../theme'
import { IconProps } from './types'

export const Ellipse12: FC<IconProps> = (props) => {
  const theme: LightTheme = useTheme()
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={11}
      height={11}
      viewBox="0 0 10 10"
      fill="none"
      {...props}
    >
      <circle
        cx="5"
        cy="5"
        r="5"
        strokeWidth={4}
        stroke={props.fill || theme.colors.darkBlue}
        fill={theme.colors.white}
      />
    </svg>
  )
}
