import React, { FC, useCallback, useEffect, useState } from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../theme'
import {
  QuestionModal,
  Row,
  Text,
  PickerButton,
  PickerButtonVariant
} from '../../components'
import { RPSResolution } from '../../services'
import {
  approveLabelProps,
  incompleteLabelProps,
  inprogressLabelProps,
  rejectLabelProps
} from './labels'

import { ResolutionModalProps } from './resolution-modal.types'
import { useStyle } from './resolution-modal.styles'

export const ResolutionModal: FC<ResolutionModalProps> = ({
  open,
  children,
  docId,
  defaultValue,
  description,
  descriptionTx,
  disabled,
  title,
  titleTx,
  onClose,
  onSubmit,
  onResolutionChange
}) => {
  const classes = useStyle()
  const theme = useTheme<LightTheme>()

  const [resolutionData, changeResolutionData] = useState<RPSResolution>(
    defaultValue || RPSResolution.NONE
  )

  const handleOnResolutionClick = useCallback(
    (value: PickerButtonVariant, pickerStatus: boolean) => {
      const isApproved = Boolean(RPSResolution.APPROVED) === pickerStatus
      const isRejected = Boolean(RPSResolution.INELIGIBLE) === pickerStatus
      const isInProgress = Boolean(RPSResolution.IN_PROGRESS) === pickerStatus
      const isIncomplete = Boolean(RPSResolution.INCOMPLETE) === pickerStatus

      const isResolutionSelected =
        isApproved || isRejected || isInProgress || isIncomplete
      const nextValue = value as RPSResolution
      const docResolution: RPSResolution = isResolutionSelected
        ? nextValue
        : RPSResolution.IN_PROGRESS

      changeResolutionData(docResolution)
      if (onResolutionChange) {
        onResolutionChange(docResolution)
      }
    },
    []
  )

  const handleOnSubmit = () => {
    if (onSubmit) {
      onSubmit(resolutionData)
    }
  }

  useEffect(() => {
    if (defaultValue) {
      changeResolutionData(defaultValue)
    }
  }, [defaultValue, docId])

  if (!open) {
    return <></>
  }

  return (
    <QuestionModal
      title={title}
      titleTx={titleTx}
      disabled={disabled}
      onSubmit={handleOnSubmit}
      onClose={onClose}
    >
      <Row fullWidth className={classes.description}>
        <Text
          text={description}
          tx={descriptionTx}
          preset="body"
          color="inactive"
        />
      </Row>
      <Row className={classes.resolution} fullWidth justifyContent="flex-start">
        <Text
          text="Resolution:"
          tx="document.resolution"
          preset="caption"
          color="inactive"
        />
      </Row>
      <Row className={classes.types} justifyContent="space-between">
        <PickerButton
          name="IN_PROGRESS"
          iconName="TIMELAPSE"
          iconColor={theme.colors.blue}
          titleProps={inprogressLabelProps}
          variants={RPSResolution}
          value={resolutionData}
          onClick={handleOnResolutionClick}
        />
        <PickerButton
          name="APPROVED"
          iconName="CheckCircleOutline"
          iconColor={theme.colors.button}
          titleProps={approveLabelProps}
          variants={RPSResolution}
          value={resolutionData}
          onClick={handleOnResolutionClick}
        />
        <PickerButton
          name="INCOMPLETE"
          iconName="Info"
          iconColor={theme.colors.orange}
          titleProps={incompleteLabelProps}
          variants={RPSResolution}
          value={resolutionData}
          onClick={handleOnResolutionClick}
        />
        <PickerButton
          name="INELIGIBLE"
          iconName="HighlightOff"
          iconColor={theme.colors.hoverRedButton}
          titleProps={rejectLabelProps}
          variants={RPSResolution}
          value={resolutionData}
          onClick={handleOnResolutionClick}
        />
      </Row>
      {children}
    </QuestionModal>
  )
}
