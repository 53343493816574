import { createUseStyles } from 'react-jss'

import { ValidationFieldHintStyleProps } from './validation-field-hint.types'

export const useStyle = createUseStyles({
  modal: {
    minHeight: 136,
    padding: ({ isScrollable }: ValidationFieldHintStyleProps) =>
      isScrollable ? '32px 0 0 0' : '32px 0 32px 0'
  },
  basic: {
    minHeight: 122
  },
  title: {
    marginLeft: 16
  },
  titleContainer: {
    marginTop: 32,
    marginLeft: 32,
    width: 'calc(100% - 32px)'
  },
  description: {
    marginTop: 32,
    textAlign: 'start'
  },
  emptyContent: {
    marginTop: 20,
    width: 'calc(100% - 64px)'
  }
})
