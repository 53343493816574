import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    background: theme.colors.white,
    boxShadow: theme.boxShadow,
    padding: '24px 16px',
    width: 'calc(100% - 32px)'
  },
  row: {
    marginTop: 16
  },
  title: {
    marginLeft: 16,
    whiteSpace: 'nowrap'
  },
  noPadding: {
    padding: 0
  }
}))
