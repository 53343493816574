import { useContext, useMemo } from 'react'

import { UserContext } from '../../../../providers'
import { getCatalogData } from '../../../helpers'

import { SettingsProps } from './types'

export const useSettings = ({ catalog, rpsReviewer }: SettingsProps) => {
  const { isSysAdmin, isSuperUser } = useContext(UserContext)

  const reviewer = getCatalogData({
    catalog,
    id: 'rpsReviewer',
    value: rpsReviewer
  })

  const isDeleteAvailable = useMemo(
    () => isSysAdmin || isSuperUser,
    [isSysAdmin, isSuperUser]
  )

  return {
    reviewer,
    isDeleteAvailable
  }
}
