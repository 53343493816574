import React, { FC, useState } from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../../../theme'
import { Column, concatClassNames, Row, Text } from '../../../../components'
import { SortButton } from '../sort-button'

import { ColumnKeys, SortTypes } from '../../application-list.types'
import { ListHeaderProps } from './apps-list-header.types'
import { useStyle } from './apps-list-header.styles'

const defaultState = {
  program: SortTypes.DESC,
  title: SortTypes.DESC,
  application_date: SortTypes.DESC,
  resolution: SortTypes.DESC,
  last_recognition_time: SortTypes.DESC,
  rps_reviewer: SortTypes.DESC,
  rps_review_date: SortTypes.DESC,
  rps_resolution: SortTypes.DESC
}

export const ListHeader: FC<ListHeaderProps> = ({ className, onSort }) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })
  const [sorts, changeSorts] = useState(defaultState)

  const handleOnSort = (id: ColumnKeys) => () => {
    const sortBy = sorts[id] === SortTypes.ASC ? SortTypes.DESC : SortTypes.ASC

    changeSorts({
      ...sorts,
      ...defaultState,
      [id]: sortBy
    })

    if (onSort) {
      onSort(id, sortBy)
    }
  }

  return (
    <Row
      className={concatClassNames(className, classes.container)}
      justifyContent="space-between"
    >
      <Row fullWidth justifyContent="space-between">
        <Column
          className={classes.name}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Row
            className={classes.row}
            onClick={handleOnSort(ColumnKeys.PROGRAM)}
          >
            <Text text="Program" tx="application.program" preset="boldHeader" />
            <SortButton sort={sorts[ColumnKeys.PROGRAM]} />
          </Row>
        </Column>
        <Column
          className={concatClassNames(classes.appId, classes.indent)}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Row
            className={classes.row}
            onClick={handleOnSort(ColumnKeys.APP_ID)}
          >
            <Text text="App ID" tx="application.appId" preset="boldHeader" />
            <SortButton sort={sorts[ColumnKeys.APP_ID]} />
          </Row>
        </Column>
        <Column
          className={concatClassNames(classes.applDate, classes.indent)}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Row
            className={classes.row}
            onClick={handleOnSort(ColumnKeys.APPLICATION_DATE)}
          >
            <Text text="SF Date" tx="application.sfDate" preset="boldHeader" />
            <SortButton sort={sorts[ColumnKeys.APPLICATION_DATE]} />
          </Row>
        </Column>
        <Column
          className={concatClassNames(classes.iqrRes, classes.indent)}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Row
            className={classes.row}
            onClick={handleOnSort(ColumnKeys.STATUS)}
          >
            <Text
              text="IQR Result"
              tx="application.iqrResult"
              preset="boldHeader"
            />
            <SortButton sort={sorts[ColumnKeys.STATUS]} />
          </Row>
        </Column>
        <Column
          className={concatClassNames(classes.iqr, classes.indent)}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Text
            text="IQR Doc Results"
            tx="application.iqrResults"
            preset="boldHeader"
          />
        </Column>
        <Column
          className={classes.runDate}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Row
            className={concatClassNames(classes.row, classes.indent)}
            onClick={handleOnSort(ColumnKeys.IQR_RUN_DATE)}
          >
            <Text
              text="IQR Run Date"
              tx="application.iqrRunDate"
              preset="boldHeader"
            />
            <SortButton sort={sorts[ColumnKeys.IQR_RUN_DATE]} />
          </Row>
        </Column>
        <Column
          className={concatClassNames(classes.reviewedBy, classes.indent)}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Row className={classes.row} onClick={handleOnSort(ColumnKeys.RPS)}>
            <Text
              text="Reviewed By"
              tx="application.reviewedBy"
              preset="boldHeader"
            />
            <SortButton sort={sorts[ColumnKeys.RPS]} />
          </Row>
        </Column>
        <Column
          className={concatClassNames(classes.reviewedDate, classes.indent)}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Row
            className={classes.row}
            onClick={handleOnSort(ColumnKeys.RPS_REWIEW_DATE)}
          >
            <Text
              text="Reviewed Date"
              tx="application.reviewedDate"
              preset="boldHeader"
            />
            <SortButton sort={sorts[ColumnKeys.RPS_REWIEW_DATE]} />
          </Row>
        </Column>
        <Column
          className={concatClassNames(classes.resolution, classes.indent)}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Row
            className={classes.row}
            onClick={handleOnSort(ColumnKeys.APP_RESOLUTION)}
          >
            <Text
              text="App Resolution"
              tx="application.appResolution"
              preset="boldHeader"
            />
            <SortButton sort={sorts[ColumnKeys.APP_RESOLUTION]} />
          </Row>
        </Column>
        <Column
          className={classes.iqr}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Text
            text="Reviewer Results"
            tx="application.reviewerResults"
            preset="boldHeader"
          />
        </Column>
      </Row>
    </Row>
  )
}
