import { FieldValueHoverProps } from './multi-line.types'

const MAX_SYMBOLS_IN_VALUE = 60

export const onFieldValueHover =
  ({ value, changeOpenTooltip }: FieldValueHoverProps) =>
  (flag: boolean) =>
  () => {
    const valueLength = value.length
    if (valueLength > MAX_SYMBOLS_IN_VALUE) {
      changeOpenTooltip(flag)
    }
  }
