import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    backgroundColor: theme.colors.white,
    height: 56,
    width: 'calc(100% - 32px)',
    padding: '0 16px',
    cursor: 'pointer',
    zIndex: 1,
    borderRadius: 4,
    borderTop: '1px solid transparent',
    borderRight: '1px solid transparent',
    borderBottom: '1px solid transparent',
    '&:not(:first-child)': {
      marginTop: 8
    },
    '&:hover': {
      borderTop: `1px solid ${theme.colors.headerGradient}`,
      borderRight: `1px solid ${theme.colors.headerGradient}`,
      borderBottom: `1px solid ${theme.colors.headerGradient}`,
      boxShadow: '2px 4px 12px rgb(3 82 127 / 12%)',
      zIndex: 3,
      width: 'calc(100% - 32px)',
      padding: '0 16px'
    }
  },
  subContainer: {
    height: 56,
    zIndex: 4,
    width: '100%'
  },
  menu: {
    marginLeft: 16,
    '& > *': {
      cursor: 'pointer'
    }
  },
  docType: {
    width: 300,
    '& > *': {
      width: '100%'
    }
  },
  resolution: {
    width: 160,
    marginLeft: 8
  },
  version: {
    width: 50,
    marginLeft: 8
  },
  column: {
    width: 90,
    marginLeft: 8
  },
  text: {
    cursor: 'pointer',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  decline: {
    borderLeft: `4px solid ${theme.colors.hoverRedButton}`,
    '&:hover': {
      borderLeft: `4px solid ${theme.colors.hoverRedButton}`
    }
  },
  pass: {
    borderLeft: `4px solid ${theme.colors.button}`,
    '&:hover': {
      borderLeft: `4px solid ${theme.colors.button}`
    }
  },
  verification: {
    borderLeft: `4px solid ${theme.colors.orange}`,
    '&:hover': {
      borderLeft: `4px solid ${theme.colors.orange}`
    }
  },
  ignore: {
    borderLeft: `4px solid ${theme.colors.border}`,
    '&:hover': {
      borderLeft: `4px solid ${theme.colors.border}`
    }
  },
  information: {
    borderLeft: `4px solid ${theme.colors.darkBlue}`,
    '&:hover': {
      borderLeft: `4px solid ${theme.colors.darkBlue}`
    }
  },
  '@media screen and (max-width: 800px)': {
    name: {
      width: 100
    },
    text: {
      maxWidth: 90
    }
  }
}))
