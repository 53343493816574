import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../../theme'
import { SVGIconProps } from './icons.types'

export const TimesCircle: FC<SVGIconProps> = (props) => {
  const theme: LightTheme = useTheme()
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00004 1.33325C4.33337 1.33325 1.33337 4.33325 1.33337 7.99992C1.33337 11.6666 4.33337 14.6666 8.00004 14.6666C11.6667 14.6666 14.6667 11.6666 14.6667 7.99992C14.6667 4.33325 11.6667 1.33325 8.00004 1.33325ZM10.4667 9.53325C10.7334 9.79992 10.7334 10.1999 10.4667 10.4666C10.2 10.7333 9.80004 10.7333 9.53337 10.4666L8.00004 8.93325L6.46671 10.4666C6.20004 10.7333 5.80004 10.7333 5.53337 10.4666C5.26671 10.1999 5.26671 9.79992 5.53337 9.53325L7.06671 7.99992L5.53337 6.46659C5.26671 6.19992 5.26671 5.79992 5.53337 5.53325C5.80004 5.26659 6.20004 5.26659 6.46671 5.53325L8.00004 7.06659L9.53337 5.53325C9.80004 5.26659 10.2 5.26659 10.4667 5.53325C10.7334 5.79992 10.7334 6.19992 10.4667 6.46659L8.93337 7.99992L10.4667 9.53325Z"
        fill={props.fill || theme.colors.hoverRedButton}
      />
    </svg>
  )
}
