import React from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../theme'
import { Row } from '../../row'
import { Icon, IconName } from '../../icon'

import { RightCaretProps } from './right-caret.types'
import { useStyle } from './right-caret.styles'

export const RightCaret = ({
  className = '',
  open,
  custom,
  iconName: iconNameOverride,
  fill: fillOverride,
  onClick
}: RightCaretProps) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ open, custom })

  const iconName = iconNameOverride || IconName.CARET_DOWN
  const caretClassName = iconNameOverride
    ? classes.caret
    : `${classes.caret} ${classes.caretDown}`
  const fill = iconNameOverride ? theme.colors.border : fillOverride

  return (
    <Row className={`${classes.container} ${className}`} onClick={onClick}>
      <Icon className={caretClassName} name={iconName} fill={fill} />
    </Row>
  )
}
