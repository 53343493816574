import { GeneralApiProblem } from '../api-problem'

export enum AppPathes {
  GET_CATALOG = '/iqr/catalog'
}

export enum RPSResolution {
  APPROVED = 5,
  IN_PROGRESS = 10,
  INELIGIBLE = 15,
  INCOMPLETE = 20,
  NONE = 25
}
export enum CommonNames {
  NONE = 'None'
}

export enum RPSResolutionNames {
  APPROVED = 'approved',
  IN_PROGRESS = 'in progress',
  INELIGIBLE = 'Ineligible',
  INCOMPLETE = 'incomplete',
  NONE = 'None'
}

export enum Resolution {
  PASS = 1,
  VERIFICATION = 2,
  DECLINE = 3,
  IGNORE = 4,
  FYI = 5
}

export enum DocumentType {
  PALA = 1,
  POR = 2,
  REG = 3,
  C_ACK = 4,
  T_C = 5
}

export type GetCatalogRequest = {}

export type RawCoreItem = [string, string]

export type UCatalog<T extends [] | {}> = {
  program: T[]
  resolution: T[]
  document: T[]
  rpsResolution: T[]
  rpsReviewer: T[]
  field: T[]
  fieldRuleType: T[]
  fieldRuleValue: T[]
  rejectionReason: T[]
  allReviewers: T[]
}

export type CatalogKeys = keyof UCatalog<RawCoreItem>

export type GetCatalogResponse = UCatalog<RawCoreItem>

export type GetCatalogResults =
  | {
      kind?: string
      catalog: GetCatalogResponse
    }
  | GeneralApiProblem

export type AppApi = {
  getCatalog: (options: GetCatalogRequest) => Promise<GetCatalogResults>
}
