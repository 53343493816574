import { useMemo } from 'react'

import { formatDateBySlash, getCatalogData } from '../../pages'

import { DataProps } from './full-program-card.types'

export const useData = ({
  updatedAt,
  catalog,
  field,
  value,
  manualValue,
  type,
  resolution
}: DataProps) => {
  const DateValue = useMemo(
    () => formatDateBySlash(new Date(updatedAt)),
    [updatedAt]
  )
  const Name = useMemo(
    () =>
      getCatalogData({
        catalog,
        id: 'field',
        value: field
      }),
    [field, catalog]
  )
  const Value = useMemo(
    () => getCatalogData({ catalog, id: 'fieldRuleValue', value }),
    [value]
  )
  const Type = useMemo(
    () => getCatalogData({ catalog, id: 'fieldRuleType', value: type }),
    [type]
  )
  const Resolution = useMemo(
    () => getCatalogData({ catalog, id: 'resolution', value: resolution }),
    [resolution]
  )
  const UnitedValue = manualValue ? `${Value} [${manualValue}]` : Value

  return {
    DateValue,
    Name,
    Value: UnitedValue,
    Type,
    Resolution
  }
}
