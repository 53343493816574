import React, { FC } from 'react'

import { Column } from '../../components'
import { FieldsTable } from '../fields-table'
import { FullFieldsMenuHeader } from './full-fields-menu-header'

import { FullFieldsMenuProps } from './full-fields-menu.types'
import { useStyle } from './full-fields-menu.styles'

export const FullFieldsMenu: FC<FullFieldsMenuProps> = ({
  activeField,
  className = '',
  isEditable,
  data,
  multiValue,
  title,
  titleTx,
  onApprove,
  onReject,
  onFocusInput,
  onBlurInput
}) => {
  const classes = useStyle()

  return (
    <Column fullWidth className={className}>
      <FullFieldsMenuHeader
        count={data.length}
        title={title}
        titleTx={titleTx}
      />
      <FieldsTable
        className={classes.table}
        isEditable={isEditable}
        activeField={activeField}
        data={data}
        multiValue={multiValue}
        onApprove={onApprove}
        onReject={onReject}
        onFocusInput={onFocusInput}
        onBlurInput={onBlurInput}
      />
    </Column>
  )
}
