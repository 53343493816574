import { ReactNode } from 'react'

export enum UserRoleStateNames {
  RPS = 'isRPS',
  COORDINATOR = 'isCoordinator',
  MANAGER = 'isManager',
  SYSADMIN = 'isSysAdmin',
  SUPERUSER = 'isSuperUser'
}

export type UserProviderProps = {
  children: ReactNode
}

export type UserContextValue = {
  isRPS: boolean
  isCoordinator: boolean
  isManager: boolean
  isSysAdmin: boolean
  isSuperUser: boolean
}
