import React, { MouseEventHandler } from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../../../theme'
import { Column } from '../../../../components'
import { Document } from '../../../../services'

import { DocsListItem } from '../docs-list-item'

import { ItemsListProps } from './items-list.types'
import { useStyle } from './items-list.styles'

export const ItemsList = ({ data, catalog, onClickItem }: ItemsListProps) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })

  const handleOnClickItem =
    (doc: Document): MouseEventHandler =>
    (event) => {
      if (onClickItem) {
        onClickItem(doc, event)
      }
    }

  if (!data) {
    return null
  }

  return (
    <Column fullWidth className={classes.container}>
      {data.documents.map((doc: Document) => (
        <DocsListItem
          key={doc.id}
          {...doc}
          catalog={catalog}
          createdAt={data.applicationDate}
          onClick={handleOnClickItem(doc)}
        />
      ))}
    </Column>
  )
}
