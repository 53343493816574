import { createActions, createTypes } from 'reduxsauce'

import {
  ApplicationsActions,
  ApplicationsActionsTypes
} from './applications.types'

export const applicationsStringTypes = createTypes<ApplicationsActionsTypes>(`
    GET_APPLICATIONS_REQUEST
    GET_APPLICATIONS_SUCCESS
    GET_APPLICATIONS_FAILURE

    GET_APPLICATION_REQUEST
    GET_APPLICATION_SUCCESS
    GET_APPLICATION_FAILURE

    GET_SEARCH_PREVIEW_REQUEST
    GET_SEARCH_PREVIEW_SUCCESS
    GET_SEARCH_PREVIEW_FAILURE

    DELETE_APPLICATION_REQUEST
    DELETE_APPLICATION_SUCCESS
    DELETE_APPLICATION_FAILURE
`)

const { Types: ApplicationsActionTypes, Creators: ApplicationsCreators } =
  createActions<ApplicationsActionsTypes, ApplicationsActions>({
    getApplicationsRequest: ['payload'],
    getApplicationsSuccess: ['payload'],
    getApplicationsFailure: ['payload'],

    getApplicationRequest: ['payload'],
    getApplicationSuccess: ['payload'],
    getApplicationFailure: ['payload'],

    getSearchPreviewRequest: ['payload'],
    getSearchPreviewSuccess: ['payload'],
    getSearchPreviewFailure: ['payload'],

    deleteApplicationRequest: ['payload'],
    deleteApplicationSuccess: ['payload'],
    deleteApplicationFailure: ['payload']
  })

export { ApplicationsCreators, ApplicationsActionTypes }
