import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

import { PopupDocumentValidationStyleProps } from './popup-document-validation.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    position: 'fixed',
    background: theme.colors.white,
    inset: '0px 0px 0px 0px',
    zIndex: 15
  },
  header: {
    borderBottom: `1px solid ${theme.colors.secondButton}`
  },
  rightPanelMenu: {
    overflowY: 'scroll',
    position: 'absolute',
    right: '100%',
    top: 0,
    bottom: 0,
    padding: '24px 16px',
    width: 484,
    borderLeft: `1px solid ${theme.colors.secondButton}`,
    backgroundColor: theme.colors.white
  },
  viewer: {
    width: '100%',
    height: '100%'
  },
  menuContainer: {
    borderLeft: `1px solid ${theme.colors.secondButton}`,
    padding: '16px 0'
  },
  menuItem: {
    padding: 16,
    '& > path': {
      fill: theme.colors.text
    },
    '&:hover': {
      backgroundColor: theme.colors.footerBackground
    }
  },
  validatedItem: ({ fieldType }: PopupDocumentValidationStyleProps) => ({
    backgroundColor: fieldType === 'validated' && theme.colors.footerBackground,
    '& > path': {
      fill: theme.colors[fieldType === 'validated' ? 'green' : 'text']
    },
    '&:hover > path': {
      fill: theme.colors.green
    }
  }),
  verificationItem: ({ fieldType }: PopupDocumentValidationStyleProps) => ({
    backgroundColor:
      fieldType === 'verification' && theme.colors.footerBackground,
    '& > path': {
      fill: theme.colors[fieldType === 'verification' ? 'red' : 'text']
    },
    '&:hover > path': {
      fill: theme.colors.red
    }
  })
}))
