import { createUseStyles } from 'react-jss'

import { ColumnProps } from './column.types'

const getFullfilledHeight = (fullFilledHeight?: boolean) =>
  fullFilledHeight ? '100vh' : 'auto'

export const useStyle = createUseStyles({
  column: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    alignItems: (props: ColumnProps) => props.alignItems || 'center',
    justifyContent: (props: ColumnProps) => props.justifyContent || 'center',
    height: ({ fullHeight, fullFilledHeight }: ColumnProps) =>
      fullHeight ? '100%' : getFullfilledHeight(fullFilledHeight),
    width: ({ fullWidth }: ColumnProps) => (fullWidth ? '100%' : 'auto')
  }
})
