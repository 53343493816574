import React, { FC, useState } from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../../../theme'
import {
  Column,
  concatClassNames,
  Row,
  Text,
  SortButton
} from '../../../../components'
import { SortTypes } from '../../../../services'

import { ColumnKeys } from '../../logs.types'
import { ListHeaderProps } from './logs-list-header.types'
import { useStyle } from './logs-list-header.styles'

const defaultState = {
  program: SortTypes.DESC,
  application: SortTypes.DESC,
  message: SortTypes.DESC,
  user_name: SortTypes.DESC,
  created_at: SortTypes.DESC
}

export const LogsListHeader: FC<ListHeaderProps> = ({ className, onSort }) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })
  const [sorts, changeSorts] = useState(defaultState)

  const handleOnSort = (id: ColumnKeys) => () => {
    const sortBy = sorts[id] === SortTypes.ASC ? SortTypes.DESC : SortTypes.ASC

    changeSorts({
      ...sorts,
      ...defaultState,
      [id]: sortBy
    })

    if (onSort) {
      onSort(id, sortBy)
    }
  }

  return (
    <Row
      className={concatClassNames(className, classes.container)}
      justifyContent="space-between"
    >
      <Row fullWidth justifyContent="space-between">
        <Column
          className={classes.program}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Row
            className={classes.row}
            onClick={handleOnSort(ColumnKeys.PROGRAM)}
          >
            <Text text="Program" tx="application.program" preset="boldHeader" />
            <SortButton sort={sorts[ColumnKeys.PROGRAM]} />
          </Row>
        </Column>
        <Column
          className={classes.column}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Row
            className={classes.row}
            onClick={handleOnSort(ColumnKeys.APP_ID)}
          >
            <Text text="App ID" tx="application.appId" preset="boldHeader" />
            <SortButton sort={sorts[ColumnKeys.APP_ID]} />
          </Row>
        </Column>
        <Column
          className={classes.message}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Row
            className={classes.row}
            onClick={handleOnSort(ColumnKeys.ACTION_LOG)}
          >
            <Text
              text="Action Log"
              tx="activityLog.actionLog"
              preset="boldHeader"
            />
            <SortButton sort={sorts[ColumnKeys.ACTION_LOG]} />
          </Row>
        </Column>
        <Column
          className={classes.date}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Row className={classes.row} onClick={handleOnSort(ColumnKeys.USER)}>
            <Text text="User" tx="activityLog.user" preset="boldHeader" />
            <SortButton sort={sorts[ColumnKeys.USER]} />
          </Row>
        </Column>
        <Column
          className={classes.date}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Row
            className={classes.row}
            onClick={handleOnSort(ColumnKeys.DATE_OF_ACTION)}
          >
            <Text
              text="IQR Run Date"
              tx="activityLog.dateOfAction"
              preset="boldHeader"
            />
            <SortButton sort={sorts[ColumnKeys.DATE_OF_ACTION]} />
          </Row>
        </Column>
      </Row>
    </Row>
  )
}
