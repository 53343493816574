import React, { FC, useMemo, useState } from 'react'
import { useDefaultValue } from '@cse/ui'

import { DocumentViewerOptions } from '../../pages'
import { Column } from '../column'
import { concatClassNames } from '../helpers'
import { DocumentViewerContent } from './document-viewer-content'
import { DocumentViewerHeader } from './document-viewer-header'

import { DocumentViewerProps } from './document-viewer.types'
import { useStyle } from './document-viewer.styles'

export const DocumentViewer: FC<DocumentViewerProps> = ({
  activeField,
  className = '',
  children,
  data,
  options: valueOptions,
  defaultValue,
  pages,
  listWidth,
  listHeight,
  onChangePage,
  onChangeOptions,
  onChangeZoom,
  onClickPolygon,
  onChangeRotate,
  onChangeFitToPage,
  onChangeFitToWidth,
  onChangePopOut
}) => {
  const DefaultValue = useMemo(() => {
    const defaultValueOptions: DocumentViewerOptions = {
      zoom: 1,
      page: 1,
      rotate: 0
    }

    return defaultValue || defaultValueOptions
  }, [defaultValue])

  const [options, changeOptions] = useState<DocumentViewerOptions>(DefaultValue)
  const Options = useDefaultValue(options, valueOptions)

  const classes = useStyle()

  const handleOnChangeOptions = (nextOptions: DocumentViewerOptions) => {
    changeOptions(nextOptions)

    if (onChangeOptions) {
      onChangeOptions(nextOptions)
    }
  }

  return (
    <Column
      alignItems="flex-start"
      className={concatClassNames(className, classes.container)}
    >
      <DocumentViewerHeader
        options={Options}
        pages={pages}
        onChangeOptions={handleOnChangeOptions}
        onChangePage={onChangePage}
        onChangeZoom={onChangeZoom}
        onChangeRotate={onChangeRotate}
        onChangeFitToPage={onChangeFitToPage}
        onChangeFitToWidth={onChangeFitToWidth}
        onChangePopOut={onChangePopOut}
      >
        {children}
      </DocumentViewerHeader>
      <DocumentViewerContent
        className={classes.content}
        activeField={activeField}
        data={data}
        listWidth={listWidth}
        listHeight={listHeight}
        options={Options}
        onClickPolygon={onClickPolygon}
      />
    </Column>
  )
}
