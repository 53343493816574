import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../theme'
import { IconProps } from './types'

export const Frame: FC<IconProps> = (props) => {
  const theme: LightTheme = useTheme()
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.1903 12.4968C7.93657 12.2125 7.93657 11.7875 8.1903 11.5032L14.6549 4.25857C15.1219 3.73521 16 4.05954 16 4.75538L16 19.2446C16 19.9405 15.1219 20.2648 14.6549 19.7414L8.1903 12.4968Z"
        fill={props.fill || theme.colors.darkBlue}
      />
    </svg>
  )
}
