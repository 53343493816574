import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../../theme'
import { SVGIconProps } from './icons.types'

export const HighlightOff: FC<SVGIconProps> = (props) => {
  const theme: LightTheme = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00016 1.33301C4.3135 1.33301 1.3335 4.31301 1.3335 7.99967C1.3335 11.6863 4.3135 14.6663 8.00016 14.6663C11.6868 14.6663 14.6668 11.6863 14.6668 7.99967C14.6668 4.31301 11.6868 1.33301 8.00016 1.33301ZM9.72683 5.33301L8.00016 7.05967L6.2735 5.33301L5.3335 6.27301L7.06016 7.99967L5.3335 9.72634L6.2735 10.6663L8.00016 8.93967L9.72683 10.6663L10.6668 9.72634L8.94016 7.99967L10.6668 6.27301L9.72683 5.33301ZM2.66683 7.99967C2.66683 10.9397 5.06016 13.333 8.00016 13.333C10.9402 13.333 13.3335 10.9397 13.3335 7.99967C13.3335 5.05967 10.9402 2.66634 8.00016 2.66634C5.06016 2.66634 2.66683 5.05967 2.66683 7.99967Z"
        fill={props.fill || theme.colors.red}
      />
    </svg>
  )
}
