import React, { ForwardedRef, forwardRef } from 'react'
import { useTheme } from 'react-jss'

import { useForwardedRef, useOutsideAlerter } from '../../../hooks'

import { LightTheme } from '../../theme'
import { Column } from '../../column'

import { DropdownItem, DropdownItemData } from '../dropdown-item'

import { ItemsListProps, PositionDropdown } from './items-list.types'
import { useStyle } from './items-list.styles'

export const ItemsList = forwardRef(
  <T extends string>(
    {
      className = '',
      open,
      active,
      data,
      width,
      height,
      position = PositionDropdown.BOTTOM,
      isScrollable,
      onChange,
      onOpen
    }: ItemsListProps<T>,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const itemsRef = useForwardedRef<HTMLDivElement>(ref)
    const usualRef = isScrollable ? null : itemsRef

    const theme: LightTheme = useTheme()
    const classes = useStyle({
      width,
      height,
      open,
      isScrollable,
      theme
    })

    const handleOnChange = (value: DropdownItemData<T>) => {
      const { onClick } = value
      const isSelected = String(active) === value.id
      if (onChange && !isSelected) {
        onChange(value)
      }

      if (onClick) {
        onClick(value)
      }
    }

    useOutsideAlerter(itemsRef, open, onOpen(false))

    return (
      <Column
        justifyContent="flex-start"
        className={`${className} ${classes.itemsContainer} ${classes[position]}`}
        ref={usualRef}
      >
        {data.map((item) => {
          const activeItem = String(active) === String(item.id)
          return (
            <DropdownItem
              key={`dropdown_${item.id}`}
              item={item}
              disabled={activeItem}
              onClick={handleOnChange}
            />
          )
        })}
      </Column>
    )
  }
)
