import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

import { RoundColorIconStyleProps } from './round-color-icon.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    background: ({ color }: RoundColorIconStyleProps) =>
      `${theme.colors[color]}33`,
    borderRadius: '50%',
    height: ({ size, height }: RoundColorIconStyleProps) =>
      size || height || 24,
    width: ({ size, width }: RoundColorIconStyleProps) => size || width || 24
  }
}))
